import React, { Component } from "react";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faRandom,
	faEdit,
	faWarning,
	faArrowAltCircleLeft,
	faArrowAltCircleRight,
	faArrowLeft,
	faBars,
	faCalculator,
	faCartArrowDown,
	faCartPlus,
	faCode,
	faCodeBranch,
	faCoins,
	faComment,
	faCommentAlt,
	faCommentSlash,
	faCross,
	faDatabase,
	faDice,
	faDollarSign,
	faDownload,
	faEnvelope,
	faEnvelopeOpenText,
	faEquals,
	faExternalLinkAlt,
	faEye,
	faFile,
	faFileImage,
	faGreaterThanEqual,
	faGripVertical,
	faHashtag,
	faHourglass,
	faHourglassEnd,
	faLongArrowAltLeft,
	faMoneyBill,
	faMoneyBillAlt,
	faMouse,
	faMousePointer,
	faPaperPlane,
	faPercent,
	faQuestion,
	faQuestionCircle,
	faReply,
	faReplyAll,
	faShoppingBasket,
	faShoppingCart,
	faSortNumericDown,
	faTextHeight,
	faTimes,
	faTrash,
	faUser,
	faUserAltSlash,
	faUserFriends,
	faUserMinus,
	faUserPlus,
	faUsers,
	faUserSlash,
	faUsersSlash,
	faUserTag,
	faUserCog,
	faUserTimes,
	faLock,
	faMapPin,
	faStore,
	faTabletAlt,
	faExclamation,
	faExclamationTriangle,
	faFingerprint,
	faTrashAlt,
	faBullhorn,
	faArrowAltCircleDown,
	faArrowsAlt,
	faHourglassHalf,
	faSmile,
	faFrown,
	faSave,
	faThumbsUp,
	faStickyNote
} from "@fortawesome/free-solid-svg-icons";
import { faTable } from "@fortawesome/free-solid-svg-icons";

import CommandBuilderObject from "./CommandBuilderObject";
import { CustomCommandHelper } from "../elements/CustomCommandHelper";
import CommandTemplatesModal from "../elements/CommandTemplatesModal";
import { TEMPLATES, ACTION_TYPES, CUSTOM_EVENTS } from "../../../variables";
import CommandBuilderTemplate from "./CommandBuilderTemplate";
import buildExistingCommand from "../commandbuilder/buildExistingCommand";
import { setCommandSaveFailed, hideAside, setElements, setSelected, setBotModule, saveBot, setBotSettings, setBuilderIndex } from "../../../actions";
import ReactGA from "react-ga";
import CustomEventSelector from "../elements/CustomEventSelector";
import { faPinterest, faReplyd } from "@fortawesome/free-brands-svg-icons";
import MarketCommands from "./MarketCommands";
import BlockTemplates from "./BlockTemplates";
import { isMobile } from "react-device-detect";

const ASIDE_ACTION_TYPES = [
	{
		name: "Advanced Message",
		options: [
			{ icon: faReply, value: "advanced_message", title: "Send or Edit a Message", label: "Send or edit a message with optional buttons and select menus." },
			{ icon: faEdit, value: "edit_component", title: "Edit a Button or Select Menu", label: "Edit a button or select menu in a message" }
		]
	},
	{
		name: "Reply Actions (Old)",
		options: [
			{ icon: faReply, value: "plain_text", title: "Plain Text Reply", label: "Bot replies with a plain text response" },

			{ icon: faCodeBranch, title: "Embed Reply", value: "embed", label: "Bot replies with an embed response" },
			{ icon: faReplyAll, title: "Random Reply", value: "random_response", label: "Bot responds with a random response" },
			{ icon: faPaperPlane, title: "Send a message to a Channel", value: "targeted_response", label: "Bot sends a message to a specific channel", disabled: false },
			{ icon: faEnvelope, title: "Direct Message a User", value: "dm_response", label: "Bot sends a direct message to a member", disabled: false },
			{ icon: faMousePointer, title: "Send a Message with attached Buttons", value: "button_response", label: "Add buttons and button actions", button: false },
			{ icon: faBars, title: "Send a Message with a Select Menu", value: "select_menu", label: "Create a select menu with attached actions", button: false },

			// {value:"plain_text_channel",label:"Bot waits x (amount of time) before next action (COMING SOON)", disabled:true},
		]
	},
	{
		name: "Message Actions",
		options: [
			{ icon: faTable, title: "Send a Form", value: "modal", label: "Send a form or modal and wait for a response", button: false },

			{ icon: faTrash, title: "Delete a Message", value: "delete_message", label: "Delete a message or reply", disabled: false },
			{ icon: faRandom, title: "Publish a Message", value: "crossport_message", label: "Publish a message sent in an announcement channel", button: false },
			{ icon: faThumbsUp, title: "React to a Message", value: "react_message", label: "React to a message", button: false },
			{ icon: faMapPin, title: "Pin a Message", value: "pin_message", label: "Pin a message or reply sent in this command", disabled: false },
		]
	},
	{
		name: "Custom Variable Actions",
		options: [
			{ icon: faEquals, title: "Set Variable", value: "set_var", label: "Set the value of a custom variable", button: false },
			{ icon: faCalculator, title: "Run Equation on Variable", value: "math_var", label: "Run an equation on a custom variable", button: false },
			{ icon: faTimes, title: "Delete Variable", value: "math_var", label: "Delete the value of a custom variable", button: false }
		]
	},
	{
		name: "API Actions",
		options: [
			{ icon: faCode, title: "Send an API Request", value: "api_action", label: "Send a HTTP request to an external API", button: false },
			{ icon: faCode, title: "Execute an IFTTT Trigger", value: "ifttt_action", label: "Execute an IFTTT trigger and any associated applets", button: false }
		]
	},

	{
		name: "Loop Actions",
		options: [
			{ icon: faHourglassEnd, title: "Run a Loop", value: "loop", label: "Loop through actions and conditions a set amount of times" },
			{ icon: faHourglassHalf, title: "Stop a Loop", value: "break_loop", label: "Break out of and stop any active loop" }
		]
	},

	// if (action_config.type === "voice_join") await voice_join(components, action_config, bot, handling, interaction, options, config, customInteractionOptions);
	// if (action_config.type === "voice_kick") await voice_kick(components, action_config, bot, handling, interaction, options, config, customInteractionOptions);
	// if (action_config.type === "voice_leave") await voice_leave(components, action_config, bot, handling, interaction, options, config, customInteractionOptions);
	// if (action_config.type === "voice_move") await voice_move(components, action_config, bot, handling, interaction, options, config, customInteractionOptions);
	// if (action_config.type === "voice_mute") await voice_mute(components, action_config, bot, handling, interaction, options, config, customInteractionOptions);
	// if (action_config.type === "voice_deafen") await voice_deafen(components, action_config, bot, handling, interaction, options, config, customInteractionOptions);

	{
		name: "Voice Actions",
		options: [
			{ icon: faUserPlus, title: "Join a Voice Channel", value: "voice_join", label: "Join a voice channel" },
			{ icon: faUserMinus, title: "Leave a Voice Channel", value: "voice_leave", label: "Leave a voice channel" },
			{ icon: faUserFriends, title: "Voice Move a Member", value: "voice_move", label: "Move a member to another voice channel" },
			{ icon: faUserSlash, title: "Voice Kick a Member", value: "voice_kick", label: "Kick a member from a voice channel" },
			{ icon: faUserTimes, title: "Mute or Unmute a Member", value: "voice_mute", label: "Mute or unmute a member in a voice channel" },
			{ icon: faUserAltSlash, title: "Deafen or Undeafen a Member", value: "voice_deafen", label: "Deafen or undeafen a member in a voice channel" }
		]
	},
	{
		name: "Role Actions",
		options: [
			{ icon: faUserPlus, title: "Add Roles", value: "role_add", label: "Adds one or more roles to a user" },
			{ icon: faUserMinus, title: "Remove Roles", value: "role_remove", label: "Removes one or more roles from a user" },
			{ icon: faUserFriends, title: "Create a role", value: "create_role", label: "Creates a role in the server" },
			{ icon: faUsersSlash, title: "Delete a role", value: "delete_role", label: "Deletes a role in the server" },
			{ icon: faUserCog, title: "Edit a Role", value: "Edit a Role", label: "Edits a role in the server" }
		]
	},
	// {
	//     name: "Economy Actions",
	//     options: [
	//         { icon: faMoneyBill, title: "Add Currency", value: "add_currency", label: "Adds currency to a users balance" },
	//         { icon: faMoneyBillAlt, title: "Remove Currency", value: "remove_currency", label: "Removes currency from a users balance" },
	//         { icon: faCartPlus, title: "Add Item", value: "add_item", label: "Adds an item to a users inventory" },
	//         { icon: faCartArrowDown, title: "Remove Item", value: "remove_item", label: "Removes an item from a users inventory" },
	//     ]
	// },
	{
		name: "Channel Actions",
		options: [
			{ icon: faComment, value: "channel_create", title: "Create a Channel", label: "Creates a channel in the server" },
			{ icon: faEdit, value: "channel_edit", title: "Edit a Channel", label: "Edits a channel in the server" },
			{ icon: faCommentSlash, value: "channel_delete", title: "Delete a Channel", label: "Deletes a channel in the server" }
		]
	},
	{
		name: "Thread and Forum Actions",
		options: [
			{ icon: faComment, value: "thread_create", title: "Create a Thread", label: "Create a public or private thread in the server" },
			{ icon: faEdit, value: "thread_edit", title: "Edit a Thread", label: "Edit a public or private thread in the server" },
			{ icon: faCommentSlash, value: "thread_delete", title: "Delete a Thread", label: "Delete a public or private thread in the server" }
		]
	},
	{
		name: "Server Actions",
		options: [
			{ icon: faUserSlash, value: "kick_action", title: "Kick a Member", label: "Kick a member from the server" },
			{ icon: faUserTimes, value: "ban_action", title: "Ban a Member", label: "Ban a member from the server" },
			{ icon: faHourglassEnd, value: "timeout_action", title: "Timeout a Member", label: "Timeout a member in the server" },
			{ icon: faEdit, value: "change_nickname", title: "Change a Members Nickname", label: "Change a member nickname in the server" },
			{ icon: faTrashAlt, value: "purge_messages", title: "Purge Messages", label: "Purge messages in a channel" },
			{ icon: faUserPlus, value: "create_invite", title: "Create Server Invite", label: "Create an invite for the server" }
		]
	},
	// {
	//     name: "Emoji Actions",
	//     options: [
	//         { icon: faSmile, value: "create_emoji", title: "Create an Emoji", label: "Create an emoji in a server" },
	//         { icon: faFrown, value: "delete_emoji", title: "Delete an Emoji", label: "Delete an emoji in a server" },
	//     ]
	// },

	{
		name: "Other Actions",
		options: [
			{ icon: faHourglass, title: "Wait before running another action", value: "wait_action", label: "Wait before executing another action" },
			{ icon: faTextHeight, title: "Manipulate some text", value: "string_manipulation", label: "Manipulate and run functions on provided text" },
			{ icon: faExclamation, title: "Send an error log message", value: "send_error_message", label: "Trigger an error message" },
			{ icon: faFingerprint, title: "Set a unique variable", value: "set_unique_variable", label: "Set a unique variable for every execution of this command or event." },
			{ icon: faBullhorn, title: "Change the Bot's Status", value: "status_change", label: "Change the bot's status or presence" },
			// Note action
			// { icon: faStickyNote, title: "Note", value: "note_action", label: "Write a note in the tree", type: "note"  }
		]
	}

	// {
	//     name:"Reaction Actions",
	//     options:[
	//         {value:"Command Reaction",label:"Adds one or more reactions to the message that triggered the command. (COMING SOON)",disabled:true},
	//     ]
	// },
];

export class CommandBuilderAside extends Component {
	constructor(props) {
		super(props);

		this.state = {
			menu: "actions",
			showHelper: false,
			showCommandTemplates: false,
			menuFilter: "",
			hidden: true,
			mobileAsideOpen: false
		};
	}

	componentDidMount(prevProps, prevState) {
		if (this.props.mode == "event") {
			this.setState({ menu: "actions" });
		}
	}

	renderOptions = () => {
		if (this.props.module_id != null && !this.props.premium) {
			return (
				<div className="command-builder-objects flex justify-center text-center p-4 ml-[-20px]">
					<div className="flex align-center flex-col text-center justify-center">
						<FontAwesomeIcon icon={faExclamationTriangle} color={"#9d9b9b"} className="text-[50px] text-center mb-2" />
						<span className="text-[#9d9b9b] font-bold text-lg">
							This {this.props.mode} is part of a module. Only premium users can edit the logic of module {this.props.mode}s.
						</span>
					</div>
				</div>
			);
		} else if (this.state.menu == "options") {
			return (
				<div className="command-builder-objects">
					{/* <CommandBuilderObject
						type="option"
						value={"text"}
						title={"Text Option"}
						description={"Add a text option to your command."}
						icon={faTextHeight}
						onMobileClose={this.props.onMobileClose}
					/> */}
					<CommandBuilderObject disabled={this.props.mode == "event"} color="option" type="option" title={"Text"} description={"A text option"} icon={faSortNumericDown} onMobileClose={this.props.onMobileClose} />
					<CommandBuilderObject disabled={this.props.mode == "event"} color="option" type="option" title={"Number"} description={"A number option"} icon={faSortNumericDown} onMobileClose={this.props.onMobileClose} />
					<CommandBuilderObject disabled={this.props.mode == "event"} color="option" type="option" title={"User"} description={"Select a member from the server"} icon={faUser} onMobileClose={this.props.onMobileClose} />
					<CommandBuilderObject disabled={this.props.mode == "event"} color="option" type="option" title={"Channel"} description={"Select a channel from the server"} icon={faHashtag} onMobileClose={this.props.onMobileClose} />
					<CommandBuilderObject disabled={this.props.mode == "event"} color="option" type="option" title={"Role"} description={"Select a role from the server"} icon={faUsers} onMobileClose={this.props.onMobileClose} />
					<CommandBuilderObject disabled={this.props.mode == "event"} color="option" type="option" title={"Choice"} description={"A True or False option"} icon={faQuestion} onMobileClose={this.props.onMobileClose} />
					<CommandBuilderObject disabled={this.props.mode == "event"} color="option" type="option" title={"Attachment"} description={"An attachment option"} icon={faFile} onMobileClose={this.props.onMobileClose} />
				</div>
			);
		} else if (this.state.menu == "actions") {
			var actions = [];

			var action_types = [...ASIDE_ACTION_TYPES];

			var action_options = [];

			action_types.forEach((type) => {
				var action_options = [];
				if (type.name == "Economy Actions" && !this.props.bot.commands?.economy?.enabled) return;

				if (type.name === "Reply Actions (Old)" && this.props.builder_settings?.hideOldReplyActions !== false) return;

				type.options.forEach((action) => {
					var disabled = false;

					if (action.beta != true || (action.beta == true && this.props.beta == true)) {
						if (action.title.toLowerCase().includes(this.state.menuFilter.toLowerCase()) || this.state.filter == "") {
							action_options.push(<CommandBuilderObject disabled={disabled} color="action" icon={action.icon} type="action" value={action.value} title={action.title} description={action.label} onMobileClose={this.props.onMobileClose} />);
						}
					}
				});

				// Only add the Note action if we're in Other Actions and there's no filter or "note" matches the filter
				if (type.name == "Other Actions") {
					const noteTitle = "Note";
					if (noteTitle.toLowerCase().includes(this.state.menuFilter.toLowerCase()) || this.state.menuFilter == "") {
						action_options.push(<CommandBuilderObject color="action" icon={faStickyNote} type="note" value="note_action" title={noteTitle} description={"Write a note in the tree"} onMobileClose={this.props.onMobileClose} />);
					}
				}

				if (action_options.length > 0) {
					actions.push(<div className="custom-command-sidebar-label">{type.name}</div>);
					action_options.forEach((action) => {
						actions.push(action);
					});
				}
			});
			return (
				<>
					<div className="command-builder-objects">
						<div className="actions-input" style={{ paddingRight: "20px", paddingTop: "20px" }}>
							<div className="long-input">
								<input
									type="text"
									placeholder="Search"
									value={this.state.menuFilter}
									onChange={(e) => {
										this.setState({ menuFilter: e.target.value });
									}}
								></input>
							</div>
						</div>
						{actions}
					</div>
				</>
			);
		} else if (this.state.menu == "conditions") {
			return (
				<div className="command-builder-objects">
					<CommandBuilderObject
						condition_type="comparison"
						color="condition"
						type="condition"
						value={"condition"}
						title={"Comparison Condition"}
						description={"Run actions based on the difference between two values. "}
						icon={faGreaterThanEqual}
						onMobileClose={this.props.onMobileClose}
					/>

					<CommandBuilderObject
						condition_type="chance"
						color="condition"
						type="condition"
						value={"condition"}
						title={"Chance Condition"}
						description={"Run actions based on a random chance."}
						icon={faDice}
						onMobileClose={this.props.onMobileClose}
					/>

					<CommandBuilderObject condition_type="permissions" color="condition" type="condition" value={"condition"} title={"Permissions Condition"} description={"Run actions based on the server permissions of a user."} icon={faLock} />

					<CommandBuilderObject condition_type="user" disabled={!this.props.components["User"]} color="condition" type="condition" value={"condition"} title={"User Condition"} description={`Run actions based on ${this.props.mode == "command" ? "who used the command" : "who triggered the event"}.`} icon={faUser} />
					<CommandBuilderObject condition_type="premium" color="condition" type="condition" value={"condition"} title={"Premium Check Condition"} description={"Run actions based on whether the bot is premium or not"} icon={faDollarSign} />
				</div>
			);
		} else if (this.state.menu == "templates") {
			// return <MarketCommands />;
			return <BlockTemplates />;
			// var templates = [];
			// TEMPLATES.forEach(template => {
			//     templates.push(
			//         <CommandBuilderTemplate
			//             color="template"
			//             icon={template.icon}
			//             title={template.name}
			//             description={template.description}
			//             data={template.data}
			//             importCommand={this.importCommand}
			//         />
			//     );
			// });

			// return (
			//     <div className="command-builder-objects">
			//         {templates}
			//     </div>
			// );
		}
	};

	importCommand = async (data, name) => {
		var elements = await buildExistingCommand(data);
		this.props.setBuilderIndex(null);
		this.props.setElements(elements);
		// this.props.setSelected('root')
		ReactGA.initialize("UA-122665050-1");
		ReactGA.event({
			category: "Command Template",
			label: name,
			action: "New Command Builder"
		});
	};

	renderEventSelectorModal = () => {
		var root = this.props.elements.find((e) => e.id == "root");
		var data = root.data.data;
		if (data && data.type == "") {
			return <CustomEventSelector
				open={true}
				closeModal={() => {
					// If they try to close without selecting an event type, we should select a default
					const updatedData = { ...data };
					updatedData.type = "messageCreate"; // Default event type
					this.props.updateElementData({
						data: updatedData,
						id: 'root'
					});

					const event_options = CUSTOM_EVENTS.find(event => event.value === "messageCreate");
					this.props.setBuilderMode({
						mode: "event",
						components: event_options.components
					});
					this.props.setSelected('root');
				}}
				data={data}
			/>;
		} else {
			return null;
		}
	};

	render() {
		const useMobileBuilder = this.props.builder_settings?.mobileBuilder && isMobile;

		return (
			<div className={`
				${useMobileBuilder
					? 'fixed inset-0 z-[70] transform transition-all duration-150 ease-in-out bg-black/50'
					: 'fixed z-[90] w-[400px] flex h-[calc(100%-4rem)] top-16 left-0'
				}
				${useMobileBuilder && !this.props.mobileOpen ? '-translate-x-full opacity-0 pointer-events-none' : ''}
			`}>
				{/* Mobile Overlay */}
				{useMobileBuilder && (
					<div
						className="absolute inset-0 bg-black/50 transition-opacity duration-150"
						onClick={() => this.props.onMobileClose()}
					/>
				)}

				{/* Main Content Panel */}
				<div className={`
					${useMobileBuilder
						? 'mr-auto w-[85%] max-w-[380px] transition-transform duration-150 h-full overflow-hidden flex flex-col'
						: 'w-[400px] min-w-[400px] h-full'
					}
					flex flex-col bg-[#2f3136] dark:bg-[#313338] border-r border-[#1e1f22]
					relative z-[71]
				`}>
					{/* Close Button for Mobile - Now in top right */}
					{useMobileBuilder && (
						<button
							onClick={() => this.props.onMobileClose()}
							className="absolute right-4 top-4 p-2 text-[#b5bac1] hover:text-white transition-colors"
						>
							<FontAwesomeIcon icon={faTimes} className="w-6 h-6" />
						</button>
					)}

					{/* Header Section */}
					<div className="px-6 pt-4 pb-4">
						<h3 className="text-[26px] font-bold text-white flex items-center gap-2">
							{this.state.menu == "templates" ? `Block Templates` : "Blocks"}
							<button
								onClick={() => this.setState({ showHelper: true })}
								className="text-[#b5bac1] hover:text-white transition-colors"
							>
								<FontAwesomeIcon icon={faQuestionCircle} />
							</button>
						</h3>

						{/* Description text */}
						<div className="mt-3 text-[15px] text-[#9d9b9b]">
							{this.state.menu == "templates" ? (
								<div className="space-y-2">
									<p>{useMobileBuilder ? 'Click on a saved block template to add it to your tree.' : 'Click and drag and drop a saved block template from below into your tree.'}</p>
									<p>
										You can save new block templates by selecting multiple blocks by holding{" "}
										<strong className="text-[#f45142]">Shift</strong> and then clicking the{" "}
										<strong className="text-[#f45142]">Save Template</strong> button.
									</p>
								</div>
							) : (
								<p className="text-[16px] font-semibold text-[#9d9b9b]">
									{useMobileBuilder ? 'Click on' : 'Drag and drop'}{" "}
									<span style={{ color: this.props.builder_settings?.optionColor || 'rgb(141, 14, 171)' }}>Options</span>,{" "}
									<span style={{ color: this.props.builder_settings?.actionColor || 'rgb(53, 141, 235)' }}>Actions</span>{" "}
									and{" "}
									<span style={{ color: this.props.builder_settings?.conditionColor || 'rgb(40, 167, 69)' }}>Conditions</span>{" "}
									to add them to your {this.props.mode == "event" ? "event" : "command"}. Connect the corresponding colors to create your command flow.
								</p>
							)}
						</div>
					</div>

					{/* Navigation Tabs */}
					{this.state.menu != "templates" && (this.props.module_id == null || this.props.premium) ? (
						<div className="!px-3 border-b border-[#1e1f22]">
							<div className="!flex">
								{["Options", "Actions", "Conditions"].map((tab) => (
									<button
										key={tab}
										onClick={() => this.setState({ menu: tab.toLowerCase() })}
										className={`
											!px-6 !py-3.5 text-[15px] font-semibold transition-all relative flex-1
											${this.state.menu === tab.toLowerCase()
												? 'text-white border-b-2 border-[#f45142] bg-[#36393f]'
												: 'text-[#b5bac1] hover:text-white hover:bg-[#36393f] border-b-2 border-transparent'
											}
										`}
									>
										{tab}
									</button>
								))}
							</div>
						</div>
					) : (
						<div className="w-full border-t border-[#1e1f22] opacity-20 my-4" />
					)}

					{/* Content Area - Adjusted padding */}
					<div className={`
						overflow-y-auto custom-scrollbar px-3
						${useMobileBuilder ? 'flex-1 min-h-0' : 'flex-1'}
					`}>
						{this.renderOptions()}
					</div>

					{/* Bottom Button - Adjusted padding */}
					<div className="p-3 border-t border-[#1e1f22]">
						<button
							className="w-full px-4 py-2 bg-[#5865f2] hover:bg-[#4752c4] text-white rounded-md transition-colors flex items-center justify-center gap-2 font-medium"
							onClick={() => this.setState({ menu: this.state.menu != "templates" ? "templates" : "options" })}
						>
							<FontAwesomeIcon icon={this.state.menu != "templates" ? faSave : faExternalLinkAlt} />
							{this.state.menu != "templates" ? "Block Templates" : `${this.props.mode == "event" ? "Event" : "Command"} Blocks`}
						</button>
					</div>
				</div>

				{/* Modals */}
				{this.state.showHelper && (
					<CustomCommandHelper closeModal={() => this.setState({ showHelper: false })} />
				)}
				{this.state.showCommandTemplates && (
					<CommandTemplatesModal closeModal={() => this.setState({ showCommandTemplates: false })} />
				)}
				{this.renderEventSelectorModal()}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	beta: state.data.beta,
	hidden: state.builder.hidden,
	mode: state.builder.mode,
	components: state.builder.components,
	elements: state.builder.elements,
	module_id: state.builder.module_id,
	premium: state.data.premium,
	bot: state.data.bot,
	user: state.data.user,
	builder_settings: state.data.user.builder_settings
});

const mapDispatchToProps = {
	setBuilderIndex,
	setElements,
	setSelected,
	hideAside
};

export default connect(mapStateToProps, mapDispatchToProps)(CommandBuilderAside);
