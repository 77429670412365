import React, { Component } from "react";
import { connect } from "react-redux";
import DocsLink from "../../elements/DocsLink";
import { updateElementData, setHighlightAction, setHighlightComponent } from "../../../../actions";
import Select from "../../elements/Select";
import VariableTextInput from "../VariableTextInput";
import { renderResponseActionOptions } from "../builderUtils";
import { getOutgoers } from "react-flow-renderer";
import splitElements from "../splitElements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import RoleAdd from "../../elements/RoleAdd";
import Toggle from "../../elements/Toggle";

const STYLES = [
    { value: "PRIMARY", label: "Blue" },
    { value: "SECONDARY", label: "Gray" },
    { value: "SUCCESS", label: "Green" },
    { value: "DANGER", label: "Red" },
    { value: "LINK", label: "Link" }
];

export class EditComponent extends Component {
    componentDidMount() {
        if (!this.props.data) {
            const data = {
                type: "edit_component",
                edits: [],
                action_id: "",
                target: {
                    editId: ""
                },
                validated: false
            };

            // Find the closest advMessage action and set it
            const closestAdvMessage = this.findClosestAdvMessage();
            if (closestAdvMessage) {
                data.action_id = closestAdvMessage.id;
                data.target.editId = closestAdvMessage.data.data.editId;
                this.props.setHighlightAction(closestAdvMessage.id);
            }

            this.props.updateElementData({
                data: data,
                id: this.props.id
            });
        } else {
            // Clean up any edits referencing deleted components
            this.cleanupDeletedComponents();

            // Highlight Selected Message and components
            this.props.setHighlightAction(this.props.data.action_id);
            var component_ids = this.props.data.edits.map(edit => edit.component_id);
            this.props.setHighlightComponent(component_ids);
        }
    }

    findClosestAdvMessage = () => {
        const advMessageActions = this.props.actions.filter(action =>
            action?.data?.data?.type === "advanced_message" && action.id !== this.props.id
        );

        if (advMessageActions.length > 0) {
            const closestAction = advMessageActions[0];

            // Add editId if it doesn't exist
            if (!("editId" in closestAction.data.data)) {
                var data = { ...closestAction.data.data };
                data.editId = `${Date.now()}_${Math.floor(Math.random() * 100000)}`;
                this.props.updateElementData({
                    data: data,
                    id: closestAction.id
                });
                closestAction.data.data.editId = data.editId;
            }

            return closestAction;
        }

        return null;
    };

    componentDidUpdate(prevProps, prevState) {
        // If current data is undefined but we have a valid ID, initialize it
        if (this.props.data === undefined && this.props.id) {
            this.props.updateElementData({
                data: {
                    type: "edit_component",
                    edits: [],
                    action_id: "",
                    target: {
                        editId: ""
                    },
                    validated: false
                },
                id: this.props.id
            });
            return;
        }

        // Don't proceed if we don't have valid data
        if (!this.props.data || !prevProps.data) {
            return;
        }

        // // Check if any referenced components have been deleted
        const needsCleanup = this.props.data.edits?.some(edit => {
            const componentExists = this.props.elements.some(el => el.id === edit.component_id);
            return !componentExists;
        });

        console.log(needsCleanup, this.props.data, "NEEDS CLEANUP");

        if (needsCleanup) {
            this.cleanupDeletedComponents();
        }
    }

    cleanupDeletedComponents = () => {
        if (!this.props.data.edits) return;

        // Filter out edits that reference deleted components
        const validEdits = this.props.data.edits.filter(edit => {
            const componentExists = this.props.elements.some(el => el.id === edit.component_id);
            return componentExists;
        });

        // If we removed any edits, update the data
        if (validEdits.length !== this.props.data.edits.length) {
            const newData = {
                ...this.props.data,
                edits: validEdits
            };

            // If we have no valid edits left, reset to initial state
            if (validEdits.length === 0) {
                newData.edits = [];
            }

            this.props.updateElementData({
                data: newData,
                id: this.props.id
            });

            // Update component highlights
            const componentIds = validEdits
                .map(edit => edit.component_id)
                .filter(id => id);
            this.props.setHighlightComponent(componentIds);
        }
    };

    validateAction = () => {
        console.log(this.props.data, 'DATA HERE');
        // Check if we have a target message selected
        if (!this.props.data.action_id) {
            return false;
        }

        // Check if we have at least one component selected and it's valid
        if (!this.props.data.edits || this.props.data.edits.length === 0) {
            return false;
        }

        // Verify each edit has required fields
        for (const edit of this.props.data.edits) {
            if (!edit.component_id || !edit.editComponentId) {
                return false;
            }

            // Find the component
            const component = this.props.elements.find(el => el.id === edit.component_id);
            if (!component) {
                return false;
            }

            // Validate button settings
            if (component.type === "advMessageButton" && edit.button) {
                // Button must either have a label value set (can be empty string) or be marked for removal
                if (!('label' in edit.button) && !edit.button.remove) {
                    return false;
                }
            }

            // Validate select menu settings
            if (component.type === "advMessageSelectMenu" && edit.select_menu) {
                // Menu must either have a placeholder value set (can be empty string) or be marked for removal
                if (!('placeholder' in edit.select_menu) && !edit.select_menu.remove) {
                    return false;
                }

                // If it's a basic menu and not being removed, validate options
                if (component.data.data.menu_type === "basic" && !edit.select_menu.remove) {
                    // Check if there are any options and they're not all hidden
                    const options = edit.select_menu.basic_options || [];
                    const visibleOptions = options.filter(opt => !opt.hidden);
                    if (options.length === 0 || visibleOptions.length === 0) {
                        return false;
                    }

                    // Validate each option has required fields
                    for (const option of options) {
                        if (!option.label) {
                            return false;
                        }
                    }
                }
            }
        }

        return true;
    };

    componentWillUnmount() {
        this.props.setHighlightAction(null);
        this.props.setHighlightComponent([]);
    }

    validateTarget = (target) => {
        return target.editId.trim() !== '';
    };

    updateData = (key, value) => {
        var data = { ...this.props.data };

        // Handle nested paths
        if (key.includes('.')) {
            const [parent, child] = key.split('.');
            data[parent] = { ...data[parent], [child]: value };
        } else {
            data[key] = value;
        }

        // Validate after data changes
        const isValid = this.validateAction();
        data.validated = isValid;

        this.props.updateElementData({
            data: data,
            id: this.props.id
        });
    };

    selectChange = (value) => {
        if (!value) {
            this.props.setHighlightAction(null);
            this.props.setHighlightComponent([]);
            this.updateData("action_id", "");
            this.updateData("target.editId", "");
            return;
        }


        const action = this.props.actions.find((action) => action.id === value);
        if (!action) return;

        // Prepare all updates in a single object to avoid multiple re-renders
        const updates = {
            action_id: value,
            target: { editId: "" },
            edits: [{ editComponentId: "", component_id: "" }]
        };

        if (!("editId" in action.data.data)) {
            const editId = `${Date.now()}_${Math.floor(Math.random() * 100000)}`;
            // Update the action separately
            this.props.updateElementData({
                data: { ...action.data.data, editId },
                id: value
            });
            updates.target.editId = editId;
        } else {
            updates.target.editId = action.data.data.editId;
        }

        // Update all data at once
        this.props.updateElementData({
            data: { ...this.props.data, ...updates },
            id: this.props.id
        });

        this.props.setHighlightAction(value);
        this.props.setHighlightComponent([]);
    };

    validateAndHighlightComponent = (componentId) => {
        const selectedComponent = this.props.elements.find(el => el.id === componentId);
        const selectedAction = this.props.actions.find(action => action.id === this.props.data.action_id);

        if (selectedComponent && selectedAction) {
            const elementChildren = getOutgoers(selectedAction, splitElements(this.props.actions).nodes, splitElements(this.props.actions).edges);
            const isChild = elementChildren.some(node => node.id === componentId);

            if (isChild && (selectedComponent.type === "advMessageButton" || selectedComponent.type === "advMessageSelectMenu")) {
                this.props.setHighlightComponent([componentId]);

                // Ensure editComponentId is synced
                if (!this.props.data.edits?.[0]?.editComponentId && selectedComponent.data.data.editComponentId) {
                    this.updateData("edits", [{ ...this.props.data.edits[0], editComponentId: selectedComponent.data.data.editComponentId }]);
                }
                return true;
            }
        }

        // Clear selection if validation fails
        this.updateData("edits", [{ editComponentId: "", component_id: "" }]);
        this.props.setHighlightComponent([]);
        return false;
    };

    componentSelectChange = (value) => {
        // Allow deselection
        console.log(value, "VALUE");
        if (!value) {
            this.props.setHighlightComponent([]);
            this.updateData("edits", [{ editComponentId: "", component_id: "" }]);
            return;
        }

        const selectedComponent = this.props.elements.find(el => el.id === value);
        if (!selectedComponent) return;

        // Generate or use existing editComponentId
        let componentId = selectedComponent.data.data.editComponentId;
        if (!componentId) {
            componentId = `${Date.now()}_${Math.floor(Math.random() * 100000)}`;

            // Update the component with the new editComponentId
            const componentData = { ...selectedComponent.data.data };
            componentData.editComponentId = componentId;

            this.props.updateElementData({
                data: componentData,
                id: selectedComponent.id
            });
        }

        var data = { ...this.props.data };
        data.edits = [{ ...data.edits[0], component_id: value, editComponentId: componentId }];

        // If this is a select menu, inherit its properties
        if (selectedComponent.type === "advMessageSelectMenu") {
            const menuData = selectedComponent.data.data;
            data.edits = [{
                ...data.edits[0],
                select_menu: {
                    placeholder: menuData.placeholder || "",
                    min_values: menuData.minOptions || "1",
                    max_values: menuData.maxOptions || "1",
                    disabled: menuData.disabled || "",
                    basic_options: menuData.menu_type === "basic" ? [...(menuData.basic_options || [])] : [],
                    reset_options: false, // Don't reset options by default when inheriting
                    multiselect: menuData.multiselect || false, // Inherit multiselect setting
                },
            }];

            // Add channel-specific settings if it's a channel select menu
            if (menuData.menu_type === "channel") {
                data.edits = [{
                    ...data.edits[0],
                    select_menu: {
                        ...data.edits[0].select_menu,
                        channel_types: [...(menuData.channel_types || [])],
                        additional_channel_types: menuData.additional_channel_types || "",
                        default_channels: [...(menuData.default_channels || [])],
                        additional_channels: menuData.additional_channels || "",
                        reset_channel_types: false,
                        reset_default_channels: false
                    },
                }];
            }

            // Add role-specific settings if it's a role select menu
            if (menuData.menu_type === "role") {
                data.edits = [{
                    ...data.edits[0],
                    select_menu: {
                        ...data.edits[0].select_menu,
                        default_roles: [...(menuData.default_roles || [])],
                        additional_roles: menuData.additional_roles || "",
                    },
                }];
            }

            // Add user-specific settings if it's a user select menu
            if (menuData.menu_type === "user") {
                data.edits = [{
                    ...data.edits[0],
                    select_menu: {
                        ...data.edits[0].select_menu,
                        default_users: [...(menuData.default_users || [])],
                        additional_users: menuData.additional_users || "",
                    },
                }];
            }

            // Add channel-specific settings if it's a mentionable select menu
            if (menuData.menu_type === "mentionable") {
                data.edits = [{
                    ...data.edits[0],
                    select_menu: {
                        ...data.edits[0].select_menu,
                        default_roles: [...(menuData.default_roles || [])],
                        additional_roles: menuData.additional_roles || "",
                        additional_users: menuData.additional_users || "",
                    },
                }];
            }
        }

        this.props.updateElementData({
            data: data,
            id: this.props.id
        });

        this.props.setHighlightComponent([value]);
    };

    renderAdvMessageOptions = () => {
        const advMessageActions = this.props.actions.filter(action =>
            action?.data?.data?.type === "advanced_message" && action.id !== this.props.id
        ).map(action => ({
            value: action.id,
            label: `Advanced Message ${action?.data?.data?.command_label ? `- ${action?.data?.data?.command_label}` : `- ${action?.id}`}`
        }));

        // Always add empty option to allow deselecting
        advMessageActions.unshift({ value: "", label: "Select a message" });

        return advMessageActions;
    };

    renderComponentOptions = () => {
        if (!this.props.data.action_id) return [{ value: "", label: "Select a component" }];

        const selectedAction = this.props.actions.find(action => action.id === this.props.data.action_id);
        if (!selectedAction) return [{ value: "", label: "Select a component" }];

        var elementChildren = getOutgoers(selectedAction, splitElements(this.props.actions).nodes, splitElements(this.props.actions).edges);

        const options = [{ value: "", label: "Select a component" }];
        elementChildren.forEach(node => {
            if (node.type === "advMessageButton") {
                const label = node.data.data.label || "Unlabeled Button";
                options.push({
                    value: node.id,
                    label: `Button: ${label}`
                });
            } else if (node.type === "advMessageSelectMenu") {
                const placeholder = node.data.data.placeholder || "Unnamed Select Menu";
                const type = node.data.data.menu_type || "basic";
                options.push({
                    value: node.id,
                    label: `${type.charAt(0).toUpperCase() + type.slice(1)} Select Menu: ${placeholder}`
                });
            }
        });

        return options;
    };

    renderButtonSettings = (edit, index) => {
        // Add null check for edit parameter
        if (!edit && !this.props.data?.edits?.[0]) return null;

        // Use the passed edit object or fall back to the first edit in single mode
        const currentEdit = edit || this.props.data.edits[0];
        if (!currentEdit.component_id) return null;

        // Find the selected component by component_id
        const selectedComponent = this.props.elements.find(el => el.id === currentEdit.component_id);
        if (!selectedComponent || selectedComponent.type !== "advMessageButton") return null;

        // Helper function to update a specific edit while preserving others
        const updateEditAtIndex = (updatedEdit) => {
            const currentEdits = [...(this.props.data.edits || [])];
            const editIndex = currentEdits.findIndex(e => e.component_id === currentEdit.component_id);
            if (editIndex !== -1) {
                currentEdits[editIndex] = updatedEdit;
            } else {
                currentEdits.push(updatedEdit);
            }
            this.updateData("edits", currentEdits);
        };

        // If component doesn't have an editComponentId, generate one
        if (!selectedComponent.data.data.editComponentId) {
            const componentId = `${Date.now()}_${Math.floor(Math.random() * 100000)}`;
            const componentData = { ...selectedComponent.data.data };
            componentData.editComponentId = componentId;

            this.props.updateElementData({
                data: componentData,
                id: selectedComponent.id
            });

            // Update our action's editComponentId
            updateEditAtIndex({ ...currentEdit, editComponentId: componentId });
        } else if (!currentEdit.editComponentId) {
            // If component has editComponentId but our action doesn't, sync it
            updateEditAtIndex({ ...currentEdit, editComponentId: selectedComponent.data.data.editComponentId });
        }

        // Get settings from our action's data, falling back to the button's current values
        const button = currentEdit.button || {};

        // Filter out LINK from styles for non-link buttons
        const availableStyles = STYLES.filter(style => style.value !== "LINK");

        return (
            <div className="mt-15">
                <hr style={{ borderTop: "1px solid #adb5bd" }} className="slash-hr" />
                <h4>Button Settings</h4>

                <div className="mb-15">
                    <h4 style={{ marginBottom: "0px" }}>
                        Button Text <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/send-a-message-with-attached-buttons#button-text" />
                    </h4>
                    <div className="section-content-header mb-15">The text of this button. All options and variables can be used.</div>
                    <VariableTextInput
                        label="Text"
                        value={button.label === undefined ? "" : button.label}
                        onChange={(value) => {
                            const updatedButton = {
                                ...button,
                                label: value
                            };
                            updateEditAtIndex({ ...currentEdit, button: updatedButton });
                        }}
                        placeholder="Enter button text"
                        required={false}
                        slash_options={this.props.options}
                    />
                </div>

                <div className="mb-15">
                    <h4 style={{ marginBottom: "0px" }}>
                        Emoji Id <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/send-a-message-with-attached-buttons#button-text" />
                    </h4>
                    <div className="section-content-header mb-15">An optional emoji id to set for this button.</div>
                    <VariableTextInput
                        label="Emoji"
                        value={button.emoji_id === undefined ? "" : button.emoji_id}
                        onChange={(value) => {
                            const updatedButton = {
                                ...button,
                                emoji_id: value
                            };
                            updateEditAtIndex({ ...currentEdit, button: updatedButton });
                        }}
                        placeholder="Enter emoji ID"
                        required={false}
                        slash_options={this.props.options}
                    />
                </div>

                <hr style={{ borderTop: "1px solid #adb5bd" }} className="slash-hr" />
                <div className="mb-15 slash-action">
                    <h4>
                        Button Style <DocsLink location={"https://docs.botghost.com/custom-commands-and-events/actions/send-a-message-with-attached-buttons#button-style"} />
                    </h4>
                    <span>The style and color of this button.</span>
                    <div className="mt-15">
                        <Select
                            value={button.style}
                            onChange={(value) => {
                                const updatedButton = {
                                    ...button,
                                    style: value
                                };
                                updateEditAtIndex({ ...currentEdit, button: updatedButton });
                            }}
                            options={availableStyles}
                        />
                    </div>
                </div>

                <div className="mb-15">
                    <h4 style={{ marginBottom: "0px" }}>Custom Button Style</h4>
                    <div className="section-content-header mb-15">Optionally enter a custom style for the button. This will override the selected style above.</div>
                    <VariableTextInput
                        label="Custom Style"
                        value={button.customStyle}
                        onChange={(value) => {
                            const updatedButton = {
                                ...button,
                                customStyle: value
                            };
                            updateEditAtIndex({ ...currentEdit, button: updatedButton });
                        }}
                        placeholder="Enter custom style (e.g. PRIMARY, SECONDARY)"
                        required={false}
                        slash_options={this.props.options}
                    />
                    <p className="text-sm text-gray-400 mt-1">
                        Enter a custom style to override the selected style. Leave blank to use the selected style above.
                    </p>
                </div>

                <hr style={{ borderTop: "1px solid #adb5bd" }} className="slash-hr" />
                <div className="mb-15">
                    <h4 style={{ marginBottom: "0px" }}>Disable Button</h4>
                    <div className="section-content-header mb-15">Set to 'true' to disable this button.</div>
                    <VariableTextInput
                        label="Disabled"
                        value={button.disabled}
                        onChange={(value) => {
                            const updatedButton = {
                                ...button,
                                disabled: value
                            };
                            updateEditAtIndex({ ...currentEdit, button: updatedButton });
                        }}
                        placeholder="true or false"
                        required={false}
                        slash_options={this.props.options}
                    />
                    <p className="text-sm text-gray-400 mt-1">
                        Enter 'true' to disable this button, or 'false' to enable it if it was previously disabled.
                    </p>
                </div>

                <div className="mb-15">
                    <h4 style={{ marginBottom: "0px" }}>Remove Button</h4>
                    <div className="section-content-header mb-15">Set to 'true' to remove this button from the message.</div>
                    <VariableTextInput
                        label="Remove"
                        value={button.remove}
                        onChange={(value) => {
                            const updatedButton = {
                                ...button,
                                remove: value
                            };
                            updateEditAtIndex({ ...currentEdit, button: updatedButton });
                        }}
                        placeholder="true or false"
                        required={false}
                        slash_options={this.props.options}
                    />
                    <p className="text-sm text-gray-400 mt-1">
                        Enter 'true' to remove this button from the message. This cannot be undone.
                    </p>
                </div>

                {/* Component Ordering */}
                <hr style={{ borderTop: "1px solid #adb5bd" }} className="slash-hr" />
                <div className="flex flex-row items-center gap-x-3 mb-15">
                    <div>
                        <h4 className="mb-2">Enable Component Ordering</h4>
                        <p>Order this button amongst other message components</p>
                    </div>
                    <Toggle
                        value={button.orderComponent || false}
                        update={(value) => {
                            const updatedButton = {
                                ...button,
                                orderComponent: value
                            };
                            updateEditAtIndex({ ...currentEdit, button: updatedButton });
                        }}
                    />
                </div>

                {button.orderComponent && (
                    <>
                        <div>
                            <div>
                                <h4 className="mb-2">Row</h4>
                                <p>Which row to place this button on. </p>
                            </div>
                            <VariableTextInput
                                label={"Row"}
                                placeholder="1"
                                slash_options={this.props.options}
                                required={false}
                                value={button.row}
                                onChange={(value) => {
                                    const updatedButton = {
                                        ...button,
                                        row: value
                                    };
                                    updateEditAtIndex({ ...currentEdit, button: updatedButton });
                                }}
                            />
                        </div>

                        <div className="mt-3">
                            <div>
                                <h4 className="mb-2">Position Weight</h4>
                                <p>Which position to place this button on the row. Buttons with a higher weight will be placed first. </p>
                            </div>
                            <VariableTextInput
                                label={"Weight"}
                                placeholder="1"
                                slash_options={this.props.options}
                                required={false}
                                value={button.weight}
                                onChange={(value) => {
                                    const updatedButton = {
                                        ...button,
                                        weight: value
                                    };
                                    updateEditAtIndex({ ...currentEdit, button: updatedButton });
                                }}
                            />
                            <p className="text-sm text-gray-500 mt-1">Note: A weight of -1 will cause the button to be ignored.</p>
                        </div>
                    </>
                )}
            </div>
        );
    };

    renderMenuSettings = (edit, index) => {
        // Add null check for edit parameter
        if (!edit && !this.props.data?.edits?.[0]) return null;

        // Use the passed edit object or fall back to the first edit in single mode
        const currentEdit = edit || this.props.data.edits[0];
        if (!currentEdit.component_id) return null;

        // Find the selected component by component_id
        const selectedComponent = this.props.elements.find(el => el.id === currentEdit.component_id);
        if (!selectedComponent || selectedComponent.type !== "advMessageSelectMenu") return null;

        // Helper function to update a specific edit while preserving others
        const updateEditAtIndex = (updatedEdit) => {
            const currentEdits = [...(this.props.data.edits || [])];
            const editIndex = currentEdits.findIndex(e => e.component_id === currentEdit.component_id);
            if (editIndex !== -1) {
                currentEdits[editIndex] = updatedEdit;
            } else {
                currentEdits.push(updatedEdit);
            }
            this.updateData("edits", currentEdits);
        };

        // If component doesn't have an editComponentId, generate one
        if (!selectedComponent.data.data.editComponentId) {
            const componentId = `${Date.now()}_${Math.floor(Math.random() * 100000)}`;
            const componentData = { ...selectedComponent.data.data };
            componentData.editComponentId = componentId;

            this.props.updateElementData({
                data: componentData,
                id: selectedComponent.id
            });

            // Update our action's editComponentId
            updateEditAtIndex({ ...currentEdit, editComponentId: componentId });
        } else if (!currentEdit.editComponentId) {
            // If component has editComponentId but our action doesn't, sync it
            updateEditAtIndex({ ...currentEdit, editComponentId: selectedComponent.data.data.editComponentId });
        }

        // Get settings from our action's data, falling back to the menu's current values
        const select_menu = currentEdit.select_menu || {};

        return (
            <div className="mt-15">
                {/* <hr style={{ borderTop: "1px solid #adb5bd" }} className="slash-hr" /> */}
                {/* <h4>Menu Settings</h4> */}



                <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd" }}></hr>

                {selectedComponent.data.data.menu_type === "basic" && (
                    <>
                        <div className="mb-15">
                            <h4>Options</h4>
                            <p>Add options for users to select from the menu. (Max 25 options)</p>
                        </div>
                        {(select_menu.basic_options || []).map((option, index) => (
                            <div key={index} className="mb-15 option-container">
                                <details className="option-details bg-darkGray">
                                    <summary className="option-summary flex items-center justify-between p-3 bg-gray-800 rounded-md cursor-pointer">
                                        <span className="font-medium text-white">Option {index + 1}: {option.label ? option.label : `Option ${index + 1}`}</span>
                                        <FontAwesomeIcon icon={faChevronDown} className="text-white" />
                                    </summary>
                                    <div className="option-content mt-3 p-4 bg-gray-700 rounded-md">
                                        <div className="mb-4">
                                            <h4 className="text-white mb-2">Label</h4>
                                            <div className="section-content-header mb-2">The text displayed for this option in the menu.</div>

                                            <VariableTextInput
                                                label="Label"
                                                value={option.label}
                                                onChange={(value) => {
                                                    const newOptions = [...(select_menu.basic_options || [])];
                                                    newOptions[index].label = value;
                                                    const updatedMenu = {
                                                        ...select_menu,
                                                        basic_options: newOptions
                                                    };
                                                    updateEditAtIndex({ ...currentEdit, select_menu: updatedMenu });
                                                }}
                                                placeholder="Option label"
                                                required={true}
                                                slash_options={this.props.options}
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <h4 className="text-white mb-2">Description</h4>
                                            <div className="section-content-header mb-2">Additional information about this option.</div>
                                            <VariableTextInput
                                                label="Description"
                                                value={option.description}
                                                onChange={(value) => {
                                                    const newOptions = [...(select_menu.basic_options || [])];
                                                    newOptions[index].description = value;
                                                    const updatedMenu = {
                                                        ...select_menu,
                                                        basic_options: newOptions
                                                    };
                                                    updateEditAtIndex({ ...currentEdit, select_menu: updatedMenu });
                                                }}
                                                placeholder="Option description"
                                                required={false}
                                                slash_options={this.props.options}
                                            />
                                        </div>

                                        <div className="mb-4">
                                            <h4 className="text-white mb-2">Value (Optional)</h4>
                                            <div className="section-content-header mb-2">
                                                The value associated with this option. If left empty, the option's label will be used as its value.
                                            </div>
                                            <VariableTextInput
                                                label="Value"
                                                value={option.value || ""}
                                                onChange={(value) => {
                                                    const newOptions = [...(select_menu.basic_options || [])];
                                                    newOptions[index].value = value;
                                                    const updatedMenu = {
                                                        ...select_menu,
                                                        basic_options: newOptions
                                                    };
                                                    updateEditAtIndex({ ...currentEdit, select_menu: updatedMenu });
                                                }}
                                                placeholder="Option value"
                                                required={false}
                                                slash_options={this.props.options}
                                            />
                                            {!option.value && (
                                                <p className="text-sm text-gray-400 mt-1">
                                                    Note: If left empty, the option's label will be used as its value.
                                                </p>
                                            )}
                                        </div>
                                        <div className="mb-4">
                                            <h4 className="text-white mb-2">Emoji ID</h4>
                                            <div className="section-content-header mb-2">Optional emoji to display next to the option.</div>
                                            <VariableTextInput
                                                label="Emoji ID (Optional)"
                                                value={option.emoji_id || ""}
                                                onChange={(value) => {
                                                    const newOptions = [...(select_menu.basic_options || [])];
                                                    newOptions[index].emoji_id = value;
                                                    const updatedMenu = {
                                                        ...select_menu,
                                                        basic_options: newOptions
                                                    };
                                                    updateEditAtIndex({ ...currentEdit, select_menu: updatedMenu });
                                                }}
                                                placeholder="Emoji ID"
                                                required={false}
                                                slash_options={this.props.options}
                                            />
                                        </div>

                                        <div className="mb-4">
                                            <h4 className="text-white mb-2">Hide Option</h4>
                                            <div className="section-content-header mb-2">
                                                When set to true, this option will be hidden and not added to the menu.
                                            </div>
                                            <VariableTextInput
                                                label="Hide Option"
                                                value={option.hidden || ""}
                                                onChange={(value) => {
                                                    const newOptions = [...(select_menu.basic_options || [])];
                                                    newOptions[index].hidden = value;
                                                    const updatedMenu = {
                                                        ...select_menu,
                                                        basic_options: newOptions
                                                    };
                                                    updateEditAtIndex({ ...currentEdit, select_menu: updatedMenu });
                                                }}
                                                placeholder="true or false"
                                                required={false}
                                                slash_options={this.props.options}
                                            />
                                            <p className="text-sm text-gray-400 mt-1">
                                                Enter 'true' to hide this option, or 'false' to show it.
                                            </p>
                                        </div>
                                        <button
                                            onClick={() => {
                                                const newOptions = (select_menu.basic_options || []).filter((_, i) => i !== index);
                                                const updatedMenu = {
                                                    ...select_menu,
                                                    basic_options: newOptions
                                                };
                                                updateEditAtIndex({ ...currentEdit, select_menu: updatedMenu });
                                            }}
                                            className="bg-red hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                                        >
                                            Remove Option
                                        </button>
                                    </div>
                                </details>
                            </div>
                        ))}
                        {(!select_menu.basic_options || select_menu.basic_options.length < 25) && (
                            <button
                                className='btn btn-red'
                                onClick={() => {
                                    const optionNumber = (select_menu.basic_options || []).length + 1;
                                    const newOptions = [...(select_menu.basic_options || []), {
                                        label: `Option ${optionNumber}`,
                                        description: `Option ${optionNumber}`,
                                        emoji_id: "",
                                        value: ``
                                    }];
                                    const updatedMenu = {
                                        ...select_menu,
                                        basic_options: newOptions
                                    };
                                    updateEditAtIndex({ ...currentEdit, select_menu: updatedMenu });
                                }}
                            >
                                Add Option
                            </button>
                        )}
                        {(!select_menu.basic_options || select_menu.basic_options.length === 0) && (
                            <div className="error-message mt-3 mb-3" style={{ color: 'red', fontWeight: 'bold' }}>
                                Error: At least one option must be added to the select menu.
                            </div>
                        )}
                        <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd", marginTop: "20px", marginBottom: "20px" }}></hr>
                    </>
                )}

                <div className="mb-15">
                    <h4 style={{ marginBottom: "0px" }}>Enable Multiselect</h4>
                    <div className="section-content-header mb-15">Allow users to select more than one option.</div>
                    <select
                        onChange={(e) => {
                            const value = e.target.value === "true";
                            const updatedMenu = {
                                ...select_menu,
                                multiselect: value,
                                min_values: value ? "1" : "1",
                                max_values: value ? "1" : "1"
                            };
                            updateEditAtIndex({ ...currentEdit, select_menu: updatedMenu });
                        }}
                        value={select_menu.multiselect ? "true" : "false"}
                    >
                        <option value="false">Single Select</option>
                        <option value="true">Multi Select</option>
                    </select>
                </div>

                {select_menu.multiselect && (
                    <>
                        <div className="mb-15">
                            <h4 style={{ marginBottom: "0px" }}>Minimum Values</h4>
                            <div className="section-content-header mb-15">The minimum number of options that must be selected.</div>
                            <VariableTextInput
                                label="Min Values"
                                value={select_menu.min_values}
                                onChange={(value) => {
                                    // Get the current number of visible options

                                    const updatedMenu = {
                                        ...select_menu,
                                        min_values: value
                                    };
                                    updateEditAtIndex({ ...currentEdit, select_menu: updatedMenu });
                                }}
                                placeholder="1"
                                required={false}
                                slash_options={this.props.options}
                            />
                        </div>

                        <div className="mb-15">
                            <h4 style={{ marginBottom: "0px" }}>Maximum Values</h4>
                            <div className="section-content-header mb-15">The maximum number of options that can be selected.</div>
                            <VariableTextInput
                                label="Max Values"
                                value={select_menu.max_values}
                                onChange={(value) => {
                                    // Get the current number of visible options


                                    const updatedMenu = {
                                        ...select_menu,
                                        max_values: value
                                    };
                                    updateEditAtIndex({ ...currentEdit, select_menu: updatedMenu });
                                }}
                                placeholder="1"
                                required={false}
                                slash_options={this.props.options}
                            />
                        </div>
                    </>
                )}

                <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd", marginTop: "20px", marginBottom: "20px" }}></hr>

                <div className="mb-15">
                    <h4 style={{ marginBottom: "0px" }}>
                        Placeholder Text <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/send-a-message-with-attached-select-menus#placeholder" />
                    </h4>
                    <div className="section-content-header mb-15">The text shown when no option is selected. All options and variables can be used.</div>
                    <VariableTextInput
                        label="Placeholder"
                        value={select_menu.placeholder}
                        onChange={(value) => {
                            const updatedMenu = {
                                ...select_menu,
                                placeholder: value
                            };
                            updateEditAtIndex({ ...currentEdit, select_menu: updatedMenu });
                        }}
                        placeholder="Choose an option..."
                        required={false}
                        slash_options={this.props.options}
                    />
                </div>

                <div className="mb-15">
                    <h4 style={{ marginBottom: "0px" }}>Disable Menu</h4>
                    <div className="section-content-header mb-15">Set to 'true' to disable this menu.</div>
                    <VariableTextInput
                        label="Disabled"
                        value={select_menu.disabled}
                        onChange={(value) => {
                            const updatedMenu = {
                                ...select_menu,
                                disabled: value
                            };
                            updateEditAtIndex({ ...currentEdit, select_menu: updatedMenu });
                        }}
                        placeholder="true or false"
                        required={false}
                        slash_options={this.props.options}
                    />
                </div>

                <div className="mb-15">
                    <h4 style={{ marginBottom: "0px" }}>Remove Menu</h4>
                    <div className="section-content-header mb-15">Set to 'true' to remove this menu from the message.</div>
                    <VariableTextInput
                        label="Remove"
                        value={select_menu.remove}
                        onChange={(value) => {
                            const updatedMenu = {
                                ...select_menu,
                                remove: value
                            };
                            updateEditAtIndex({ ...currentEdit, select_menu: updatedMenu });
                        }}
                        placeholder="true or false"
                        required={false}
                        slash_options={this.props.options}
                    />
                    <p className="text-sm text-gray-400 mt-1">
                        Enter 'true' to remove this menu from the message. This cannot be undone.
                    </p>
                </div>

                {/* Component Ordering */}
                <hr style={{ borderTop: "1px solid #adb5bd" }} className="slash-hr" />
                <div className="flex flex-row items-center gap-x-3 mb-15">
                    <div>
                        <h4 className="mb-2">Enable Component Ordering</h4>
                        <p>Order this select menu amongst other message components</p>
                    </div>
                    <Toggle
                        value={select_menu.orderComponent || false}
                        update={(value) => {
                            const updatedMenu = {
                                ...select_menu,
                                orderComponent: value
                            };
                            updateEditAtIndex({ ...currentEdit, select_menu: updatedMenu });
                        }}
                    />
                </div>

                {select_menu.orderComponent && (
                    <>
                        <div>
                            <div>
                                <h4 className="mb-2">Row</h4>
                                <p>Which row to place this select menu on. </p>
                            </div>
                            <VariableTextInput
                                label={"Row"}
                                placeholder="1"
                                slash_options={this.props.options}
                                required={false}
                                value={select_menu.row}
                                onChange={(value) => {
                                    const updatedMenu = {
                                        ...select_menu,
                                        row: value
                                    };
                                    updateEditAtIndex({ ...currentEdit, select_menu: updatedMenu });
                                }}
                            />
                        </div>

                        <div className="mt-3">
                            <div>
                                <h4 className="mb-2">Position Weight</h4>
                                <p>Which position to place this select menu on the row. Components with a higher weight will be placed first. </p>
                            </div>
                            <VariableTextInput
                                label={"Weight"}
                                placeholder="1"
                                slash_options={this.props.options}
                                required={false}
                                value={select_menu.weight}
                                onChange={(value) => {
                                    const updatedMenu = {
                                        ...select_menu,
                                        weight: value
                                    };
                                    updateEditAtIndex({ ...currentEdit, select_menu: updatedMenu });
                                }}
                            />
                            <p className="text-sm text-gray-500 mt-1">Note: A weight of -1 will cause the menu to be ignored.</p>
                        </div>
                    </>
                )}

                <hr style={{ borderTop: "1px solid #adb5bd" }} className="slash-hr" />
                <div className="mb-15">
                    <h4 style={{ marginBottom: "0px" }}>
                        Placeholder Text <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/send-a-message-with-attached-select-menus#placeholder" />
                    </h4>
                    <div className="section-content-header mb-15">The text shown when no option is selected. All options and variables can be used.</div>
                    <VariableTextInput
                        label="Placeholder"
                        value={select_menu.placeholder}
                        onChange={(value) => {
                            const updatedMenu = {
                                ...select_menu,
                                placeholder: value
                            };
                            updateEditAtIndex({ ...currentEdit, select_menu: updatedMenu });
                        }}
                        placeholder="Choose an option..."
                        required={false}
                        slash_options={this.props.options}
                    />
                </div>

                <div className="mb-15">
                    <h4 style={{ marginBottom: "0px" }}>Disable Menu</h4>
                    <div className="section-content-header mb-15">Set to 'true' to disable this menu.</div>
                    <VariableTextInput
                        label="Disabled"
                        value={select_menu.disabled}
                        onChange={(value) => {
                            const updatedMenu = {
                                ...select_menu,
                                disabled: value
                            };
                            updateEditAtIndex({ ...currentEdit, select_menu: updatedMenu });
                        }}
                        placeholder="true or false"
                        required={false}
                        slash_options={this.props.options}
                    />
                </div>

                <div className="mb-15">
                    <h4 style={{ marginBottom: "0px" }}>Remove Menu</h4>
                    <div className="section-content-header mb-15">Set to 'true' to remove this menu from the message.</div>
                    <VariableTextInput
                        label="Remove"
                        value={select_menu.remove}
                        onChange={(value) => {
                            const updatedMenu = {
                                ...select_menu,
                                remove: value
                            };
                            updateEditAtIndex({ ...currentEdit, select_menu: updatedMenu });
                        }}
                        placeholder="true or false"
                        required={false}
                        slash_options={this.props.options}
                    />
                    <p className="text-sm text-gray-400 mt-1">
                        Enter 'true' to remove this menu from the message. This cannot be undone.
                    </p>
                </div>

                <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd", marginTop: "20px", marginBottom: "20px" }}></hr>

                {selectedComponent.data.data.menu_type === "channel" && (
                    <>
                        <div className="mb-15">
                            <h4 className="mb-2">Default Selected Channels</h4>
                            <p>Select the channels that will be pre-selected in the menu.</p>
                            <RoleAdd
                                items={select_menu.default_channels}
                                update={(channels) => {
                                    const updatedMenu = {
                                        ...select_menu,
                                        default_channels: channels
                                    };
                                    updateEditAtIndex({ ...currentEdit, select_menu: updatedMenu });
                                }}
                                bot_data={this.props.bot_data}
                                type="channels"
                                slash_options={this.props.options}
                            />
                        </div>

                        <div className="mb-15 mt-3">
                            <h4 style={{ marginBottom: "0px" }}>Optional Additional Channels</h4>
                            <div className="section-content-header mb-15">Add additional channel IDs or variables that resolve to channel IDs. Separate each with commas.</div>
                            <VariableTextInput
                                label="Channels"
                                value={select_menu.additional_channels}
                                onChange={(value) => {
                                    const updatedMenu = {
                                        ...select_menu,
                                        additional_channels: value
                                    };
                                    updateEditAtIndex({ ...currentEdit, select_menu: updatedMenu });
                                }}
                                required={false}
                                placeholder="Enter channel IDs or variables, separated by commas"
                                slash_options={this.props.options}
                            />
                        </div>

                        <div className="mb-15 mt-3">
                            <h4 className="mb-2">Optional Channel Types</h4>
                            <p>Select the types of channels that will be available in the menu.</p>
                            <RoleAdd
                                items={select_menu.channel_types}
                                update={(channelTypes) => {
                                    const updatedMenu = {
                                        ...select_menu,
                                        channel_types: channelTypes
                                    };
                                    updateEditAtIndex({ ...currentEdit, select_menu: updatedMenu });
                                }}
                                bot_data={this.props.bot_data}
                                type="channelTypes"
                                slash_options={this.props.options}
                            />
                        </div>

                        <div className="mb-15 mt-3">
                            <h4 style={{ marginBottom: "0px" }}>Optional Additional Channel Types</h4>
                            <div className="section-content-header block mb-15">
                                Add additional channel type integers or variables here. Separate each with commas.
                                For a list of channel type integers, see the <a className='text-red font-bold' href="https://discord.com/developers/docs/resources/channel#channel-object-channel-types" target="_blank" rel="noopener noreferrer">Discord API documentation</a>.
                            </div>
                            <VariableTextInput
                                label="Channel Types"
                                required={false}
                                value={select_menu.additional_channel_types}
                                onChange={(value) => {
                                    const updatedMenu = {
                                        ...select_menu,
                                        additional_channel_types: value
                                    };
                                    updateEditAtIndex({ ...currentEdit, select_menu: updatedMenu });
                                }}
                                placeholder="Enter additional channel type integers or variables"
                                slash_options={this.props.options}
                            />
                        </div>

                    </>
                )}


                {selectedComponent.data.data.menu_type === "role" && (
                    <>
                        <div>
                            <h4 className="mb-2">Optional Default Selected Roles</h4>
                            <p>Select the roles that will be pre-selected in the menu.</p>
                            <RoleAdd
                                items={select_menu.default_roles}
                                update={(roles) => {
                                    const updatedMenu = {
                                        ...select_menu,
                                        default_roles: roles
                                    };
                                    updateEditAtIndex({ ...currentEdit, select_menu: updatedMenu });
                                }}
                                bot_data={this.props.bot_data}
                                type="roles"
                                slash_options={this.props.options}
                            />
                        </div>
                        <div className="mb-15 mt-3">
                            <h4 style={{ marginBottom: "0px" }}>Optional Additional Roles</h4>
                            <div className="section-content-header mb-15">Add additional role IDs or variables that resolve to role IDs. Separate each with commas.</div>
                            <VariableTextInput
                                label="Roles"
                                required={false}
                                value={select_menu.additional_roles}
                                onChange={(value) => {
                                    const updatedMenu = {
                                        ...select_menu,
                                        additional_roles: value
                                    };
                                    updateEditAtIndex({ ...currentEdit, select_menu: updatedMenu });
                                }}
                                placeholder="Enter role IDs or variables, separated by commas"
                                slash_options={this.props.slash_options}
                            />
                        </div>

                    </>)}


                {
                    selectedComponent.data.data.menu_type === "user" && (
                        <>

                            <div className="mb-15 mt-3">
                                <h4 style={{ marginBottom: "0px" }}>Optional Default Selected Users</h4>
                                <p>Enter user IDs or variables that resolve to user IDs. Separate each with a comma.</p>
                                <VariableTextInput
                                    label="Users"
                                    required={false}
                                    value={select_menu.additional_users}
                                    onChange={(value) => {
                                        const updatedMenu = {
                                            ...select_menu,
                                            additional_users: value
                                        };
                                        updateEditAtIndex({ ...currentEdit, select_menu: updatedMenu });
                                    }}
                                    placeholder="Enter user IDs or variables, separated by commas"
                                    slash_options={this.props.slash_options}
                                />
                            </div>
                            {/* <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd" }}></hr> */}
                        </>
                    )


                }

                {
                    selectedComponent.data.data.menu_type === "mentionable" && (
                        <>
                            <div>
                                <h4 className="mb-2">Optional Default Selected Roles</h4>
                                <p>Select the roles that will be pre-selected in the menu.</p>
                                <RoleAdd
                                    items={select_menu.default_roles}
                                    update={(roles) => {
                                        const updatedMenu = {
                                            ...select_menu,
                                            default_roles: roles
                                        };
                                        updateEditAtIndex({ ...currentEdit, select_menu: updatedMenu });
                                    }}
                                    bot_data={this.props.bot_data}
                                    type="roles"
                                    slash_options={this.props.slash_options}
                                />
                            </div>
                            <div className="mb-15 mt-3">
                                <h4 style={{ marginBottom: "0px" }}>Additional Roles</h4>
                                <div className="section-content-header mb-15">Add additional role IDs or variables that resolve to role IDs. Separate each with commas.</div>
                                <VariableTextInput
                                    label="Roles"
                                    required={false}
                                    value={select_menu.additional_roles}
                                    onChange={(value) => {
                                        const updatedMenu = {
                                            ...select_menu,
                                            additional_roles: value
                                        };
                                        updateEditAtIndex({ ...currentEdit, select_menu: updatedMenu });
                                    }}
                                    placeholder="Enter role IDs or variables, separated by commas"
                                    slash_options={this.props.slash_options}
                                />
                            </div>
                            <div className="mb-15 mt-3">
                                <h4 style={{ marginBottom: "0px" }}>Optional Default Selected Users</h4>
                                <p>Enter user IDs or variables that resolve to user IDs. Separate each with a comma.</p>
                                <VariableTextInput
                                    label="Users"
                                    required={false}
                                    value={select_menu.default_users}
                                    onChange={(value) => {
                                        const updatedMenu = {
                                            ...select_menu,
                                            default_users: value
                                        };
                                        updateEditAtIndex({ ...currentEdit, select_menu: updatedMenu });
                                    }}
                                    placeholder="Enter user IDs or variables, separated by commas"
                                    slash_options={this.props.slash_options}
                                />
                            </div>


                        </>
                    )
                }
            </div>
        );
    };

    renderMultiSelectUI = () => {
        if (!this.props.data.action_id) return null;

        const selectedAction = this.props.actions.find(action => action.id === this.props.data.action_id);
        if (!selectedAction) return null;

        const elementChildren = getOutgoers(selectedAction, splitElements(this.props.actions).nodes, splitElements(this.props.actions).edges);
        const components = elementChildren.filter(node =>
            node.type === "advMessageButton" || node.type === "advMessageSelectMenu"
        );

        console.log(components, "COMPONENTS");

        const selectedComponents = this.props.data.edits || [];

        return (
            <div>
                <div className="mb-4">
                    <h4 className="text-[#e6e6e6] text-base font-medium mb-2">Available Components</h4>
                    <p className="text-[#adb5bd] text-sm mb-4">Click on components to select them for editing.</p>
                    <div className="flex flex-wrap gap-2">
                        {components.map(component => {
                            const isSelected = selectedComponents.some(edit => edit.component_id === component.id);
                            const label = component.type === "advMessageButton"
                                ? component.data.data.label || "Unlabeled Button"
                                : component.data.data.placeholder || "Unnamed Select Menu";
                            const type = component.type === "advMessageButton"
                                ? "Button"
                                : `${component.data.data.menu_type?.charAt(0).toUpperCase() + component.data.data.menu_type?.slice(1) || 'Basic'} Select Menu`;

                            return (
                                <button
                                    key={component.id}
                                    onClick={() => {
                                        const currentEdits = [...(this.props.data.edits || [])];
                                        const editIndex = currentEdits.findIndex(edit => edit.component_id === component.id);

                                        console.log(editIndex, "COMPONENT DATA INDEX");

                                        if (editIndex === -1) {
                                            // Add new edit
                                            const componentId = component.data.data.editComponentId || `${Date.now()}_${Math.floor(Math.random() * 100000)}`;

                                            console.log(componentId, "COMPONENT DATA ID");

                                            // Update component if it doesn't have an editComponentId
                                            if (!component.data.data.editComponentId) {
                                                const componentData = { ...component.data.data };
                                                componentData.editComponentId = componentId;
                                                this.props.updateElementData({
                                                    data: componentData,
                                                    id: component.id
                                                });

                                                console.log(componentData, "COMPONENT DATA");
                                            }

                                            // Create new edit object
                                            const newEdit = {
                                                editComponentId: componentId,
                                                component_id: component.id,
                                            };

                                            // Add type-specific properties
                                            if (component.type === "advMessageSelectMenu") {
                                                const menuData = component.data.data;
                                                newEdit.select_menu = {
                                                    placeholder: menuData.placeholder || "",
                                                    min_values: menuData.minOptions || "1",
                                                    max_values: menuData.maxOptions || "1",
                                                    disabled: menuData.disabled || false,
                                                    basic_options: menuData.menu_type === "basic" ? [...(menuData.basic_options || [])] : [],
                                                    reset_options: false,
                                                    multiselect: menuData.multiselect || false,
                                                    menu_type: menuData.menu_type || "basic"
                                                };

                                                // Add channel-specific settings if it's a channel select menu
                                                if (menuData.menu_type === "channel") {
                                                    newEdit.select_menu = {
                                                        ...newEdit.select_menu,
                                                        channel_types: [...(menuData.channel_types || [])],
                                                        additional_channel_types: menuData.additional_channel_types || "",
                                                        default_channels: [...(menuData.default_channels || [])],
                                                        additional_channels: menuData.additional_channels || "",
                                                        reset_channel_types: false,
                                                        reset_default_channels: false
                                                    };
                                                }

                                                // Add role-specific settings if it's a role select menu
                                                if (menuData.menu_type === "role") {
                                                    newEdit.select_menu = {
                                                        ...newEdit.select_menu,
                                                        default_roles: [...(menuData.default_roles || [])],
                                                        additional_roles: menuData.additional_roles || "",
                                                        reset_default_roles: false
                                                    };
                                                }

                                                // Add user-specific settings if it's a user select menu
                                                if (menuData.menu_type === "user") {
                                                    newEdit.select_menu = {
                                                        ...newEdit.select_menu,
                                                        default_users: [...(menuData.default_users || [])],
                                                        additional_users: menuData.additional_users || "",
                                                        reset_default_users: false
                                                    };
                                                }

                                                // Add mentionable-specific settings if it's a mentionable select menu
                                                if (menuData.menu_type === "mentionable") {
                                                    newEdit.select_menu = {
                                                        ...newEdit.select_menu,
                                                        default_roles: [...(menuData.default_roles || [])],
                                                        additional_roles: menuData.additional_roles || "",
                                                        default_users: [...(menuData.default_users || [])],
                                                        additional_users: menuData.additional_users || "",
                                                        reset_default_roles: false,
                                                        reset_default_users: false
                                                    };
                                                }
                                            } else if (component.type === "advMessageButton") {
                                                const buttonData = component.data.data;
                                                newEdit.button = {
                                                    label: buttonData.label || "",
                                                    style: buttonData.style || "PRIMARY",
                                                    disabled: buttonData.disabled || false,
                                                    emoji_id: buttonData.emoji_id || "",
                                                    url: buttonData.url || "",
                                                    remove: false
                                                };
                                            }

                                            currentEdits.push(newEdit);
                                        } else {
                                            // Remove edit
                                            currentEdits.splice(editIndex, 1);
                                        }

                                        this.updateData("edits", currentEdits);
                                    }}
                                    className={`px-4 py-2 rounded text-sm font-medium transition-colors ${isSelected
                                        ? 'bg-[#f44336] text-white hover:bg-[#d32f2f]'
                                        : 'bg-[#2b2d31] text-[#e6e6e6] hover:bg-[#36373d]'
                                        }`}
                                >
                                    {type}: {label}
                                </button>
                            );
                        })}
                    </div>
                </div>

                {selectedComponents.length > 0 && (
                    <div className="mt-6">
                        <h4 className="text-[#e6e6e6] text-base font-medium mb-2">Selected Components</h4>
                        <p className="text-[#adb5bd] text-sm mb-4">Click to expand and edit settings for each component.</p>
                        <div className="space-y-2">
                            {selectedComponents.map((edit, index) => {
                                const component = this.props.elements.find(el => el.id === edit.component_id);
                                if (!component) return null;

                                const label = component.type === "advMessageButton"
                                    ? component.data.data.label || "Unlabeled Button"
                                    : component.data.data.placeholder || "Unnamed Select Menu";
                                const type = component.type === "advMessageButton"
                                    ? "Button"
                                    : `${component.data.data.menu_type?.charAt(0).toUpperCase() + component.data.data.menu_type?.slice(1) || 'Basic'} Select Menu`;

                                return (
                                    <details key={edit.component_id} className="bg-[#2b2d31] rounded">
                                        <summary className="p-3 cursor-pointer flex items-center justify-between text-[#e6e6e6] hover:bg-[#36373d] rounded">
                                            <span className="font-medium">{type}: {label}</span>
                                            <div className="flex items-center gap-3">
                                                <button
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        const newEdits = selectedComponents.filter((_, i) => i !== index);
                                                        this.updateData("edits", newEdits);
                                                    }}
                                                    className="text-[#f44336] hover:text-[#d32f2f] font-medium text-sm"
                                                >
                                                    Remove
                                                </button>
                                                <FontAwesomeIcon icon={faChevronDown} className="text-[#adb5bd] w-4 h-4" />
                                            </div>
                                        </summary>
                                        <div className="p-4 border-t border-[#36373d]">
                                            {component.type === "advMessageButton" ? (
                                                this.renderButtonSettings(edit, index)
                                            ) : (
                                                this.renderMenuSettings(edit, index)
                                            )}
                                        </div>
                                    </details>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    renderComponentValue = () => {
        if (!this.props.data.edits?.[0]?.editComponentId) return "";

        // First find the component that has this editComponentId
        const component = this.props.elements.find(el =>
            el.type === "advMessageButton" || el.type === "advMessageSelectMenu" &&
            el.data?.data?.editComponentId === this.props.data.edits[0].editComponentId
        );
        return component ? component.id : "";
    };

    render() {
        if (this.props.data != undefined) {
            return (
                <div>
                    <div className="mb-15">
                        <h4>Edit Component</h4>
                        <p>Edit existing buttons or select menus in a message.</p>
                    </div>

                    <div>
                        <h4 style={{ marginBottom: "0px" }}>
                            Target Message <DocsLink location={"https://docs.botghost.com/custom-commands-and-events/actions/edit-component"}></DocsLink>
                        </h4>
                        <div className="section-content-header mb-15">Select the message action containing the components you want to edit.</div>
                        <Select
                            saveFailed={this.props.data.action_id == "" && this.props.saveFailed}
                            value={this.props.data.action_id}
                            options={this.renderAdvMessageOptions()}
                            onChange={this.selectChange}
                        />
                        <p style={{ fontSize: "0.875rem", color: "#adb5bd", marginTop: "0.25rem" }}>
                            Tip: You can add labels to your Advanced Messages to make them easier to identify here.
                        </p>
                    </div>

                    {this.renderMultiSelectUI()}
                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements,
    saveFailed: state.builder.saveFailed,
    actions: state.builder.elements,
    beta: state.data.beta
});

const mapDispatchToProps = {
    updateElementData,
    setHighlightAction,
    setHighlightComponent
};

export default connect(mapStateToProps, mapDispatchToProps)(EditComponent);
