import { isBrowser } from 'react-device-detect';

// Base handle sizes
const BASE_SIZES = {
    desktop: {
        normal: 15,
        expanded: 15,
        position: 0,
        expandedPosition: 0
    },
    mobile: {
        normal: 32,
        expanded: 32,
        position: 0,
        expandedPosition: 0
    }
};

// Added new style configurations
const HANDLE_STYLES = {
    desktop: {
        base: 'transform-gpu origin-center -translate-y-1/2',
    },
    mobile: {
        base: ' shadow-lg transform-gpu origin-center -translate-y-1/2',
    }
};

/**
 * Get handle size based on device type and state
 */
export const getHandleSize = (isAction, handleId, handleType, nodeId, currentNodeId) => {
    const sizes = isBrowser ? BASE_SIZES.desktop : BASE_SIZES.mobile;

    if (isAction && handleId === 'action' && handleType !== nodeId && nodeId !== currentNodeId) {
        return `${sizes.expanded}px`;
    }

    return `${sizes.normal}px`;
};

/**
 * Get handle position based on device type and state
 */
export const getHandlePosition = (isAction, handleId, handleType, nodeId, currentNodeId) => {
    const sizes = isBrowser ? BASE_SIZES.desktop : BASE_SIZES.mobile;

    if (isAction && handleId === 'action' && handleType !== nodeId && nodeId !== currentNodeId) {
        return sizes.expandedPosition;
    }

    return sizes.position;
};

export const getHandleStyles = () => {
    return isBrowser ? HANDLE_STYLES.desktop.base : HANDLE_STYLES.mobile.base;
}; 