import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import DefaultHandle from './DefaultHandle';
import { getIncomers } from 'react-flow-renderer';
import splitElements from '../splitElements';
import { isMobile } from 'react-device-detect';

export class LoopChildNode extends Component {
    renderLoopData = (id, type) => {
        if (type == "loop_actions") {
            const { elements } = this.props;
            var element = elements.find((element) => element.id == id);
            if (!element) return null;
            var edges = splitElements(elements).edges;

            var parentLoop = getIncomers(element, splitElements(elements).nodes, edges);
            var times = 0;
            if (parentLoop[0] && parentLoop[0].data) {
                times = parentLoop[0].data.data.count;
            }
            return (
                <>
                    <span className="select-menu-item-label" style={{ fontWeight: "600" }}>
                        Loop {times} times
                    </span>
                    <span className="select-menu-description">Connected Actions & Conditions will be executed {times} times.</span>
                </>
            );
        } else {
            return (
                <>
                    <span className="select-menu-item-label" style={{ fontWeight: "600" }}>
                        After loop
                    </span>
                    <span className="select-menu-description">Run when the loop has finished</span>
                </>
            );
        }
    };

    render() {
        const {
            id,
            data,
            selected,
            builder_settings,
            nodeHandleID,
            handleType,
            handleId,
            actionConnection,
            setSelected
        } = this.props;

        var useMobileBuilder = builder_settings?.mobileBuilder && isMobile;
        var nodePadding = useMobileBuilder ? "py-[50px]" : "py-[25px]";

        return (
            <>
                <DefaultHandle
                    nodeType="selectmenu"
                    selectedNodeHandleType={handleId}
                    nodeHandleID={nodeHandleID}
                    selectedNodeID={nodeHandleID}
                    selectedHandleType={handleType}
                    id={id}
                    position="top"
                    handleType="target"
                    isValidConnection={actionConnection}
                    nonInteractive={true}
                />

                <div
                    id={id}
                    onTouchStart={(e) => {
                        // setSelected(id);
                    }}
                    onClick={(e) => {
                        // setSelected(id);
                    }}
                    style={{
                        border: selected == id
                            ? `2px solid ${builder_settings?.selectedColor || '#5865f2'}`
                            : '2px solid transparent'
                    }}
                    className={`menu-option-node ${nodePadding}`}
                >
                    <div className="inner">
                        <div className="select-menu-item-info">
                            {this.renderLoopData(id, data.type)}
                        </div>
                    </div>
                </div>

                <DefaultHandle
                    nodeType="action"
                    selectedNodeHandleType={handleId}
                    nodeHandleID={nodeHandleID}
                    selectedNodeID={nodeHandleID}
                    selectedHandleType={handleType}
                    id={id}
                    position="bottom"
                    handleType="source"
                    isValidConnection={actionConnection}
                />
            </>
        );
    }
} 