import React, { Component } from 'react';
import { connect } from 'react-redux';
import RefreshBotData from '../elements/RefreshBotData';
import Select from '../elements/Select';
import Toggle from '../elements/Toggle';
import RoleAdd from '../elements/RoleAdd';
import { ChromePicker } from "react-color";
import InputTitle from './InputTitle';
export class CustomColorPicker extends Component {
    renderDescription = () => {
        if (this.props.settings.html) {
            return <div dangerouslySetInnerHTML={{ __html: this.props.settings.description }}></div>;
        } else {
            return <p>{this.props.settings.description}</p>;
        }
    };

    constructor(props) {
        super(props);

        this.state = {
            word: "",
            showColorPicker: false
        };

        this.wrapperRef = React.createRef();
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({ showColorPicker: false });
        }
    }

    componentDidMount() {
        // Set embed id to random text
        this.setState({ embed_id: Math.random().toString(36).substring(7) });
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    render() {
        return (
            <div className={`${this.props.settings.premium && !this.props.premium ? "opacity-75 pointer-events-none" : ""} 
                ${!this.props.slot ? "section-content" : "flex justify-between items-start"}`}
            >
                <div>
                    <h4 className="text-[16px] font-semibold text-[#060607] dark:text-[#ffffff]">
                        {this.props.settings.title}
                    </h4>
                    <p className="mt-1 text-[#4e5058] dark:text-[#b5bac1] text-sm">
                        {this.props.settings.description}
                    </p>
                </div>

                <div className="relative flex items-center gap-2" ref={this.setWrapperRef}>
                    <div 
                        className="w-[38px] h-[38px] rounded border border-[#e3e5e8] dark:border-[#3f4147] overflow-hidden cursor-pointer hover:border-[#b5bac1] dark:hover:border-[#b5bac1] transition-colors"
                        style={{
                            backgroundColor: this.props.value
                        }}
                        onClick={() => {
                            this.setState({ showColorPicker: !this.state.showColorPicker });
                        }}
                    />
                    <input
                        type="text"
                        value={this.props.value}
                        onChange={(e) => this.props.change(e.target.value)}
                        className="w-[100px] px-2.5 py-1.5 bg-transparent border border-[#e3e5e8] dark:border-[#3f4147] rounded text-sm text-[#060607] dark:text-[#ffffff] focus:outline-none focus:ring-2 focus:ring-[#5865f2] focus:border-transparent"
                    />
                    {this.state.showColorPicker && (
                        <div className="absolute z-50 right-0 top-[calc(100%+8px)]">
                            <div className="p-2 bg-[#ffffff] dark:bg-[#2b2d31] rounded-lg shadow-lg border border-[#e3e5e8] dark:border-[#1e1f22]">
                                <ChromePicker
                                    color={this.props.value}
                                    onChangeComplete={(color) => {
                                        this.props.change(color.hex);
                                    }}
                                    styles={{
                                        default: {
                                            picker: {
                                                background: 'transparent',
                                                boxShadow: 'none'
                                            }
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    premium: state.data.premium
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CustomColorPicker);