import React, { Component } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCarrot, faCopy, faTimes, faTimesCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import DocsLink from "../elements/DocsLink";
import Toggle from "../elements/Toggle";
import Option from "./settings/Option";
import update from "immutability-helper";
import { setSelected, setElements, updateElementData, setFuture } from "../../../actions";
import PlainTextReply from "./settings/PlainTextReply";
import EmbedReply from "./settings/EmbedReply";
import RandomReply from "./settings/RandomReply";
import TargetedResponse from "./settings/TargetedResponse";
import DMResponse from "./settings/DMResponse";
import RoleAction from "./settings/RoleAction";
import ButtonAction from "./settings/ButtonAction";
import ButtonHandler from "./settings/ButtonHandler";
import Command from "./settings/Command";
import { applyNodeChanges, ongetIncomers, getOutgoers, getConnectedEdges, addEdge } from "react-flow-renderer";
import WaitAction from "./settings/WaitAction";
import ChannelDeleteAction from "./settings/ChannelDeleteAction";
import ChannelCreateAction from "./settings/ChannelCreateAction";
import KickMemberAction from "./settings/KickMemberAction";
import BanAction from "./settings/BanAction.js";
import RoleDeleteAction from "./settings/RoleDeleteAction";
import CreateRoleAction from "./settings/CreateRoleAction";
import TimeoutAction from "./settings/TimeoutAction";
import OptionsCondition from "./settings/OptionsCondition";
import ChanceCondition from "./settings/ChanceCondition";
import OptionConditionChild from "./settings/OptionConditionChild";
import ChanceConditionChild from "./settings/ChanceConditionChild";
import CurrencyAction from "./settings/CurrencyAction";
import ItemAction from "./settings/ItemAction";
import SelectMenuAction from "./settings/SelectMenuAction";
import SelectMenuOption from "./settings/SelectMenuOption";
import ChannelCondition from "./settings/ChannelCondition";
import ChannelConditionChild from "./settings/ChannelConditionChild";
import RoleConditionChild from "./settings/RoleConditionChild";
import CurrencyConditionChild from "./settings/CurrencyConditionChild";
import UserConditionChild from "./settings/UserConditionChild";
import RoleCondition from "./settings/RoleCondition";
import UserCondition from "./settings/UserCondition";
import CurrencyCondition from "./settings/CurrencyCondition";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import splitElements from "./splitElements";
import DeleteMessage from "./settings/DeleteMessage";
import ItemCondition from "./settings/ItemCondition";
import ItemConditionChild from "./settings/ItemConditionChild";
import SetVariable from "./settings/SetVariable";
import EquationAction from "./settings/EquationAction";
import DeleteVariable from "./settings/DeleteVariable";
import CustomVariableCondition from "./settings/CustomVariableCondition";
import CustomVariableConditionChild from "./settings/CustomVariableConditionChild";
import CustomEvent from "./settings/CustomEvent";
import HTTPRequestAction from "./settings/HTTPRequestAction";
import ComparisonCondition from "./settings/ComparisonCondition";
import ComparisonConditionChild from "./settings/ComparisonConditionChild";
import ChangeNicknameAction from "./settings/ChangeNicknameAction";
import ChannelEditAction from "./settings/ChannelEditAction";
import RoleEditAction from "./settings/RoleEditAction";
import ErrorHandler from "./settings/ErrorHandler";
import PermissionsCondition from "./settings/PermissionsCondition";
import PinMessage from "./settings/PinMessage";
import PermissionsConditionChild from "./settings/PermissonsConditionChild";
import ModalAction from "./settings/ModalAction";
import ErrorLogAction from "./settings/ErrorLogAction";
import SetUniqueVariable from "./settings/SetUniqueVariable";
import PurgeMessagesAction from "./settings/PurgeMessagesAction";
import ChangeStatus from "./settings/ChangeStatus";
import LoopAction from "./settings/LoopAction";
import BreakLoop from "./settings/BreakLoop";
import IFTTTAction from "./settings/IFTTTAction";
import AdvancedReply from "./settings/AdvancedReply";
import CreateServerInvite from "./settings/CreateServerInvite";
import RegexMatchCondition from "./settings/RegexMatchCondition.js";
import CrosspostMessageAction from "./settings/CrosspostMessageAction.js";
import ThreadCreateAction from "./settings/ThreadCreateAction.js";
import ThreadEditAction from "./settings/ThreadEditAction.js";
import ThreadDeleteAction from "./settings/ThreadDeleteAction.js";
import PremiumCondition from "./settings/PremiumCondition.js";
import PremiumConditionChild from "./settings/PremiumConditionChild.js";
import CreateEmoji from "./settings/CreateEmoji.js";
import DeleteEmoji from "./settings/DeleteEmoji.js";
import StringManipulation from "./settings/StringManipulation.js";
import VoiceJoin from "./settings/VoiceJoin.js";
import VoiceKick from "./settings/VoiceKick.js";
import VoiceLeave from "./settings/VoiceLeave.js";
import VoiceMove from "./settings/VoiceMove.js";
import VoiceDeafen from "./settings/VoiceDeafen.js";
import VoiceMute from "./settings/VoiceMute.js";
import AdvSelectMenu from "./settings/AdvSelectMenu.js";
import AdvButton from "./settings/AdvButton";
import ReactMessageAction from "./settings/ReactMessageAction.js";
import NoteAction from "./settings/NoteAction.js";
import { isMobile } from 'react-device-detect';
import EditComponent from "./settings/EditComponent.js";

export class CommandBuilderProperties extends Component {
	constructor(props) {
		super(props);

		this.state = {
			open: true,
			tutorial: ""
		};
	}

	componentDidMount() { }

	componentDidUpdate(prevProps, prevState) {
		this.renderTutorial();
	}

	deleteElements = (id) => {
		var index = this.props.elements.findIndex((element) => element.id == id);

		var elements = [...this.props.elements];
		elements.splice(index, 1);
		return elements;
	};

	copyBlock = () => {
		var elements = [...this.props.elements];
		var element = this.props.elements.find((element) => element.id == this.props.selected);

		if (element.type == "action" || element.type == "note") {
			// Action
			var id = `action_${new Date().getTime()}`;

			var data = {
				node_options: copy(element.data.node_options),
				data: copy(element.data.data)
			};

			// var data = {
			//     node_options: { ...element.data.node_options },
			//     data: { ...element.data.data }
			// };

			if (data.node_options.title == "Random Reply") {
				var responses = [];
				data.data.responses.forEach((response) => {
					responses.push({ ...response });
				});
				// data.data.responses = [...data.data.responses]
			}

			/**
			 * Here is if the duplicated actions need a unique ID
			 */
			if (data.node_options.title == "Create a Channel") {
				data.data.variable = `created_channel_${s4()}`;
			}

			if (data.node_options.title == "Create a Thread") {
				data.data.variable = `created_thread_${s4()}`;
			}

			if (data.node_options.title == "Edit a Thread") {
				data.data.variable = `edited_thread_${s4()}`;
			}

			var newElement = {
				id: id,
				type: element.type,
				data: data,
				position: {
					y: element.position.y,
					x: element.position.x + element.width + 25
				}
			};
			elements.push(newElement);

			this.props.setElements(elements);
			if (element.type == "api_action") {
				this.props.setSelected(id);
			} else {
				this.props.setSelected(null);
			}
		} else if (element.type == "option") {
			var id = `${new Date().getTime()}`;
			var data = {
				node_options: { ...element.data.node_options },
				data: { ...element.data.data }
			};
			const newElement = update(
				{},
				{
					id: id,
					type: element.type,
					data: data,
					position: {
						y: element.position.y,
						x: element.position.x + element.width + 25
					}
				}
			);
			elements.push(newElement);
			elements = addEdge(
				{
					source: id,
					target: "root",
					type: "step",
					animated: false,
					arrowHeadType: "arrowclosed"
				},
				elements
			);
			this.props.setElements(elements);
		}

		// console.log(element);
	};

	deleteBlock = () => {
		var element = this.props.elements.find((element) => element.id == this.props.selected);
		if (this.props.selected != null && ((element && element.data.data.type == "select_menu") || element.data.data.type == "loop" || this.props.selected.includes("condition") || (element && element.data.data.type == "button_response") || (element && element.data.data.type == "advanced_message" && element.data.data.messageType == "advanced"))) {
			var edges = splitElements(this.props.elements).edges;
			var elementChildren = getOutgoers(element, splitElements(this.props.elements).nodes, edges);
			if (element && element.data.data.type == "advanced_message") {
				elementChildren = elementChildren.filter((child) => child?.type == "advMessageSelectMenu" || child?.type == "advMessageButton");
			}
			var elementsDel = [element];
			elementsDel = elementsDel.concat(elementChildren);
			var connectedEdges = getConnectedEdges(elementsDel, splitElements(this.props.elements).edges);

			var elementsDel = elementsDel.concat(connectedEdges);

			var elements = [...this.props.elements];
			var future_redo = [];
			elementsDel.forEach((eleDel) => {
				future_redo.push(eleDel);
				var index = elements.findIndex((element) => element.id == eleDel.id);
				elements.splice(index, 1);
			});

			if (future_redo.length > 0) {
				var future = [...this.props.future];
				future.push(future_redo);
				this.props.setFuture(future);
			}

			this.props.setElements(elements);
			this.props.setSelected(null);
			if (this.props.onClose) {
				this.props.onClose();
			}
		} else {
			var index = this.props.elements.findIndex((element) => element.id == this.props.selected);
			var element = this.props.elements.find((element) => element.id == this.props.selected);
			var elementsDel = [element];
			var connectedEdges = getConnectedEdges(elementsDel, splitElements(this.props.elements).edges);
			var elementsDel = elementsDel.concat(connectedEdges);
			var elements = [...this.props.elements];
			var future_redo = [];
			elementsDel.forEach((eleDel) => {
				future_redo.push(eleDel);
				var index = elements.findIndex((element) => element.id == eleDel.id);
				elements.splice(index, 1);
			});

			if (future_redo.length > 0) {
				var future = [...this.props.future];
				future.push(future_redo);
				this.props.setFuture(future);
			}

			this.props.setElements(elements);
			this.props.setSelected(null);
			if (this.props.onClose) {
				this.props.onClose();
			}
		}
	};

	renderOptionsComponent = () => {
		var element = this.props.elements.find((element) => element.id == this.props.selected);

		var options = [];
		var actions = [];

		this.props.elements.forEach((element) => {
			if (element.type == "option" && element.data.data != undefined) {
				options.push(element.data.data);
			} else if (element.type == "action" && element.data.data != undefined && element.data.data.type === "channel_create" && element.data.data.name != "") {
				options.push({
					description: element.data.data.name,
					name: element.data.data.name,
					type: "channel"
				});
			} else if (element.type == "action" && element.data.data != undefined && element.data.data.type === "create_role" && element.data.data.name != "") {
				options.push({
					description: element.data.data.name,
					name: element.data.data.name,
					type: "role"
				});
			}
		});

		this.props.elements.forEach((element) => {
			if (element.type == "action") {
				actions.push(element);
			}
		});

		if (element == undefined) {
			return null;
		} else if (element.type == "option") {
			return <Option title={element.data.node_options.title} description={element.data.node_options.description} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
		} else if (element.type == "button") {
			return <ButtonHandler node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
		} else if (element.type == "action") {
			// Check if has outgoing connection;

			if (element.data.node_options.title == "Plain Text Reply") {
				return <PlainTextReply actions={actions} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Send a Message" || element.data.node_options.title == "Send or Edit a Message") {
				return <AdvancedReply actions={actions} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Embed Reply") {
				return <EmbedReply actions={actions} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Random Reply") {
				return <RandomReply actions={actions} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Send a message to a Channel") {
				return <TargetedResponse options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Direct Message a User") {
				return <DMResponse options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Add Roles") {
				return <RoleAction type="role_add" options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Remove Roles") {
				return <RoleAction type="role_remove" options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Send a Message with attached Buttons") {
				return <ButtonAction actions={actions} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Wait before running another action") {
				return <WaitAction options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Delete a Message") {
				return <DeleteMessage actions={actions} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Publish a Message") {
				return <CrosspostMessageAction actions={actions} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Edit a Role") {
				return <RoleEditAction actions={actions} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
				// Channel
			} else if (element.data.node_options.title == "Edit a Button or Select Menu") {
				return <EditComponent actions={actions} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "React to a Message") {
				return <ReactMessageAction actions={actions} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			}
			else if (element.data.node_options.title == "Delete a Channel") {
				return <ChannelDeleteAction options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Create a Channel") {
				return <ChannelCreateAction options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Edit a Channel") {
				return <ChannelEditAction options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
				// Threads
			} else if (element.data.node_options.title == "Create a Thread") {
				return <ThreadCreateAction options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} type="thread" />;
			} else if (element.data.node_options.title == "Edit a Thread") {
				return <ThreadEditAction options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} type="thread" />;
			} else if (element.data.node_options.title == "Delete a Thread") {
				return <ThreadDeleteAction actions={actions} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} type="thread" />;
				// Members
			} else if (element.data.node_options.title == "Kick a Member") {
				return <KickMemberAction options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Ban a Member") {
				return <BanAction options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Delete a role") {
				return <RoleDeleteAction options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Create a role") {
				return <CreateRoleAction options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Timeout a Member") {
				return <TimeoutAction options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Add Currency") {
				return <CurrencyAction type="add_currency" options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Remove Currency") {
				return <CurrencyAction type="remove_currency" options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Add Item") {
				return <ItemAction type="add_item" options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Remove Item") {
				return <ItemAction type="remove_item" options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Send a Message with a Select Menu") {
				return <SelectMenuAction actions={actions} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Set Variable") {
				return <SetVariable options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Run Equation on Variable") {
				return <EquationAction options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Delete Variable") {
				return <DeleteVariable options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Send an API Request") {
				return <HTTPRequestAction options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Change a Members Nickname") {
				return <ChangeNicknameAction options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Pin a Message") {
				return <PinMessage actions={actions} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Send a Form") {
				return <ModalAction actions={actions} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Send an error log message") {
				return <ErrorLogAction actions={actions} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Set a unique variable") {
				return <SetUniqueVariable actions={actions} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Purge Messages") {
				return <PurgeMessagesAction actions={actions} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Change the Bot's Status") {
				return <ChangeStatus actions={actions} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Run a Loop") {
				return <LoopAction actions={actions} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Stop a Loop") {
				return <BreakLoop actions={actions} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Execute an IFTTT Trigger") {
				return <IFTTTAction actions={actions} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Create Server Invite") {
				return <CreateServerInvite actions={actions} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Create an Emoji") {
				return <CreateEmoji actions={actions} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Delete an Emoji") {
				return <DeleteEmoji actions={actions} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Manipulate some text") {
				return <StringManipulation actions={actions} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Join a Voice Channel") {
				return <VoiceJoin actions={actions} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Leave a Voice Channel") {
				return <VoiceLeave actions={actions} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Voice Kick a Member") {
				return <VoiceKick actions={actions} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Voice Move a Member") {
				return <VoiceMove actions={actions} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Deafen or Undeafen a Member") {
				return <VoiceDeafen actions={actions} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Mute or Unmute a Member") {
				return <VoiceMute actions={actions} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			}
		} else if (element.type == "root") {
			if (this.props.mode == "command") {
				return <Command name={element.data.title} description={element.data.description} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else {
				return <CustomEvent name={element.data.title} description={element.data.description} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			}
		} else if (element.type == "error") {
			return <ErrorHandler name={element.data.title} description={element.data.description} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
		} else if (element.type == "note") {
			return <NoteAction actions={actions} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
		} else if (element.type == "condition") {
			if (element.data.node_options.title == "Options Condition") {
				return <OptionsCondition options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Chance Condition") {
				return <ChanceCondition options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Channel Condition") {
				return <ChannelCondition options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Role Condition") {
				return <RoleCondition options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "User Condition") {
				return <UserCondition options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Currency Condition") {
				return <CurrencyCondition options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Item Condition") {
				return <ItemCondition options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Custom Variable Condition") {
				return <CustomVariableCondition options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Comparison Condition") {
				return <ComparisonCondition options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Permissions Condition") {
				return <PermissionsCondition options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Regex Match Condition") {
				return <RegexMatchCondition options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.title == "Premium Check Condition") {
				return <PremiumCondition options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			}
		} else if (element.type == "conditionChild") {
			if (element.data.node_options.type == "option" || element.data.node_options.type == "option_condition") {
				return <OptionConditionChild options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.type == "chance") {
				return <ChanceConditionChild options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.type == "channel") {
				return <ChannelConditionChild options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.type == "user") {
				return <UserConditionChild options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.type == "role") {
				return <RoleConditionChild options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.type == "currency") {
				return <CurrencyConditionChild options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.type == "item") {
				return <ItemConditionChild options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.type == "custom_variable") {
				return <CustomVariableConditionChild options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.type == "comparison") {
				return <ComparisonConditionChild options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.type == "permissions") {
				return <PermissionsConditionChild options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			} else if (element.data.node_options.type == "premium") {
				return <PremiumConditionChild options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
			}
		} else if (element.type == "selectMenuOption") {
			return <SelectMenuOption node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
		} else if (element.type == "advMessageSelectMenu") {
			return <AdvSelectMenu actions={actions} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
		} else if (element.type == "advMessageButton") {
			return <AdvButton actions={actions} options={options} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
		}
	};

	renderTutorial = () => {
		var element = this.props.elements.find((element) => element.id == this.props.selected);
		var tutorial = "";
		if (element == undefined) {
			return null;
		} else if (element.type == "option") {
			return <Option title={element.data.node_options.title} description={element.data.node_options.description} node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
		} else if (element.type == "button") {
			return <ButtonHandler node_options={element.data.node_options} data={element.data.data} id={this.props.selected} />;
		} else if (element.type == "action") {
			// Check if has outgoing connection;

			if (element.data.node_options.title == "Plain Text Reply") {
				tutorial = "https://www.youtube.com/watch?v=FPAbIXS4OS0";
			} else if (element.data.node_options.title == "Embed Reply") {
				tutorial = "https://www.youtube.com/watch?v=FPAbIXS4OS0";
			} else if (element.data.node_options.title == "Random Reply") {
				tutorial = "https://www.youtube.com/watch?v=FPAbIXS4OS0";
			} else if (element.data.node_options.title == "Send a message to a Channel") {
				tutorial = "https://www.youtube.com/watch?v=vhRfwj-p3lo";
			} else if (element.data.node_options.title == "Direct Message a User") {
				tutorial = "https://www.youtube.com/watch?v=vhRfwj-p3lo";
			} else if (element.data.node_options.title == "Add Roles") {
			} else if (element.data.node_options.title == "Remove Roles") {
			} else if (element.data.node_options.title == "Send a Message with attached Buttons") {
				tutorial = "https://www.youtube.com/watch?v=SvP9gaXpDlE";
			} else if (element.data.node_options.title == "Wait before running another action") {
			} else if (element.data.node_options.title == "Delete a Channel") {
			} else if (element.data.node_options.title == "Create a Channel") {
			} else if (element.data.node_options.title == "Kick a Member") {
				tutorial = "https://www.youtube.com/watch?v=-soV96HeVH0";
			} else if (element.data.node_options.title == "Ban a Member") {
				tutorial = "https://www.youtube.com/watch?v=QaPtyn9Vb2Y";
			} else if (element.data.node_options.title == "Delete a role") {
			} else if (element.data.node_options.title == "Create a role") {
			} else if (element.data.node_options.title == "Timeout a Member") {
			} else if (element.data.node_options.title == "Add Currency") {
			} else if (element.data.node_options.title == "Remove Currency") {
			} else if (element.data.node_options.title == "Add Item") {
			} else if (element.data.node_options.title == "Remove Item") {
			} else if (element.data.node_options.title == "Send a Message with a Select Menu") {
				tutorial = "https://www.youtube.com/watch?v=Ko4Ff_Y02nQ";
			} else if (element.data.node_options.title == "Delete a Message") {
				tutorial = "https://youtu.be/6aAYImRvszM";
			}
		} else if (element.type == "root") {
		} else if (element.type == "condition") {
			if (element.data.node_options.title == "Options Condition") {
				tutorial = "https://www.youtube.com/watch?v=2rjUTJ74EI8";
			} else if (element.data.node_options.title == "Chance Condition") {
				tutorial = "https://youtu.be/nXhXR9Ay-rY";
			} else if (element.data.node_options.title == "Channel Condition") {
				tutorial = "https://www.youtube.com/watch?v=g1065PeNl7s";
			} else if (element.data.node_options.title == "Role Condition") {
				tutorial = "https://www.youtube.com/watch?v=UUmVHBHtT9M";
			} else if (element.data.node_options.title == "User Condition") {
				tutorial = "https://www.youtube.com/watch?v=M8CrR-taWXY";
			} else if (element.data.node_options.title == "Currency Condition") {
				tutorial = "https://www.youtube.com/watch?v=WGkPUUor8a8";
			}
		} else if (element.type == "conditionChild") {
			if (element.data.node_options.type == "option" || element.data.node_options.type == "option_condition") {
				tutorial = "https://www.youtube.com/watch?v=2rjUTJ74EI8";
			} else if (element.data.node_options.type == "chance") {
				tutorial = "https://youtu.be/nXhXR9Ay-rY";
			} else if (element.data.node_options.type == "channel") {
				tutorial = "https://www.youtube.com/watch?v=g1065PeNl7s";
			} else if (element.data.node_options.type == "user") {
				tutorial = "https://www.youtube.com/watch?v=M8CrR-taWXY";
			} else if (element.data.node_options.type == "role") {
				tutorial = "https://www.youtube.com/watch?v=UUmVHBHtT9M";
			} else if (element.data.node_options.type == "currency") {
				tutorial = "https://www.youtube.com/watch?v=WGkPUUor8a8";
			}
		} else if (element.type == "selectMenuOption") {
			tutorial = "https://www.youtube.com/watch?v=vhRfwj-p3lo";
		}

		if (this.state.tutorial != tutorial) {
			this.setState({ tutorial });
		}
	};

	renderSuccessErrorLabels = () => {
		var element = this.props.elements.find((element) => element.id == this.props.selected);
		if (element?.data?.data?.success_handles != undefined) {
			return (
				<>
					<hr style={{ borderTop: "1px solid #adb5bd" }} className="slash-hr mt-15" />
					<div className="mb-15 flex flex-row items-center gap-x-3">
						<div>
							<h4 className="mb-2">Enable Success/Error Handles</h4>
							<p>Enable or disable success and error handles for this action.</p>
						</div>
						<Toggle
							value={element.data.data.success_handles}
							update={(value) => {
								// this.updateData('success_handles', value);

								// Remove all connected edges if disabled
								var elements = [...this.props.elements];
								var element = elements.find((element) => element.id == this.props.selected);
								element.data.data.success_handles = value;
								var edges = splitElements(this.props.elements).edges;
								var connected_edges = getConnectedEdges([element], edges);
								// Go through connected edges, if they are success or error, remove them via index
								connected_edges.forEach((edge) => {
									console.log(edge, "EDGE");
									if (edge.source != this.props.selected) {
										return;
									}
									var index = elements.findIndex((element) => element.id == edge.id);

									// if (value) {
									if (edge.sourceHandle == "success_actions" || edge.sourceHandle == "error_actions" || edge.sourceHandle == "action") {
										console.log("DELETE EDGE");
										elements.splice(index, 1);
									}
									// }
								});
								console.log(elements, "AFTER");
								this.props.setElements(elements);
							}}
						/>
					</div>

					{
						// If success handles are enabled, show the success and error actions
						element.data?.data?.success_handles ? (
							<div className="mb-15 slash-action">
								<h4>Variables </h4>
								<span style={{ marginBottom: "15px" }}>
									Handle errors when they occur. Use the following variables in actions linked to the <span className="font-bold !text-red">red</span> error handle.{" "}
								</span>
								<p
									style={{
										color: "#fff",
										opacity: "0.7",
										fontWeight: 500,
										marginTop: "15px"
									}}
								>
									<span style={{ color: "#f45142", opacity: "1" }}>{`{error_reason}`}</span> - Returns the reason why the error occurred.
								</p>

								<p
									style={{
										color: "#fff",
										opacity: "0.7",
										fontWeight: 500
									}}
								>
									<span style={{ color: "#f45142", opacity: "1" }}>{`{error_message}`}</span> - Returns the error message associated with the action the error occurred from.
								</p>

								<p
									style={{
										color: "#fff",
										opacity: "0.7",
										fontWeight: 500
									}}
								>
									<span style={{ color: "#f45142", opacity: "1" }}>{`{error_block}`}</span> - Returns name of the block which the error occurred in.
								</p>
							</div>
						) : null
					}
				</>
			);
		}
	};

	renderCommandLabel = () => {
		if (this.props.selected != null) {
			var element = this.props.elements.find((element) => element.id == this.props.selected);
			if (element && element.data && element.data.data && (element.type == "action" || element.type == "condition")) {
				return (
					<div className="mt-15 mb-15">
						<h4 style={{ marginBottom: "0px" }}>Block Label</h4>
						<div className="section-content-header mb-15">Add an optional label to this block. This will change how the block appears in the builder. This can be used to make the command tree more readable.</div>
						<div class="long-input mt-15">
							<label>Label</label>
							<input
								onChange={(e) => {
									var data = { ...element.data.data };
									data.command_label = e.target.value;
									this.props.updateElementData({
										data: data,
										id: element.id
									});
									// this.updateData("guild_id", e.target.value);
								}}
								maxLength={50}
								type="text"
								value={"command_label" in element.data.data ? element.data.data.command_label : element.data.node_options.title}
							/>
						</div>

						<hr style={{ borderTop: "1px solid #adb5bd" }} className="slash-hr mt-15" />
					</div>
				);
			} else {
				return null;
			}
		} else {
			return null;
		}
	};

	checkActionType = () => {
		var element = this.props.elements.find((element) => element.id == this.props.selected);
		if (element && element.data && element.data.data && element.type == "action") {
			return element.data.node_options.title;
		} else {
			return null;
		}
	};

	render() {
		const showMobileView = isMobile && this.props.builder_settings?.mobileBuilder;

		if (this.props.selected != null) {
			return (
				<div
					id="command-builder-properties"
					className={`
						${showMobileView
							? 'fixed inset-0 !z-[9999] bg-[#313338] flex flex-col transform transition-transform duration-200'
							: 'command-builder-props-container fixed top-16 right-0 bottom-0 w-[400px] bg-[#313338] border-l border-[#1e1f22] !z-[150] flex flex-col'}
						${this.props.selected != null ? "open" : ""}
						${showMobileView && !this.props.mobileOpen ? 'translate-x-full' : ''}
					`}
				>
					{/* Header - Different styling for mobile and desktop */}
					<div className={`
						flex items-center justify-between px-4 border-b border-[#1e1f22] shrink-0
						${showMobileView
							? 'h-14 bg-[#1e1f22] sticky top-0 left-0 right-0 z-[9999]'
							: 'h-14 bg-[#1e1f22] relative'}
					`}>
						<h3 className="text-base font-semibold text-[#ffffff] m-0">Block Settings</h3>

						<div className="flex items-center gap-3">
							{this.state.tutorial && (
								<a
									target="_blank"
									href={this.state.tutorial}
									className="flex items-center gap-2 px-2.5 py-1.5 bg-[#2b2d31] text-[#b5bac1] hover:bg-[#35363c] rounded-md transition-colors"
								>
									<FontAwesomeIcon icon={faYoutube} className="w-4 h-4" />
									<span className="text-xs font-medium">Tutorial</span>
								</a>
							)}

							<button
								onClick={() => {
									this.props.onClose();
									this.props.setSelected(null);
								}}
								className="flex items-center justify-center w-8 h-8 rounded-lg bg-[#ed4245] text-white hover:bg-[#c03537] transition-colors"
								aria-label="Close settings"
							>
								<FontAwesomeIcon icon={faTimes} className="w-4 h-4" />
							</button>
						</div>
					</div>

					{/* Main Content */}
					<div className={`
						${showMobileView
							? 'flex-1 overflow-y-auto pb-28 bg-[#2b2d31] pt-0'
							: 'command-builder-props pr-[20px] overflow-y-auto flex-1 relative'} 
						${this.props.selected != null ? "open" : ""}
					`}>
						<div className={`
							command-builder-props-content
							${showMobileView ? 'p-6' : 'pt-6 pr-0'}
						`}>
							{this.renderOptionsComponent()}
							{this.renderSuccessErrorLabels()}
							<hr className="border-t border-[#1e1f22] my-4" />
							{this.renderCommandLabel()}

							{/* Desktop Action Buttons */}
							{!showMobileView && (
								<div className="mt-4 mb-8">
									<div className="flex flex-col gap-2">
										{this.props.selected !== "root" &&
											this.props.selected !== "error_handler" &&
											!this.props.selected.includes("else") && (
												<button
													disabled={this.props.selected === "root" || (this.props.module_id != null && !this.props.premium)}
													onClick={this.deleteBlock}
													className="w-full px-4 py-2 bg-[#ed4245] hover:bg-[#c03537] text-white text-sm rounded-md transition-colors flex items-center justify-center gap-2 font-medium"
												>
													<FontAwesomeIcon icon={faTrash} className="w-4 h-4" />
													<span>Delete Block</span>
												</button>
											)}

										{this.props.selected !== "root" &&
											this.props.selected !== "error_handler" &&
											!(this.props.selected.includes("con") ||
												this.props.selected.includes("option") ||
												this.props.selected.includes("else") ||
												(this.props.selected.includes("button") && !this.props.selected.includes("action")) ||
												this.props.selected.includes("select") ||
												this.checkActionType() === "Run a Loop") && (
												<button
													disabled={this.props.selected === "root" || (this.props.module_id != null && !this.props.premium)}
													onClick={this.copyBlock}
													className="w-full px-4 py-2 bg-[#4e5058] hover:bg-[#6d6f78] text-white rounded-md transition-colors flex items-center justify-center gap-2"
												>
													<FontAwesomeIcon icon={faCopy} />
													<span>Duplicate Block</span>
												</button>
											)}
									</div>
								</div>
							)}
						</div>
					</div>

					{/* Mobile Action Buttons */}
					{showMobileView && (
						<div className="fixed bottom-0 left-0 right-0 p-3 bg-[#1e1f22] border-t border-[#1e1f22] !z-[152]">
							<div className="flex flex-col gap-2">
								{this.props.selected !== "root" &&
									this.props.selected !== "error_handler" &&
									!this.props.selected.includes("else") && (
										<button
											disabled={this.props.selected === "root" || (this.props.module_id != null && !this.props.premium)}
											onClick={this.deleteBlock}
											className="w-full px-4 py-2 bg-[#ed4245] hover:bg-[#c03537] text-white text-sm rounded-md transition-colors flex items-center justify-center gap-2 font-medium"
										>
											<FontAwesomeIcon icon={faTrash} className="w-4 h-4" />
											<span>Delete Block</span>
										</button>
									)}

								{this.props.selected !== "root" &&
									this.props.selected !== "error_handler" &&
									!(this.props.selected.includes("con") ||
										this.props.selected.includes("option") ||
										this.props.selected.includes("else") ||
										(this.props.selected.includes("button") && !this.props.selected.includes("action")) ||
										this.props.selected.includes("select") ||
										this.checkActionType() === "Run a Loop") && (
										<button
											disabled={this.props.selected === "root" || (this.props.module_id != null && !this.props.premium)}
											onClick={this.copyBlock}
											className="w-full px-4 py-2 bg-[#4e5058] hover:bg-[#6d6f78] text-white rounded-md transition-colors flex items-center justify-center gap-2"
										>
											<FontAwesomeIcon icon={faCopy} />
											<span>Duplicate Block</span>
										</button>
									)}
							</div>
						</div>
					)}
				</div>
			);
		}
		return null;
	}
}

const mapStateToProps = (state) => ({
	selected: state.builder.selected,
	elements: state.builder.elements,
	mode: state.builder.mode,
	module_id: state.builder.module_id,
	premium: state.data.premium,
	beta: state.data.beta,
	future: state.builder.future,
	builder_settings: state.data.user.builder_settings
});

const mapDispatchToProps = {
	setSelected,
	setElements,
	updateElementData,
	setFuture
};

function copy(obj) {
	// Get object type
	let type = Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();

	/**
	 * Create an immutable copy of an object
	 * @return {Object}
	 */
	function cloneObj() {
		let clone = {};
		for (let key in obj) {
			if (obj.hasOwnProperty(key)) {
				clone[key] = copy(obj[key]);
			}
		}
		return clone;
	}

	/**
	 * Create an immutable copy of an array
	 * @return {Array}
	 */
	function cloneArr() {
		return obj.map(function (item) {
			return copy(item);
		});
	}

	// Return a clone based on the object type
	if (type === "object") return cloneObj();
	if (type === "array") return cloneArr();
	return obj;
}

let s4 = () => {
	return Math.floor((1 + Math.random()) * 0x10000)
		.toString(16)
		.substring(1);
};

export default connect(mapStateToProps, mapDispatchToProps)(CommandBuilderProperties);
