import React, { useState, useCallback } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import CustomColorPicker from '../../inputs/CustomColorPicker';
import SettingsInput from './components/SettingsInput';
import server from '../../../../api/server';
import { updateBuilderSettings } from '../../../../actions';



function BuilderSettings({ open, closeModal, builder_settings, updateBuilderSettings, premium }) {
    const [selectedTab, setSelectedTab] = useState(0);
    const [defaultEmbedColor, setDefaultEmbedColor] = useState(
        builder_settings?.defaultEmbedColor || '#000000'
    );

    // Generic settings update function
    const updateSetting = useCallback(async (key, value) => {
        try {
            updateBuilderSettings({
                ...builder_settings,
                [key]: value
            });
            const response = await server.post('/dashboard/updateBuilderSettings', {
                settings: {
                    [key]: value
                }
            });

            console.log(response, 'RESPONSE');
            if (response.data.success) {
                console.log('Setting updated:', key, value);
                return true;
            } else {
                console.error('Failed to update setting:', response.message);
                return false;
            }
        } catch (error) {
            console.error('Error updating setting:', error);
            return false;
        }
    }, [builder_settings, updateBuilderSettings]);

    // Update color when builder_settings changes
    React.useEffect(() => {
        if (builder_settings?.defaultEmbedColor) {
            setDefaultEmbedColor(builder_settings.defaultEmbedColor);
        }
    }, [builder_settings]);

    // Specific handler for color changes
    const handleColorChange = useCallback((color) => {
        setDefaultEmbedColor(color);
        updateSetting('defaultEmbedColor', color);
    }, [updateSetting]);

    // Add useEffect to disable autosave if not premium
    React.useEffect(() => {
        if (!premium && builder_settings?.autoSave) {
            updateBuilderSettings({
                ...builder_settings,
                autoSave: false
            });
            server.post('/dashboard/updateBuilderSettings', {
                settings: {
                    autoSave: false
                }
            });
        }
    }, [premium, builder_settings]);

    const handleAutoSaveChange = useCallback((enabled) => {
        // Check premium before allowing enable
        if (enabled && !premium) {
            return;
        }

        // First update Redux state
        updateBuilderSettings({
            ...builder_settings,
            autoSave: enabled,
            // If disabling, remove the interval. If enabling, set default interval
            autoSaveInterval: enabled ? (builder_settings?.autoSaveInterval || 60) : null
        });

        // Then update server
        server.post('/dashboard/updateBuilderSettings', {
            settings: {
                autoSave: enabled,
                autoSaveInterval: enabled ? (builder_settings?.autoSaveInterval || 60) : null
            }
        });
    }, [builder_settings, updateBuilderSettings, premium]);

    const handleIntervalChange = useCallback((e) => {
        const value = parseInt(e.target.value);
        if (!isNaN(value)) {
            // If value is below 60, set it to 60, otherwise use the input value
            updateSetting('autoSaveInterval', value);
        }
    }, [updateSetting]);

    const handleResetSettings = useCallback(async () => {
        const defaultSettings = {
            autoSave: false,
            autoSaveInterval: 60,
            actionColor: '#358deb',
            conditionColor: '#28a745',
            elseColor: '#f45142',
            optionColor: '#8d0eab',
            rootColor: '#ffc107',
            defaultEmbedColor: '#000000',
            mobileBuilder: false
        };

        try {
            // Update Redux state
            updateBuilderSettings(defaultSettings);

            // Update server
            await server.post('/dashboard/updateBuilderSettings', {
                // settings: defaultSettings,

                reset: true
            });

            // Update local state for embed color
            setDefaultEmbedColor(defaultSettings.defaultEmbedColor);
        } catch (error) {
            console.error('Error resetting settings:', error);
        }
    }, [updateBuilderSettings]);

    const handleMobileBuilderChange = useCallback(async (enabled) => {
        await updateSetting('mobileBuilder', enabled);
        // Refresh and redirect to root after the setting is updated
        window.location.href = '/';
    }, [updateSetting]);

    const tabs = [
        {
            name: 'General',
            content: (
                <div className="space-y-8">
                    <div>
                        <h3 className="text-[22px] font-semibold text-[#060607] dark:text-[#ffffff]">
                            General Settings
                        </h3>
                        <p className="mt-1 text-[#4e5058] dark:text-[#b5bac1] text-sm">
                            Configure general builder settings
                        </p>
                    </div>

                    <div className="space-y-6">
                        {/* Auto Save Setting */}
                        <div className="border-t border-[#e3e5e8] dark:border-[#3f4147] pt-6">
                            <div className="flex justify-between items-start">
                                <div>
                                    <div className="flex items-center gap-2">
                                        <h4 className="text-[16px] font-semibold text-[#060607] dark:text-[#ffffff]">
                                            Auto Save
                                        </h4>
                                        <span className="px-1.5 py-0.5 text-xs font-medium bg-[#ed4245] text-white rounded">
                                            Premium
                                        </span>
                                    </div>
                                    <p className="mt-1 text-[#4e5058] dark:text-[#b5bac1] text-sm">
                                        {premium ? (
                                            "Automatically save changes if possible. Will only save when all blocks are properly configured and connected."
                                        ) : (
                                            <span className="flex items-center gap-2">
                                                <span>Upgrade to Premium to enable auto-save functionality.</span>
                                            </span>
                                        )}
                                        <br />
                                        <span className="text-[#87898C] dark:text-[#949BA4] text-xs">
                                            Minimum interval: 60 seconds
                                        </span>
                                    </p>
                                </div>
                                <div className="flex items-center gap-3">
                                    {/* Toggle Switch */}
                                    <button
                                        onClick={() => handleAutoSaveChange(!builder_settings?.autoSave)}
                                        className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors ${builder_settings?.autoSave
                                            ? 'bg-[#5865f2]'
                                            : 'bg-[#e3e5e8] dark:bg-[#3f4147]'
                                            } ${!premium ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
                                        disabled={!premium}
                                    >
                                        <span
                                            className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${builder_settings?.autoSave ? 'translate-x-6' : 'translate-x-1'
                                                }`}
                                        />
                                    </button>

                                    {/* Interval Input - only shown if auto save is enabled and premium */}
                                    {builder_settings?.autoSave && premium && (
                                        <div className="flex items-center gap-2">
                                            <input
                                                type="number"
                                                value={builder_settings?.autoSaveInterval || 60}
                                                onChange={handleIntervalChange}
                                                className="w-[80px] px-2.5 py-1.5 bg-transparent border border-[#e3e5e8] dark:border-[#3f4147] rounded text-sm text-[#060607] dark:text-[#ffffff] focus:outline-none focus:ring-2 focus:ring-[#5865f2] focus:border-transparent"
                                            />
                                            <span className="text-sm text-[#4e5058] dark:text-[#b5bac1]">seconds</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Mobile Builder Setting */}
                        <div className="border-t border-[#e3e5e8] dark:border-[#3f4147] pt-6">
                            <div className="flex justify-between items-start">
                                <div>
                                    <div className="flex items-center gap-2">
                                        <h4 className="text-[16px] font-semibold text-[#060607] dark:text-[#ffffff]">
                                            Mobile Builder
                                        </h4>
                                        <span className="px-1.5 py-0.5 text-xs font-medium bg-[#f0b232] text-white rounded">
                                            Experimental
                                        </span>
                                    </div>
                                    <p className="mt-1 text-[#4e5058] dark:text-[#b5bac1] text-sm">
                                        Enable the new experimental mobile builder interface
                                        <br />
                                        <span className="text-[#87898C] dark:text-[#949BA4] text-xs">
                                            This feature is in beta and may have some bugs
                                        </span>
                                    </p>
                                </div>
                                <div className="flex items-center">
                                    <button
                                        onClick={() => handleMobileBuilderChange(!builder_settings?.mobileBuilder)}
                                        className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors ${builder_settings?.mobileBuilder
                                            ? 'bg-[#5865f2]'
                                            : 'bg-[#e3e5e8] dark:bg-[#3f4147]'
                                            } cursor-pointer`}
                                    >
                                        <span
                                            className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${builder_settings?.mobileBuilder ? 'translate-x-6' : 'translate-x-1'}`}
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Hide Old Reply Actions Setting */}
                        <div className="border-t border-[#e3e5e8] dark:border-[#3f4147] pt-6">
                            <div className="flex justify-between items-start">
                                <div>
                                    <h4 className="text-[16px] font-semibold text-[#060607] dark:text-[#ffffff]">
                                        Hide Legacy Reply Actions
                                    </h4>
                                    <p className="mt-1 text-[#4e5058] dark:text-[#b5bac1] text-sm">
                                        Hide the old reply actions in favor of using the new Advanced Message system
                                    </p>
                                </div>
                                <div className="flex items-center gap-3">
                                    <button
                                        onClick={() => updateSetting('hideOldReplyActions', builder_settings?.hideOldReplyActions === undefined ? false : !builder_settings?.hideOldReplyActions)}
                                        className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors ${builder_settings?.hideOldReplyActions !== false ? 'bg-[#5865f2]' : 'bg-[#e3e5e8] dark:bg-[#3f4147]'
                                            } cursor-pointer`}
                                    >
                                        <span
                                            className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${builder_settings?.hideOldReplyActions !== false ? 'translate-x-6' : 'translate-x-1'
                                                }`}
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        },
        {
            name: 'Block Colors',
            content: (
                <div className="space-y-8">
                    <div>
                        <h3 className="text-[18px] md:text-[22px] font-semibold text-[#060607] dark:text-[#ffffff]">
                            Block Colors
                        </h3>
                        <p className="mt-1 text-[#4e5058] dark:text-[#b5bac1] text-sm">
                            Customize the colors of different block types
                        </p>
                    </div>

                    <div className="space-y-6">
                        <CustomColorPicker
                            settings={{
                                title: "Action Color",
                                description: "Set the default color for action blocks",
                                premium: false
                            }}
                            value={builder_settings?.actionColor || '#358deb'}
                            change={(color) => updateSetting('actionColor', color)}
                            slot={true}
                        />

                        <CustomColorPicker
                            settings={{
                                title: "Condition Color",
                                description: "Set the default color for condition blocks",
                                premium: false
                            }}
                            value={builder_settings?.conditionColor || '#28a745'}
                            change={(color) => updateSetting('conditionColor', color)}
                            slot={true}
                        />

                        <CustomColorPicker
                            settings={{
                                title: "Else Color",
                                description: "Set the default color for else condition blocks",
                                premium: false
                            }}
                            value={builder_settings?.elseColor || '#f45142'}
                            change={(color) => updateSetting('elseColor', color)}
                            slot={true}
                        />

                        <CustomColorPicker
                            settings={{
                                title: "Option Color",
                                description: "Set the default color for option blocks",
                                premium: false
                            }}
                            value={builder_settings?.optionColor || '#8d0eab'}
                            change={(color) => updateSetting('optionColor', color)}
                            slot={true}
                        />

                        <CustomColorPicker
                            settings={{
                                title: "Root Color",
                                description: "Set the default color for the root block",
                                premium: false
                            }}
                            value={builder_settings?.rootColor || '#ffc107'}
                            change={(color) => updateSetting('rootColor', color)}
                            slot={true}
                        />

                        <CustomColorPicker
                            settings={{
                                title: "Selected Block Color",
                                description: "Set the outline color for selected blocks",
                                premium: false
                            }}
                            value={builder_settings?.selectedColor || '#5865f2'}
                            change={(color) => updateSetting('selectedColor', color)}
                            slot={true}
                        />
                    </div>
                </div>
            )
        },
        {
            name: 'Block Defaults',
            content: (
                <div className="space-y-8">
                    <div>
                        <h3 className="text-[18px] md:text-[22px] font-semibold text-[#060607] dark:text-[#ffffff]">
                            Block Defaults
                        </h3>
                        <p className="mt-1 text-[#4e5058] dark:text-[#b5bac1] text-sm">
                            Configure default settings for new blocks
                        </p>
                    </div>

                    <div className="space-y-6">
                        <div className="border-t border-[#e3e5e8] dark:border-[#3f4147] pt-6">
                            <h4 className="text-[16px] font-semibold text-[#060607] dark:text-[#ffffff] mb-4">
                                Advanced Message
                            </h4>
                            <CustomColorPicker
                                settings={{
                                    title: "Default Embed Color",
                                    description: "Set the default color for new embed blocks",
                                    premium: false
                                }}
                                value={defaultEmbedColor}
                                change={handleColorChange}
                                slot={true}
                            />
                        </div>

                        <div className="border-t border-[#e3e5e8] dark:border-[#3f4147] pt-6">
                            <h4 className="text-[16px] font-semibold text-[#060607] dark:text-[#ffffff] mb-4">
                                Comparison Condition
                            </h4>
                            <div className="flex flex-col md:flex-row md:justify-between md:items-start gap-4 md:gap-0">
                                <div className="flex-1">
                                    <h4 className="text-[16px] font-semibold text-[#060607] dark:text-[#ffffff]">
                                        Default Condition Type
                                    </h4>
                                    <p className="mt-1 text-[#4e5058] dark:text-[#b5bac1] text-sm">
                                        Set the default condition type for new comparison blocks
                                    </p>
                                </div>
                                <div className="w-full md:w-[280px]">
                                    <select
                                        value={builder_settings?.defaultConditionType || 'equal_to'}
                                        onChange={(e) => updateSetting('defaultConditionType', e.target.value)}
                                        className="w-full px-2.5 py-1.5 bg-transparent border border-[#e3e5e8] dark:border-[#3f4147] rounded text-sm text-[#060607] dark:text-[#ffffff] focus:outline-none focus:ring-2 focus:ring-[#5865f2] focus:border-transparent"
                                    >
                                        <option value="less_than" className="bg-[#ffffff] dark:bg-[#313338]">Less than</option>
                                        <option value="less_than_equal_to" className="bg-[#ffffff] dark:bg-[#313338]">Less than or equal to</option>
                                        <option value="greater_than" className="bg-[#ffffff] dark:bg-[#313338]">Greater than</option>
                                        <option value="greater_than_equal_to" className="bg-[#ffffff] dark:bg-[#313338]">Greater than or equal to</option>
                                        <option value="equal_to" className="bg-[#ffffff] dark:bg-[#313338]">Equal to</option>
                                        <option value="not" className="bg-[#ffffff] dark:bg-[#313338]">Not equal to</option>
                                        <option value="contains" className="bg-[#ffffff] dark:bg-[#313338]">Contains</option>
                                        <option value="not_contains" className="bg-[#ffffff] dark:bg-[#313338]">Does not contain</option>
                                        <option value="starts_with" className="bg-[#ffffff] dark:bg-[#313338]">Starts with</option>
                                        <option value="ends_with" className="bg-[#ffffff] dark:bg-[#313338]">Ends with</option>
                                        <option value="not_starts_with" className="bg-[#ffffff] dark:bg-[#313338]">Does not start with</option>
                                        <option value="not_ends_with" className="bg-[#ffffff] dark:bg-[#313338]">Does not end with</option>
                                        <option value="array_contains" className="bg-[#ffffff] dark:bg-[#313338]">Collection contains</option>
                                        <option value="array_not_contain" className="bg-[#ffffff] dark:bg-[#313338]">Collection does not contain</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        },
        {
            name: 'Keybinds',
            content: (
                <div className="space-y-8">
                    {/* Page Header */}
                    <div>
                        <h3 className="text-[22px] font-semibold text-[#060607] dark:text-[#ffffff]">
                            Keyboard Shortcuts
                        </h3>
                        <p className="mt-1 text-[#4e5058] dark:text-[#b5bac1] text-sm">
                            Configure keyboard shortcuts for the builder
                        </p>
                    </div>

                    {/* Coming Soon Message */}
                    <div className="flex flex-col items-center justify-center py-16 text-center">
                        <h4 className="text-xl font-semibold text-[#060607] dark:text-[#ffffff] mb-2">
                            Coming Soon
                        </h4>
                        <p className="text-[#4e5058] dark:text-[#b5bac1] text-sm max-w-sm">
                            We're working on bringing keyboard shortcuts to the builder. Stay tuned for updates!
                        </p>
                    </div>
                </div>
            )
        }
    ];

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-[10000]" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-[#000000]/75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-50 overflow-hidden">
                    <div className="flex min-h-full items-center justify-center p-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative w-[95vw] md:w-[1200px] max-w-[95vw] h-[95vh] md:h-[800px] max-h-[95vh] transform overflow-hidden rounded-lg bg-[#ffffff] dark:bg-[#313338] shadow-xl transition-all">
                                <div className="h-full flex flex-col">
                                    <div className="flex-1 flex flex-col md:flex-row">
                                        {/* Mobile Tab Navigation */}
                                        <div className="md:hidden flex overflow-x-auto px-4 py-2 bg-[#f2f3f5] dark:bg-[#2b2d31] border-b border-[#e3e5e8] dark:border-[#1e1f22] relative z-[2]">
                                            {tabs.map((tab, index) => (
                                                <button
                                                    key={tab.name}
                                                    onClick={() => setSelectedTab(index)}
                                                    className={`flex-shrink-0 px-4 py-2 text-[14px] font-medium transition-colors ${selectedTab === index
                                                        ? 'text-[#5865f2] border-b-2 border-[#5865f2]'
                                                        : 'text-[#4e5058] dark:text-[#b5bac1]'
                                                        }`}
                                                >
                                                    {tab.name}
                                                </button>
                                            ))}
                                        </div>

                                        {/* Desktop Sidebar - Hidden on Mobile */}
                                        <div className="hidden md:flex w-60 bg-[#f2f3f5] dark:bg-[#2b2d31] border-r border-[#e3e5e8] dark:border-[#1e1f22] flex-col relative z-[1]">
                                            {/* Sidebar content */}
                                            <div className="flex-1 px-3 py-4">
                                                <Dialog.Title className="px-3 text-[16px] font-semibold text-[#060607] dark:text-[#ffffff]">
                                                    Builder Settings
                                                </Dialog.Title>
                                                <div className="mt-4 space-y-0.5">
                                                    {tabs.map((tab, index) => (
                                                        <button
                                                            key={tab.name}
                                                            onClick={() => setSelectedTab(index)}
                                                            className={`w-full text-left px-3 py-1.5 rounded text-[14px] font-medium transition-colors ${selectedTab === index
                                                                ? 'bg-[#e3e5e8] dark:bg-[#404249] text-[#060607] dark:text-[#ffffff]'
                                                                : 'text-[#4e5058] dark:text-[#b5bac1] hover:bg-[#e3e5e8] hover:dark:bg-[#35363c] hover:text-[#060607] hover:dark:text-[#ffffff]'
                                                                }`}
                                                        >
                                                            {tab.name}
                                                        </button>
                                                    ))}
                                                </div>
                                            </div>

                                            {/* Reset button at bottom of sidebar */}
                                            <div className="p-3 border-t border-[#e3e5e8] dark:border-[#1e1f22]">
                                                <button
                                                    onClick={handleResetSettings}
                                                    className="w-full px-3 py-1.5 bg-[#ed4245] hover:bg-[#c03537] text-white text-sm rounded-md transition-colors flex items-center justify-center gap-2"
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                                    </svg>
                                                    Reset Settings
                                                </button>
                                            </div>
                                        </div>

                                        {/* Main content */}
                                        <div className="flex-1 overflow-y-auto">
                                            <div className="px-4 md:px-10 py-6 md:py-8">
                                                <div className="max-w-3xl mx-auto">
                                                    {tabs[selectedTab].content}
                                                </div>
                                            </div>
                                        </div>

                                        {/* Mobile Reset Button */}
                                        <div className="md:hidden p-4 border-t border-[#e3e5e8] dark:border-[#1e1f22]">
                                            <button
                                                onClick={handleResetSettings}
                                                className="w-full px-3 py-1.5 bg-[#ed4245] hover:bg-[#c03537] text-white text-sm rounded-md transition-colors flex items-center justify-center gap-2"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                                </svg>
                                                Reset Settings
                                            </button>
                                        </div>

                                        {/* Close button */}
                                        <button
                                            type="button"
                                            className="absolute top-2 md:top-4 right-2 md:right-4 p-2 text-[#4e5058] dark:text-[#b5bac1] hover:text-[#060607] dark:hover:text-[#ffffff] rounded transition-colors z-[3]"
                                            onClick={closeModal}
                                        >
                                            <span className="sr-only">Close</span>
                                            <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

const mapStateToProps = (state) => ({
    builder_settings: state.data.user.builder_settings,
    premium: state.data.premium
});

const mapDispatchToProps = {
    updateBuilderSettings
};

export default connect(mapStateToProps, mapDispatchToProps)(BuilderSettings); 