import update from "immutability-helper";

import { CLEAN_BOT } from "../variables";

const INIT_STATE = {
	bot: CLEAN_BOT,
	pre_save: CLEAN_BOT,
	user: {
		favModules: [],
		ifttt_test: false,
		builder_settings: {}
	},
	premium: false,
	loading: true,
	save_required: false,
	processing: false,
	co_create: false,
	collab: false,
	server_members_intent: true,
	bot_data: [],
	api_errors: [],
	modules: [],
	bots: [],
	created_at: null,
	beta: false,
	expires: null,
	logsChecked: null,
	current_sale: null,
	bv: 1
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case "SET_DATA":
			return update(state, {
				bot: { $set: action.payload.bot },
				pre_save: { $set: action.payload.bot },
				user: { $set: action.payload.user },
				premium: { $set: action.payload.bot.premium },
				loading: { $set: false },
				beta: { $set: action.payload.beta },
				modules: { $set: action.payload.modules },
				api_errors: { $set: action.payload.api_errors },
				created_at: { $set: action.payload.created_at },
				expires: { $set: action.payload.expires },
				current_sale: { $set: action.payload.current_sale },
				server_members_intent: { $set: action.payload.server_members_intent },
				bv: { $set: action.payload.bv },
				active_sale: { $set: action.payload.active_sale }
			});
		case "SAVE_REQUIRED":
			return update(state, {
				save_required: { $set: true }
			});
		case "UPDATE_PROCESSING": {
			return update(state, {
				processing: { $set: action.payload }
			});
		}
		case "UPDATE_INACTIVE_BOT": {
			return update(state, {
				bot: {
					bot_inactive: { $set: false }
				}
			});
		}
		case "UPDATE_BUILDER_SETTINGS": {
			return update(state, {
				user: {
					builder_settings: { $set: action.payload }
				}
			});
		}
		case "REMOVE_SERVER_LIMIT_ERROR": {
			return update(state, {
				bot: {
					server_limit_error: { $set: null }
				}
			});
		}

		case "IFTTT_LINK_UPDATE": {
			return update(state, {
				user: {
					ifttt_linked: { $set: action.payload }
				}
			});
		}

		case "SET_FAV_MODULES": {
			return update(state, {
				user: {
					favModules: { $set: action.payload }
				}
			});
		}

		case "UPDATE_USAGE": {
			return update(state, {
				user: {
					current_usage: { $set: action.payload.current_usage },
					storage_limit: { $set: action.payload.storage_limit },
					storage_by_variable: { $set: action.payload.storage_by_variable },
					usage_by_variable: { $set: action.payload.usage_by_variable }
				}
			});
		}
		case "SET_BLOCK_TEMPLATES": {
			return update(state, {
				user: {
					block_templates: { $set: action.payload }
				}
			});
		}
		case "UPDATE_MODALS": {
			return update(state, {
				user: {
					modals: { $set: action.payload }
				}
			});
		}
		case "UPDATE_SERVER_POPUP": {
			return update(state, {
				user: {
					server_popup: { $set: true }
				}
			});
		}
		case "SET_BOT_SETTINGS":
			return update(state, {
				save_required: { $set: true },
				bot: {
					[action.payload.key]: { $set: action.payload.value }
				}
			});
		case "SET_BOT_MODULE":
			return update(state, {
				save_required: { $set: true },
				bot: {
					commands: {
						[action.payload.module]: { $set: action.payload.module_data }
					}
				}
			});
		case "SET_BOT_DATA":
			return update(state, {
				bot_data: { $set: action.payload }
			});
		case "SET_BOTS":
			return update(state, {
				bots: { $set: action.payload }
			});
		case "UPDATE_EMPTY_BLACKLIST":
			return update(state, {
				bot: {
					commands: {
						automod: {
							automod: {
								options: {
									empty: { $set: action.payload }
								}
							}
						}
					}
				}
			});
		case "UPDATE_ERRORLOGS_CHECKED":
			return update(state, {
				logsChecked: { $set: action.payload }
			});
		case "UPDATE_COMMAND_OPTIONS":
			return update(state, {
				save_required: { $set: true },
				bot: {
					commands: {
						[action.payload.module]: {
							[action.payload.command]: { $set: action.payload.options }
						}
					}
				}
			});
		case "UPDATE_CUSTOM_COMMAND_OPTIONS":
			return update(state, {
				save_required: { $set: true },
				bot: {
					commands: {
						autoresponder: { $set: action.payload }
					}
				}
			});
		case "UPGRADE_SUBSCRIPTION":
			return update(state, {
				processing: { $set: false },
				user: {
					tokens: { $set: action.payload.tokens },
					transactions: { $set: action.payload.transactions }
				}
			});
		case "UPDATE_BETA":
			return update(state, {
				beta: { $set: action.payload }
			});
		case "UPGRADE_BOT":
			return update(state, {
				save_required: { $set: true },
				bot: { $set: action.payload.bot },
				premium: { $set: action.payload.premium },
				user: {
					tokens: { $set: action.payload.tokens }
				}
			});
		case "UPDATE_SUBMODULE_COMMAND_OPTIONS":
			return update(state, {
				save_required: { $set: true },
				bot: {
					commands: {
						[action.payload.module]: {
							[action.payload.submodule]: {
								[action.payload.command]: { $set: action.payload.options }
							}
						}
					}
				}
			});
		case "REVERT_CHANGES":
			return update(state, {
				bot: { $set: action.payload },
				save_required: { $set: false }
			});
		case "CHANGE_NAME":
			return update(state, {
				bot: {
					name: { $set: action.payload }
				}
			});

		case "CHANGE_IMAGE":
			return update(state, {
				bot: {
					img: { $set: action.payload }
				}
			});
		case "SAVE_BOT":
			return update(state, {
				save_required: { $set: false },
				pre_save: { $set: action.payload }
			});
		case "CHANGE_BOT":
			return update(state, {
				bot: { $set: action.payload.data.bot },
				pre_save: { $set: action.payload.data.bot },
				premium: { $set: action.payload.data.bot.premium },
				co_create: { $set: action.payload.cocreator },
				api_errors: { $set: action.payload.data.api_errors },
				created_at: { $set: action.payload.data.created_at },
				expires: { $set: action.payload.data.expires },
				beta: { $set: action.payload.data.beta },
				server_members_intent: { $set: action.payload.data.server_members_intent },
				bot_data: { $set: [] },
				collab: { $set: action.payload.collab }
			});
		case "SET_COCREATE":
			return update(state, {
				co_create: { $set: action.payload }
			});
		case "SET_COLLAB":
			return update(state, {
				collab: { $set: action.payload }
			});
		case "SET_ONLINE_STATUS":
			return update(state, {
				bot: {
					online: { $set: action.payload }
				}
			});
		case "UPDATE_ERROR_LOGS":
			return update(state, {
				api_errors: { $set: action.payload }
			});
		case "UPDATE_NOTIFICATIONS":
			return update(state, {
				user: {
					notifications: { $set: action.payload }
				}
			});
		case "SET_SERVER_INTENTS":
			return update(state, {
				server_members_intent: { $set: action.payload }
			});
		default:
			return state;
	}
};
