import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import DefaultHandle from './DefaultHandle';
import { isMobile } from 'react-device-detect';

export class ConditionChildNode extends Component {
    renderConditionChildOptions = (id, data) => {
        console.log(data, 'CONDITION DATA');
        if (data.node_options.type == "option_condition" || data.node_options.type == "option") {
            return (
                <div className="object-info object-info-condition" style={{ justifyContent: "center" }}>
                    <span className="object-description">{data.data.option}</span>
                    <span className="object-title">{data.data.type == "else" ? "Else" : "name" in data.data && data.data.name != "" ? data.data.name : data.data.value}</span>
                </div>
            );
        } else if (data.node_options.type == "chance") {
            return (
                <div className="object-info object-info-condition" style={{ justifyContent: "center" }}>
                    <span className="object-description">{data.data.type == "else" ? "Else" : "Chance"}</span>
                    <span className="object-title">{data.data.type == "else" ? "Else" : `${data.data.value}%`}</span>
                </div>
            );
        } else if (data.node_options.type == "channel" || data.node_options.type == "role") {
            return (
                <div className="object-info object-info-condition" style={{ justifyContent: "center" }}>
                    <span className="object-description">{data.data.type == "else" ? "Else" : data.data.id}</span>
                    <span className="object-title">{data.data.type == "else" ? "Else" : `${data.data.name}`}</span>
                </div>
            );
        } else if (data.node_options.type == "user") {
            return (
                <div className="object-info object-info-condition" style={{ justifyContent: "center" }}>
                    <span className="object-description">User</span>
                    <span className="object-title">{data.data.type == "else" ? "Else" : `${data.data.id}`}</span>
                </div>
            );
        } else if (data.node_options.type == "currency") {
            return (
                <div className="object-info object-info-condition" style={{ justifyContent: "center" }}>
                    <span className="object-description">
                        <span className="object-description">{data.data.type == "else" ? "Else" : this.renderChanceType(data.data.type)}</span>
                    </span>
                    <span className="object-title">{data.data.type == "else" ? "Else" : `$${data.data.amount}`}</span>
                </div>
            );
        } else if (data.node_options.type == "item") {
            return (
                <div className="object-info object-info-condition" style={{ justifyContent: "center" }}>
                    <span className="object-description">
                        <span className="object-description">{data.data.type == "else" ? "Else" : this.itemConditionChild(data.data.possesses, data.data.amount)}</span>
                    </span>
                    <span className="object-title">{data.data.type == "else" ? "Else" : `${data.data.item}`}</span>
                </div>
            );
        } else if (data.node_options.type == "custom_variable") {
            return (
                <div className="object-info object-info-condition" style={{ justifyContent: "center" }}>
                    <span className="object-description">
                        <span className="object-description">{data.data.type == "else" ? "Else" : `${this.renderVariableConditionName(id)} ${this.renderChanceType(data.data.type)}`}</span>
                    </span>
                    <span className="object-title">{data.data.type == "else" ? "Else" : `${data.data.value}`}</span>
                </div>
            );
        } else if (data.node_options.type == "comparison") {
            return (
                <div className="object-info object-info-condition" style={{ justifyContent: "center" }}>
                    <span className="object-description">
                        <span className="object-description">{data.data.type == "else" ? "Else" : `${this.renderComparisonConditionBase(id)} ${this.renderChanceType(data.data.type)}`}</span>
                    </span>
                    <span className="object-title">{data.data.type == "else" ? "Else" : `${data.data.comparison_value}`}</span>
                </div>
            );
        } else if (data.node_options.type == "permissions") {
            return (
                <div className="object-info object-info-condition" style={{ justifyContent: "center" }}>
                    <span className="object-description">
                        <span className="object-description">{data.data.type == "else" ? "Else" : `${data.data.type == "has_all" ? "Has all of these permissions" : "Has at least one of these permissions"}`}</span>
                    </span>
                    <span className="object-title">{data.data.type == "else" ? "Else" : `${data.data.permissions.join(", ")}`}</span>
                </div>
            );
        } else if (data.node_options.type == "premium") {
            return (
                <div className="object-info object-info-condition" style={{ justifyContent: "center" }}>
                    <span className="object-description">
                        <span className="object-description">{data.data.type == "else" ? "Else" : "Premium"}</span>
                    </span>
                    <span className="object-title">{data.data.type == "else" ? "Not Premium" : "Premium"}</span>
                </div>
            );
        }
    };

    renderChanceType = (type) => {
        if (type == "less_than") return "is less than";
        if (type == "less_than_equal_to") return "is less than or equal to";
        if (type == "greater_than") return "is greater than";
        if (type == "greater_than_equal_to") return "is greater than or equal to";
        if (type == "equal_to") return "is equal to";
        if (type == "not") return "is not equal to";
        if (type == "contains") return "contains";
        if (type == "not_contains") return "does not contain";
        if (type == "starts_with") return "starts with";
        if (type == "ends_with") return "ends with";
        if (type == "not_starts_with") return "does not start with";
        if (type == "not_ends_with") return "does not end with";
        if (type == "array_contains") return "collection contains";
        if (type == "array_not_contain") return "collection does not contain";
    };

    itemConditionChild = (possesses, amount) => {
        return `Owns ${amount} or ${possesses ? "more" : "less"}`;
    };

    renderVariableConditionName = (id) => {
        var name = "";
        var parentCondition = this.props.elements?.find(element => {
            if (!element.data?.data) return false;
            return element.data.data.reference === id;
        });

        if (parentCondition && parentCondition.data) {
            name = `{BGVAR_${parentCondition.data.data.reference}}`;
        }

        return name;
    };

    renderComparisonConditionBase = (id) => {
        var name = "";
        var parentCondition = this.props.elements?.find(element => {
            if (!element.data?.data) return false;
            return element.data.data.reference === id;
        });

        if (parentCondition && parentCondition.data) {
            name = parentCondition.data.data.base_value;
        }

        return name;
    };

    render() {
        const {
            id,
            data,
            selected,
            builder_settings,
            nodeHandleID,
            handleType,
            handleId,
            actionConnection,
            setSelected
        } = this.props;

        var useMobileBuilder = builder_settings?.mobileBuilder && isMobile;
        var nodePadding = useMobileBuilder ? "py-[50px]" : "py-[25px]";

        return (
            <>
                <DefaultHandle
                    nodeType="condition"
                    selectedNodeHandleType={handleId}
                    nodeHandleID={nodeHandleID}
                    selectedNodeID={nodeHandleID}
                    selectedHandleType={handleType}
                    id={id}
                    position="top"
                    handleType="target"
                    isValidConnection={actionConnection}
                    nonInteractive={true}
                    builder_settings={builder_settings}
                />

                <div
                    id={id}
                    onTouchStart={(e) => {
                        setSelected(id);
                    }}
                    onClick={(e) => {
                        setSelected(id);
                    }}
                    style={{
                        border: selected == id
                            ? `2px solid ${builder_settings?.selectedColor || '#5865f2'}`
                            : '2px solid transparent'
                    }}
                    className={`action-node ${nodePadding}`}
                >
                    <div className="inner" style={{ display: "flex" }}>
                        <div
                            className={`object-icon object-icon-${data.data.type == "else" ? "else" : "condition"}`}
                            style={{
                                backgroundColor: data.data.type == "else"
                                    ? builder_settings?.elseColor || '#f45142'
                                    : builder_settings?.conditionColor || '#28a745'
                            }}
                        >
                            <FontAwesomeIcon icon={faQuestion} />
                        </div>

                        {this.renderConditionChildOptions(id, data)}
                    </div>
                </div>

                <DefaultHandle
                    nodeType="action"
                    selectedNodeHandleType={handleId}
                    nodeHandleID={nodeHandleID}
                    selectedNodeID={nodeHandleID}
                    selectedHandleType={handleType}
                    id={id}
                    position="bottom"
                    handleType="source"
                    isValidConnection={actionConnection}
                />
            </>
        );
    }
} 