import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import server from '../../../api/server';

import history from "../../../history";
import "../styles/requestbuilder.css";
import Toggle from './Toggle';
import ReactJSON from 'react-json-view';
import axios from 'axios';
import _ from "lodash";
import DocsLink from './DocsLink';
import update from "immutability-helper";
import { Dialog, Transition } from "@headlessui/react";

export class RequestBuilder extends Component {

    constructor(props) {
        super(props);

        this.state = {
            mode: "params",
            loaded: false,
            loading: false,
            variable: "",
            api_data: {
                response: { test: "dog" },
                status: "",
                statusText: "",
            }
        };
    }

    componentDidMount() {
        this.setState({ variable: `{${this.props.name}}` });

        // navigator.clipboard.readText()
        //     .then(text =>{
        //         console.log(text)
        //     })
        //     .catch((e) =>{
        //         console.log(e)
        //     })
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log("Updating data");
    }

    testRequest = async () => {

        this.setState({ loaded: false, loading: true });
        var response = await server.post("/dashboard/test_api_action", {
            request_options: this.props.request_options,
            bot_id: this.props.bot_id,
        });

        if (response && response.data && response.data.success == true) {
            var variable = `{${this.props.name}}`;
            if ("response" in response.data.api_data) {
                variable = `{${this.props.name}.response.${Object.keys(response.data.api_data.response)[0]}}`;
            }
            this.setState({
                api_data: response.data.api_data,
                loaded: true,
                loading: false,
                variable: variable
            });
        }



        // if (response && response.data) {
        //     var api_data = {
        //         response: response.data,
        //         status: response.status,
        //         statusText: response.statusText,
        //     };
        //     this.setState({ api_data: api_data });
        // }
    };

    modalBackgroundClick = (e) => {
        // console.log("MODAL BACKGROUND CLICK");
        if (e.currentTarget == e.target) {
            this.props.closeModal(e);
        }
    };

    import = async () => {
        this.setState({ disabled: true, error: "" });
        const response = await server.post("/dashboard/importcommand", { id: this.state.code });
        // console.log(response, 'response');
        if (response.data == false || response.data.command == undefined) {
            // ERROR
            this.setState({ disabled: false, error: "Could not find a command with that share code." });
        } else {
            this.props.importCommand(response.data.command);
        }
    };


    renderOptions = (mode) => {
        var array = mode == "headers" ? this.props.request_options.headers : mode == 'params' ? this.props.request_options.params : this.props.request_options.body;
        var options = [];
        array.forEach((option, index) => {
            options.push(
                <div className='bg-[#1E1E1E] rounded-lg p-4 relative' key={index}>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        <div>
                            <label className="block text-[#E5E7EB] text-sm font-medium mb-2">Key</label>
                            <input
                                className="w-full bg-[#2A2A2A] text-[#E5E7EB] border border-[#374151] rounded-md px-3 py-2 focus:outline-none focus:border-[#EF4444]"
                                placeholder="key"
                                required
                                type="text"
                                value={option.key}
                                onChange={(e) => {
                                    var request_options = { ...this.props.request_options };
                                    request_options[mode][index].key = e.target.value;
                                    this.props.updateData(request_options);
                                }}
                            />
                        </div>

                        <div>
                            <label className="block text-[#E5E7EB] text-sm font-medium mb-2">Value</label>
                            <input
                                className="w-full bg-[#2A2A2A] text-[#E5E7EB] border border-[#374151] rounded-md px-3 py-2 focus:outline-none focus:border-[#EF4444]"
                                placeholder="value"
                                required
                                type="text"
                                value={option.value}
                                onChange={(e) => {
                                    var request_options = { ...this.props.request_options };
                                    request_options[mode][index].value = e.target.value;
                                    this.props.updateData(request_options);
                                }}
                            />
                        </div>
                    </div>
                    <button
                        className='absolute -right-1.5 -top-1.5 bg-[#EF4444] text-[#FFFFFF] p-1 rounded-full hover:bg-[#DC2626] transition-colors shadow-lg flex items-center justify-center w-5 h-5'
                        onClick={() => {
                            var request_options = { ...this.props.request_options };
                            request_options[mode].splice(index, 1);
                            this.props.updateData(request_options);
                        }}
                    >
                        <FontAwesomeIcon icon={faTimes} className="w-2.5 h-2.5" />
                    </button>
                </div>
            );
        });
        return options;
    };


    renderResult = () => {
        if (!this.state.api_data || this.props.name == "" || this.state.variable == "") return null;
        var data = {
            [this.props.name]: this.state.api_data
        };
        var variable = this.state.variable;
        var value = _.get(data, variable.slice(1, -1));
        if (value && typeof value == "object") {
            return '[object Object]';
        } else if (!value && value != false) {
            return 'undefined';
        } else {
            // console.log(value, 'VALUE');
            return `${value}`;
        }
        // console.log(JSON.stringify(value), value.type == 'object', 'RESULT VALUE');
        // if (value && value.type == 'object') {
        //     return JSON.stringify(value);
        // } else if (!value) {
        //     return 'undefined';
        // } else {
        //     return value;
        // }

        // return value;
    };



    render() {
        return (
            <Transition.Root show={true} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-[10000]"
                    onClose={this.props.closeModal}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-[#0000008a] transition-opacity z-[10000]" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-[10000] overflow-y-auto">
                        <div className="flex min-h-full items-start justify-center p-4 text-center sm:items-start sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="w-screen lg:max-w-[1500px] min-h-[90vh] relative transform bg-[#141414] sm:rounded-lg text-left shadow-xl transition-all sm:my-8">
                                    <div className="h-full sm:h-auto p-6">
                                        <div className="w-full max-w-[1400px] mx-auto">
                                            <div className="mb-8">
                                                <div className="flex items-center justify-between">
                                                    <div>
                                                        <h2 className="text-2xl font-bold text-[#FFFFFF] flex items-center gap-2">
                                                            Request Builder
                                                            <DocsLink location="https://docs.botghost.com/custom-commands/api-requests" />
                                                        </h2>
                                                        <p className="text-[#9CA3AF] text-sm mt-1">Build the request to be send when this action is executed.</p>
                                                    </div>

                                                    <button
                                                        type="button"
                                                        className="rounded-full bg-[#EF4444] p-1.5 text-[#FFFFFF] hover:bg-[#DC2626] transition-colors shadow-lg"
                                                        onClick={this.props.closeModal}
                                                    >
                                                        <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>

                                            {this.state.error && (
                                                <div className="bg-red-500/10 border border-red-500/20 rounded-lg p-4 mb-6">
                                                    <p className="text-[#EF4444] font-medium">{this.state.error}</p>
                                                </div>
                                            )}

                                            <div className="space-y-4">
                                                <div className="flex flex-col sm:flex-row gap-4 items-end">
                                                    <select
                                                        className="w-full sm:w-24 bg-[#2A2A2A] text-[#E5E7EB] border border-[#374151] rounded-md px-3 py-2 focus:outline-none focus:border-[#EF4444]"
                                                        value={this.props.request_options.method}
                                                        onChange={(e) => {
                                                            var value = e.target.value;
                                                            var request_options = { ...this.props.request_options };
                                                            request_options.method = value;
                                                            this.props.updateData(request_options);
                                                            if (this.state.mode == "body" && (value == "GET" || value == "HEAD")) {
                                                                this.setState({ mode: "params" });
                                                            }
                                                        }}
                                                    >
                                                        <option>GET</option>
                                                        <option>POST</option>
                                                        <option>PUT</option>
                                                        <option>PATCH</option>
                                                        <option>DELETE</option>
                                                        <option>HEAD</option>
                                                    </select>

                                                    <div className="flex-1">
                                                        <label className="block text-[#E5E7EB] text-sm font-medium mb-2">URL</label>
                                                        <input
                                                            className="w-full bg-[#2A2A2A] text-[#E5E7EB] border border-[#374151] rounded-md px-3 py-2 focus:outline-none focus:border-[#EF4444]"
                                                            value={this.props.request_options.url}
                                                            placeholder="ex: https://www.example.com"
                                                            required
                                                            type="text"
                                                            onChange={(e) => {
                                                                var value = e.target.value;
                                                                value = value.trim();
                                                                var request_options = { ...this.props.request_options };
                                                                request_options.url = value;
                                                                this.props.updateData(request_options);
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="bg-[#1A1A1A] rounded-lg overflow-hidden">
                                                    <div className="flex overflow-x-auto border-b border-[#374151]">
                                                        {[
                                                            { id: 'params', label: 'URL Params' },
                                                            { id: 'headers', label: 'HTTP Headers' },
                                                            {
                                                                id: 'body',
                                                                label: 'Request Body',
                                                                disabled: this.props.request_options.method == "GET" || this.props.request_options.method == "HEAD"
                                                            },
                                                            { id: 'options', label: 'Options' },
                                                            { id: 'test', label: 'Test Request' }
                                                        ].map(tab => (
                                                            <button
                                                                key={tab.id}
                                                                className={`
                                                                    px-4 py-3 text-sm font-medium whitespace-nowrap
                                                                    ${this.state.mode === tab.id ? 'text-[#FFFFFF] bg-[#2A2A2A] border-b-2 border-[#EF4444]' : 'text-[#9CA3AF]'}
                                                                    ${tab.disabled ? 'opacity-50 cursor-not-allowed' : 'hover:text-[#FFFFFF] hover:bg-[#2A2A2A] cursor-pointer'}
                                                                    transition-colors
                                                                `}
                                                                onClick={() => !tab.disabled && this.setState({ mode: tab.id })}
                                                                disabled={tab.disabled}
                                                            >
                                                                {tab.label}
                                                            </button>
                                                        ))}
                                                    </div>

                                                    <div className="p-4">
                                                        {this.state.mode == "headers" ? (
                                                            <div className="space-y-4">
                                                                {this.renderOptions("headers")}
                                                                <div className="flex justify-end">
                                                                    <button
                                                                        className="bg-[#EF4444] text-[#FFFFFF] px-4 py-2 rounded-md hover:bg-[#DC2626] transition-colors font-medium shadow-lg disabled:opacity-50 disabled:cursor-not-allowed"
                                                                        onClick={() => {
                                                                            if (this.props.request_options.headers.length >= 50) return;
                                                                            var request_options = { ...this.props.request_options };
                                                                            request_options.headers.push({ key: "", value: "" });
                                                                            this.props.updateData(request_options);
                                                                        }}
                                                                    >
                                                                        Add Header
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ) : this.state.mode == "params" ? (
                                                            <div className="space-y-4">
                                                                {this.renderOptions("params")}
                                                                <div className="flex justify-end">
                                                                    <button
                                                                        className="bg-[#EF4444] text-[#FFFFFF] px-4 py-2 rounded-md hover:bg-[#DC2626] transition-colors font-medium shadow-lg disabled:opacity-50 disabled:cursor-not-allowed"
                                                                        onClick={() => {
                                                                            if (this.props.request_options.params.length >= 50) return;
                                                                            var request_options = { ...this.props.request_options };
                                                                            request_options.params.push({ key: "", value: "" });
                                                                            this.props.updateData(request_options);
                                                                        }}
                                                                    >
                                                                        Add Parameter
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ) : this.state.mode == "body" ? (
                                                            <div className="space-y-4">
                                                                {this.renderOptions("body")}
                                                                <div className="flex justify-end">
                                                                    <button
                                                                        className="bg-[#EF4444] text-[#FFFFFF] px-4 py-2 rounded-md hover:bg-[#DC2626] transition-colors font-medium shadow-lg disabled:opacity-50 disabled:cursor-not-allowed"
                                                                        onClick={() => {
                                                                            var request_options = { ...this.props.request_options };
                                                                            request_options.body.push({ key: "", value: "" });
                                                                            this.props.updateData(request_options);
                                                                        }}
                                                                    >
                                                                        Add Body Field
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ) : this.state.mode == "options" ? (
                                                            <div className="space-y-4 p-4 bg-[#1E1E1E] rounded-lg">
                                                                <div className="flex items-center gap-3">
                                                                    <Toggle value={this.props.request_options.options.excludeEmptyFields} update={(value) => {
                                                                        var request_options = { ...this.props.request_options };
                                                                        request_options.options.excludeEmptyFields = !request_options.options.excludeEmptyFields;
                                                                        this.props.updateData(request_options);
                                                                    }} />
                                                                    <span className="text-[#E5E7EB]">Automatically exclude fields that are empty</span>
                                                                </div>

                                                                <div className="flex items-center gap-3">
                                                                    <Toggle value={this.props.request_options.options.replaceURL} update={(value) => {
                                                                        var request_options = { ...this.props.request_options };
                                                                        request_options.options.replaceURL = !request_options.options.replaceURL;
                                                                        this.props.updateData(request_options);
                                                                    }} />
                                                                    <span className="text-[#E5E7EB]">Replace variables in the URL</span>
                                                                </div>

                                                                <div className="flex items-center gap-3">
                                                                    <Toggle value={this.props.request_options.options.replaceParams} update={(value) => {
                                                                        var request_options = { ...this.props.request_options };
                                                                        request_options.options.replaceParams = !request_options.options.replaceParams;
                                                                        this.props.updateData(request_options);
                                                                    }} />
                                                                    <span className="text-[#E5E7EB]">Replace variables in the URL Params</span>
                                                                </div>

                                                                <div className="flex items-center gap-3">
                                                                    <Toggle value={this.props.request_options.options.replaceHeaders} update={(value) => {
                                                                        var request_options = { ...this.props.request_options };
                                                                        request_options.options.replaceHeaders = !request_options.options.replaceHeaders;
                                                                        this.props.updateData(request_options);
                                                                    }} />
                                                                    <span className="text-[#E5E7EB]">Replace variables in the HTTP Headers</span>
                                                                </div>

                                                                <div className="flex items-center gap-3">
                                                                    <Toggle value={this.props.request_options.options.replaceBody} update={(value) => {
                                                                        var request_options = { ...this.props.request_options };
                                                                        request_options.options.replaceBody = !request_options.options.replaceBody;
                                                                        this.props.updateData(request_options);
                                                                    }} />
                                                                    <span className="text-[#E5E7EB]">Replace variables in body</span>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="space-y-4">
                                                                {this.state.loaded ? (
                                                                    <div className="space-y-6">
                                                                        <div className="bg-[#1E1E1E] rounded-lg p-4">
                                                                            <h4 className="text-lg font-bold text-[#FFFFFF] mb-2">Variables <DocsLink location="https://docs.botghost.com/custom-commands/api-requests#api-action-variables" /></h4>
                                                                            <p className="text-[#9CA3AF]">You can test the potential value of a variable below. Use the <span className="text-[#EF4444] font-medium">{`{${this.props.name}}`}</span> variable with dot notation to access the below response.</p>
                                                                        </div>

                                                                        <div className="bg-[#1E1E1E] rounded-lg p-4">
                                                                            <h4 className="text-lg font-bold text-[#FFFFFF] mb-2">Test Variable <DocsLink location="https://docs.botghost.com/custom-commands/api-requests#api-action-variables" /></h4>
                                                                            <p className="text-[#9CA3AF] mb-4">Test the potential value of a variable based on the response above. Click on a value in the response below to copy the variable required to return that data in another action.</p>

                                                                            <div className="mb-4">
                                                                                <label className="block text-[#E5E7EB] text-sm font-medium mb-2">Variable</label>
                                                                                <input
                                                                                    className="w-full sm:w-1/2 bg-[#2A2A2A] text-[#E5E7EB] border border-[#374151] rounded-md px-3 py-2 focus:outline-none focus:border-[#EF4444]"
                                                                                    placeholder="Variable"
                                                                                    type="text"
                                                                                    value={this.state.variable}
                                                                                    onChange={(e) => {
                                                                                        this.setState({ variable: e.target.value });
                                                                                    }}
                                                                                />
                                                                            </div>

                                                                            <h4 className="text-lg font-bold text-[#FFFFFF] flex items-center gap-2">
                                                                                Variable Result:
                                                                                <span className={this.renderResult() == "undefined" || this.renderResult() == '[object Object]' ? "text-[#EF4444]" : "text-[#A3A3A3]"} style={{ color: this.renderResult() == "undefined" || this.renderResult() == '[object Object]' ? '#EF4444' : '#A3A3A3' }}>
                                                                                    {this.renderResult()}
                                                                                </span>
                                                                            </h4>
                                                                        </div>

                                                                        <div className="bg-[#1E1E1E] rounded-lg p-4">
                                                                            <h4 className="text-lg font-bold text-[#FFFFFF] mb-2">Response</h4>
                                                                            <div className="max-w-full overflow-x-auto">
                                                                                <ReactJSON
                                                                                    copy={false}
                                                                                    onSelect={(data) => {
                                                                                        var variable = this.props.name;
                                                                                        if (data.namespace.length > 0) {
                                                                                            variable += "." + data.namespace.join(".") + "." + data.name;
                                                                                        } else {
                                                                                            variable += "." + data.name;
                                                                                        }
                                                                                        navigator.clipboard.writeText(`{${variable}}`);
                                                                                        this.setState({
                                                                                            variable: `{${variable}}`
                                                                                        });
                                                                                    }}
                                                                                    style={{
                                                                                        backgroundColor: '#2A2A2A',
                                                                                        borderRadius: '0.375rem',
                                                                                        padding: '1rem'
                                                                                    }}
                                                                                    theme={{
                                                                                        base00: '#2A2A2A',
                                                                                        base01: '#383830',
                                                                                        base02: '#49483e',
                                                                                        base03: '#75715e',
                                                                                        base04: '#a59f85',
                                                                                        base05: '#f8f8f2',
                                                                                        base06: '#f5f4f1',
                                                                                        base07: '#f9f8f5',
                                                                                        base08: '#f92672',
                                                                                        base09: '#fd971f',
                                                                                        base0A: '#f4bf75',
                                                                                        base0B: '#a6e22e',
                                                                                        base0C: '#a1efe4',
                                                                                        base0D: '#66d9ef',
                                                                                        base0E: '#ae81ff',
                                                                                        base0F: '#cc6633'
                                                                                    }}
                                                                                    maxHeight={"500px"}
                                                                                    collapsed={2}
                                                                                    name={false}
                                                                                    displayDataTypes={false}
                                                                                    src={this.state.api_data}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : null}

                                                                <div className="flex justify-end">
                                                                    <button
                                                                        onClick={this.testRequest}
                                                                        className="bg-[#EF4444] text-[#FFFFFF] px-4 py-2 rounded-md hover:bg-[#DC2626] transition-colors font-medium shadow-lg disabled:opacity-50 disabled:cursor-not-allowed"
                                                                        disabled={this.state.loading}
                                                                    >
                                                                        {this.state.loading ? 'Testing...' : 'Test Request'}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        );
    }
}

const mapStateToProps = (state) => ({
    token: state.data.bot.token,
    bot_id: state.data.bot.id

});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(RequestBuilder);
