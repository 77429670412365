import React, { Component } from "react";
import { connect } from "react-redux";
import { CheckoutCart } from "@chargebee/chargebee-js-react-wrapper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import server from "../../../api/server";

import SectionHeader from "../elements/SectionHeader";
import PremiumTable from "../elements/PremiumTable";
import ModuleHeader from "../elements/ModuleHeader";
import Toggle from "../elements/Toggle";
import Command from "../elements/Command";
import PremiumPlan from "../elements/PremiumPlan";
import PremiumPlan2 from "../elements/PremiumPlan2";
import { PremiumFeature } from "../elements/PremiumFeature";
import { Accordion } from "../elements/Accordion";
import "../styles/premium.css";
import { setAuth, upgradeSubscription, updateProcessing } from "../../../actions";
import ReactGA from "react-ga4";
import Dashboard from "../Dashboard";
import { Loader } from "../../Loader";
import { Cookies } from "react-cookie";
import Select from "../elements/Select";
import axios from "axios";
import history from "../../../history";
import PricingTable from "../PricingTable";

var STEPS = [1, 5, 10, 25, 50, 100];

const subscription_types = {
	Lifetime: {
		single: {
			USD: "botghost-lifetime-3_v3",
			EURO: "botghost-lifetime-euro_v3"
		},
		unlimited: {
			USD: "lifetime_unlimited_89.99",
			EURO: "lifetime_unlimited_89.99_EURO"
		}
	},
	Yearly: {
		single: {
			USD: "botghost-yearly-tiered_v2",
			EURO: "botghost-yearly-tiered-euro_v2"
		},
		unlimited: {
			USD: "botghost-yearly-tiered-unlimited_69.99",
			EURO: "botghost-yearly-tiered-unlimited_69.99_euro"
		}
	},
	Monthly: {
		single: {
			USD: "botghost-monthly-tiered_v3",
			EURO: "botghost-monthly-tiered-euro_v3"
		},
		unlimited: {
			USD: "botghost-monthly-tiered-unlimited_23.99",
			EURO: "botghost-monthly-tiered-unlimited_v3-euro-23.99"
		}
	}
};

// Add this CSS at the top of the file, after the other imports
const toggleStyles = `
  .premium-toggle {
    width: 64px;
    height: 34px;
    border-radius: 17px;
    background: #2A2A2A;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .premium-toggle.checked {
    background: #FFD700;
  }

  .premium-toggle .toggle-circle {
    width: 28px;
    height: 28px;
    background: white;
    border-radius: 50%;
    position: absolute;
    top: 3px;
    transition: all 0.3s ease;
  }
`;

const styles = `
  ${toggleStyles}
  
  @keyframes slow-bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  .slow-bounce {
    animation: slow-bounce 3s infinite ease-in-out;
  }
`;

export class Premium extends Component {
	constructor(props) {
		super(props);

		this.state = {
			chargebeeReady: false,
			instance: null,
			cart: null,
			portal: null,
			currency: "USD",
			processing: true,
			plan: process.env.REACT_APP_YEARLY,
			ga_price: 39.99,
			ga_plan: "Yearly",
			selected: "Yearly",
			type: true,
			quantity: 1,
			stepIndex: 0,
			loading: false,
			version: "A",
			billing_type: false,
			unlimitedPanels: true,
			// Sale Stuff
			sale: false,
			sale_amount: this.props.current_sale ? (100 - this.props.current_sale.percent) / 100 : 0,
			sale_coupon: this.props.current_sale ? this.props.current_sale.code : ""
		};
	}

	componentDidMount() {

		// console.log(window, "WINDOW");

		try {
			if (window.sa_event) {
				window.sa_event("visit_premium_page");
			}
		} catch (e) { }

		var version = "A";
		const cookies = new Cookies();
		if (cookies.get("premium_version") == "B") {
			version = "B";
		}
		this.setState({ version: version });

		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
		const el = document.createElement("script");
		el.onload = () => {
			window.Chargebee.init({
				site: "botghost"
			});
			window.Chargebee.registerAgain();
			this.setState({ chargebeeReady: true });
		};
		el.setAttribute("src", "https://js.chargebee.com/v2/chargebee.js");
		document.body.appendChild(el);
		var plan = process.env.REACT_APP_YEARLY;

		this.setState({ plan: plan });
		this.setCurrency();
		this.props.setAuth();

		if (this.props.user.id == undefined) {
			history.push("/dashboard/modules");
		}
		this.countPremiumView();
	}

	countPremiumView = async () => {
		var response = await server.post("/dashboard/premiumPageCount");
	};

	componentDidUpdate(prevProps, prevState) {
		if (!this.props.auth) {
			this.props.setAuth();
		}
	}

	setCurrency = async () => {
		var response = axios
			.get("https://ipapi.co/json")
			.then((results) => {
				if ("data" in results && "currency" in results.data && results.data.currency == "EUR") {
					this.setState({ currency: "EURO" });
				}
			})
			.catch((e) => {
				// console.log(e, "aRHUASUHAHUSDHASDU");
				console.error(e);
			});
	};

	typeChange = () => { };

	setPortalCallbacks = (portal) => {
		portal.callbacks.subscriptionChanged = (data) => {
			// console.log("SUB CHANGE");
			this.setState({ loading: true });
			setTimeout(() => {
				window.location.replace("/dashboard/settings");
			}, 10000);
		};

		portal.callbacks.loaded = () => {
			// console.log("PORTAL LOADED");
		};
		// console.log(portal.callbacks, portal, "CALLBACKS");
		return portal;
	};

	setCurrency = async () => {
		var response = axios
			.get("https://ipapi.co/json")
			.then((results) => {
				if ("data" in results && "currency" in results.data && results.data.currency == "EUR") {
					this.setState({ currency: "EURO" });
				}
			})
			.catch((e) => {
				// console.log(e, "aRHUASUHAHUSDHASDU");
				console.error(e);
			});
	};

	typeChange = () => { };

	setCallBacks = (cart) => {
		cart.callbacks.close = async () => {
			this.props.updateProcessing(false);
			var response = await server.post("/dashboard/abandondedcart", { email: this.props.user.email });
		};

		cart.callbacks.success = (pageId) => {
			this.props.updateProcessing(true);
			this.checkoutSuccess(pageId);
		};

		cart.callbacks.step = (step) => {
			ReactGA.initialize("G-WHCMYGFHKX");
			ReactGA.event({
				category: "Checkout Step",
				label: step,
				action: "New Dashboard"
			});
		};
		return cart;
	};

	createInstance = () => {
		if (this.state.chargebeeReady && this.state.instance == null) {
			var cbInstance = window.Chargebee.getInstance();
			var cbPortal = cbInstance.createChargebeePortal();
			cbPortal = this.setPortalCallbacks(cbPortal);

			var cart = cbInstance.getCart();



			const cookies = new Cookies();
			console.log(cookies.get("_fprom_tid"), "FPROM TID");
			var tid = cookies.get("_fprom_tid") || ('fp_' + (new Date).getTime());
			var customer = { cf_user_id: this.props.user.id, cf_premium_version: this.state.version, cf_tid: tid };
			console.log(customer, "CUSTOMER");
			cart.setCustomer(customer);
			// console.log(cart, "CART");
			cart = this.setCallBacks(cart);
			this.setState({ instance: cbInstance, cart: cart, portal: cbPortal });
		}
	};

	toggleChange = () => {
		if (this.state.type == false) {
			var plan = process.env.REACT_APP_YEARLY_ULTIMATE;
			this.setState({ type: true, plan: plan, selected: "Yearly" });
		} else {
			var plan = process.env.REACT_APP_YEARLY;
			this.setState({ type: false, plan: plan, selected: "Yearly" });
		}
	};

	change = () => {
		this.state.portal.open({
			subscriptionChanged: () => {
				// console.log("SUB CHANGE1");
			}
		});
	};

	checkout = (type, price) => {
		const planType = this.state.unlimitedPanels ? 'unlimited' : 'single';
		const currency = this.state.currency;
		let planName;

		switch (type) {
			case 'monthly':
				planName = 'Monthly';
				break;
			case 'yearly':
				planName = 'Yearly';
				break;
			case 'lifetime':
				planName = 'Lifetime';
				break;
		}

		const subscriptionType = subscription_types[planName][planType][currency];

		this.props.updateProcessing(true);
		let product = this.state.instance.initializeProduct(subscriptionType);

		// Check if this plan type is included in active sale
		const saleItemKey = type + (this.state.unlimitedPanels ? '_unlimited' : '');
		if (this.props.active_sale?.sale_items?.[saleItemKey] === true) {
			product.addCoupon(this.props.active_sale.code);
		}
		// Only apply personal sale if no active sale exists at all
		else if (!this.props.active_sale && this.props.personal_sale?.coupon_code) {
			product.addCoupon(this.props.personal_sale.coupon_code);
		}

		// Add referral coupon if exists
		const cookies = new Cookies();
		const referred_by = cookies.get("referred_by");
		if (referred_by != undefined) {
			product.addCoupon("REFERRAL");
		}

		this.state.cart.replaceProduct(product);
		this.state.cart.proceedToCheckout();

		// Calculate analytics price based on applicable discount
		const saleDiscount = this.props.active_sale?.sale_items?.[saleItemKey] === true
			? (1 - (this.props.active_sale.percent || 0) / 100)
			: !this.props.active_sale && this.props.personal_sale ? 0.6 : 1;

		const analyticsPrice = price * saleDiscount;
		this.setState({ ga_plan: planName, ga_price: analyticsPrice });

		try {
			if (window.sa_loaded) {
				window.sa_event("checkout");
			}
		} catch (e) { }
	};

	checkoutSuccess = async (hostedPageId) => {


		if (window.sa_loaded) {
			window.sa_event("sale");
		}

		ReactGA.plugin.execute("ecommerce", "send");
		this.setState({ loading: true });
		// const response =await  server.post("/dashboard/chargebee",{page_id:hostedPageId,bot:this.props.bot,user:this.props.user});
		setTimeout(() => {
			window.location.replace("/dashboard/settings");
		}, 10000);
	};

	renderProcessing = () => {
		if ((this.state.processing = true)) {
			return <Loader />;
		} else {
			return null;
		}
	};

	renderPlan = (type) => {
		var code = "";
		if (type == "lifetime") {
			// if (this.state.type == false) {
			code = "REACT_APP_LIFETIME";
			// } else {
			// code = "REACT_APP_LIFETIME_ULTIMATE";
			// }
		} else if (type == "yearly") {
			// if (this.state.type == false) {
			code = "REACT_APP_YEARLY";
			// }
			// else {
			//   code = "REACT_APP_YEARLY_ULTIMATE";
			// }
		} else {
			// if (this.state.type == false) {
			code = "REACT_APP_MONTHLY";
			// }
			// else {
			//   code = "REACT_APP_MONTHLY_ULTIMATE";
			// }
		}
		return process.env[`${code}${this.state.currency == "USD" ? "" : "_EURO"}`];
	};

	renderVersion = () => {
		const { personal_sale, active_sale } = this.props;

		// Calculate the effective discount based on active_sale or personal_sale
		const getDiscount = (type, isUnlimited) => {
			// Only check active sale if it exists
			if (active_sale?.sale_items) {
				const planKey = type + (isUnlimited ? '_unlimited' : '');
				if (active_sale.sale_items[planKey] === true) {
					return active_sale.percent ? (1 - active_sale.percent / 100) : 1;
				}
				// If active sale exists but this plan isn't included, return no discount
				return 1;
			}
			// Only fall back to personal sale if no active sale exists at all
			return personal_sale && !active_sale ? 0.6 : 1;
		};

		const prices = {
			monthly: {
				base: 11.99,
				unlimited: 23.99,
				display: this.state.unlimitedPanels ? 23.99 : 11.99,
				salePrice: this.state.unlimitedPanels
					? (23.99 * getDiscount('monthly', true)).toFixed(2)
					: (11.99 * getDiscount('monthly', false)).toFixed(2),
				hasDiscount: this.state.unlimitedPanels
					? (active_sale?.sale_items?.monthly_unlimited || (!active_sale && personal_sale))
					: (active_sale?.sale_items?.monthly || (!active_sale && personal_sale))
			},
			yearly: {
				base: 49.99,
				unlimited: 69.99,
				display: this.state.unlimitedPanels ? 69.99 : 49.99,
				salePrice: this.state.unlimitedPanels
					? (69.99 * getDiscount('yearly', true)).toFixed(2)
					: (49.99 * getDiscount('yearly', false)).toFixed(2),
				hasDiscount: this.state.unlimitedPanels
					? (active_sale?.sale_items?.yearly_unlimited || (!active_sale && personal_sale))
					: (active_sale?.sale_items?.yearly || (!active_sale && personal_sale))
			},
			lifetime: {
				base: 69.99,
				unlimited: 89.99,
				display: this.state.unlimitedPanels ? 89.99 : 69.99,
				salePrice: this.state.unlimitedPanels
					? (89.99 * getDiscount('lifetime', true)).toFixed(2)
					: (69.99 * getDiscount('lifetime', false)).toFixed(2),
				hasDiscount: this.state.unlimitedPanels
					? (active_sale?.sale_items?.lifetime_unlimited || (!active_sale && personal_sale))
					: (active_sale?.sale_items?.lifetime || (!active_sale && personal_sale))
			}
		};

		// Dynamic styles based on unlimited toggle
		const getCardStyles = (type) => {
			const baseStyles = "rounded-lg transition-all duration-300 flex flex-col";
			const hoverEffect = "transform hover:-translate-y-1 duration-200";

			if (this.state.unlimitedPanels) {
				switch (type) {
					case 'monthly':
						return `${baseStyles} ${hoverEffect} border-2 border-[#FF4444] p-8 bg-[#FF4444] shadow-lg`;
					case 'yearly':
						return `${baseStyles} ${hoverEffect} border-2 border-[#FF4444] p-10 bg-[#FF5555] shadow-lg`;
					case 'lifetime':
						return `${baseStyles} ${hoverEffect} border-2 border-[#FFD700] p-12 bg-gradient-to-r from-[#FFD700] to-[#FFA500] shadow-[0_0_30px_rgba(255,215,0,0.3)]`;
				}
			} else {
				// Single bot styling - all plans look the same
				return `${baseStyles} ${hoverEffect} border border-[#374151] p-6 hover:border-[#FF4444] bg-[#1A1A1A]`;
			}
		};

		const getHeightStyle = (type) => {
			if (this.state.unlimitedPanels) {
				switch (type) {
					case 'monthly': return { height: '380px' };
					case 'yearly': return { height: '400px' };
					case 'lifetime': return { height: '420px' };
				}
			} else {
				switch (type) {
					case 'monthly': return { height: '360px' };
					case 'yearly': return { height: '380px' };
					case 'lifetime': return { height: '400px' };
				}
			}
		};

		// Add new function for text colors
		const getTextColors = (type) => {
			if (this.state.unlimitedPanels) {
				if (type === 'monthly' || type === 'yearly') {
					return {
						title: 'text-white',
						price: 'text-white',
						description: 'text-white opacity-80'
					};
				} else {
					return {
						title: 'text-black',
						price: 'text-black',
						description: 'text-black opacity-80'
					};
				}
			} else {
				// Single bot text colors - all plans look the same
				return {
					title: 'text-white',
					price: 'text-white',
					description: 'text-white opacity-60'
				};
			}
		};

		// Add a function to get button styles
		const getButtonStyles = (type) => {
			if (this.state.unlimitedPanels) {
				if (type === 'monthly' || type === 'yearly') {
					return `w-full py-3 px-4 bg-[#1F1F1F] hover:bg-[#2A2A2A] text-white rounded-md font-medium transition-all border border-[#FF4444]`;
				} else {
					return `w-full py-3 px-4 bg-[#1F1F1F] hover:bg-[#2A2A2A] text-[#FFD700] rounded-md font-medium transition-all`;
				}
			} else {
				// Single bot button styling - all plans look the same
				return `w-full py-3 px-4 bg-[#FF4444] hover:bg-[#FF6666] text-white rounded-md font-medium transition-all`;
			}
		};

		return (
			<section className="max-w-7xl mx-auto px-4 py-8">
				<div className="text-center mb-12">
					<span className="text-[#FF4444] text-sm font-medium tracking-wide uppercase">Premium Features</span>
					<h1 className="text-4xl font-bold text-white mt-3 mb-4">
						Unlock Premium Features
					</h1>
					<p className="text-[#E5E7EB] text-lg mb-3 max-w-2xl mx-auto">
						Take your Discord bot to the next level with premium features and unlimited possibilities.
					</p>
					<p className="text-[#9CA3AF] text-sm">
						Includes 7-day money-back guarantee. Need help? <a href="https://discord.gg/botghost" target="_blank" rel="noopener noreferrer" className="text-[#FF4444] hover:text-[#FF6666] transition-colors">Contact us</a>.
					</p>
				</div>

				<div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8 items-end">
					{/* Monthly Plan */}
					<div className={getCardStyles('monthly')} style={getHeightStyle('monthly')}>
						<div className="flex-grow">
							<h3 className={`${this.state.unlimitedPanels ? 'text-2xl' : 'text-xl'} font-bold ${getTextColors('monthly').title} mb-2`}>
								{this.state.unlimitedPanels ? 'Monthly Unlimited' : 'Monthly Single Bot'}
							</h3>
							<p className={`text-sm mb-6 ${getTextColors('monthly').description}`}>
								{this.state.unlimitedPanels
									? 'Scale without limits - create and manage unlimited premium bots with our most flexible plan'
									: 'Perfect for a single premium bot with all features included'}
							</p>
							<div className="mb-6">
								{prices.monthly.hasDiscount && (
									<span className={`text-lg line-through mr-2 ${getTextColors('monthly').description}`}>
										{this.state.currency === "EURO" ? "€" : "$"}{prices.monthly.display}
									</span>
								)}
								<span className={`${this.state.unlimitedPanels ? 'text-4xl' : 'text-3xl'} font-bold ${getTextColors('monthly').price}`}>
									{this.state.currency === "EURO" ? "€" : "$"}
									{prices.monthly.hasDiscount ? prices.monthly.salePrice : prices.monthly.display}
								</span>
								<span className={`text-sm ${getTextColors('monthly').description}`}>/month</span>
							</div>
						</div>
						<button
							onClick={() => this.checkout('monthly', prices.monthly.display)}
							className={getButtonStyles('monthly')}>
							Upgrade
						</button>
					</div>

					{/* Yearly Plan */}
					<div className={getCardStyles('yearly')} style={getHeightStyle('yearly')}>
						<div className="flex-grow">
							<h3 className={`${this.state.unlimitedPanels ? 'text-3xl' : 'text-2xl'} font-bold ${getTextColors('yearly').title} mb-2`}>
								{this.state.unlimitedPanels ? 'Yearly Unlimited' : 'Yearly Single Bot'}
							</h3>
							<p className={`text-sm mb-6 ${getTextColors('yearly').description}`}>
								{this.state.unlimitedPanels
									? 'Maximum savings with our comprehensive unlimited plan'
									: 'Annual subscription for a single premium bot'}
							</p>
							<div className="mb-6">
								{prices.yearly.hasDiscount && (
									<span className={`text-lg line-through mr-2 ${getTextColors('yearly').description}`}>
										{this.state.currency === "EURO" ? "€" : "$"}{prices.yearly.display}
									</span>
								)}
								<span className={`${this.state.unlimitedPanels ? 'text-5xl' : 'text-4xl'} font-bold ${getTextColors('yearly').price}`}>
									{this.state.currency === "EURO" ? "€" : "$"}
									{prices.yearly.hasDiscount ? prices.yearly.salePrice : prices.yearly.display}
								</span>
								<span className={`text-sm ${getTextColors('yearly').description}`}>/year</span>
							</div>
						</div>
						<button
							onClick={() => this.checkout('yearly', prices.yearly.display)}
							className={getButtonStyles('yearly')}>
							Upgrade
						</button>
					</div>

					{/* Lifetime Plan */}
					<div className={`${getCardStyles('lifetime')} ${this.state.unlimitedPanels ? 'slow-bounce' : ''}`} style={getHeightStyle('lifetime')}>
						<div className="flex-grow">
							<h3 className={`${this.state.unlimitedPanels ? 'text-4xl' : 'text-2xl'} font-bold ${getTextColors('lifetime').title} mb-2`}>
								{this.state.unlimitedPanels ? 'Lifetime Unlimited' : 'Lifetime Single Bot'}
							</h3>
							<p className={`text-sm mb-6 ${getTextColors('lifetime').description}`}>
								{this.state.unlimitedPanels
									? 'Ultimate freedom - one payment for unlimited premium bots forever with all future updates'
									: 'One-time payment for a single premium bot with lifetime access'}
							</p>
							<div className="mb-6">
								{prices.lifetime.hasDiscount && (
									<span className={`text-lg line-through mr-2 ${getTextColors('lifetime').description}`}>
										{this.state.currency === "EURO" ? "€" : "$"}{prices.lifetime.display}
									</span>
								)}
								<span className={`${this.state.unlimitedPanels ? 'text-6xl' : 'text-4xl'} font-bold ${getTextColors('lifetime').price}`}>
									{this.state.currency === "EURO" ? "€" : "$"}
									{prices.lifetime.hasDiscount ? prices.lifetime.salePrice : prices.lifetime.display}
								</span>
								<span className={`text-sm ${getTextColors('lifetime').description}`}>/lifetime</span>
							</div>
						</div>
						<button
							onClick={() => this.checkout('lifetime', prices.lifetime.display)}
							className={getButtonStyles('lifetime')}>
							Upgrade
						</button>
					</div>
				</div>

				{/* Unlimited Bot Panels */}
				<div className={`border-2 ${this.state.unlimitedPanels ? 'border-[#FFD700] bg-gradient-to-r from-[#FFD700] to-[#FFA500] bg-opacity-10' : 'border-[#FFD700]'} rounded-lg p-8 flex justify-between items-center transition-colors bg-gradient-to-r from-[#222327] to-[#2A2A1F] shadow-[0_0_15px_rgba(255,215,0,0.3)]`}>
					<style>{toggleStyles}</style>
					<div className="flex-grow">
						<h3 className="text-2xl font-bold text-[#FFD700] mb-2">Create Unlimited Premium Bots</h3>
						<p className="text-[#9CA3AF] text-sm">
							Upgrade to unlimited and create as many premium bots as you want. Perfect for developers and power users who need multiple premium bots.
						</p>
					</div>
					<div className="ml-8">
						<div
							onClick={() => {
								const newValue = !this.state.unlimitedPanels;
								this.setState({ unlimitedPanels: newValue });
								ReactGA.event({
									category: "Pricing",
									action: "Toggle Unlimited",
									label: newValue ? "Enabled" : "Disabled"
								});
							}}
							className={`premium-toggle ${this.state.unlimitedPanels ? 'checked' : ''}`}
						>
							<div
								className="toggle-circle"
								style={{
									left: this.state.unlimitedPanels ? "33px" : "3px"
								}}
							/>
						</div>
					</div>
				</div>
			</section>
		);
	};

	render() {
		if (this.state.loading == true) {
			return <Loader processing={true} />;
		} else {
			return (
				<Dashboard>
					<ToastContainer></ToastContainer>
					{this.createInstance()}
					{/* {this.renderProcessing()} */}
					{this.state.chargebeeReady ? (
						<div>

							{this.renderVersion()}

							{/* <section>
								<SectionHeader title="Features" pretitle="Premium" />

								<PremiumTable />
							</section> */}

							{/* <section>
                        <SectionHeader title="Features" pretitle="Premium" ></SectionHeader>
                        <div className="premium-features">
    
                            <div className="row premium-features-container">
    
    
                                <PremiumFeature
                                    icon="customcommands.png"
                                    title="Remove all BotGhost Advertising."
                                    info="Remove all BotGhost branding from your bot."
                                />
                                                            <PremiumFeature
                                    icon="discord.png"
                                    title="Premium Role"
                                    info="Receieve a premium role and support in the BotGhost official Discord Server."
                                />
    
                                <PremiumFeature
                                    icon="unlock.png"
                                    title="Unlock all current and future features"
                                    info="Gain complete access to all current and future features."
                                />
    
                                <PremiumFeature
                                    icon="moneyback.png"
                                    title="7 day money back guarantee"
                                    info="Rest assured with a 7 day money back guarantee; no questions asked."
                                />
    
                                <PremiumFeature
                                    icon="levels.png"
                                    title="Customize your Leaderboard"
                                    info="Individually customize your bots levels leaderboards."
                                />
    
                                <PremiumFeature
                                    icon="priority.png"
                                    title="Priority Hosting"
                                    info="Unlock superior performance compared to trial bots."
                                />
    
                                
    
    
                            </div>
                        </div>
    
                    </section> */}

							<section>
								{/* <SectionHeader title="Features" pretitle="Premium"></SectionHeader> */}
								<PricingTable />
							</section>

							<section>
								<SectionHeader title="Frequently Asked Questions" pretitle="Premium"></SectionHeader>

								<div className="accordions">
									<Accordion title="What do I get from BotGhost premium?" description={`BotGhost premium allows you access to all current and future modules, you may also set your own status message, removing the "Made with botghost.com" message from your bot, premium bots also do not share hosting with bots that are on a free trial, meaning that they are detected more resources and are generally faster than their non premium counterparts.`}></Accordion>

									<Accordion title="Can I get a refund if I am not satisified?" description={`Of course! If you are not satisfied with your premium subscription simply contact us within 7 days and we will give you a full refund, no questions asked.`}></Accordion>

									<Accordion title="How do I stop my subscription?" description={`Simply go to settings and click 'Manage Subscription'. From there you can download your receipt and cancel your subscription, if you do decide to cancel your subscription, your bot will stay online for the entire period you payed for, this means you can buy a premium subscription and cancel it straight away if you only want one month of premium.`}></Accordion>

									<Accordion title="How many Discord Servers is my subscription valid for?" description={`Your premium subscription is valid for EVERY server that your bot is in, a premium subscription is valid for ONE bot only and an Unlimited subscription is valid for UNLIMITED bots.`}></Accordion>

									<Accordion title="What payment methods are supported?" description={`We support all credit cards, Paypal, Google Pay and Apple Pay, please note, that if your credit card does not work it may be due to your bank provider blocking online payments, in this case, please use PayPal to complete your transaction.`}></Accordion>

									<Accordion
										title="Is my credit card secure?"
										description={`
                               We do not store any credit cart information, all credit and debit card transactions are handled through Stripe, a payment processor which handles billions of dollars in online transactions every year, to learn more about their security, visit https://stripe.com/docs/security/stripe, similarly, we also never have access to your Paypal account information and your transactions with us area protected by PayPal's Purchase Protection.`}
									></Accordion>

									<Accordion title="How do I upgrade my bot after purchasing a subscription." description={`Once you have purchased a subscription, you will receive premium tokens based on how many bots you chose to upgrade, you then must go to 'Settings' and click 'Upgrade this Bot' to upgrade that bot to premium, if you wish to upgrade a different bot then the one you are currently editing, change bots by clicking the dropdown in the very top right of this page.`}></Accordion>


									<Accordion title="Can I pay in a different currency?" description={`All prices are listed in USD or EURO depending on your location, you can still pay in your own currency however as both Stripe and Paypal will do the conversion for you.`}></Accordion>
								</div>
							</section>
						</div>
					) : null}
				</Dashboard>
			);
		}
	}
}

const mapStateToProps = (state) => ({
	bot: state.data.bot,
	user: state.data.user,
	auth: state.auth,
	active_sale: state.data.active_sale,
	personal_sale: state.data.user?.personal_sale
});

const mapDispatchToProps = {
	upgradeSubscription,
	updateProcessing,
	setAuth
};

export default connect(mapStateToProps, mapDispatchToProps)(Premium);
