import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from './Select';
import SlashCommandOption from './SlashCommandOption';
import SlashCommandAction from './SlashCommandAction';
import Toggle from './Toggle';
import { setSelected, setBuilderModuleId, setBotModule, setElements, setBuilderIndex, setBuilderMode, setFuture, setGroupId } from "../../../actions";
import ow from 'ow';
import DocsLink from './DocsLink';
import PremiumModal from './PremiumModal';
import SlashCommandPermissions from './SlashCommandPermissions';
import DiscordMessagePreview from './DiscordMessagePreview';
import DiscordSlashPreview from './DiscordSlashPreview';
import ReactGA from 'react-ga';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faFileImport, faCode, faCodeBranch, faEnvelope, faEye, faHashtag, faMouse, faMousePointer, faPaperPlane, faReply, faReplyAll, faSortNumericDown, faTextHeight, faUser, faUserMinus, faUserPlus, faUsers, faQuestion, faHourglass, faComment, faComments, faCommentSlash, faUserSlash, faUserTimes, faUserFriends, faUsersSlash, faPercent, faGripVertical, faHammer, faTrash, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import history from "../../../history.js";
import { v4 as uuidv4 } from 'uuid';
import server from '../../../api/server';
import CommandImporter from './CommandImporter';
import { addEdge } from 'react-flow-renderer';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { ACTION_TYPES, CUSTOM_EVENTS } from '../../../variables';
import { TASKS } from '../../../variables';
import buildExistingCommand from '../commandbuilder/buildExistingCommand';
import LZString from 'lz-string';

import toast, { Toaster } from "react-hot-toast";
import createTree from '../commandbuilder/createTree';
import PremiumRequiredModal from './PremiumRequiredModal.js';

var types = [
    { icon: faTextHeight, title: "Text", description: "A plain text option", type: "string" },
    { icon: faSortNumericDown, title: "Number", description: "A number option", type: "int" },
    { icon: faUser, title: "User", description: "Select a member from the server", type: "user" },
    { icon: faHashtag, title: "Channel", description: "Select a channel from the server", type: "channel" },
    { icon: faUsers, title: "Role", description: "Select a role from the server", type: "role" },
    { icon: faQuestion, title: "Choice", description: "A True or False option", type: "boolean" },
];


var CONDITION_TYPES = [
    { icon: faQuestion, title: "Options Condition", description: "Run actions based on option values.", condition_type: "option" },
    { icon: faPercent, title: "Chance Condition", description: "Set a percent chance for actions to run.", condition_type: "chance" }
];


export class SlashCommandEditor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            step: "trigger_description",
            premiumModal: false,
            name: "",
            premiumMessage: "",
            id: "",
            showImportModal: false,
            description: "",
            options: [
            ],
            actions: [
            ],
            compressed_actions: null,
            permissions: {
                allowed_roles: [{ name: '@everyone', id: 'everyone' }],
                banned_roles: [],
                banned_channels: [],
                banned_users: [],
                required_permissions: []
            },
            cooldown: {
                type: "disabled",
                interval: 1,
                interval_type: "minutes"
            },
            error: "",
            ephemeral: false,
            handleErrors: true,
            errors: [],
            future: []
        };
    }

    componentDidMount() {
        if (this.props.command != undefined && this.props.command != {}) {
            var command = { ...this.props.command };


            if (!("cooldown" in command)) {
                command.cooldown = this.state.cooldown;
            }
            this.setState(this.props.command);
        } else if (this.props.event != undefined && this.props.event != {}) {
            var event = { ...this.props.event };
            this.setState(this.props.event);
        }

    }
    updateOption = (key, value, index) => {
        var options = [...this.state.options];
        var option = { ...this.state.options[index] };
        option[key] = value;
        options[index] = option;
        this.setState({ options: options });
    };

    deleteOption = (index) => {
        var options = [...this.state.options];
        options.splice(index, 1);
        this.setState({ options: options });
    };

    addOption = () => {
        var options = [...this.state.options];
        options.push({
            type: "string",
            name: "",
            description: "",
            required: false
        });
        this.setState({ options: options });
    };
    renderOptions = () => {
        var options = [];
        this.state.options.forEach((option, index) => {
            options.push(
                <SlashCommandOption
                    data={option}
                    index={index}
                    edit={this.props.edit}
                    update={this.updateOption}
                    delete={this.deleteOption}
                ></SlashCommandOption>
            );
        });

        return options;
    };


    updateAction = (key, value, index) => {
        var actions = [...this.state.actions];
        var action = { ...this.state.actions[index] };
        action[key] = value;
        actions[index] = action;
        this.setState({ actions: actions });
    };

    deleteAction = (index) => {
        var actions = [...this.state.actions];
        actions.splice(index, 1);
        this.setState({ actions: actions });
    };


    renderActions = () => {
        var actions = [];
        this.state.actions.forEach((action, index) => {
            actions.push(
                <SlashCommandAction
                    data={action}
                    index={index}
                    edit={this.props.edit}
                    update={this.updateAction}
                    delete={this.deleteAction}
                    options={this.state.options}
                    name={this.state.name}

                />
            );
        });
        return actions;

    };

    addAction = () => {
        var actions = [...this.state.actions];
        actions.push({
            type: "plain_text",

        });
        this.setState({ actions: actions });
    };

    reset = () => {
        this.setState({
            name: "",
            description: "",
            options: [
            ],
            actions: [
            ],
            error: "",
            step: "trigger_description"
        });
    };

    submit = (e) => {
        e.preventDefault();
        this.save();
    };

    checkActiveCommands = () => {
        if (this.props.mode == "event") {
            var count = 0;
            this.props.custom_events.events.forEach((event) => {
                if (event.enabled == undefined || event.enabled == true) {
                    count += 1;
                }
            });
            console.log(count, 'COUNT HERE');
            if (count >= 3) {
                return false;
            }
        } else {
            var count = 0;
            this.props.slash.commands.forEach((command) => {
                if (command.enabled == undefined || command.enabled == true) {
                    count += 1;
                }
            });

            if ((count >= 8 && this.props.bv != 2) || (count >= 3 && this.props.bv == 2)) {
                return false;
            }
        }

        return true;
    };

    commandBuilder = async () => {
        console.log(this.props.premium, 'PREMIUM');
        if (this.props.index == undefined && this.props.premium == false && !this.checkActiveCommands()) {
            if (this.props.mode == "event") {
                return this.setState({ premiumModal: true, premiumMessage: "You have reached your custom event limit. Either delete or disable some events or upgrade to Premium for unlimited custom events." });
            } else {
                return this.setState({ premiumModal: true, premiumMessage: "You have reached your custom command limit. Either delete or disable some commands or upgrade to Premium for unlimited custom commands." });
            }
        }
        var data = {
            title: this.state.name,
            description: this.state.description,

            data: {
                ephemeral: false,
                permissions: {
                    allowed_roles: [{ name: '@everyone', id: 'everyone' }],
                    banned_roles: [],
                    banned_channels: [],
                    banned_users: [],
                    required_permissions: []
                },
                cooldown: this.state.cooldown,
                handleErrors: this.state.handleErrors,
                errors: this.state.errors,
            }
        };



        console.log(data, 'DATA');
        // return;
        var components = {
            Guild: true,
            Channel: true,
            User: true,
            Member: true,
            Interaction: true,
        };
        if (this.props.mode == "event") {
            data = {
                event: true,
                enabled: true,
                data: {
                    name: "",
                    type: "",
                }
            };

            components = {
                Guild: true,
                Channel: true,
            };
        }
        if (this.props.edit != true) {
            var elements = [
                {
                    id: 'root',
                    type: 'root',

                    data: data,
                    // target:"3",
                    draggable: false,
                    position: { x: 250, y: 250 },
                },
            ];
            if (this.props.mode != "event") {
                var errorElements = await buildExistingCommand({
                    options: [],
                    actions: [{

                        nodeID: "action_3_6d1a_9853",
                        embed: {
                            color: "#FF0000",
                            description: "{error_reason}",
                            title: ":x: {error_message}",
                            footer: "{error_block}"
                        },
                        emojis: [],
                        target: { reply: true },
                        type: "embed"
                    }
                    ]
                }, 'command', true);
                elements.push(...errorElements);
            } else {
                elements.push({
                    id: "error_handler",
                    type: "error",
                    position: {
                        x: 850,
                        y: 0
                    },
                    draggable: false,
                    data: {
                        node_options: {
                            title: "Error Handler",
                            description: "Handle errors that occur during the event execution",
                            icon: faExclamationTriangle
                        },
                        data: {}
                    }
                });
            }
            // console.log(elements, 'ELEMENTS ', this.props.mode);
            this.props.setElements(elements);
            this.props.setBuilderMode({
                mode: this.props.mode == "event" ? "event" : "command",
                components: components
            });
            this.props.setSelected(null);
            this.props.setBuilderIndex(null);
            history.push("/dashboard/builder");
        } else {
            // this.buildExistingCommand()
            if (this.props.mode == "event") {

                var errorElements = [];

                // console.log(errorElements, 'ERROR ELEMENTS');
                var event_options = CUSTOM_EVENTS.find(event => event.value == this.props.event.type);
                components = event_options.components;

                var eventState = { ...this.state };
                var builtElements = [];
                if ('compressed_tree' in eventState && eventState.compressed_tree != null) {
                    var compressedString = LZString.decompressFromBase64(this.state.compressed_tree);
                    var tree = JSON.parse(compressedString);

                    builtElements = tree;
                } else
                    if ('compressed_actions' in eventState && eventState.compressed_actions != null) {
                        var compressedString = LZString.decompressFromBase64(this.state.compressed_actions);
                        var actions = JSON.parse(compressedString);
                        eventState.actions = actions;
                        builtElements = await buildExistingCommand(eventState, 'event', false);

                        errorElements = await buildExistingCommand({
                            options: [],
                            actions: this.state.errors
                        }, 'event', true);
                    } else {
                        builtElements = await buildExistingCommand(eventState, 'event', false);

                        errorElements = await buildExistingCommand({
                            options: [],
                            actions: this.state.errors
                        }, 'event', true);
                    }
                // if (this.props.beta) {
                builtElements = builtElements.concat(errorElements);
                // }

                if ('future_string' in eventState && eventState.future_string != null) {
                    var futureString = LZString.decompressFromBase64(this.state.future_string);
                    var future = JSON.parse(futureString);

                    this.props.setFuture(future);
                }
                this.props.setBuilderIndex(this.props.index);
                if (this.props.event.module_id) {
                    this.props.setBuilderModuleId(this.props.event.module_id);
                }
                this.props.setElements(builtElements);
                this.props.setBuilderMode({
                    mode: this.props.mode == "event" ? "event" : "command",
                    components: components
                });

                this.props.setSelected(null);
                this.props.setGroupId(this.props.event.groupId);
                history.push("/dashboard/builder");
            } else {
                var errorElements = [];
                // console.log(errorElements, this.state.errors, 'HERE TWO ELEMENTS');

                var commandState = { ...this.state };
                var builtElements = [];
                if ('compressed_tree' in commandState && commandState.compressed_tree != null) {
                    var compressedString = LZString.decompressFromBase64(this.state.compressed_tree);
                    var tree = JSON.parse(compressedString);
                    builtElements = tree;
                } else if ('compressed_actions' in commandState && commandState.compressed_actions != null) {
                    var compressedString = LZString.decompressFromBase64(this.state.compressed_actions);
                    var actions = JSON.parse(compressedString);
                    commandState.actions = actions;
                    builtElements = await buildExistingCommand(commandState, "command", false);
                    errorElements = await buildExistingCommand({
                        options: [],
                        actions: this.state.errors
                    }, 'command', true);
                } else {
                    builtElements = await buildExistingCommand(commandState, "command", false);
                    errorElements = await buildExistingCommand({
                        options: [],
                        actions: this.state.errors
                    }, 'command', true);
                }

                if ('future_string' in commandState && commandState.future_string != null) {
                    var futureString = LZString.decompressFromBase64(this.state.future_string);
                    var future = JSON.parse(futureString);
                    this.props.setFuture(future);
                }

                // if (this.props.beta) {
                builtElements = builtElements.concat(errorElements);
                // }
                this.props.setBuilderIndex(this.props.index);
                if (this.props.command.module_id) {
                    this.props.setBuilderModuleId(this.props.command.module_id);
                }
                this.props.setElements(builtElements);
                this.props.setBuilderMode({
                    mode: this.props.mode == "event" ? "event" : "command",
                    components: {
                        Guild: true,
                        Channel: true,
                        User: true,
                        Member: true,
                        Interaction: true,
                    }
                });
                this.props.setGroupId(this.props.command.groupId);
                this.props.setSelected(null);
                history.push("/dashboard/builder");
            }

        }
    };

    buildExistingCommand = () => {
        var elements = [
            {
                id: 'root',
                type: 'root',
                data: {
                    title: this.state.name,
                    description: this.state.description,
                    data: {
                        ephemeral: this.state.ephemeral,
                        permissions: this.state.permissions,
                        cooldown: this.state.cooldown
                    }
                },
                // target:"3",
                draggable: false,
                position: { x: 250, y: 250 },
            },
        ];

        //   Add Options
        this.state.options.forEach((option, index) => {
            var node_options = types.find(t => t.type == option.type);
            var id = `option_${index + 1}`;
            var element = {
                id: id,
                type: "option",
                position: {
                    x: 250,
                    y: 250
                },
                draggable: true,
                data: {
                    node_options: node_options,
                    data: { ...option, validated: true },
                }
            };

            elements = addEdge({
                source: id,
                target: "root",
                type: "step",
                animated: false,
                arrowHeadType: "arrowclosed"

            }, elements);
            elements.push(element);
        });
        var previousNode = elements[0].id;
        this.state.actions.forEach((action, index) => {
            if (action.type == "condition") {
                var id = `condition_${elements.length + 1}`;
                var node_options = CONDITION_TYPES.find(t => t.condition_type == action.condition_type);

                var element = {
                    id: id,
                    type: "condition",
                    position: {
                        x: 250,
                        y: 250
                    },
                    draggable: true,
                    data: {
                        node_options: node_options,
                        data: { ...action, validated: true },
                    }
                };
                elements.push(element);
                elements = addEdge({
                    source: previousNode,
                    target: id,
                    type: "custom",
                    animated: false,
                    arrowHeadType: "arrowclosed"

                }, elements);
                previousNode = id;
                elements = this.createConditionAction(action, id, elements);
            } else {
                var id = `action_${elements.length + 1}`;
                var node_options = ACTION_TYPES.find(t => t.value == action.type);
                node_options.description = node_options.label;
                var element = {
                    id: id,
                    type: "action",
                    position: {
                        x: 250,
                        y: 250
                    },
                    draggable: true,
                    data: {
                        node_options: node_options,
                        data: { ...action, validated: true },
                    }
                };
                elements.push(element);
                elements = addEdge({
                    source: previousNode,
                    target: id,
                    type: "custom",
                    animated: false,
                    arrowHeadType: "arrowclosed"

                }, elements);
                previousNode = id;

                if (action.type == "button_response") {
                    elements = this.createButtonAction(action, id, elements);
                } else if (action.type == "select_menu") {
                    elements = this.createSelectMenuAction(action, id, elements);
                }
            }
        });
        this.props.setBuilderIndex(this.props.index);
        this.props.setElements(elements);
        this.props.setSelected(null);
        history.push("/dashboard/builder");
    };
    createConditionAction = (action, parentId, elements) => {
        // console.log(action, 'ACTION HERE');
        var elseID = `else_${elements.length + 1}`;
        var element = {
            id: elseID,
            type: "conditionChild",
            position: {
                x: 250,
                y: 250
            },
            draggable: true,
            data: {
                node_options: {
                    type: action.condition_type
                },
                data: {
                    type: "else",
                    option: action.option,
                    actions: []
                }
            }
        };
        elements.push(element);
        elements = addEdge({
            source: parentId,
            target: elseID,
            type: "step",
            animated: false,
            arrowHeadType: "arrowclosed"
        }, elements);

        var previousElseConID = elseID;
        action.else.actions.forEach((conditionAction, index) => {
            if (conditionAction.type == "condition") {
                var id = `condition_${elements.length + 1}`;

                var node_options = CONDITION_TYPES.find(t => t.condition_type == conditionAction.condition_type);

                var element = {
                    id: id,
                    type: "condition",
                    position: {
                        x: 250,
                        y: 250
                    },
                    draggable: true,
                    data: {
                        node_options: node_options,
                        data: { ...conditionAction, validated: true },
                    }
                };
                elements.push(element);
                elements = addEdge({
                    source: previousElseConID,
                    target: id,
                    type: "custom",
                    animated: false,
                    arrowHeadType: "arrowclosed"

                }, elements);
                elements = this.createConditionAction(conditionAction, id, elements);
            } else {
                var conditionActionID = `action_${elements.length + 1}`;
                var node_options = ACTION_TYPES.find(t => t.value == conditionAction.type);
                node_options.description = node_options.label;
                var element = {
                    id: conditionActionID,
                    type: "action",
                    position: {
                        x: 250,
                        y: 250
                    },
                    draggable: true,
                    data: {
                        node_options: node_options,
                        data: { ...conditionAction, validated: true },
                    }
                };
                elements.push(element);
                elements = addEdge({
                    source: previousElseConID,
                    target: conditionActionID,
                    type: "custom",
                    animated: false,
                    arrowHeadType: "arrowclosed"

                }, elements);
                previousElseConID = conditionActionID;
                if (conditionAction.type == "button_response") {
                    elements = this.createButtonAction(conditionAction, conditionActionID, elements);
                } else if (conditionAction.type == "select_menu") {
                    elements = this.createSelectMenuAction(conditionAction, conditionActionID, elements);
                }
            }

        });
        action.conditions.forEach((condition, index) => {
            var conChild_id = `conChild${elements.length + 1}`;

            var element = {
                id: conChild_id,
                type: "conditionChild",
                position: {
                    x: 250,
                    y: 250
                },
                draggable: true,
                data: {
                    node_options: {
                        type: action.condition_type
                    },
                    data: condition
                }
            };

            elements.push(element);
            elements = addEdge({
                source: parentId,
                target: conChild_id,
                type: "step",
                animated: false,
                arrowHeadType: "arrowclosed"
            }, elements);

            var previousConditionChildNode = conChild_id;
            condition.actions.forEach((conditionAction, index) => {
                if (conditionAction.type == "condition") {
                    var id = `condition_${elements.length + 1}`;

                    var node_options = CONDITION_TYPES.find(t => t.condition_type == conditionAction.condition_type);

                    var element = {
                        id: id,
                        type: "condition",
                        position: {
                            x: 250,
                            y: 250
                        },
                        draggable: true,
                        data: {
                            node_options: node_options,
                            data: { ...conditionAction, validated: true },
                        }
                    };
                    elements.push(element);
                    elements = addEdge({
                        source: previousConditionChildNode,
                        target: id,
                        type: "custom",
                        animated: false,
                        arrowHeadType: "arrowclosed"

                    }, elements);
                    elements = this.createConditionAction(conditionAction, id, elements);

                } else {
                    var conditionActionID = `action_${elements.length + 1}`;
                    var node_options = ACTION_TYPES.find(t => t.value == conditionAction.type);
                    node_options.description = node_options.label;
                    var element = {
                        id: conditionActionID,
                        type: "action",
                        position: {
                            x: 250,
                            y: 250
                        },
                        draggable: true,
                        data: {
                            node_options: node_options,
                            data: { ...conditionAction, validated: true },
                        }
                    };
                    elements.push(element);
                    elements = addEdge({
                        source: previousConditionChildNode,
                        target: conditionActionID,
                        type: "custom",
                        animated: false,
                        arrowHeadType: "arrowclosed"

                    }, elements);
                    previousConditionChildNode = conditionActionID;
                    if (conditionAction.type == "button_response") {
                        elements = this.createButtonAction(conditionAction, conditionActionID, elements);
                    } else if (conditionAction.type == "select_menu") {
                        elements = this.createSelectMenuAction(conditionAction, conditionActionID, elements);
                    }
                }

            });
        });

        return elements;
    };
    createButtonAction = (action, parentId, elements) => {
        action.buttons.forEach((button, index) => {
            var button_id = `button_${elements.length + 1}`;

            var element = {
                id: button_id,
                type: "button",
                position: {
                    x: 250,
                    y: 250
                },
                draggable: true,
                data: {
                    node_options: {
                        label: button.label,
                        style: button.style
                    },
                    data: {
                        label: button.label,
                        style: button.style,
                        actions: button.actions,
                        validated: true
                    },
                }
            };
            if (button.style == "LINK") {
                element.data.data.URL = button.URL;
            }
            elements.push(element);
            elements = addEdge({
                source: parentId,
                target: button_id,
                type: "step",
                animated: false,
                arrowHeadType: "arrowclosed"
            }, elements);

            var previousButtonNode = button_id;
            button.actions.forEach((buttonAction, index) => {
                if (buttonAction.type == "condition") {
                    var id = `condition_${elements.length + 1}`;

                    var node_options = CONDITION_TYPES.find(t => t.condition_type == buttonAction.condition_type);

                    var element = {
                        id: id,
                        type: "condition",
                        position: {
                            x: 250,
                            y: 250
                        },
                        draggable: true,
                        data: {
                            node_options: node_options,
                            data: { ...buttonAction, validated: true },
                        }
                    };

                    elements.push(element);
                    elements = addEdge({
                        source: previousButtonNode,
                        target: id,
                        type: "custom",
                        animated: false,
                        arrowHeadType: "arrowclosed"

                    }, elements);
                    elements = this.createConditionAction(buttonAction, id, elements);

                } else {
                    var buttonActionid = `button_action_${elements.length + 1}`;
                    var node_options = ACTION_TYPES.find(t => t.value == buttonAction.type);
                    node_options.description = node_options.label;
                    var element = {
                        id: buttonActionid,
                        type: "action",
                        position: {
                            x: 250,
                            y: 250
                        },
                        draggable: true,
                        data: {
                            node_options: node_options,
                            data: { ...buttonAction, validated: true },
                        }
                    };
                    elements.push(element);
                    elements = addEdge({
                        source: previousButtonNode,
                        target: buttonActionid,
                        type: "custom",
                        animated: false,
                        arrowHeadType: "arrowclosed"

                    }, elements);
                    previousButtonNode = buttonActionid;
                    if (buttonAction.type == "button_response") {
                        elements = this.createButtonAction(buttonAction, buttonActionid, elements);
                    } else if (buttonAction.type == "select_menu") {
                        elements = this.createSelectMenuAction(buttonAction, buttonActionid, elements);
                    }
                }
            });
        });
        return elements;
    };

    createSelectMenuAction = (action, parentId, elements) => {
        action.options.forEach((menuOption, index) => {
            var menuOption_id = `select_menu_option_${elements.length + 1}`;

            var element = {
                id: menuOption_id,
                type: "selectMenuOption",
                position: {
                    x: 250,
                    y: 250
                },
                draggable: true,
                data: {
                    node_options: {

                    },
                    data: {
                        label: menuOption.label,
                        description: menuOption.description,
                        validated: true
                    },
                }
            };

            elements.push(element);
            elements = addEdge({
                source: parentId,
                target: menuOption_id,
                type: "step",
                animated: false,
                arrowHeadType: "arrowclosed"
            }, elements);

            var previousButtonNode = menuOption_id;
            menuOption.actions.forEach((menuOptionAction, index) => {
                if (menuOptionAction.type == "condition") {
                    var id = `condition_${elements.length + 1}`;

                    var node_options = CONDITION_TYPES.find(t => t.condition_type == menuOptionAction.condition_type);

                    var element = {
                        id: id,
                        type: "condition",
                        position: {
                            x: 250,
                            y: 250
                        },
                        draggable: true,
                        data: {
                            node_options: node_options,
                            data: { ...menuOptionAction, validated: true },
                        }
                    };

                    elements.push(element);
                    elements = addEdge({
                        source: previousButtonNode,
                        target: id,
                        type: "custom",
                        animated: false,
                        arrowHeadType: "arrowclosed"

                    }, elements);
                    elements = this.createConditionAction(menuOptionAction, id, elements);

                } else {
                    var menuOptionActionid = `menu_action_${elements.length + 1}`;
                    var node_options = ACTION_TYPES.find(t => t.value == menuOptionAction.type);
                    node_options.description = node_options.label;
                    var element = {
                        id: menuOptionActionid,
                        type: "action",
                        position: {
                            x: 250,
                            y: 250
                        },
                        draggable: true,
                        data: {
                            node_options: node_options,
                            data: { ...menuOptionAction, validated: true },
                        }
                    };
                    elements.push(element);
                    elements = addEdge({
                        source: previousButtonNode,
                        target: menuOptionActionid,
                        type: "custom",
                        animated: false,
                        arrowHeadType: "arrowclosed"

                    }, elements);
                    previousButtonNode = menuOptionActionid;
                    if (menuOptionAction.type == "button_response") {
                        elements = this.createButtonAction(menuOptionAction, menuOptionActionid, elements);
                    } else if (menuOptionAction.type == "select_menu") {
                        elements = this.createSelectMenuAction(menuOptionAction, menuOptionActionid, elements);
                    }
                }
            });
        });
        return elements;
    };

    save = () => {

        this.setState({ error: "" });
        if (this.state.step == "trigger_description") {
            // console.log("HERE");
            // Go to Command Builder
            this.setState({ step: "options" });
            // this.setState({ step: "options" })
        } else if (this.state.step == "options") {
            this.setState({ step: "actions" });
        } else if (this.state.step == "actions") {
            if (this.state.actions.length == 0) {
                this.setState({ error: "Please add atleast one action to your slash command" });
            } else {
                this.setState({ step: "additional" });
            }
        } else if (this.state.step == "additional") {
            if (this.state.actions.length == 0) {
                this.setState({ error: "Please add atleast one action to your slash command" });
            } else {
                const data = { ...this.props.slash };
                var command = { ...this.state };
                delete command.error;
                if (this.props.index != undefined && this.props.edit == true) {
                    delete command.showPremiumModal;
                    delete command.step;
                    data.commands[this.props.index] = command;
                    this.props.setBotModule(
                        {
                            module: "slash",
                            module_data: data
                        }
                    );
                    this.props.close();
                } else {

                    if (this.props.premium == true || data.commands.length < 5) {
                        delete command.showPremiumModal;
                        delete command.step;
                        data.commands.push(command);
                        this.props.setBotModule(
                            {
                                module: "slash",
                                module_data: data
                            }
                        );

                        ReactGA.initialize('UA-122665050-1');
                        ReactGA.event({
                            category: "Custom Command",
                            label: "Created",
                            action: "New Dashboard"
                        });
                        this.reset();
                    } else {
                        this.setState({ showPremiumModal: true });
                    }

                }
            }
        }
    };

    delete = () => {
        if (this.props.mode == "event") {
            const data = { ...this.props.bot.commands.custom_events };
            const events = [...data.events];
            events.splice(this.props.index, 1);
            this.props.close();
            data.events = events;
            this.props.setBotModule(
                {
                    module: "custom_events",
                    module_data: data
                }
            );
        } else {
            const data = { ...this.props.slash };
            const commands = [...data.commands];
            commands.splice(this.props.index, 1);
            this.props.close();
            data.commands = commands;
            this.props.setBotModule(
                {
                    module: "slash",
                    module_data: data
                }
            );
        }

    };

    nameChange = (value) => {



        value = value.toLowerCase();
        value = value.substring(0, 32);
        value = value.replace(" ", "-");
        // value = value.replace(/[^\w]+/gi,"")
        // value = value.replace(/[^[A-zÀ-Ÿ\d-]+/gi,"")
        const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/ug);
        value = value.replace(regexForNonAlphaNum, "");
        this.setState({ name: value });
    };

    countActiveCommands = () => {
        if (this.props.mode == "event") {
            var count = 0;
            this.props.custom_events.events.forEach(event => {
                if (event.enabled == undefined || event.enabled == true) {
                    count += 1;
                }
            });
            return count;
        } else {
            var count = 0;
            this.props.slash.commands.forEach(command => {
                if (command.enabled == undefined || command.enabled == true) {
                    count += 1;
                }
            });
            return count;
        }

    };

    enableDisable = (value) => {

        if (value == true && this.props.premium != true && !this.state.module_id && ((this.props.mode == "event" && this.countActiveCommands() >= 3) || this.countActiveCommands() >= 8)) {
            if (this.props.mode == "event") {
                toast.error("You have reached the 3 custom event limit for free accounts. Disable other events or upgrade to premium to enable this event.", {
                    duration: 5000,
                    style: {
                        borderRadius: "10px",
                        background: "#333",
                        color: "#fff"
                    }
                });
            } else {
                toast.error("You have reached the 8 custom command limit for free accounts. Disable other commands or upgrade to premium to enable this command.", {
                    duration: 5000,
                    style: {
                        borderRadius: "10px",
                        background: "#333",
                        color: "#fff"
                    }
                });
            }
            return;
        }

        if (this.props.mode == "event") {
            var data = { ...this.props.custom_events };
            var events = [...data.events];
            events[this.props.index].enabled = value;
            data.events = events;
            this.props.setBotModule(
                {
                    module: "custom_events",
                    module_data: data
                }
            );
            this.props.close();
        } else {

            if (this.props.command.module_id) {
                var module_settings = { ...this.props.moduleSettings };
                module_settings[this.props.command.module_id].commands[this.props.index].enabled = value;
                // console.log(module_settings, 'MODULE SETTINGS');
                this.props.setBotModule({
                    module: "moduleSettings",
                    module_data: module_settings
                });
            } else {
                var data = { ...this.props.slash };
                var commands = [...data.commands];
                commands[this.props.index].enabled = value;
                data.commands = commands;
                this.props.setBotModule(
                    {
                        module: "slash",
                        module_data: data
                    }
                );

                this.props.close();
            }

        }

    };


    renderSteps = () => {
        if (this.state.step == "trigger_description") {
            // if (isBrowser) {
            return (
                <div className='custom-command-builder-goto' onClick={(e) => { this.commandBuilder(); }} >
                    <div className={`hithere action-node option-node`}>

                        <div className="inner" style={{ display: "flex" }}>


                            <div className="object-icon object-icon-template">
                                <FontAwesomeIcon icon={faHammer} />
                            </div>

                            <div className="object-info object-info-action">
                                {this.props.mode == "event" ? <span className="object-title">{this.state.name != "" ? `${this.state.name}` : "Custom Event Builder"}</span>
                                    : <span className="object-title">{this.state.name != "" ? `/${this.state.name}` : "Command Builder"}</span>
                                }
                                {/* <span className="object-title">{this.state.name != "" ? `/${this.state.name}` : "Command Builder"}</span> */}
                                <span className="object-description">{this.state.description != "" ? this.state.description : `Click here to start building a custom ${this.props.mode == "event" ? "event" : "command"}.`}</span>
                            </div>
                        </div>
                    </div>
                </div>
            );
            // } else {
            // return (
            //     <div>
            //         <div style={{ display: "flex" }}>
            //             <div >
            //                 <h3 style={{ fontSize: "27px" }}>Command Information <DocsLink location={"https://docs.botghost.com/modules/slash-commands"}></DocsLink></h3>
            //                 <div className="section-content-header mb-15">Your new custom commands Trigger and Description.</div>
            //             </div>
            //             {/* <div style={{marginLeft:"auto"}}>
            //                 <button className="btn btn-red" type="button" onClick={() =>{}}>Disable</button>
            //             </div> */}
            //         </div>


            //         <hr class="slashcommand-hr" />
            //         <div className="row">
            //             <div className='col-12 col-xl-6'>
            //                 <div className="mb-15">

            //                     <div className="mb-15">
            //                         <h3 style={{ marginBottom: "0px" }}>Trigger <DocsLink location={""} /></h3>
            //                         <div className="section-content-header mb-15">The trigger and name of your custom command.</div>

            //                         <div className="long-input">
            //                             <label>Trigger</label>
            //                             <input required type="text" value={this.state.name} onChange={(e) => this.nameChange(e.target.value)}></input>
            //                         </div>
            //                     </div>

            //                     <div className="mb-15">
            //                         <h3 style={{ marginBottom: "0px" }}>Description <DocsLink location={"https://docs.botghost.com/modules/slash-commands#description"}></DocsLink></h3>
            //                         <div className="section-content-header mb-15">The description of what the command does.</div>

            //                         <div className="long-input">
            //                             <label>Description</label>
            //                             <input required type="text" value={this.state.description} onChange={(e) => { this.setState({ description: e.target.value }) }}></input>
            //                         </div>
            //                     </div>

            //                     {this.props.edit == true ?
            //                         <div className="mb-15">
            //                             <h3 style={{ marginBottom: "0px" }}>Enable Command </h3>
            //                             <div className="section-content-header mb-15">Enable or Disable this command.</div>
            //                             <Toggle update={(value) => { this.enableDisable(value) }} value={this.props.command.enabled != undefined ? this.props.command.enabled : true} />

            //                         </div> : null}


            //                 </div>
            //             </div>
            //             <div className="col-12 col-xl-6">
            //                 <h3 style={{ marginBottom: "0px" }}>Preview <DocsLink location={"https://docs.botghost.com/modules/slash-commands#description"}></DocsLink></h3>
            //                 <div className="section-content-header mb-15">A preview of your command.</div>

            //                 <DiscordSlashPreview name={this.state.name} description={this.state.description} />
            //             </div>
            //         </div>
            //     </div>
            // )
            // }
        } else if (this.state.step == "options") {
            return (
                <div className='row'>
                    <div className="mb-15 col-12 col-xl-6">
                        <h3 style={{ fontSize: "27px" }}>Options <DocsLink location={"https://docs.botghost.com/modules/slash-commands/options-1"}></DocsLink></h3>
                        <div className="section-content-header mb-15">Add options to your custom command.</div>
                        <hr class="slashcommand-hr" />
                        <div className="slashcommand-options">
                            {this.renderOptions()}
                        </div>
                        <button className="btn btn-red btn-800 hithere" type="button" onClick={(e) => { this.addOption(); }}>Add Option</button>
                    </div>

                    <div className="col-12 col-xl-6">
                        <h3 style={{ marginBottom: "0px" }}>Preview <DocsLink location={"https://docs.botghost.com/modules/slash-commands#description"}></DocsLink></h3>
                        <div className="section-content-header mb-15">A preview of your command.</div>

                        <DiscordSlashPreview options={this.state.options} name={this.state.name} description={this.state.description} />
                    </div>
                </div>

            );
        } else if (this.state.step == "actions") {
            return (

                <div className="mb-15">
                    <h3 style={{ fontSize: "27px" }}>Actions <DocsLink location={"https://docs.botghost.com/modules/slash-commands/actions"} /></h3>
                    <div className="section-content-header mb-15">Add actions to your custom command.</div>
                    <hr class="slashcommand-hr" />
                    <div className="slashcommand-options">
                        {this.renderActions()}
                    </div>


                    <button className="btn btn-red btn-800 hithere" type="button" onClick={(e) => { this.addAction(); }}>Add Action</button>


                </div>
            );
        } else if (this.state.step == "additional") {
            return (
                <div>
                    <div className="mb-15">

                        <h3 style={{ fontSize: "27px" }}>Additional Settings <DocsLink location={"https://docs.botghost.com/modules/slash-commands/actions"} /></h3>
                        <div className="section-content-header mb-15">Set extra options for your command.</div>
                        <hr class="slashcommand-hr" />


                        <div className="slashcommand-option">
                            <div className="section-content section-content-no-bg">
                                <div className="mb-15 slash-action">
                                    <h4>Hide bot's replies from everyone but the executor of the slash command.  <DocsLink location={"https://docs.botghost.com/modules/slash-commands/actions"} /></h4>
                                    <span>Hides the replies of the bot from everyone except the person who triggered the command. Will not work for targeted responses and DM actions.</span>
                                </div>

                                <div style={{ marginLeft: "auto", paddingLeft: "50px" }}>
                                    <Toggle value={this.state.ephemeral} update={(value) => { this.setState({ ephemeral: value }); }} />

                                </div>
                            </div>

                            <SlashCommandPermissions
                                data={this.state.permissions}
                                edit={this.props.edit}
                                update={(value) => { this.setState({ permissions: value }); }}
                            />
                        </div>
                    </div>
                </div>
            );
        }
    };

    generateCode = async () => {

        if (!("id" in this.state) || this.state.id == "") {
            var id = `CMD_${uuidv4()}`;
            if (this.props.mode == "event") {
                id = `EVENT_${uuidv4()}`;
            }
            var response = await server.post("/dashboard/commandtemplate", { type: this.props.mode == "event" ? "event" : "command", bot_id: this.props.bot.id, user_id: this.props.user.id, id: id, command: this.state });
            if (response && response.data == true) {
                this.setState({ id: id });

                var command = { ...this.state };
                delete command.showPremiumModal;
                delete command.step;
                if (this.props.mode == "event") {
                    const data = { ...this.props.custom_events };
                    data.events[this.props.index] = command;
                    this.props.setBotModule(
                        {
                            module: "custom_events",
                            module_data: data
                        }
                    );
                } else {
                    const data = { ...this.props.slash };
                    data.commands[this.props.index] = command;
                    this.props.setBotModule(
                        {
                            module: "slash",
                            module_data: data
                        }
                    );
                }

                navigator.clipboard.writeText(id);

            }
            // GENERATE NEW ID
        } else {
            navigator.clipboard.writeText(this.state.id);

            // copy to clipboard.
        }


    };

    importCommand = (command) => {
        command.showImportModal = false;
        this.setState(command);
    };

    renderCommandButtons = () => {
        var buttons = [];
        if (this.state.step == "trigger_description") {
            if (this.props.mode == "event" && this.props.edit == true) {
                buttons.push(
                    <button className="btn btn-red" type="button" onClick={() => {
                        var value = false;
                        if (this.props.event.enabled == false) {
                            value = true;
                        }
                        this.enableDisable(value);

                    }}>{this.props.event.enabled == true || this.props.event.enabled == undefined ? "Disable" : 'Enable'} Event</button>
                );
            } else if (this.props.edit == true) {
                buttons.push(
                    <button className="btn btn-red" type="button" onClick={() => {
                        var value = false;
                        if (this.props.command.enabled == false) {
                            value = true;
                        }

                        this.enableDisable(value);

                    }}>{this.props.command.enabled == true || this.props.command.enabled == undefined ? "Disable" : 'Enable'} Command</button>
                );
            }
            // else if(!isBrowser) {
            //     buttons.push(
            //         <button className="btn btn-red" type="submit">Next Step: Options</button>

            //     )
            // }
            // buttons.push(
            //     <button className="btn btn-red" type="submit">Next Step: Options</button>

            // )

        } else if (this.state.step == "options") {
            buttons.push(
                <div>
                    <button className="btn btn-gray" type="button" onClick={(e) => { this.setState({ step: "trigger_description" }); }}>Back</button>
                    <button className="btn btn-red" type="submit">Next Step: Actions</button>
                </div>

            );
        } else if (this.state.step == "actions") {
            buttons.push(
                <div>
                    <button className="btn btn-gray" type="button" onClick={(e) => { this.setState({ step: "options" }); }}>Back</button>
                    <button className="btn btn-red" type="submit">Next Step: Additional Options</button>
                </div>

            );
        } else if (this.state.step == "additional") {
            buttons.push(
                <div>
                    <button className="btn btn-gray" type="button" onClick={(e) => { this.setState({ step: "actions" }); }}>Back</button>
                    <button className="btn btn-red" type="submit">{this.props.edit == true ? "Save Command" : "Create Slash Command"}</button>
                </div>

            );
        }

        return (
            <div class="commandAdd-buttons" style={{ marginRight: "auto" }}>
                {this.props.edit == true && ((this.props.mode == "event" && !this.props.event.module_id) || (this.props.mode != "event" && !this.props.command.module_id)) ? <button style={{ marginRight: "auto" }} className="btn btn-gray" type="button" onClick={(e) => { this.delete(); }}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></button> : null}
                {this.props.edit == true && (this.props.mode == "event" && !this.props.event.module_id || this.props.mode != "event" && !this.props.command.module_id) ? <button button style={{ marginRight: "auto" }} className="btn btn-blue" type="button" onClick={(e) => { this.generateCode(); }}><FontAwesomeIcon icon={faCopy} /> {this.state.id == "" || !this.state.id ? "Generate" : "Copy"} Share Code</button> : null}

                {/* { this.props.edit != true ? <button style={{ marginRight: "auto" }} className="btn btn-blue" type="button" onClick={(e) => { this.setState({showImportModal:true})}}><FontAwesomeIcon icon={faFileImport}/> Import Command</button>:null} */}


                {/* <button className="btn btn-red" type="submit">{this.props.edit == true ? "Save Command":"Create Slash Command"}</button> */}
                {buttons}
            </div >
        );
    };


    render() {

        return (
            <>
                {/* <Toaster position="top-left" reverseOrder={false} /> */}
                <form onSubmit={(e) => { this.submit(e); }}>
                    {this.state.showImportModal == true ? <CommandImporter importCommand={this.importCommand} closeModal={(e) => { this.setState({ showImportModal: false }); }} /> : null}
                    {this.state.premiumModal == true ? <PremiumRequiredModal close={() => { this.setState({ premiumModal: false, premiumMessage: "" }); }} premiumMessage={this.state.premiumMessage} /> : null}

                    <div className="section-content-normal m-mt-0 p-4 animate-pulse">
                        {this.state.error != "" ?
                            <div className="errors">
                                <div class="alert alert-danger" role="alert">
                                    {this.state.error}
                                </div>
                            </div> :
                            null}
                        {this.renderSteps()}

                        <hr class="slashcommand-hr" />
                        {this.renderCommandButtons()}
                    </div>
                </form>
            </>
        );
    }


}

const mapStateToProps = (state) => ({
    slash: state.data.bot.commands.slash,
    custom_events: state.data.bot.commands.custom_events,
    premium: state.data.premium,
    bot: state.data.bot,
    user: state.data.user,
    beta: state.data.beta,
    moduleSettings: state.data.bot.commands.moduleSettings,
    bv: state.data.bv
});

const mapDispatchToProps = {
    setBotModule,
    setBuilderIndex,
    setBuilderModuleId,
    setElements,
    setSelected,
    setFuture,
    setBuilderMode,
    setGroupId
};

export default connect(mapStateToProps, mapDispatchToProps)(SlashCommandEditor);
