import React, { Component } from 'react';
import DefaultHandle from './DefaultHandle';
import { isMobile } from 'react-device-detect';

export class ButtonNode extends Component {
    render() {
        const {
            id,
            data,
            selected,
            builder_settings,
            nodeHandleID,
            handleType,
            handleId,
            actionConnection,
            setSelected
        } = this.props;

        var useMobileBuilder = builder_settings?.mobileBuilder && isMobile;
        var nodePadding = useMobileBuilder ? "py-[50px]" : "py-[25px]";

        return (
            <>
                <DefaultHandle
                    nodeType="button"
                    selectedNodeHandleType={handleId}
                    nodeHandleID={nodeHandleID}
                    selectedNodeID={nodeHandleID}
                    selectedHandleType={handleType}
                    id={id}
                    position="top"
                    handleType="target"
                    isValidConnection={actionConnection}
                    nonInteractive={true}
                />

                <div
                    id={id}
                    onTouchStart={(e) => {
                        setSelected(id);
                    }}
                    onClick={(e) => {
                        setSelected(id);
                    }}
                    style={{
                        border: selected == id
                            ? `2px solid ${builder_settings?.selectedColor || '#5865f2'}`
                            : '2px solid transparent'
                    }}
                    className={`button-node ${nodePadding} discord-button-${data.data.style.toLowerCase()}`}
                >
                    <div className="inner">
                        <span>{data.data.label || "Button"}</span>
                    </div>
                </div>

                {data.data.style !== "LINK" && (
                    <DefaultHandle
                        nodeType="action"
                        selectedNodeHandleType={handleId}
                        nodeHandleID={nodeHandleID}
                        selectedNodeID={nodeHandleID}
                        selectedHandleType={handleType}
                        id={id}
                        position="bottom"
                        handleType="source"
                        isValidConnection={actionConnection}
                    />
                )}
            </>
        );
    }
} 