import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation, faLink, faHandPointer } from "@fortawesome/free-solid-svg-icons";
import DefaultHandle from './DefaultHandle';

export class AdvancedMessageButton extends Component {
    renderAdvancedButtonStyle = (data) => {
        if (!data?.data?.style) return this.props.builder_settings?.actionColor || '#358deb';
        if (data?.data?.style == "PRIMARY") return this.props.builder_settings?.actionColor || '#358deb';
        if (data?.data?.style == "SECONDARY") return "bg-[#4f545c]";
        if (data?.data?.style == "SUCCESS") return "bg-green";
        if (data?.data?.style == "DANGER") return "bg-red";
        if (data?.data?.style == "LINK") return "bg-[#4f545c]";
    };

    render() {
        const {
            id,
            data,
            selected,
            builder_settings,
            nodeHandleID,
            handleType,
            handleId,
            actionConnection,
            setSelected
        } = this.props;

        return (
            <>
                <DefaultHandle
                    nodeType="button"
                    selectedNodeHandleType={handleId}
                    nodeHandleID={nodeHandleID}
                    selectedNodeID={nodeHandleID}
                    selectedHandleType={handleType}
                    id={id}
                    position="top"
                    handleType="target"
                    isValidConnection={actionConnection}
                    nonInteractive={true}
                />

                <div
                    id={id}
                    onTouchStart={(e) => {
                        setSelected(id);
                    }}
                    onClick={(e) => {
                        setSelected(id);
                    }}
                    style={{
                        border: selected == id
                            ? `2px solid ${builder_settings?.selectedColor || '#5865f2'}`
                            : '2px solid transparent'
                    }}
                    className="action-node !py-[20px] p-[10px] option-node"
                >
                    <div className="inner" style={{ display: "flex" }}>
                        {data.data == undefined || data.data.validated == false ? (
                            <div className="node-invalid">
                                <FontAwesomeIcon icon={faExclamation} />
                            </div>
                        ) : null}

                        {data.data == undefined || data.data.linked == false ? (
                            <div className="node-invalid" style={{ right: `${data.data != undefined && data.data.validated == false ? "10px" : ""}` }}>
                                <FontAwesomeIcon icon={faLink} />
                            </div>
                        ) : null}
                        <div
                            className={`object-icon object-icon-action ${this.renderAdvancedButtonStyle(data)}`}
                        >
                            <FontAwesomeIcon icon={faHandPointer} />
                        </div>

                        <div className="object-info object-info-action">
                            <span className="object-title text-sm">
                                <span className="mr-1">{data?.data?.emoji_id ? data?.data?.emoji_id : ""}</span>
                                {data?.data?.label ? data?.data?.label : !data?.data?.emoji_id ? "Button" : ""}
                            </span>
                            <span className="object-description text-xs">Button</span>
                        </div>
                    </div>
                </div>

                {data?.data?.style !== "LINK" && (
                    <DefaultHandle
                        nodeType="action"
                        selectedNodeHandleType={handleId}
                        nodeHandleID={nodeHandleID}
                        selectedNodeID={nodeHandleID}
                        selectedHandleType={handleType}
                        id={id}
                        position="bottom"
                        handleType="source"
                        isValidConnection={actionConnection}
                    />
                )}
            </>
        );
    }
} 