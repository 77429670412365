import React, { Component } from 'react';
import { Handle } from 'react-flow-renderer';
import { connect } from 'react-redux';
import { getHandlePosition, getHandleStyles } from '../HandleSizes';
import { isBrowser } from 'react-device-detect';
import splitElements from '../splitElements';

const BASE_SIZES = {
    desktop: {
        normal: 25,
        expanded: 25,
        position: 0,
        expandedPosition: 0,
        nonInteractive: 10 // Added size for nonInteractive handles
    },
    mobile: {
        normal: 50,
        expanded: 50,
        position: 0,
        expandedPosition: 0,
        nonInteractive: 15 // Added size for nonInteractive handles
    }
};

export class ActionHandle extends Component {

    getHandleSize = (isAction, handleId, handleType, nodeId, currentNodeId) => {
        const sizes = isBrowser ? BASE_SIZES.desktop : BASE_SIZES.mobile;

        // If handle is hidden, always use normal size regardless of interactive state
        if (this.props.hidden) {
            return `${sizes.normal}`;
        }

        if (this.props.nonInteractive) {
            return `${sizes.nonInteractive}`;
        }

        return `${sizes.normal}`;
    };

    getHandleHighlight = () => {
        if (this.props.nonInteractive) {
            return "";
        }

        // If its the same node type,
        // And different different handleTypes I.E target -> source or source -> target ,
        // AND if nodeHandleID !== id
        if (this.props.selectedNodeHandleType == this.props.nodeType && this.props.nodeHandleID !== this.props.id && this.props.handleType !== this.props.selectedHandleType) {

            // Also check if the node already has a connection;

            // if (edge) {
            return "!ring-[#fff] !ring-2 animate-pulse duration-1000";

        }
    };
    render() {
        const {
            customClasses = '',
            customStyle = {},
            hidden = false
        } = this.props;

        const handleSize = this.getHandleSize(true, "action", "target", this.props.id, this.props.nodeHandleID);

        const style = {
            zIndex: 10,
            backgroundColor: this.props.nonInteractive
                ? "#545b6b"
                : (this.props.builder_settings?.actionColor || '#358deb'),
            border: "none",
            height: handleSize + "px",
            width: handleSize + "px",
            transform: 'translate(-50%, -50%)',
            ...(hidden && { opacity: 0 }),
            ...customStyle
        };

        if (this.props.customPosition && this.props.position == "bottom") {
            style.bottom = this.props.customPosition;
        } else if (this.props.position == "bottom") {
            style.bottom = `-${handleSize}px`;
        } else {
            style.top = getHandlePosition(true, "action", this.props.handleType, this.props.id, this.props.nodeHandleID);
        }

        console.log(this.props.nodeHandleID == this.props.id && this.props.selectedHandleType == this.props.handleType, 'CONNECT START HIGHLIGHT CHECK');
        return (
            <Handle
                isConnectable={!this.props.nonInteractive === true}
                className={`
                    ${this.props.nodeHandleID == this.props.id && this.props.selectedHandleType == this.props.handleType
                        ? "!ring-2 !ring-[#fff]" // Added ! to force specificity
                        : ""   // Default ring state
                    } 
                    ${this.getHandleHighlight()}
                    ${getHandleStyles()}
                    absolute cursor-pointer rounded-full transition-all duration-200
                    ${hidden ? 'hover:opacity-50' : ''}
                    ${customClasses}
                `}
                style={style}
                id={this.props.nodeType}
                type={this.props.handleType}
                position={this.props.position}
                isValidConnection={this.props.isValidConnection}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    builder_settings: state.data.user.builder_settings,
    elements: state.builder.elements
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ActionHandle);