import React, { Component } from 'react';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { Draggable } from "react-draggable";
import ReactGA from "react-ga";
import { addEdge, getIncomers } from 'react-flow-renderer';
import { setElements, setSelected } from '../../../actions';
import splitElements from './splitElements';
import { isMobile } from 'react-device-detect';
export class CommandBuilderObject extends Component {

  onDragStart = (event) => {
    event.dataTransfer.setData('options', JSON.stringify({
      type: this.props.title,
      category: this.props.type,
      description: this.props.description,
      icon: this.props.icon,
      data: this.props.data,
      condition_type: this.props.condition_type

    }));
    event.dataTransfer.effectAllowed = 'move';
  };

  componentDidMount() {
    //   $(".command-builder-object").draggable();
  }

  onClick = (event) => {
    if (this.props.disabled) return;
    try {
      this.props.setSelected(null);
      //   event.preventDefault();
      //   const reactFlowBounds = this.reactFlowWrapper.current.getBoundingClientRect();

      //   const position = this.state.reactFlowInstance.project({
      //     x: event.clientX - reactFlowBounds.left,
      //     y: event.clientY - reactFlowBounds.top,
      //   });
      var position = {
        x: 130,
        y: 300
      };

      // var root = this.props.elements.find(element => element.type == "root");
      // if(options.category == "option"){
      //     var incomers = getIncomers(root,splitElements(this.props.elements).nodes,splitElements(this.props.elements).edges);

      // }


      var options = {
        type: this.props.title,
        category: this.props.type,
        description: this.props.description,
        icon: this.props.icon,
        data: this.props.data,
        condition_type: this.props.condition_type
      };
      var elements = [...this.props.elements];
      var length = elements.length + 1;

      var data = {
        node_options: {
          title: options.type,
          description: options.description,
          icon: options.icon,
        },
        data: options.data
      };
      var id = `${options.category}_${length}_${s4()}_${s4()}`;
      const newNode = {
        id: id,
        type: options.category,
        data: data,
        position: position
      };

      ReactGA.initialize('UA-122665050-1');
      ReactGA.event({
        category: "Command Block",
        label: options.type,
        action: "New Dashboard"
      });

      if (options.category == "option") {
        var elements = addEdge({
          source: id,
          target: "root",
          type: "step",
          animated: false,
          arrowHeadType: "arrowclosed"

        }, elements);

      }
      else if (options.category == "condition") {
        var else_id = `$else_${length}_${s4()}_${s4()}`;
        elements.push(
          {
            id: else_id,
            type: 'conditionChild',
            data: {
              node_options: {
                type: options.condition_type,
              },
              data: {
                type: "else",
                actions: []
              }

            },
            // target:"3",
            draggable: true,
            position: { x: position.x + 200, y: position.y + 150 },
          },
        );

        elements = addEdge({
          source: id,
          target: else_id,
          type: "step",
          animated: false,
          arrowHeadType: "arrowclosed"

        }, elements);
      }

      elements.push(newNode);

      this.props.setSelected(newNode.id);
      this.props.setElements(elements);

      // Add this to close the aside on mobile after adding the object
      if (this.props.builder_settings?.mobileBuilder) {
        this.props.onMobileClose();
      }
    } catch (e) {
      console.error(e);
    }
  };

  getNodeColor = () => {
    const { type, builder_settings } = this.props;

    switch (type) {
      case 'action':
        return builder_settings?.actionColor || '#358deb';
      case 'note':
        return builder_settings?.actionColor || '#358deb';
      case 'condition':
        return builder_settings?.conditionColor || '#28a745';
      case 'option':
        return builder_settings?.optionColor || '#8d0eab';
      default:
        return '#358deb';
    }
  };

  render() {
    const useMobileBuilder = this.props.builder_settings?.mobileBuilder && isMobile;

    return (
      <div
        onClick={() => { this.onClick(); }}
        className={`
          !flex !items-start p-3 my-3 rounded hover:bg-[#36393f] transition-colors cursor-grab
          ${this.props.disabled ? "opacity-50 cursor-not-allowed" : ""}
          ${useMobileBuilder ? '-ml-2' : ''}
        `}
        draggable={this.props.disabled ? false : true}
        onDragStart={(e) => this.onDragStart(e)}
      >
        {/* Grip Handle - Only show on desktop, aligned with title */}
        {!useMobileBuilder && (
          <div className="text-[#72767d] !flex !items-start !mr-3 !ml-1 !mt-[6px]">
            <FontAwesomeIcon icon={faGripVertical} className="w-4 h-4" />
          </div>
        )}

        {/* Icon and Text Container - Aligned to top */}
        <div className="!flex !items-start !flex-1">
          {/* Icon */}
          <div
            className={`
              !flex !items-center !justify-center rounded-sm shrink-0 !mr-3 !mt-1
              ${useMobileBuilder ? 'w-10 h-10' : 'w-8 h-8'}
            `}
            style={{ backgroundColor: this.getNodeColor() }}
          >
            <FontAwesomeIcon
              icon={this.props.icon}
              className="w-4 h-4 text-white"
            />
          </div>

          {/* Text Content */}
          <div className="!flex !flex-col !min-w-0 !flex-1 !gap-[3px]">
            <span className={`
              !font-semibold !text-[#f2f3f5] !break-words !leading-[24px] 
              ${useMobileBuilder ? 'text-base' : '!text-[20px]'}
            `}>
              {this.props.title}
            </span>
            <span className={`
              !text-[#9d9b9b] !font-semibold !break-words !leading-[21px] 
              ${useMobileBuilder ? 'text-sm' : '!text-[15px]'}
            `}>
              {this.props.description}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  elements: state.builder.elements,
  builder_settings: state.data.user.builder_settings
});

const mapDispatchToProps = {
  setElements,
  setSelected

};

let s4 = () => {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
};

export default connect(mapStateToProps, mapDispatchToProps)(CommandBuilderObject);
