import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHammer } from "@fortawesome/free-solid-svg-icons";
import DefaultHandle from './DefaultHandle';
import { CUSTOM_EVENTS } from "../../../../variables";
import { isMobile } from 'react-device-detect';

export class RootNode extends Component {
    renderEventDescription = (type) => {
        var description = CUSTOM_EVENTS.find((e) => e.value == type);
        if (description != undefined) {
            return description.label;
        } else {
            return null;
        }
    };

    render() {
        const {
            id,
            data,
            selected,
            builder_settings,
            nodeHandleID,
            handleType,
            handleId,
            actionConnection,
            setSelected,
            mode
        } = this.props;

        var useMobileBuilder = builder_settings?.mobileBuilder && isMobile;
        var nodePadding = useMobileBuilder ? "py-[50px]" : "py-[25px]";

        return (
            <>
                {mode !== "event" && (
                    <DefaultHandle
                        nodeType="action"
                        selectedNodeHandleType={handleId}
                        nodeHandleID={nodeHandleID}
                        selectedNodeID={nodeHandleID}
                        selectedHandleType={handleType}
                        id={id}
                        position="top"
                        handleType="target"
                        isValidConnection={(connection) => false}
                        nonInteractive={true}
                    />
                )}

                <div
                    id={id}
                    onTouchStart={(e) => {
                        setSelected(id);
                    }}
                    onClick={(e) => {
                        setSelected(id);
                    }}
                    style={{
                        width: "600px",
                        border: selected == id
                            ? `2px solid ${builder_settings?.selectedColor || '#5865f2'}`
                            : '2px solid transparent'
                    }}
                    className={`action-node ${nodePadding} root-action-node`}
                >
                    <div id="root-inner" className="inner" style={{ display: "flex" }}>
                        <div
                            className="object-icon object-icon-root"
                            style={{ backgroundColor: builder_settings?.rootColor || '#358deb' }}
                        >
                            <FontAwesomeIcon icon={faHammer} />
                        </div>

                        {mode == "event" ? (
                            <div className="object-info object-info-action">
                                <span className="object-title" style={{ fontSize: "30px", lineHeight: "30px" }}>
                                    {this.renderEventDescription(data.data.type)}
                                </span>
                                <span className="object-description" style={{ fontSize: "20px", lineHeight: "20px" }}>
                                    Drag and drop different{" "}
                                    <span style={{ color: builder_settings?.actionColor || 'rgb(53, 141, 235)' }}>
                                        actions
                                    </span>
                                    {" "}and{" "}
                                    <span style={{ color: builder_settings?.conditionColor || 'rgb(40, 167, 69)' }}>
                                        conditions
                                    </span>
                                    {" "}to add them to your custom event. Connect the corresponding colors to create your custom event.
                                </span>
                            </div>
                        ) : (
                            <div className="object-info object-info-action">
                                <span className="object-title" style={{ fontSize: "30px", lineHeight: "30px" }}>
                                    /{data.title}
                                </span>
                                <span className="object-description" style={{ fontSize: "20px", lineHeight: "20px" }}>
                                    Drag and drop different{" "}
                                    <span style={{ color: builder_settings?.optionColor || 'rgb(141, 14, 171)' }}>
                                        options
                                    </span>
                                    ,{" "}
                                    <span style={{ color: builder_settings?.actionColor || 'rgb(53, 141, 235)' }}>
                                        actions
                                    </span>
                                    {" "}and{" "}
                                    <span style={{ color: builder_settings?.conditionColor || 'rgb(40, 167, 69)' }}>
                                        conditions
                                    </span>
                                    {" "}to add them to your command. Connect the corresponding colors to create your command.
                                </span>
                            </div>
                        )}
                    </div>
                </div>

                <DefaultHandle
                    nodeType="action"
                    selectedNodeHandleType={handleId}
                    nodeHandleID={nodeHandleID}
                    selectedNodeID={nodeHandleID}
                    selectedHandleType={handleType}
                    id={id}
                    position="bottom"
                    handleType="source"
                    isValidConnection={actionConnection}
                />
            </>
        );
    }
} 