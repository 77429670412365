import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Transition } from '@headlessui/react';
import { AllModules } from "../../variables";
import { BotSwitcher } from "./modules/BotSwitcher";
import history from "../../history";
import { updatePage } from "../../actions";
import moment from "moment";

import { faHSquare, faArrowAltCircleLeft, faTimes, faHome, faCog, faLink, faServer, faDollarSign, faQuestionCircle, faExclamationCircle, faShoppingCart, faGift, faHandshake, faDatabase, faWrench, faCodeBranch } from "@fortawesome/free-solid-svg-icons";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import CustomModuleSideMenuItem from "./elements/CustomModuleSideMenuItem";

export class MobileMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hovering: "",
			openBotSwitcher: false
		};
	}

	customModuleClick = (id, name) => {
		if (this.props.mobileAction) {
			this.props.mobileAction("close");
		}
		history.push(`/dashboard/module/${id}/${name}`);
	};
	handleMouseHover = (e, category) => {
		// this.setState({hovering:category})
		// this.setState(this.toggleHoverState);
	};
	changePage = (page) => {
		if (this.props.mobileAction) {
			this.props.mobileAction("close");
		}
		history.push("/dashboard/" + page);
	};

	checkCustomModuleEnabled = (id) => {
		var enabled = false;
		if (this.props.moduleSettings[id] != undefined && this.props.moduleSettings[id].enabled == true) {
			enabled = true;
		}
		return enabled;
	};

	renderErrorCount = () => {
		if (this.props.user.logged == undefined || this.props.api_errors.length == 0) {
			return null;
		} else {
			var count = 0;
			this.props.api_errors.forEach((error) => {
				if ((this.props.logsChecked == null || moment(this.props.logsChecked).isBefore(error.timeStamp)) && moment(this.props.user.logged).isBefore(moment(error.timeStamp))) {
					count += 1;
				}
			});
			if (count == 0) {
				return null;
			} else {
				return (
					<div className="error-log-count hithere">
						<div className="error-log-count-inner">{count}</div>
					</div>
				);
			}
		}
	};

	checkEnabled = (module) => {
		if (module == "Moderation") {
			var check = false;
			Object.keys(this.props.bot.commands.moderation).forEach((key) => {
				if (this.props.bot.commands.moderation[key].enabled === true && key != "afk" && key != "unban") {
					check = true;
					// console.log(key, "KEY");
				}
			});
			return check;
		} else if (module == "Announcements" && (this.props.bot.commands.announcements.join.length > 0 || this.props.bot.commands.announcements.ban.length > 0 || this.props.bot.commands.announcements.leave.length > 0)) {
			return true;
		} else if (module == "Automod" && this.props.bot.commands.automod.automod.enabled == true) {
			return true;
		} else if (module == "Autoresponder" && this.props.bot.commands.responder.enabled == true) {
			return true;
		} else if (module == "Auto-Role" && this.props.bot.commands.autorole.enabled == true) {
			return true;
		} else if (module == "Timed Messages" && this.props.bot.commands.messenger.enabled == true) {
			return true;
		} else if (module == "Statistics Channels" && this.props.bot.commands.statsChannels.enabled == true) {
			return true;
		} else if (module == "Status" && this.props.premium == true) {
			return true;
		} else if (module == "Reaction Roles" && this.props.bot.commands.reaction_roles.enabled == true) {
			return true;
		} else if (module == "YouTube" && this.props.bot.commands.youtube.enabled == true) {
			return true;
		} else if (module == "Twitter" && this.props.bot.commands.twitter.enabled == true) {
			return true;
		} else if (module == "Reddit" && this.props.bot.commands.reddit.enabled == true) {
			return true;
		} else if (module == "Twitch" && this.props.bot.commands.twitch.length > 0) {
			return true;
		} else if (module == "Meme Creator" && this.props.bot.commands.meme_creator.enabled == true) {
			return true;
		} else if (module == "Levels" && this.props.bot.commands.levels.enabled == true) {
			return true;
		} else if (module == "Economy" && this.props.bot.commands.economy.enabled == true) {
			return true;
		} else if (module == "Music" && this.props.bot.commands.music.enabled == true) {
			return true;
		} else if (module == "Crypto" && this.props.bot.commands.crypto.enabled == true) {
			return true;
		} else if (module == "NFTs" && this.props.bot.commands.opensea.enabled == true) {
			return true;
		} else if (module == "Embed Builder") {
			return true;
		} else if (module == "Giveaways" && this.props.bot.commands.giveaways.enabled == true) {
			return true;
		} else if (module == "Trivia" && this.props.bot.commands.fun.trivia.enabled == true) {
			return true;
		} else if (module == "Search") {
			var check = false;
			Object.keys(this.props.bot.commands.search).forEach((key) => {
				if (this.props.bot.commands.search[key].enabled == true) {
					check = true;
				}
			});
			return check;
		} else if (module == "Logging") {
			var check = false;
			Object.keys(this.props.bot.commands.logging).forEach((key) => {
				if (this.props.bot.commands.logging[key].enabled == true) {
					check = true;
				}
			});
			return check;
		} else if (module == "Botlist" && this.props.bot.commands.advertise.enabled == true) {
			return true;
		} else if (module == "Weather" && this.props.bot.commands.utils.weather.enabled == true) {
			return true;
		} else if (module == "Translator" && this.props.bot.commands.utils.translate.enabled == true) {
			return true;
		} else if (module == "Co Create" && this.props.bot.commands.cocreate.enabled == true) {
			return true;
		} else if (module == "Fortnite" && this.props.bot.commands.games.fortnite.enabled == true) {
			return true;
		} else if (module == "Apex Legends" && this.props.bot.commands.games.apex.enabled == true) {
			return true;
		} else if (module == "Custom Commands" && this.props.bot.commands.slash.commands.length > 0) {
			return true;
		} else if (module == "Usage" && this.props.bot.commands.settings.embeds.enabled == true) {
			return true;
		} else if (module == "Tickets" && this.props.bot.commands.tickets.enabled == true) {
			return true;
		}
	};

	checkDepreciation(module) {
		var depreciated = true;
		if (module.name == "Reaction Roles") {
			if (this.props.bot?.commands?.reaction_roles?.enabled == true) {
				depreciated = false;
			}
		}

		return depreciated;
	}

	renderModules = () => {
		var modules = [];
		var customModules = [...this.props.modules];
		var featured = [];
		var favourited = [];
		var newModules = [];
		var favModules = this.props.user.favModules;
		var ungrouped = [];

		AllModules.forEach((moduleCategory) => {
			var module_items = [];

			moduleCategory.modules.forEach((module) => {
				if (module.name != "Text Commands" && (module.beta != true || this.props.beta == true)) {
					if (module.depreciated == true && this.checkDepreciation(module)) {
						return;
					}

					var moduleItem = (
						<div
							key={module.name}
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								this.changePage(module.name.toLowerCase().split(" ").join(""));
							}}
							className={`flex items-center px-3 py-2 text-sm font-medium rounded-lg cursor-pointer
								${window.location.pathname === `/dashboard/${module.name.toLowerCase().split(" ").join("")}`
									? "bg-[#ef4444] text-[#ffffff]"
									: "text-[#ffffff]"}
								${module.premium && !this.props.premium ? "opacity-75" : ""}
							`}
						>
							<div className="flex items-center">
								<img
									src={process.env.PUBLIC_URL + "/images/icons/" + module.icon}
									alt={module.name}
									className="w-5 h-5 mr-3 rounded-full object-cover"
								/>
							</div>
							<span className="flex-1">{module.name}</span>
							{module.premium && !this.props.premium && (
								<span className="ml-2 px-2 py-0.5 text-xs font-medium text-[#fcd34d] border border-[#fcd34d] rounded">
									Premium
								</span>
							)}
						</div>
					);

					if (module.featured) {
						featured.push(moduleItem);
					} else if (favModules.includes(module.name)) {
						favourited.push(moduleItem);
					} else if (module.new) {
						newModules.push(moduleItem);
					} else {
						module_items.push(moduleItem);
					}
				}
			});

			var index = customModules.length;
			while (index--) {
				var customModule = customModules[index];
				if (customModule.hidden) {
					customModules.splice(index, 1);
					continue;
				}

				var moduleItem = (
					<CustomModuleSideMenuItem
						key={customModule.id}
						changePage={(id, name) => {
							this.customModuleClick(id, name);
							this.props.mobileAction("close");
						}}
						active={history.location.pathname === `/dashboard/module/${customModule.id}/${customModule.name.toLowerCase().split(" ").join("")}`}
						mobile={true}
						id={customModule.id}
						name={customModule.name}
						img={customModule.img}
						premiumRequired={customModule.premium}
					/>
				);

				if (customModule.featured) {
					featured.push(moduleItem);
					customModules.splice(index, 1);
				} else if (favModules.includes(customModule.id)) {
					favourited.push(moduleItem);
					customModules.splice(index, 1);
				} else if (customModule.new) {
					newModules.push(moduleItem);
					customModules.splice(index, 1);
				} else if (customModule.category === moduleCategory.name) {
					module_items.unshift(moduleItem);
					customModules.splice(index, 1);
				}
			}

			if (module_items.length > 0) {
				modules.push(
					<div key={moduleCategory.name} className="space-y-2">
						<h3 className="px-3 text-xs font-semibold text-[#9ca3af] uppercase tracking-wider">
							{moduleCategory.name}
						</h3>
						<div className="space-y-1">
							{module_items}
						</div>
					</div>
				);
			}
		});

		var groupedModules = customModules.reduce((r, a) => {
			r[a.category] = [...(r[a.category] || []), a];
			return r;
		}, {});

		Object.keys(groupedModules).forEach((key) => {
			var module_items = [];
			groupedModules[key].forEach((customModule) => {
				module_items.unshift(
					<CustomModuleSideMenuItem
						key={customModule.id}
						changePage={(id, name) => {
							this.customModuleClick(id, name);
							this.props.mobileAction("close");
						}}
						active={history.location.pathname === `/dashboard/module/${customModule.id}/${customModule.name.toLowerCase().split(" ").join("")}`}
						mobile={true}
						id={customModule.id}
						name={customModule.name}
						img={customModule.img}
					/>
				);
			});

			modules.push(
				<div key={key} className="space-y-2">
					<h3 className="px-3 text-xs font-semibold text-[#9ca3af] uppercase tracking-wider">
						{key}
					</h3>
					<div className="space-y-1">
						{module_items}
					</div>
				</div>
			);
		});

		var featuredandfavourited = [...featured, ...favourited, ...newModules];
		if (featured.length > 0) {
			modules.unshift(
				<div key="featured" className="space-y-2">
					<h3 className="px-3 text-xs font-semibold text-[#9ca3af] uppercase tracking-wider">
						Featured & Favourited
					</h3>
					<div className="space-y-1">
						{featuredandfavourited}
					</div>
				</div>
			);
		}

		return modules;
	};

	render() {
		if (!this.props.mobileNav) return null;

		return (
			<Transition.Root show={this.props.mobileNav} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-[9999]"
					onClose={() => this.props.mobileAction("close")}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-[#000000]/80 backdrop-blur-sm" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-hidden">
						<div className="absolute inset-0 overflow-hidden">
							<div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full">
								<Transition.Child
									as={Fragment}
									enter="transform transition ease-in-out duration-300"
									enterFrom="-translate-x-full"
									enterTo="translate-x-0"
									leave="transform transition ease-in-out duration-300"
									leaveFrom="translate-x-0"
									leaveTo="-translate-x-full"
								>
									<Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
										<div className="flex h-full flex-col overflow-y-auto bg-[#1a1c1f] shadow-xl">
											{/* Header */}
											<div className="relative flex items-center justify-between px-4 py-6 border-b border-[#1f2937]">
												<div className="flex items-center space-x-3">
													<img
														src={process.env.PUBLIC_URL + "/images/logo-red.png"}
														alt="BotGhost Logo"
														className="h-8 w-8"
													/>
													<span className="text-xl font-semibold text-[#ffffff]">BotGhost</span>
												</div>
												<button
													type="button"
													onClick={(e) => {
														e.preventDefault();
														e.stopPropagation();
														if (this.props.mobileAction) {
															this.props.mobileAction("close");
														}
													}}
													className="relative z-[10000] rounded-md p-2 text-[#ffffff] hover:text-[#d1d5db] focus:outline-none transition-colors"
												>
													<span className="sr-only">Close menu</span>
													<FontAwesomeIcon icon={faTimes} className="h-6 w-6" />
												</button>
											</div>

											{/* Menu Content */}
											<div className="flex-1 overflow-y-auto">
												<nav className="px-4 py-4 space-y-6">
													{/* Dashboard Section */}
													<div>
														<div
															onClick={(e) => {
																this.changePage("modules");
																this.props.mobileAction("close");
															}}
															className={`flex items-center px-3 py-2 text-sm font-medium rounded-lg cursor-pointer
																${window.location.pathname === "/dashboard/modules" || window.location.pathname === "/dashboard"
																	? "bg-[#ef4444] text-[#ffffff]"
																	: "text-[#ffffff]"}`}
														>
															<FontAwesomeIcon icon={faHome} className="mr-3 h-5 w-5 text-[#ffffff]" />
															Modules
														</div>
													</div>

													{/* Market Link */}
													<div>
														<a
															href="https://botghost.com/market"
															target="_blank"
															rel="noopener noreferrer"
															className="flex items-center px-3 py-2 text-sm font-medium text-[#ffffff] rounded-lg hover:bg-[#374151]"
														>
															<FontAwesomeIcon icon={faShoppingCart} className="mr-3 h-5 w-5 text-[#ffffff]" />
															Market
														</a>
													</div>

													{/* BotPanel Link */}
													<div>
														<a
															href="https://botpanel.gg/"
															target="_blank"
															rel="noopener noreferrer"
															className="flex items-center px-3 py-2 text-sm font-medium text-[#ffffff] rounded-lg hover:bg-[#374151]"
														>
															<img
																src="https://botpanel.gg/img/logo.png"
																alt="BotPanel"
																className="mr-3 h-5 w-5"
															/>
															<span className="flex items-center">
																BotPanel
																<span className="ml-2 px-2 py-0.5 text-xs font-medium text-[#ffffff] bg-[#3b82f6] rounded-md">
																	New!
																</span>
															</span>
														</a>
													</div>

													{/* Settings Section */}
													<div className="border-t border-[#1f2937] pt-6">
														<h3 className="px-3 text-xs font-semibold text-[#9ca3af] uppercase tracking-wider">
															Settings
														</h3>
														<div className="mt-3 space-y-2">
															<div
																onClick={(e) => {
																	this.changePage("settings");
																	this.props.mobileAction("close");
																}}
																className={`flex items-center px-3 py-2 text-sm font-medium rounded-lg cursor-pointer
																	${window.location.pathname === "/dashboard/settings"
																		? "bg-[#ef4444] text-[#ffffff]"
																		: "text-[#ffffff]"}`}
															>
																<FontAwesomeIcon icon={faCog} className="mr-3 h-5 w-5 text-[#ffffff]" />
																Settings
															</div>
														</div>
													</div>

													{/* Premium Section */}
													<div className="border-t border-[#1f2937] pt-6">
														<h3 className="px-3 text-xs font-semibold text-[#9ca3af] uppercase tracking-wider">
															Premium
														</h3>
														<div className="mt-3 space-y-2">
															<div
																onClick={(e) => {
																	this.changePage("premium");
																	this.props.mobileAction("close");
																}}
																className={`flex items-center px-3 py-2 text-sm font-medium rounded-lg cursor-pointer
																	${window.location.pathname === "/dashboard/premium"
																		? "bg-[#ef4444] text-[#ffffff]"
																		: "text-[#ffffff]"}`}
															>
																<img
																	src={process.env.PUBLIC_URL + "/images/crown.svg"}
																	alt="Premium"
																	className="mr-3 h-5 w-5"
																/>
																Premium
															</div>
															<div
																onClick={(e) => {
																	this.changePage("gifting");
																	this.props.mobileAction("close");
																}}
																className={`flex items-center px-3 py-2 text-sm font-medium rounded-lg cursor-pointer
																	${window.location.pathname === "/dashboard/gifting"
																		? "bg-[#ef4444] text-[#ffffff]"
																		: "text-[#ffffff]"}`}
															>
																<FontAwesomeIcon icon={faGift} className="mr-3 h-5 w-5 text-[#ffffff]" />
																Gifting
															</div>
														</div>
													</div>

													{/* Help Section */}
													<div className="border-t border-[#1f2937] pt-6">
														<h3 className="px-3 text-xs font-semibold text-[#9ca3af] uppercase tracking-wider">
															Help
														</h3>
														<div className="mt-3 space-y-2">
															<a
																href="https://discord.gg/botghost"
																target="_blank"
																rel="noopener noreferrer"
																className="flex items-center px-3 py-2 text-sm font-medium text-[#ffffff] rounded-lg hover:bg-[#374151]"
															>
																<FontAwesomeIcon icon={faDiscord} className="mr-3 h-5 w-5 text-[#ffffff]" />
																Support Server
															</a>
														</div>
													</div>

													{/* Modules Section */}
													<div className="border-t border-[#1f2937] pt-6">
														<div className="space-y-6">
															{this.renderModules()}
														</div>
													</div>
												</nav>
											</div>
										</div>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		);
	}
}

const mapStateToProps = (state) => ({
	page: state.page,
	bot: state.data.bot,
	bots: state.data.bots,
	premium: state.data.premium,
	quick_start: state.quick_start,
	user: state.data.user,
	beta: state.data.beta,
	api_errors: state.data.api_errors,
	logsChecked: state.data.logsChecked,
	moduleSettings: state.data.bot.commands.moduleSettings,
	modules: state.data.modules,
	collab: state.data.collab
});

const mapDispatchToProps = {
	updatePage
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
