import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocsLink from '../../elements/DocsLink';
import { updateElementData } from '../../../../actions';
import VariableTextInput from '../VariableTextInput';
import TextArea from '../../elements/TextArea';

const COLOR_OPTIONS = [
    { label: 'Purple', value: '#9333ea' },
    { label: 'Orange', value: '#f97316' },
    { label: 'Teal', value: '#14b8a6' },
    { label: 'Pink', value: '#ec4899' },
    { label: 'Yellow', value: '#ffd700' },
    { label: 'Green', value: '#22c55e' },
    { label: 'Blue', value: '#3b82f6' },
    { label: 'Red', value: '#ef4444' },
    { label: 'Indigo', value: '#6366f1' },
    { label: 'Lime', value: '#84cc16' }
];

export class NoteAction extends Component {

    componentDidMount() {
        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: "note_action",
                        title: "",
                        description: "",
                        color: "#9333ea", // Default to purple
                        validated: false
                    },
                    id: this.props.id
                }
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: "note_action",
                        title: "",
                        description: "",
                        color: "#9333ea",
                        validated: false
                    },
                    id: this.props.id
                }
            );
        } else {
            // VALIDATE
            if (this.props.data.title != "" && this.props.data.description != "" && this.props.data.validated != true) {
                this.updateData("validated", true);
            } else if (this.props.data.validated == true && (this.props.data.title == "" || this.props.data.description == "")) {
                this.updateData("validated", false);
            }
        }
    }

    updateData = (key, value) => {
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData(
            {
                data: data,
                id: this.props.id
            }
        );
    };

    render() {
        if (this.props.data != undefined) {
            return (
                <div>
                    <div className="mb-15">
                        <h4>Add a Note</h4>
                        <p>Create a note with a title and description that will show up in the tree. This block does not perform any actions.</p>
                    </div>

                    <div>
                        <h4 style={{ marginBottom: "0px" }}>Title<DocsLink location={"https://docs.botghost.com/"}></DocsLink></h4>
                        <div className="section-content-header mb-15">Enter a title for your note</div>
                        <VariableTextInput

                            required={true}
                            slash_options={this.props.options}
                            label="Title"
                            placeholder="Note Title"
                            value={this.props.data.title}
                            onChange={(value) => {
                                // If over 100 characters, truncate
                                if (value.length > 100) {
                                    value = value.slice(0, 100);
                                }
                                this.updateData("title", value);
                            }}
                        />
                    </div>

                    <div className="mt-15">
                        <h4 style={{ marginBottom: "0px" }}>Description<DocsLink location={"https://docs.botghost.com/"}></DocsLink></h4>
                        <div className="section-content-header mb-15">Enter the content of your note</div>
                        <TextArea
                            required={true}
                            placeholder="Note Description"
                            value={this.props.data.description}
                            onChange={(value) => {
                                this.updateData("description", value);
                            }}
                        />
                    </div>

                    <div className="mt-15">
                        <h4 style={{ marginBottom: "0px" }}>Note Color<DocsLink location={"https://docs.botghost.com/"}></DocsLink></h4>
                        <div className="section-content-header mb-15">Choose a color for your note</div>
                        <div className="flex gap-2 flex-wrap">
                            {COLOR_OPTIONS.map((color) => (
                                <button
                                    key={color.value}
                                    className={`w-8 h-8 rounded-full border-2 ${this.props.data.color === color.value ? 'border-white' : 'border-transparent'} hover:border-gray-400 transition-colors duration-200`}
                                    style={{ backgroundColor: color.value }}
                                    onClick={() => this.updateData("color", color.value)}
                                    title={color.label}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements,
    saveFailed: state.builder.saveFailed
});

const mapDispatchToProps = {
    updateElementData
};

export default connect(mapStateToProps, mapDispatchToProps)(NoteAction);
