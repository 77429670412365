import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation, faLink } from "@fortawesome/free-solid-svg-icons";
import DefaultHandle from './DefaultHandle';
import { isMobile } from 'react-device-detect';

export class ErrorNode extends Component {
    render() {
        const {
            id,
            data,
            selected,
            builder_settings,
            nodeHandleID,
            handleType,
            handleId,
            actionConnection,
            setSelected
        } = this.props;

        var useMobileBuilder = builder_settings?.mobileBuilder && isMobile;
        var nodePadding = useMobileBuilder ? "py-[50px]" : "py-[25px]";

        return (
            <>
                <div
                    id={id}
                    onTouchStart={(e) => {
                        setSelected(id);
                    }}
                    onClick={(e) => {
                        setSelected(id);
                    }}
                    style={{
                        border: selected == id
                            ? `2px solid ${builder_settings?.selectedColor || '#5865f2'}`
                            : '2px solid transparent'
                    }}
                    className={`action-node ${nodePadding} option-node`}
                >
                    <div className="inner" style={{ display: "flex" }}>
                        {data.data == undefined || data.data.validated == false ? (
                            <div className="node-invalid">
                                <FontAwesomeIcon icon={faExclamation} />
                            </div>
                        ) : null}

                        {data.data == undefined || data.data.linked == false ? (
                            <div className="node-invalid" style={{ right: `${data.data != undefined && data.data.validated == false ? "10px" : ""}` }}>
                                <FontAwesomeIcon icon={faLink} />
                            </div>
                        ) : null}
                        <div className="object-icon object-icon-error">
                            <FontAwesomeIcon icon={data.node_options.icon} />
                        </div>

                        <div className="object-info object-info-action">
                            <span className="object-title">{data.node_options.title}</span>
                            <span className="object-description">{data.node_options.description}</span>
                        </div>
                    </div>
                </div>

                <DefaultHandle
                    nodeType="action"
                    selectedNodeHandleType={handleId}
                    nodeHandleID={nodeHandleID}
                    selectedNodeID={nodeHandleID}
                    selectedHandleType={handleType}
                    id={id}
                    position="bottom"
                    handleType="source"
                    isValidConnection={actionConnection}
                />
            </>
        );
    }
} 