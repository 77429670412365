import React, { Component } from "react";
import { connect } from "react-redux";

import ReactFlow, { ReactFlowProvider, getIncomers, isNode, getOutgoers, getConnectedEdges } from "react-flow-renderer";
import CommandBuilderNav from "./CommandBuilderNav";
import CommandBuilderAside from "./CommandBuilderAside";
import CommandBuilderCanvas from "./CommandBuilderCanvas";
import CommandBuilderProperties from "./CommandBuilderProperties";

import { updateElementData, setBotData, resetBuilder, setElements, setSelected, setFuture } from "../../../actions";
import history from "../../../history";

import ReactGA from "react-ga";
import splitElements from "./splitElements";
import { Steps, Hints } from "intro.js-react";
import "intro.js/introjs.css";
import Cookies from "universal-cookie";
import { Helmet } from "react-helmet";
import server from "../../../api/server";
import { faBars, faTimes, faCog, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const steps = [
	{
		element: "#text-option",
		intro: (
			<div className="command-builder-tooltip-inner">
				<h2>Drag and Drop a Block</h2>
				<p>Drag and drop an Option, Action or Condition into the command builder.</p>
				<img src={process.env.PUBLIC_URL + `/images/gifs/command_drop.gif`}></img>
			</div>
		),
		position: "right",
		tooltipClass: "command-builder-tooltip",
		highlightClass: "myHighlightClass"
	},
	{
		element: "#root-inner",
		intro: (
			<div className="command-builder-tooltip-inner">
				<h2>Connect Blocks</h2>

				<p>Connect Blocks of the same color.</p>
				<img src={process.env.PUBLIC_URL + `/images/gifs/command_connect.gif`}></img>
			</div>
		),
		position: "left",
		tooltipClass: "command-builder-tooltip",
		highlightClass: "myHighlightClass"
	},

	{
		element: "#command-builder-properties",
		intro: (
			<div className="command-builder-tooltip-inner">
				<h2>Fill in the Options</h2>

				<p>Click an Option, Action or Condition and fill in its required Details.</p>
				<img src={process.env.PUBLIC_URL + `/images/gifs/command_options.gif`}></img>
			</div>
		),
		position: "left",
		tooltipClass: "command-builder-tooltip",
		highlightClass: "myHighlightClass"
	},

	{
		element: "#command-builder-save",
		intro: (
			<div className="command-builder-tooltip-inner">
				<h2>Save your command</h2>

				<p>Save your command for your changes to take effect.</p>
				<img src={process.env.PUBLIC_URL + `/images/gifs/command_save.gif`}></img>
			</div>
		),
		position: "left",
		tooltipClass: "command-builder-tooltip",
		highlightClass: "myHighlightClass"
	}

	// {
	//   element: '.selector2',
	//   intro: 'test 2',
	// },
	// {
	//   element: '.selector3',
	//   intro: 'test 3',
	// },
];

export class CommandBuilder extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selected: "sd",
			open: true,
			saveRequired: false,
			stepsEnabled: false,
			elements: [
				{
					id: "root",
					type: "root",
					data: {
						title: "Number",
						description: "text"
					},
					draggable: false,
					position: { x: 250, y: 250 }
				}
			],
			mobileAsideOpen: false,
			mobilePropertiesOpen: false
		};
	}

	componentDidMount() {
		ReactGA.initialize("UA-122665050-1");
		// console.log(window.location.pathname)
		ReactGA.pageview(window.location.pathname);
		if (this.props.elements.length == 0) {
			history.push("/dashboard/customcommands");
		}
		// console.log("COMMAND BUILDER MOUNTED");
		const cookies = new Cookies();
		// console.lo
		if (cookies.get("commandBuilder") == null) {
			cookies.set("commandBuilder", "true");
			this.setState({ stepsEnabled: true });
		}
		this.loadBotData();
	}

	loadBotData = async () => {
		// console.log("LOADING BOT DATA");
		const response = await server
			.post("/dashboard/botData", {
				id: this.props.bot.id,
				token: this.props.bot.token
			})
			.catch((e) => { });

		if (response.data && response.data.success == true) {
			const bot_data = response.data.bot_data;
			this.props.setBotData(bot_data);
		}
	};

	componentWillUnmount() {
		// console.log("COMMAND BUILDER UNMOUNTED");
		this.props.resetBuilder();
	}

	componentDidUpdate(prevProps, prevState) {
		console.log(this.props.builder_settings?.mobileBuilder, this.props.selected, !this.state.mobilePropertiesOpen, 'TEST');
		this.props.elements.forEach((element, index) => {
			if (isNode(element) && element.type != "root" && (element.type == "action" || element.type == "condition")) {
				var edges = splitElements(this.props.elements).edges;
				var incomers = getIncomers(element, splitElements(this.props.elements).nodes, edges);
				if (element.data && element.data.data != undefined) {
					var data = { ...element.data.data };
					if (incomers.length === 0 && (element.data.data.linked == undefined || element.data.data.linked == true)) {
						data.linked = false;

						this.props.updateElementData({
							data: data,
							id: element.id
						});
					} else if (incomers.length >= 1 && (element.data.data.linked == undefined || element.data.data.linked == false)) {
						data.linked = true;

						this.props.updateElementData({
							data: data,
							id: element.id
						});
					}
				}
			}
		});
	}

	handleMobileAsideClose = () => {
		this.setState({ mobileAsideOpen: false });
	};

	handlePropertiesVisibility = (isVisible) => {
		this.setState({ mobilePropertiesOpen: isVisible });
	};

	deleteBlock = () => {
		var element = this.props.elements.find((element) => element.id == this.props.selected);
		if (this.props.selected != null && ((element && element.data.data.type == "select_menu") || element.data.data.type == "loop" || this.props.selected.includes("condition") || (element && element.data.data.type == "button_response") || (element && element.data.data.type == "advanced_message" && element.data.data.messageType == "advanced"))) {
			var edges = splitElements(this.props.elements).edges;
			var elementChildren = getOutgoers(element, splitElements(this.props.elements).nodes, edges);
			if (element && element.data.data.type == "advanced_message") {
				elementChildren = elementChildren.filter((child) => child?.type == "advMessageSelectMenu" || child?.type == "advMessageButton");
			}
			var elementsDel = [element];
			elementsDel = elementsDel.concat(elementChildren);
			var connectedEdges = getConnectedEdges(elementsDel, splitElements(this.props.elements).edges);

			var elementsDel = elementsDel.concat(connectedEdges);

			var elements = [...this.props.elements];
			var future_redo = [];
			elementsDel.forEach((eleDel) => {
				future_redo.push(eleDel);
				var index = elements.findIndex((element) => element.id == eleDel.id);
				elements.splice(index, 1);
			});

			if (future_redo.length > 0) {
				var future = [...this.props.future];
				future.push(future_redo);
				this.props.setFuture(future);
			}

			this.props.setElements(elements);
			this.props.setSelected(null);
		} else {
			var index = this.props.elements.findIndex((element) => element.id == this.props.selected);
			var element = this.props.elements.find((element) => element.id == this.props.selected);
			var elementsDel = [element];
			var connectedEdges = getConnectedEdges(elementsDel, splitElements(this.props.elements).edges);
			var elementsDel = elementsDel.concat(connectedEdges);
			var elements = [...this.props.elements];
			var future_redo = [];
			elementsDel.forEach((eleDel) => {
				future_redo.push(eleDel);
				var index = elements.findIndex((element) => element.id == eleDel.id);
				elements.splice(index, 1);
			});

			if (future_redo.length > 0) {
				var future = [...this.props.future];
				future.push(future_redo);
				this.props.setFuture(future);
			}

			this.props.setElements(elements);
			this.props.setSelected(null);
		}
	};

	render() {
		if (this.props.elements.length > 0) {
			const element = this.props.elements.find((element) => element.id == this.props.selected);
			const canDelete = this.props.selected &&
				this.props.selected !== "root" &&
				this.props.selected !== "error_handler" &&
				!this.props.selected.includes("else");

			return (
				<div className="command-builder h-full" style={{ height: "100vh" }}>
					<Helmet>
						{this.props.builder_settings?.mobileBuilder !== true ?
							<meta name="viewport" content="initial-scale=0" />
							: null
						}
					</Helmet>
					<ReactFlowProvider>
						<CommandBuilderNav
							saveRequired={this.state.saveRequired}
							triggerSave={async () => {
								if (this.state.saveRequired) {
									await this.saveCommand();
									this.setState({ saveRequired: false });
								}
							}}
						/>

						{/* Mobile Buttons Container - Always visible */}
						{this.props.builder_settings?.mobileBuilder && (
							<div className="md:hidden fixed right-4 bottom-4 flex flex-col gap-3 items-end z-[80]">
								{/* Settings Button - Show when block is selected */}
								{this.props.selected && (
									<button
										onClick={() => this.handlePropertiesVisibility(true)}
										className={`
											w-12 h-12 
											flex items-center justify-center rounded-full 
											bg-[#EF4444] text-white shadow-lg
											hover:bg-[#DC2626] transition-colors
										`}
									>
										<FontAwesomeIcon icon={faCog} className="w-5 h-5" />
									</button>
								)}

								{/* Bottom row with delete and toggle buttons */}
								<div className="flex gap-3">
									{/* Delete Button - Show when block is selected and can be deleted */}
									{canDelete && (
										<button
											onClick={this.deleteBlock}
											className={`
												w-12 h-12 
												flex items-center justify-center rounded-full 
												bg-[#EF4444] text-white shadow-lg
												hover:bg-[#DC2626] transition-colors
											`}
										>
											<FontAwesomeIcon icon={faTrash} className="w-5 h-5" />
										</button>
									)}

									{/* Toggle Button */}
									<button
										onClick={() => this.setState(prev => ({ mobileAsideOpen: !prev.mobileAsideOpen }))}
										className={`
											w-12 h-12 
											flex items-center justify-center rounded-full 
											bg-[#5865f2] text-white shadow-lg
											hover:bg-[#4752c4] transition-colors
										`}
									>
										<FontAwesomeIcon
											icon={this.state.mobileAsideOpen ? faTimes : faBars}
											className="w-5 h-5"
										/>
									</button>
								</div>
							</div>
						)}

						<CommandBuilderAside
							mobileOpen={this.state.mobileAsideOpen}
							onMobileClose={this.handleMobileAsideClose}
						/>
						<CommandBuilderCanvas
							triggerSave={() => {
								this.setState({ saveRequired: true });
							}}
							onNodeSelect={(node) => {
								// When a node is selected, show properties
								if (node) {
									this.handlePropertiesVisibility(true);
								}
							}}
						/>

						{/* Always render CommandBuilderProperties */}
						<CommandBuilderProperties
							mobileOpen={this.state.mobilePropertiesOpen}
							onClose={() => this.handlePropertiesVisibility(false)}
						/>
					</ReactFlowProvider>
				</div>
			);
		}
		return null;
	}
}

const mapStateToProps = (state) => ({
	elements: state.builder.elements,
	builder_settings: state.data.user.builder_settings,
	selected: state.builder.selected,
	bot: state.data.bot,
	future: state.builder.future
});

const mapDispatchToProps = {
	updateElementData,
	resetBuilder,
	setBotData,
	setElements,
	setSelected,
	setFuture
};

export default connect(mapStateToProps, mapDispatchToProps)(CommandBuilder);
