import React from 'react';
import { connect } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import history from "../../../history";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

function BuilderExitModal({ open, closeModal, mode }) {
    const handleExit = () => {
        history.push(`/dashboard/${mode == "event" ? "customevents" : "customcommands"}`);
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-[10000]"
                onClose={closeModal}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-[#0000008a] transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-[#141414] text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div className="p-6">
                                    <div className="flex flex-col items-center text-center">
                                        <div className="flex animate-pulse h-16 w-16 items-center justify-center rounded-full bg-[#EF4444]/10 mb-6">
                                            <FontAwesomeIcon
                                                icon={faExclamationTriangle}
                                                className="h-8 w-8 text-[#EF4444]"
                                            />
                                        </div>

                                        <Dialog.Title
                                            as="h3"
                                            className="text-2xl font-bold text-[#FFFFFF] mb-4"
                                        >
                                            Exit {mode === "event" ? "Event" : "Command"} Builder
                                        </Dialog.Title>

                                        <div className="mb-8">
                                            <p className="text-[#9CA3AF]">
                                                Are you sure you want to leave the {mode === "event" ? "event" : "command"} builder?
                                                <br />You will lose any unsaved changes.
                                            </p>
                                        </div>

                                        <div className="flex gap-3">
                                            <button
                                                type="button"
                                                className="px-4 py-2 bg-[#2A2A2A] text-[#FFFFFF] hover:bg-[#374151] rounded-md transition-colors font-medium"
                                                onClick={closeModal}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="button"
                                                className="px-4 py-2 bg-[#EF4444] text-[#FFFFFF] hover:bg-[#DC2626] rounded-md transition-colors font-medium shadow-lg shadow-[#EF4444]/40"
                                                onClick={handleExit}
                                            >
                                                Leave Builder
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

const mapStateToProps = (state) => ({
    mode: state.builder.mode,
});

export default connect(mapStateToProps)(BuilderExitModal);
