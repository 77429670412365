import React from 'react';
import { connect } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { CUSTOM_EVENTS, CUSTOM_EVENT_CATEGORIES } from '../../../variables';
import { updateElementData, setBuilderMode, setSelected, setWebhookId } from '../../../actions';

function CustomEventSelector({ open = false, closeModal, data, updateElementData, setBuilderMode, setSelected, beta, webhooks, timed_events }) {
    const updateData = (key, value) => {
        const updatedData = { ...data };
        updatedData[key] = value;
        updateElementData({
            data: updatedData,
            id: 'root'
        });
    };

    const handleEventChange = (e) => {
        const value = e.target.value;
        console.log(value);

        if (value.startsWith("WEBHOOK_")) {
            const updatedData = { ...data };
            updatedData.type = "webhookExecuted";
            updatedData.webhookId = value.replace("WEBHOOK_", "");
            updateElementData({
                data: updatedData,
                id: 'root'
            });

            setBuilderMode({
                mode: "event",
                components: {
                    Guild: true,
                    Channel: false,
                    User: false,
                    Member: false,
                    Message: false,
                    variables: []
                },
            });
            setSelected('root');
            return;
        }

        if (value.startsWith("TIMED_EVENT_")) {
            const updatedData = { ...data };
            updatedData.type = "timedEvent";
            updatedData.timer_id = value.replace("TIMED_EVENT_", "");
            updatedData.guild_id = "{DEFAULT_SERVER}";
            updateElementData({
                data: updatedData,
                id: 'root'
            });

            setBuilderMode({
                mode: "event",
                components: {
                    Guild: true,
                    Channel: false,
                    User: false,
                    Member: false,
                    Message: false,
                    variables: []
                },
            });
            setSelected('root');
            return;
        }

        updateData('type', value);
        const event_options = CUSTOM_EVENTS.find(event => event.value === value);

        setBuilderMode({
            mode: "event",
            components: event_options.components
        });
        setSelected('root');
    };

    const renderEventOptions = () => {
        const eventCategories = [...CUSTOM_EVENT_CATEGORIES].map(cat => ({
            ...cat,
            children: []
        }));

        CUSTOM_EVENTS.forEach(event => {
            if ((event.beta && !beta) || event.hidden) return;
            const eventCategory = eventCategories.find(category => category.value === event.event_category);
            if (eventCategory) {
                eventCategory.children.push(
                    <option key={event.value} value={event.value} className="bg-[#2A2A2A] text-[#FFFFFF]">
                        {event.label}
                    </option>
                );
            }
        });

        if (webhooks?.webhooks?.length > 0) {
            eventCategories.push({
                label: "Webhooks",
                children: webhooks.webhooks.map(webhook => (
                    <option key={webhook.id} value={`WEBHOOK_${webhook.id}`} className="bg-[#2A2A2A] text-[#FFFFFF]">
                        {webhook.name} Webhook
                    </option>
                ))
            });
        }

        if (timed_events?.events?.length > 0) {
            eventCategories.push({
                label: "Timed Events",
                children: timed_events.events.map(timedEvent => (
                    <option key={timedEvent.id} value={`TIMED_EVENT_${timedEvent.id}`} className="bg-[#2A2A2A] text-[#FFFFFF]">
                        {timedEvent.name} Timed Event
                    </option>
                ))
            });
        }

        return (
            <select
                value={data.type || ""}
                onChange={handleEventChange}
                className="w-full px-3 py-2 bg-[#2A2A2A] border border-[#374151] rounded-md text-[#FFFFFF] focus:outline-none focus:ring-2 focus:ring-[#5865f2] focus:border-transparent transition-colors"
            >
                <option value="" className="bg-[#2A2A2A] text-[#FFFFFF]"></option>
                {eventCategories.map((category, index) => (
                    category.children.length > 0 && (
                        <optgroup
                            key={index}
                            label={category.label}
                            className="font-bold bg-[#2A2A2A] text-[#FFFFFF]"
                        >
                            {category.children}
                        </optgroup>
                    )
                ))}
            </select>
        );
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-[10000]"
                onClose={closeModal}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-[#0000008a] transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-[#141414] text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                                <div className="p-6">
                                    <div className="flex flex-col">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-2xl font-bold text-[#FFFFFF] mb-4"
                                        >
                                            Select a Custom Event
                                        </Dialog.Title>
                                        <p className="text-[#9CA3AF] mb-6 text-center">
                                            Select a custom event to run actions and conditions when triggered.
                                            You can change this event at anytime by clicking on the root block in the center of the builder.
                                        </p>

                                        <div className="mb-6">
                                            {renderEventOptions()}
                                        </div>

                                        <div className="flex justify-end">

                                        </div>
                                    </div>
                                </div>


                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

const mapStateToProps = (state) => ({
    beta: state.data.beta,
    webhooks: state.data.bot.commands.webhooks,
    timed_events: state.data.bot.commands.timed_events,
    user: state.data.user
});

const mapDispatchToProps = {
    updateElementData,
    setBuilderMode,
    setSelected,
    setWebhookId
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomEventSelector);
