import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import deepcopy from 'deepcopy';
import { Dialog, Transition } from "@headlessui/react";

import history from "../../../history";
import "../styles/requestbuilder.css";
import Toggle from './Toggle';
import ReactJSON from 'react-json-view';
import axios from 'axios';
import _ from "lodash";
import DocsLink from './DocsLink';
import update from "immutability-helper";
import ToolInput from './ToolInput';
import ToolTextArea from './ToolTextArea';
import DiscordEmbed from './DiscordEmbed';
import DiscordEmbedPreview from './DiscordEmbedPreview';


export class MessageBuilder extends Component {

    constructor(props) {
        super(props);

        this.state = {
            messageValidated: false
        };
    }


    modalBackgroundClick = (e) => {

        if (e.currentTarget == e.target) {
            this.props.closeModal(e);
        }
    };

    renderEmbeds = () => {
        var embeds = [];


        this.props.message.embeds.forEach((embed, index) => {
            embeds.push(
                <DiscordEmbed

                    index={index} open={this.state.openEmbed === index} copyEmbed={() => {
                        if (this.state.embeds.length >= 10) return;
                        var embedCopy = deepcopy(embed);
                        var embeds = [...this.props.message.embeds];
                        embeds.push(embedCopy);
                        var message = deepcopy(this.props.message);
                        message.embeds = embeds;
                        this.props.updateMessage(message);
                    }} onChange={(updatedEmbed) => {
                        var embeds = [...this.props.message.embeds];
                        embeds[index] = updatedEmbed;
                        var message = deepcopy(this.props.message);
                        message.embeds = embeds;
                        this.props.updateMessage(message);
                    }} openClose={() => {
                        if (this.state.openEmbed === index) {
                            this.setState({ openEmbed: null });
                        } else {
                            this.setState({ openEmbed: index });
                        }
                    }} delete={() => {
                        var embeds = [...this.props.message.embeds];

                        embeds.splice(index, 1);

                        var message = deepcopy(this.props.message);
                        message.embeds = embeds;
                        console.log(message, 'AFTER');
                        this.props.updateMessage(message);
                        if (!this.props.embedValidated && this.props.embedErrorIndex === index) {
                            this.props.updateEmbedValidation(true, index);
                        }
                    }} embed={embed} key={index}></DiscordEmbed>
            );
        });

        return embeds;
    };

    renderContent() {
        return (
            <div className="w-full max-w-[1400px] mx-auto px-[16px] sm:px-0">
                <div className="mb-8">
                    <h2 className="text-2xl font-bold text-[#FFFFFF]">
                        Message Builder
                    </h2>
                    <p className="text-[#9CA3AF] text-sm mt-1">Craft powerful Discord messages with our intuitive Message Builder.</p>
                </div>

                <div className="absolute top-[16px] right-[16px] z-[9999]">
                    <button
                        type="button"
                        className="rounded-full bg-[#EF4444] p-[6px] text-[#FFFFFF] hover:bg-[#DC2626] transition-colors shadow-lg"
                        onClick={this.props.closeModal}
                    >
                        <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>

                <div className="space-y-6">
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
                        <section className='space-y-4'>
                            {/* Content */}
                            <div>
                                <div className="w-full">
                                    <ToolTextArea
                                        value={this.props.message.content}
                                        maxLength={2000}
                                        label="Message Content"
                                        onChange={(value) => {
                                            var message = deepcopy(this.props.message);
                                            message.content = value;
                                            this.props.updateMessage(message);
                                        }}
                                    />
                                </div>
                            </div>

                            <div className='border-t border-[#374151] my-4'></div>

                            {/* Embeds */}
                            <div>
                                <label className="block text-[#FFFFFF] text-md font-bold mb-2">
                                    Embeds <span className='text-[#9CA3AF]'>{this.props.message.embeds.length}/10</span>
                                </label>

                                <div>
                                    {this.renderEmbeds()}
                                </div>

                                <div className='mt-3 flex flex-col sm:flex-row gap-2'>
                                    <button
                                        className='bg-[#EF4444] text-[#FFFFFF] px-4 py-2 rounded-md hover:bg-[#DC2626] transition-colors font-medium shadow-lg w-full sm:w-auto'
                                        onClick={() => {
                                            if (this.props.message.embeds.length >= 10) return;
                                            var newEmbed = {
                                                title: "",
                                                description: "",
                                                author: {
                                                    name: "",
                                                    url: "",
                                                    icon_url: ""
                                                },
                                                color: "",
                                                footer: "",
                                                footerIcon: "",
                                                timestamp: "",
                                                image: {
                                                    url: ""
                                                },
                                                thumbnail: {
                                                    url: ""
                                                },
                                                fields: [],
                                                timestamp_variable: ""
                                            };

                                            if (this.props.builder_settings?.defaultEmbedColor) {
                                                newEmbed.color = this.props.builder_settings?.defaultEmbedColor;
                                            }

                                            var embeds = this.props.message.embeds;
                                            embeds.push(newEmbed);
                                            this.setState({ embeds: embeds });
                                            var message = deepcopy(this.props.message);
                                            message.embeds = embeds;
                                            this.props.updateMessage(message);
                                        }}>Add Embed</button>
                                    <button
                                        className='bg-[#2A2A2A] text-[#FFFFFF] px-4 py-2 rounded-md hover:bg-[#374151] transition-colors font-medium shadow-lg w-full sm:w-auto'
                                        onClick={() => {
                                            this.setState({ embeds: [] });
                                            var embeds = [];
                                            var message = deepcopy(this.props.message);
                                            message.embeds = embeds;
                                            this.props.updateMessage(message);
                                        }}>Clear Embeds</button>
                                </div>
                            </div>
                        </section>

                        <section className='space-y-4'>
                            <DiscordEmbedPreview
                                content={this.props.message.content}
                                username={this.props.bot.name}
                                avatarURL={this.props.bot.img}
                                embeds={this.props.message.embeds}
                            />
                        </section>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        // If being used within AdvancedReply, just render the content
        if (this.props.isChild) {
            return (
                <div className="h-full sm:h-auto p-0 sm:p-[16px] pt-[48px] sm:p-[24px]">
                    {this.renderContent()}
                </div>
            );
        }

        // Otherwise render with the modal wrapper
        return (
            <Transition.Root show={true} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-[9999] overflow-y-auto"
                    onClose={this.props.closeModal}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-[#0000008a] transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center sm:p-[16px] text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="w-screen lg:max-w-[1500px] min-h-screen sm:min-h-[90vh] relative transform bg-[#141414] sm:rounded-lg text-left shadow-xl transition-all sm:my-8">
                                    <div className="h-full sm:h-auto p-0 sm:p-[24px]">
                                        {this.renderContent()}
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        );
    }
}

const mapStateToProps = (state) => ({
    bot: state.data.bot,
    builder_settings: state.data.user.builder_settings
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MessageBuilder);