import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import server from '../../../api/server';

import history from "../../../history";

function CommandImporter({ open = false, closeModal, mode, importCommand }) {
    const [code, setCode] = useState("");
    const [error, setError] = useState("");
    const [disabled, setDisabled] = useState(false);

    const handleImport = async () => {
        setDisabled(true);
        setError("");
        const response = await server.post("/dashboard/importcommand", { id: code });

        if (response.data === false || response.data.command === undefined) {
            setDisabled(false);
            setError("Could not find a command with that share code.");
        } else {
            if (response.data.type && response.data.type !== mode) {
                setDisabled(false);
                setError(`This is not a ${mode} share code.`);
            } else {
                importCommand(response.data.command, response.data.type);
            }
        }
    };

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-[10000]"
                onClose={closeModal}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-[#0000008a] transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-[#141414] text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div className="p-6">
                                    <div className="flex flex-col">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-2xl font-bold text-[#FFFFFF] mb-4"
                                        >
                                            Import {mode === "event" ? "an Event" : "a Command"}
                                        </Dialog.Title>
                                        <p className="text-[#9CA3AF] mb-6">
                                            Import {mode === "event" ? "an event" : "a command"} using a {mode === "event" ? "event" : "command"} share code.
                                        </p>

                                        {error && (
                                            <div className="mb-4 px-4 py-2 bg-[#EF4444]/10 text-[#EF4444] rounded-md text-sm">
                                                {error}
                                            </div>
                                        )}

                                        <div className="mb-6">
                                            <label className="block text-sm font-medium text-[#FFFFFF] mb-2">
                                                Share Code
                                            </label>
                                            <input
                                                type="text"
                                                value={code}
                                                onChange={(e) => setCode(e.target.value.trim())}
                                                placeholder={`${mode === "event" ? "EVENT" : "CMD"}_IASDAD-2399ASDAS-293293ASD-ASD`}
                                                className="w-full px-3 py-2 bg-[#2A2A2A] border border-[#374151] rounded-md text-[#FFFFFF] placeholder-[#6B7280] focus:outline-none focus:ring-2 focus:ring-[#5865f2] focus:border-transparent transition-colors"
                                            />
                                        </div>

                                        <div className="flex justify-end gap-3">
                                            <button
                                                type="button"
                                                onClick={closeModal}
                                                className="px-4 py-2 bg-[#2A2A2A] text-[#FFFFFF] hover:bg-[#374151] rounded-md transition-colors font-medium"
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="button"
                                                onClick={handleImport}
                                                disabled={code === "" || disabled}
                                                className={`px-4 py-2 ${code === "" || disabled ? 'bg-[#EF4444]/50 cursor-not-allowed' : 'bg-[#EF4444] hover:bg-[#DC2626] shadow-lg shadow-[#EF4444]/40'} text-[#FFFFFF] rounded-md transition-colors font-medium`}
                                            >
                                                Import
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {/* Close button */}
                                <button
                                    type="button"
                                    className="absolute top-4 right-4 p-2 text-[#9CA3AF] hover:text-[#FFFFFF] rounded transition-colors"
                                    onClick={closeModal}
                                >
                                    <span className="sr-only">Close</span>
                                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

const mapStateToProps = (state) => ({
    mode: state.builder.mode
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(CommandImporter);
