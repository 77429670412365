import React, { Component, useEffect } from "react";
import { connect } from "react-redux";

import ReactFlow, {
	Background,
	ReactFlowProvider,
	addEdge,
	// removeElements,
	applyNodeChanges,
	Handle,
	isEdge,
	ControlButton,
	isNode,
	// useZoomPanHelper,
	useReactFlow,
	getBezierPath,
	getMarkerEnd,
	getEdgeCenter,
	Controls,
	getIncomers,
	setEdges,
	getOutgoers,
	getConnectedEdges
} from "react-flow-renderer";
import dagre from "dagre";

import ReactGA from "react-ga";
import { isBrowser, isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCopy, faExclamation, faGripVertical, faHammer, faHandPointer, faLink, faPlusCircle, faQuestion, faRandom, faSave, faSlash, faSort, faTimes, faTrash, faTrashAlt, faChevronDown, faChevronRight, faStickyNote } from "@fortawesome/free-solid-svg-icons";


import { faCode, faCodeBranch, faEnvelope, faEye, faHashtag, faMouse, faMousePointer, faPaperPlane, faReply, faReplyAll, faSortNumericDown, faTextHeight, faUser, faUserMinus, faUserPlus, faUsers } from "@fortawesome/free-solid-svg-icons";
import toast, { Toaster } from "react-hot-toast";

import { setElements, setSelected, updateNode, updateElementData } from "../../../actions";
import ZoomPanHelper from "./ZoomPanHelper";
import splitElements from "./splitElements";
import { checkAdvancedMessageChild, checkErrorChild, checkErrorSuccessHandles, checkSplitChild, layoutElements } from "./eventUtils";
import { CUSTOM_EVENTS } from "../../../variables";
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import KeyEventsHandler from "./KeyEventsHandler";
import { getHandleSize, getHandlePosition, getHandleStyles } from './HandleSizes';
import { OptionNode } from './nodes/OptionNode';
import DefaultHandle from "./nodes/DefaultHandle";
import { NoteNode } from './nodes/NoteNode';
import { ErrorNode } from './nodes/ErrorNode';
import { LoopChildNode } from './nodes/LoopChildNode';
import { ButtonNode } from './nodes/ButtonNode';
import { SelectMenuOptionNode } from './nodes/SelectMenuOptionNode';
import { RootNode } from './nodes/RootNode';
import { AdvancedMessageButton } from './nodes/AdvancedMessageButton';
import { AdvancedMessageSelectMenu } from './nodes/AdvancedMessageSelectMenu';
import { ConditionNode } from './nodes/ConditionNode';
import { ConditionChildNode } from './nodes/ConditionChildNode';
import { ActionNode } from './nodes/ActionNode';

const ICONS = [faCode, faCodeBranch, faEnvelope, faEye, faHashtag, faMouse, faMousePointer, faPaperPlane, faReply, faReplyAll, faSortNumericDown, faTextHeight, faUser, faUserMinus, faUserPlus, faUsers];

function withMyHook(Component) {
	return function WrappedComponent(props) {
		const { zoomTo, setCenter } = useReactFlow();
		return <Component {...props} zoomTo={zoomTo} setCenter={setCenter} />;
	};
}

export class CommandBuilderCanvas extends Component {
	constructor(props) {
		super(props);

		this.state = {
			reactFlowInstance: null,
			showSaveTemplateModal: false,
			selected: null,
			handleId: null,
			nodeHandleID: null,
			selectedElements: {
				nodes: [],
				edges: []
			},
			handleType: null,
			elements: [
				{
					id: "root",
					type: "root",
					data: {
						title: "Number",
						description: "text"
					},
					// target:"3",
					draggable: false,
					position: { x: 250, y: 250 }
				}
			],
			collapsedNodes: new Set()
		};

		this.reactFlowWrapper = React.createRef();
		this.setCenter = useReactFlow;
	}

	componentDidMount() {
		document.addEventListener("keydown", this.onKeyPress, false);
	}
	componentWillUnmount() {
		document.removeEventListener("keydown", this.onKeyPress, false);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.elements.length != this.props.elements.length) {
			var elements = [...this.props.elements];
			elements.forEach((element) => {
				if (element.data?.data?.type == "advanced_message") {
					var children = getOutgoers(element, splitElements(elements).nodes, splitElements(elements).edges);
					var components_length = 0;
					children.forEach((child) => {
						if (child.type == "advMessageSelectMenu" || child.type == "advMessageButton") {
							components_length++;
						}
					});

					if (components_length == 0 && element.data?.data?.messageType == "advanced") {
						var data = { ...element.data.data };
						data.messageType = "basic";
						this.props.updateElementData({
							data: data,
							id: element.id
						});
					} else if (components_length > 0 && element.data?.data?.messageType == "basic") {
						var data = { ...element.data.data };
						data.messageType = "advanced";
						console.log(data, "DATA TYPE HERE");
						this.props.updateElementData({
							data: data,
							id: element.id
						});
						// this.props.updateElementData(element.id, data);
					}

					// children.forEach(child => {
					//   if (child.type == 'advMessageButton' || child.type == 'advMessageSelectMenu') {
					//     state = 2;
					//   }
				}
			});
			// var element = elements.find(element => element.id == id);
			// var children = getOutgoers(element, splitElements(elements).nodes, splitElements(elements).edges);
			// // Check if any of the children are buttons or select menus
			// children.forEach(child => {
			//   if (child.type == 'advMessageButton' || child.type == 'advMessageSelectMenu') {
			//     state = 2;
			//   }
			// });
			//
		}
	}

	deleteMulti = () => {
		var nodes = this.state.selectedElements.nodes;
		var edges = splitElements(this.props.elements).edges;
		nodes.forEach((node) => {
			var element = this.props.elements.find((element) => element.id == node.id);
			if (!element || element.id == "root" || element.id.includes("else") || element.id == "error_handler") {
				return;
			}
			var elementsDel = [element];
			if (node.id.includes("condition")) {
				var elementChildren = getOutgoers(element, splitElements(this.props.elements).nodes, edges);
				// console.log(elementChildren, 'CONDITION CHILDREN'); elementsDel = elementsDel.concat(elementChildren);
			}

			var connectedEdges = getConnectedEdges(elementsDel, splitElements(this.props.elements).edges);

			// HERE
			elementsDel.forEach((eleDel) => {
				var elements = this.deleteElements(eleDel.id);
				this.props.setElements(elements);
			});

			connectedEdges.forEach((eleDel) => {
				var elements = this.deleteElements(eleDel.id);
				this.props.setElements(elements);
			});

			this.props.setSelected(null);
		});
	};

	CustomEdge = ({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, style = {}, data, arrowHeadType, markerEndId }) => {
		const edgePath = getBezierPath({
			sourceX,
			sourceY,
			sourcePosition,
			targetX,
			targetY,
			targetPosition
		});
		const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
		const [edgeCenterX, edgeCenterY] = getEdgeCenter({
			sourceX,
			sourceY,
			targetX,
			targetY
		});

		const foreignObjectSize = 40;
		const useMobile = this.props.builder_settings?.mobileBuilder && isMobile;
		var handleSize = "w-[20px] h-[20px]";
		if (useMobile) {
			handleSize = "w-[40px] h-[40px]";
		}
		return (
			<>
				<path id={id} style={style} className="pointer-events-none react-flow__edge-path" d={edgePath} markerEnd={markerEnd} />
				<foreignObject width={foreignObjectSize} height={foreignObjectSize} x={edgeCenterX - foreignObjectSize / 2} y={edgeCenterY - foreignObjectSize / 2} className="edgebutton-foreignobject" requiredExtensions="http://www.w3.org/1999/xhtml">
					<div className="edgebutton-container" onClick={(event) => this.onEdgeClick(event, id)}>
						<button className={`bg-[#fff] rounded-full ${handleSize} flex items-center justify-center ${this.props.module_id != null && !this.props.premium ? "cursor-not-allowed" : ""}`} onClick={(event) => this.onEdgeClick(event, id)} disabled={this.props.module_id != null && !this.props.premium}>
							<FontAwesomeIcon icon={faTimes} />
						</button>
					</div>
				</foreignObject>
			</>
		);
	};

	onEdgeClick = (event, id) => {
		var elements = this.deleteElements(id);
		setTimeout(() => {
			this.props.setElements(elements);
		}, 1);
		// Stop the event from propagating;
		event.preventDefault();
	};

	deleteElements = (id) => {
		var elements = [...this.props.elements];
		if (this.props.module_id != null && !this.props.premium) {
			return elements;
		}
		var index = this.props.elements.findIndex((element) => element.id == id);

		elements.splice(index, 1);

		//check if duplicated
		return elements;
	};









	renderHandleSizes = (action, type, id) => {
		var size = 15;
		if (isBrowser) {
			size = 15;
		} else {
			size = 25;
		}
		if (action == true && this.state.handleId == "action" && this.state.handleType != type && id != this.state.nodeHandleID) {
			if (isBrowser) {
				size = 30;
			} else {
				size = 50;
			}
		}

		return `${size}px`;
	};

	renderHandlePositions = (action, type, id) => {
		var size = -6;
		if (isBrowser) {
			size = -6;
		} else {
			size = -10;
		}
		if (action == true && this.state.handleId == "action" && this.state.handleType != type && id != this.state.nodeHandleID) {
			if (isBrowser) {
				size = -12;
			} else {
				size = -20;
			}
		}

		return size;
	};

	renderVariableConditionName = (id) => {
		var name = "";
		var element = this.props.elements.find((element) => element.id == id);
		if (!element) return name;
		var edges = splitElements(this.props.elements).edges;
		var parentCondition = getIncomers(element, splitElements(this.props.elements).nodes, edges);
		if (parentCondition[0] && parentCondition[0].data) {
			name = `{BGVAR_${parentCondition[0].data.data.reference}}`;
		}

		return name;
	};

	renderComparisonConditionBase = (id) => {
		var name = "";
		var element = this.props.elements.find((element) => element.id == id);
		if (!element) return name;
		var edges = splitElements(this.props.elements).edges;
		var parentCondition = getIncomers(element, splitElements(this.props.elements).nodes, edges);
		if (parentCondition && parentCondition[0] && parentCondition[0].data) {
			name = parentCondition[0].data.data.base_value;
		}

		return name;
	};

	renderConditionChildOptions = (id, data) => {
		if (data.node_options.type == "option_condition" || data.node_options.type == "option") {
			return (
				<div className="object-info object-info-condition" style={{ justifyContent: "center" }}>
					<span className="object-description">{data.data.option}</span>
					<span className="object-title">{data.data.type == "else" ? "Else" : "name" in data.data && data.data.name != "" ? data.data.name : data.data.value}</span>
				</div>
			);
		} else if (data.node_options.type == "chance") {
			return (
				<div className="object-info object-info-condition" style={{ justifyContent: "center" }}>
					<span className="object-description">{data.data.type == "else" ? "Else" : "Chance"}</span>
					<span className="object-title">{data.data.type == "else" ? "Else" : `${data.data.value}%`}</span>
				</div>
			);
		} else if (data.node_options.type == "channel" || data.node_options.type == "role") {
			return (
				<div className="object-info object-info-condition" style={{ justifyContent: "center" }}>
					<span className="object-description">{data.data.type == "else" ? "Else" : data.data.id}</span>
					<span className="object-title">{data.data.type == "else" ? "Else" : `${data.data.name}`}</span>
				</div>
			);
		} else if (data.node_options.type == "user") {
			return (
				<div className="object-info object-info-condition" style={{ justifyContent: "center" }}>
					<span className="object-description">User</span>
					<span className="object-title">{data.data.type == "else" ? "Else" : `${data.data.id}`}</span>
				</div>
			);
		} else if (data.node_options.type == "currency") {
			return (
				<div className="object-info object-info-condition" style={{ justifyContent: "center" }}>
					<span className="object-description">
						{" "}
						<span className="object-description">{data.data.type == "else" ? "Else" : this.renderChanceType(data.data.type)}</span>
					</span>
					<span className="object-title">{data.data.type == "else" ? "Else" : `$${data.data.amount}`}</span>
				</div>
			);
		} else if (data.node_options.type == "item") {
			return (
				<div className="object-info object-info-condition" style={{ justifyContent: "center" }}>
					<span className="object-description">
						{" "}
						<span className="object-description">{data.data.type == "else" ? "Else" : this.itemConditionChild(data.data.possesses, data.data.amount)}</span>
					</span>
					<span className="object-title">{data.data.type == "else" ? "Else" : `${data.data.item}`}</span>
				</div>
			);
		} else if (data.node_options.type == "custom_variable") {
			return (
				<div className="object-info object-info-condition" style={{ justifyContent: "center" }}>
					<span className="object-description">
						{" "}
						<span className="object-description">{data.data.type == "else" ? "Else" : `${this.renderVariableConditionName(id)} ${this.renderChanceType(data.data.type)}`}</span>
					</span>
					<span className="object-title">{data.data.type == "else" ? "Else" : `${data.data.value}`}</span>
				</div>
			);
		} else if (data.node_options.type == "comparison") {
			return (
				<div className="object-info object-info-condition" style={{ justifyContent: "center" }}>
					<span className="object-description">
						{" "}
						<span className="object-description">{data.data.type == "else" ? "Else" : `${this.renderComparisonConditionBase(id)} ${this.renderChanceType(data.data.type)}`}</span>
					</span>
					<span className="object-title">{data.data.type == "else" ? "Else" : `${data.data.comparison_value}`}</span>
				</div>
			);
		} else if (data.node_options.type == "permissions") {
			return (
				<div className="object-info object-info-condition" style={{ justifyContent: "center" }}>
					<span className="object-description">
						{" "}
						<span className="object-description">{data.data.type == "else" ? "Else" : `${data.data.type == "has_all" ? "Has all of these permissions" : "Has at least one of these permissions"}`}</span>
					</span>
					<span className="object-title">{data.data.type == "else" ? "Else" : `${data.data.permissions.join(", ")}`}</span>
				</div>
			);
		} else if (data.node_options.type == "premium") {
			return (
				<div className="object-info object-info-condition" style={{ justifyContent: "center" }}>
					<span className="object-description">
						{" "}
						<span className="object-description">{data.data.type == "else" ? "Else" : "Premium"}</span>
					</span>
					<span className="object-title">{data.data.type == "else" ? "Not Premium" : "Premium"}</span>
				</div>
			);
		}
	};

	ConditionChildNode = ({ id, data }) => (
		<>
			<Handle
				style={{
					backgroundColor: data.data.type == "else"
						? this.props.builder_settings?.elseColor || '#4f545c'
						: this.props.builder_settings?.conditionColor || '#28a745',
					border: "none",
					height: "10px",
					width: "10px"
				}}
				type="target"
				position="top"
				isValidConnection={(connection) => connection.target === "root"}
			/>
			<div
				id={id}
				onTouchStart={(e) => {
					this.props.setSelected(id);
				}}
				onClick={(e) => {
					this.props.setSelected(id);
				}}
				style={{
					border: this.props.selected == id
						? `2px solid ${this.props.builder_settings?.selectedColor || '#5865f2'}`
						: '2px solid transparent'
				}}
				className="action-node !py-[30px]"
			>
				<div className="inner" style={{ display: "flex" }}>
					<div
						className={`object-icon object-icon-${data.data.type == "else" ? "else" : "condition"}`}
						style={{
							backgroundColor: data.data.type == "else"
								? this.props.builder_settings?.elseColor || '#f45142'
								: this.props.builder_settings?.conditionColor || '#28a745'
						}}
					>
						<FontAwesomeIcon icon={faQuestion} />
					</div>

					{this.renderConditionChildOptions(id, data)}
				</div>
			</div>
			<Handle
				className={`
					${this.state.nodeHandleID == id && this.state.handleType == "source"
						? "!ring-[#5865f2]" // Selected state
						: ""
					}
					${getHandleStyles()}
					absolute cursor-pointer rounded-full transition-colors
				`}
				id="action"
				isValidConnection={this.actionConnection}
				style={{
					zIndex: 10,
					backgroundColor: this.props.builder_settings?.actionColor || '#358deb',
					border: "none",
					bottom: getHandlePosition(true, "source", id, this.state.nodeHandleID),
					height: getHandleSize(true, "source", id, this.state.nodeHandleID),
					width: getHandleSize(true, "source", id, this.state.nodeHandleID),
					transform: 'translate(-50%, 50%)' // Adjust transform for bottom positioning
				}}
				type="source"
				position="bottom"
			/>
		</>
	);

	renderBlockLabels = (data) => {
		if (data && data.data && "command_label" in data.data && data.data.command_label) {
			return (
				<>
					<span className="object-title">{data.data.command_label}</span>
					<span className="object-description">{data.node_options.title}</span>
				</>
			);
		} else {
			return (
				<>
					<span className="object-title">{data.node_options.title}</span>
					<span className="object-description">{data.node_options.description}</span>
				</>
			);
		}
	};






	nodeTypes = {
		option: (props) => (
			<OptionNode
				{...props}
				selected={this.props.selected}
				builder_settings={this.props.builder_settings}
				nodeHandleID={this.state.nodeHandleID}
				handleType={this.state.handleType}
				handleId={this.state.handleId}
				actionConnection={this.actionConnection}
				setSelected={this.props.setSelected}
			/>
		),
		root: (props) => (
			<RootNode
				{...props}
				selected={this.props.selected}
				builder_settings={this.props.builder_settings}
				nodeHandleID={this.state.nodeHandleID}
				handleType={this.state.handleType}
				handleId={this.state.handleId}
				actionConnection={this.actionConnection}
				setSelected={this.props.setSelected}
				mode={this.props.mode}
			/>
		),
		action: (props) => (
			<ActionNode
				{...props}
				selected={this.props.selected}
				builder_settings={this.props.builder_settings}
				nodeHandleID={this.state.nodeHandleID}
				handleType={this.state.handleType}
				handleId={this.state.handleId}
				actionConnection={this.actionConnection}
				setSelected={this.props.setSelected}
				addToMessage={this.addToMessage}
				elements={this.props.elements}
			/>
		),
		button: (props) => (
			<ButtonNode
				{...props}
				selected={this.props.selected}
				builder_settings={this.props.builder_settings}
				nodeHandleID={this.state.nodeHandleID}
				handleType={this.state.handleType}
				handleId={this.state.handleId}
				actionConnection={this.actionConnection}
				setSelected={this.props.setSelected}
			/>
		),
		condition: (props) => (
			<ConditionNode
				{...props}
				selected={this.props.selected}
				builder_settings={this.props.builder_settings}
				nodeHandleID={this.state.nodeHandleID}
				handleType={this.state.handleType}
				handleId={this.state.handleId}
				actionConnection={this.actionConnection}
				setSelected={this.props.setSelected}
				highlightAction={this.props.highlightAction}
			/>
		),
		conditionChild: (props) => (
			<ConditionChildNode
				{...props}
				selected={this.props.selected}
				builder_settings={this.props.builder_settings}
				nodeHandleID={this.state.nodeHandleID}
				handleType={this.state.handleType}
				handleId={this.state.handleId}
				actionConnection={this.actionConnection}
				setSelected={this.props.setSelected}
			/>
		),
		selectMenuOption: (props) => (
			<SelectMenuOptionNode
				{...props}
				selected={this.props.selected}
				builder_settings={this.props.builder_settings}
				nodeHandleID={this.state.nodeHandleID}
				handleType={this.state.handleType}
				handleId={this.state.handleId}
				actionConnection={this.actionConnection}
				setSelected={this.props.setSelected}
			/>
		),
		loopChild: (props) => (
			<LoopChildNode
				{...props}
				selected={this.props.selected}
				builder_settings={this.props.builder_settings}
				nodeHandleID={this.state.nodeHandleID}
				handleType={this.state.handleType}
				handleId={this.state.handleId}
				actionConnection={this.actionConnection}
				setSelected={this.props.setSelected}
				elements={this.props.elements}
			/>
		),
		error: (props) => (
			<ErrorNode
				{...props}
				selected={this.props.selected}
				builder_settings={this.props.builder_settings}
				nodeHandleID={this.state.nodeHandleID}
				handleType={this.state.handleType}
				handleId={this.state.handleId}
				actionConnection={this.actionConnection}
				setSelected={this.props.setSelected}
			/>
		),
		advMessageSelectMenu: (props) => (
			<AdvancedMessageSelectMenu
				{...props}
				selected={this.props.selected}
				builder_settings={this.props.builder_settings}
				nodeHandleID={this.state.nodeHandleID}
				handleType={this.state.handleType}
				handleId={this.state.handleId}
				actionConnection={this.actionConnection}
				setSelected={this.props.setSelected}
			/>
		),
		advMessageButton: (props) => (
			<AdvancedMessageButton
				{...props}
				selected={this.props.selected}
				builder_settings={this.props.builder_settings}
				nodeHandleID={this.state.nodeHandleID}
				handleType={this.state.handleType}
				handleId={this.state.handleId}
				actionConnection={this.actionConnection}
				setSelected={this.props.setSelected}
			/>
		),
		note: (props) => (
			<NoteNode
				{...props}
				selected={this.props.selected}
				builder_settings={this.props.builder_settings}
				nodeHandleID={this.state.nodeHandleID}
				handleType={this.state.handleType}
				renderHandlePositions={this.renderHandlePositions}
				renderHandleSizes={this.renderHandleSizes}
				actionConnection={this.actionConnection}
				setSelected={this.props.setSelected}
				handleId={this.state.handleId}
			/>
		)
	};

	edgeTypes = {
		custom: this.CustomEdge
	};

	actionConnection = (connection) => {
		if (connection.sourceHandle === connection.targetHandle) {
			return true;
		} else if ((connection.sourceHandle == "success_actions" || connection.sourceHandle == "error_actions") && connection.targetHandle == "action") {
			return true;
		} else {
			return false;
		}

	};

	onConnect = (params) => {
		if (params.source == params.target) {
			return false;
		}

		if (this.props.module_id != null && !this.props.premium) {
			return false;
		}

		// action_11_d8ad_c8a9
		var elements = [...this.props.elements];
		var check = true;

		elements.forEach((element) => {
			if (isEdge(element) && element.target) {
				checkAdvancedMessageChild(elements, params.source, params.target);
			}
			if (isEdge(element) && ((element.target == params.target && params.target != "root" && !checkSplitChild(this.props.elements, params.target)) || element.source == params.source || !checkErrorChild(this.props.elements, params.source, params.target))) {

				check = false;
			} else if (isEdge(element) && element.target == params.source && element.source == params.target) {

				check = false;
			}
		});

		var sourceElement = elements.find((element) => element.id == params.source);
		var targetElement = elements.find((element) => element.id == params.target);

		if (sourceElement.data?.data?.type == "advanced_message") {
			check = checkAdvancedMessageChild(elements, params.source, params.target);

		}
		// check = checkErrorSuccessHandles(elements, params.source, params.target);

		if (sourceElement.data?.data?.success_handles) {
			check = checkErrorSuccessHandles(elements, params.source, params.sourceHandle);

		}

		// Check if its a modal action
		if (targetElement.data.data.type == "modal") {
			var edges = splitElements(elements).edges;
			var targetParents = [sourceElement];
			var modalCheckPassed = true;
			while (targetParents.length > 0) {
				// If its root, button, or select menu break the while loop

				if ((targetParents[0].data.data.type == "root" && this.props.mode == "command") ||
					targetParents[0].type == "button" ||
					targetParents[0].type == "selectMenuOption" ||
					targetParents[0].type == "advMessageButton" ||
					targetParents[0].type == "advMessageSelectMenu") {
					break;
				}

				if (targetParents[0].type == "error") {
					modalCheckPassed = false;
					break;
				} else if (targetParents[0].type == "root" && this.props.mode == "event") {
					modalCheckPassed = false;
					break;
				}

				//
				if (!targetParents[0].data || !targetParents[0].data.data) {
					if (targetParents[0].type == "error") {
						modalCheckPassed = false;
					}
				} else if (targetParents[0].data.data.type == "plain_text" && targetParents[0].data.data.target.reply) {
					modalCheckPassed = false;
				} else if (targetParents[0].data.data.type == "embed" && targetParents[0].data.data.target.reply) {
					modalCheckPassed = false;
				} else if (targetParents[0].data.data.type == "random_response" && targetParents[0].data.data.target.reply) {
					modalCheckPassed = false;
				} else if (targetParents[0].data.data.type == "random_response" && targetParents[0].data.data.target.reply) {
					modalCheckPassed = false;
				} else if (targetParents[0].data.data && targetParents[0].data.data.type == "modal") {
					modalCheckPassed = false;
				}
				if (modalCheckPassed == false) {
					break;
				}
				targetParents = getIncomers(targetParents[0], splitElements(elements).nodes, edges);
			}

			// Check if its the child

			if (!modalCheckPassed) {
				toast.error("Forms must be the first response after an interaction. An interaction occurs after a command is used, a button is clicked or a select menu option is picked", {
					duration: 5000,
					style: {
						borderRadius: "10px",
						background: "#333",
						color: "#fff"
					}
				});
				return;
			}
		}

		// Check if the element is already a child of the target.
		// If it is, do not allow the connection

		var edges = splitElements(elements).edges;
		var parents = getIncomers(sourceElement, splitElements(elements).nodes, edges);
		var parentsArray = [];
		while (parents.length > 0) {
			parentsArray.push(parents[0]);
			parents = getIncomers(parents[0], splitElements(elements).nodes, edges);
		}
		parentsArray.forEach((parent) => {
			if (parent.id == params.target) {

				check = false;
			}
		});

		// console.log(checkSplitChild(this.props.elements, params.target), 'CHECK SPLIT CHILD');

		// Check if incoming is split from a button
		// Only the top handle can have multiple connections
		// Bottom can not

		if (check == true) {
			params.animated = false;
			params.arrowHeadType = "arrowclosed";
			// params.type = "step"
			params.type = "custom";
			var edges = splitElements(this.props.elements).edges;
			var newEdges = addEdge(params, edges);
			newEdges.forEach((newEdge) => {
				var index = elements.findIndex((element) => element.id == newEdge.id);
				if (index == -1) {
					elements.push(newEdge);
				}
				// elements[index] = newNode;
			});
			this.props.setElements(elements);
		}
	};

	onDragOver = (event) => {
		event.preventDefault();
		event.dataTransfer.dropEffect = "move";
	};

	onLoad = (reactFlowInstance) => {
		this.setState({ reactFlowInstance: reactFlowInstance });
		var elements = this.getLayoutedElements(this.props.elements);
		var root = elements.find((element) => element.id == "root");

		this.props.setElements(elements);
		var x = 0;
		var y = 0;
		var zoom = 0.4;
		var useMobileBuilder = this.props.builder_settings?.mobileBuilder && isMobile;
		if (root && useMobileBuilder) {
			x = root.position.x + 300;
			y = root.position.y + 600;
			zoom = 0.5;
		} else if (root) {
			x = root.position.x + 500;
			y = root.position.y + 100;
			zoom = 0.7;
		}

		if (elements.length == 1) {
			setTimeout(() => {
				reactFlowInstance.fitView({ padding: 2.5 });
				// reactFlowInstance.fitView({ padding: 10 });
				// this.props.zoomTo(1)
				// reactFlowInstance.setZoom(1)
			}, 0);
		} else {
			setTimeout(() => {
				reactFlowInstance.fitView({ padding: 2.5 });
				reactFlowInstance.setCenter(x, y, {
					zoom: zoom
				});
				// reactFlowInstance.fitView({ padding: 10 });
				// this.props.zoomTo(0.4);
				// reactFlowInstance.setZoom(1)
			}, 0);
		}
		// this.props.setCenter(250, 0, 0.8);
		// this.props.setZoom(1)
		// const { setCenter } = useZoomPanHelper();
		// setCenter(root.position.x,root.position.y,0.5)
	};

	onDrop = (event) => {
		try {
			event.preventDefault();
			const reactFlowBounds = this.reactFlowWrapper.current.getBoundingClientRect();

			const position = this.state.reactFlowInstance.project({
				x: event.clientX - reactFlowBounds.left,
				y: event.clientY - reactFlowBounds.top
			});
			var options = JSON.parse(event.dataTransfer.getData("options"));
			if (options?.type == "block_template") {
				var newElements = options?.elements;
				var elements = [...this.props.elements];
				// console.log(elements, options, "ELEMENTS HERE");
				var random_id = s4();
				newElements.forEach((element) => {
					if (isEdge(element)) {
						element.id = `${element.id}_${random_id}`;
						element.source = `${element.source}_${random_id}`;
						element.target = `${element.target}_${random_id}`;
					} else {
						element.id = `${element.id}_${random_id}`;
					}

					if (element.selected) {
						element.selected = false;
					}
				});

				newElements[0].position = position;

				var layouted_new_elements = layoutElements(newElements, position);

				// console.log(layouted_new_elements, "NEW ELEMENTS");

				elements = [...elements, ...layouted_new_elements];

				this.props.setElements(elements);

				// setTimeout(() => {
				// this.props.setSelected(layouted_new_elements[0].id);
				// }, 1000);
				return;
			}

			var options = JSON.parse(event.dataTransfer.getData("options"));
			var elements = [...this.props.elements];
			var length = elements.length + 1;

			var data = {
				node_options: {
					title: options.type,
					description: options.description,
					icon: options.icon
				},
				data: options.data
			};
			var id = `${options.category}_${length}_${s4()}_${s4()}`;
			const newNode = {
				id: id,
				type: options.category,
				data: data,
				position
			};

			ReactGA.initialize("UA-122665050-1");
			ReactGA.event({
				category: "Command Block",
				label: options.type,
				action: "New Dashboard"
			});

			if (options.category == "option") {
				var elements = addEdge(
					{
						source: id,
						target: "root",
						type: "step",
						animated: false,
						arrowHeadType: "arrowclosed"
					},
					elements
				);
			} else if (options.category == "condition") {
				var else_id = `$else_${length}_${s4()}_${s4()}`;
				elements.push({
					id: else_id,
					type: "conditionChild",
					data: {
						node_options: {
							type: options.condition_type
						},
						data: {
							type: "else",
							actions: []
						}
					},
					// target:"3",
					draggable: true,
					position: { x: position.x + 200, y: position.y + 150 }
				});

				elements = addEdge(
					{
						source: id,
						target: else_id,
						type: "step",
						animated: false,
						arrowHeadType: "arrowclosed"
					},
					elements
				);
			}

			elements.push(newNode);

			this.props.setSelected(newNode.id);
			this.props.setElements(elements);

			// setElements((es) => es.concat(newNode));
		} catch (e) { }
	};

	updatePosition = (e, node) => {
		this.props.updateNode(node);
	};

	sortElements = () => {
		var elements = this.getLayoutedElements(this.props.elements);
		this.props.setElements(elements);
	};

	addToMessage = (type, id) => {
		var elements = [...this.props.elements];
		var element = this.props.elements.find((element) => element.id == id);

		var children = getOutgoers(element, splitElements(elements).nodes, splitElements(elements).edges);
		console.log(element);
		if (element.data?.data?.messageType == "basic") {
			// Change to advanced;
			var data = { ...element.data.data };
			console.log(data, "DATA HERE ");
			data.messageType = "advanced";
			console.log(data, "DATA HERE 1");
			this.props.updateElementData({
				data: {
					...element.data.data,
					messageType: "advanced"
				},
				id: id
			});
		}

		var existingButtonCount = 0;
		var existingMenuCount = 0;
		var existingComponentsCount = 0;

		children.forEach((child) => {
			if (child.type === "advMessageButton") {
				existingButtonCount++;
			} else if (child.type === "advMessageSelectMenu") {
				existingMenuCount++;

			}
			existingComponentsCount++;
		});


		if (type == "button" && existingButtonCount >= 25) {
			toast.error("Maximum of 25 buttons reached.");
			return;
		}

		if (type == "menu" && (existingMenuCount >= 5)) {
			toast.error("Maximum of 5 select menus or 5 rows reached.");
			return;
		}

		if (type == "button") {
			var new_id = `adv_button${elements.length + 1}_${Math.random().toString(36).substr(2, 9)}`;
			var existingMenuOptions = [];
			if (existingMenuOptions.length >= 25) {
				return;
			}
			// var lastChild = existingMenuOptions[existingMenuOptions.length - 1];

			var width = 0;
			var position = { x: element.position.x, y: element.position.y + 100 };
			// if (lastChild) {
			//     var docuElement = document.getElementById(lastChild.id);
			//     width = docuElement.clientWidth;
			//     position = { x: lastChild.position.x + width + 20, y: lastChild.position.y };
			// }

			elements.push({
				id: new_id,
				type: "advMessageButton",
				data: {
					node_options: {},
					data: {
						type: "button",
						label: `Button ${existingButtonCount + 1}`,
						style: "PRIMARY",
						emoji_id: "",
						orderComponent: false,
						weight: "",
						row: "",
						validated: true
					}
				},
				// target:"3",
				draggable: true,
				position: { x: element.position.x - (existingComponentsCount == 0 ? 0 : existingComponentsCount * 100), y: element.position.y + 150 }
			});
			var newEdges = addEdge(
				{
					source: element.id,
					target: new_id,
					type: "step",
					sourceHandle: "components_source",
					animated: false,
					arrowHeadType: "arrowclosed"
				},
				splitElements(elements).edges
			);
			newEdges.forEach((newEdge) => {
				var index = elements.findIndex((element) => element.id == newEdge.id);
				if (index == -1) {
					elements.push(newEdge);
				}
				// elements[index] = newNode;
			});
			this.props.setElements(elements);
			this.props.setSelected(element.id);
		} else {
			var new_id = `adv_select_menu${elements.length + 1}_${Math.random().toString(36).substr(2, 9)}`;
			var existingMenuOptions = [];
			if (existingMenuOptions.length >= 25) {
				return;
			}
			// var lastChild = existingMenuOptions[existingMenuOptions.length - 1];

			var width = 0;
			var position = { x: element.position.x, y: element.position.y + 100 };
			// if (lastChild) {
			//     var docuElement = document.getElementById(lastChild.id);
			//     width = docuElement.clientWidth;
			//     position = { x: lastChild.position.x + width + 20, y: lastChild.position.y };
			// }

			elements.push({
				id: new_id,
				type: "advMessageSelectMenu",
				data: {
					node_options: {},
					data: {
						type: "adv_select_menu",
						menu_type: "basic",
						multiselect: false,
						minOptions: "1",
						maxOptions: "1",
						basic_options: [
							{
								label: `Option 1`,
								description: `Option 1`,
								emoji_id: "",
								value: ``
							}
						],
						type_options: {},
						options: [],
						validated: true,
						customVariableName: "",
					}
				},
				// target:"3",
				draggable: true,
				position: { x: element.position.x - (existingComponentsCount == 0 ? 0 : existingComponentsCount * 100), y: element.position.y + 150 }
			});
			var newEdges = addEdge(
				{
					source: element.id,
					target: new_id,
					type: "step",
					animated: false,
					sourceHandle: "components_source",
					arrowHeadType: "arrowclosed"
				},
				splitElements(elements).edges
			);
			newEdges.forEach((newEdge) => {
				var index = elements.findIndex((element) => element.id == newEdge.id);
				if (index == -1) {
					elements.push(newEdge);
				}
				// elements[index] = newNode;
			});
			this.props.setElements(elements);
			this.props.setSelected(element.id);
		}
	};


	getLayoutedElements = (ele, direction = "TB") => {
		const nodeWidth = 400;
		const nodeHeight = 90;
		const dagreGraph = new dagre.graphlib.Graph();
		dagreGraph.setDefaultEdgeLabel(() => ({}));
		dagreGraph.setGraph({ rankdir: direction });
		var elements = [...ele];
		var optionsCheck = elements.filter((element) => element.type == "option");
		if (optionsCheck.length > 0) {
			elements.push({
				source: "option_7_c44a_0728",
				target: "error_handler",
				type: "step",
				animated: true,
				arrowHeadType: "arrowclosed",
				id: "error_handler_placeholder"
			});
		}
		var verticalPadding = 50;
		const useMobile = this.props.builder_settings?.mobileBuilder && isMobile;
		if (useMobile) {
			verticalPadding = 150;
		}
		elements.forEach((el) => {
			if (isNode(el)) {
				var docuElement = document.getElementById(el.id);

				if (el.type == "selectMenuOption" || el.type == "button") {
					dagreGraph.setNode(el.id, { width: docuElement.clientWidth, height: 52 });
				} else if (el.type == "note") {
					// Get actual height for note blocks
					const height = docuElement ? docuElement.clientHeight : 79;
					dagreGraph.setNode(el.id, { width: docuElement.clientWidth, height: height + 40 }); // Add padding
				} else if (el.id != "root") {
					dagreGraph.setNode(el.id, { width: docuElement.clientWidth, height: 79 + verticalPadding });
				}
				else {
					dagreGraph.setNode(el.id, { width: 600, height: 121 + verticalPadding });
				}
			} else {
				dagreGraph.setEdge(el.source, el.target);
			}
		});

		dagre.layout(dagreGraph);

		var error_handler = elements.find((element) => element.id == "error_handler");

		elements = elements.map((el) => {
			if (isNode(el)) {
				const nodeWithPosition = dagreGraph.node(el.id);
				el.targetPosition = "top";
				el.sourcePosition = "bottom";
				var docuElement = document.getElementById(el.id);

				if (el.type == "note") {
					const height = docuElement ? docuElement.clientHeight : 79;
					el.position = {
						x: nodeWithPosition.x - docuElement.clientWidth / 2 + Math.random() / 1000,
						y: nodeWithPosition.y - height / 2
					};
				} else if (el.id != "root") {
					el.position = {
						x: nodeWithPosition.x - docuElement.clientWidth / 2 + Math.random() / 1000,
						y: nodeWithPosition.y - 79 / 2
					};
				} else {
					el.position = {
						x: nodeWithPosition.x - 600 / 2 + Math.random() / 1000,
						y: nodeWithPosition.y - 165 / 2
					};
				}
			}

			return el;
		});

		// remove placeholder edge
		elements = elements.filter((element) => element.id != "error_handler_placeholder");
		return elements;
	};

	onConnectStart = (event, info) => {
		console.log(event, info, 'CONNECT START');
		this.setState({ handleId: info.handleId, nodeHandleID: info.nodeId, handleType: info.handleType });
	};

	onConnectEnd = (event) => {
		this.setState({ handleId: null, nodeHandleID: null, handleType: null });
	};

	splitNodes = () => {
		var nodes = [];
		this.props.elements.forEach((element) => {
			if (isNode(element)) {
				nodes.push(element);
			}
		});
		return nodes;
	};

	splitEdges = () => {
		var edges = [];
		this.props.elements.forEach((element) => {
			if (isEdge(element)) {
				edges.push(element);
			}
		});
		return edges;
	};

	render() {
		return (
			<KeyEventsHandler
				triggerSave={this.props.triggerSave}
				showSaveTemplateModal={this.state.showSaveTemplateModal}
				selectedElements={this.state.selectedElements}
				closeSaveTemplateModal={() => {
					this.setState({ showSaveTemplateModal: false });
				}}
				reactFlowInstance={this.state.reactFlowInstance}
				reactFlowWrapper={this.reactFlowWrapper}
			>
				{/* <Toaster position="top-right" reverseOrder={false} /> */}
				<div
					className={`h-full ${this.props.hidden ? "w-full" : "w-full md:w-[calc(100%-400px)] md:ml-[400px]"}`}
					onClick={(e) => {
						if (e.target.className == "react-flow__pane react-flow__container") {
							this.props.setSelected(null);
						}
					}}
					ref={this.reactFlowWrapper}
				>
					<ReactFlow
						onDragOver={this.onDragOver}
						onConnect={this.onConnect}
						onDrop={this.onDrop}
						onInit={this.onLoad}
						onConnectStart={this.onConnectStart}
						onConnectEnd={this.onConnectEnd}
						defaultZoom={this.props.builder_settings?.defaultZoom || 5}
						// snapToGrid={true}
						onNodeDragStop={(event, node) => {
							// console.log(event, node, "EVENT NODE");
							this.updatePosition(event, node);
						}}
						// snapGrid={[10, 10]}
						nodeTypes={this.nodeTypes}
						edgeTypes={this.edgeTypes}
						maxZoom={1}
						minZoom={0.02}
						className="touchdevice-flow h-full"
						selectionKeyCode={["Shift"]}
						onSelectionChange={(selection) => {
							if (selection.nodes.length > 1) {
								this.props.setSelected(null);
							}
							this.setState({
								selectedElements: selection
							});
							// this.props.setSelected(null);
						}}
						onSelectionDragStart={(event) => {
							// console.log("DRAG  START");
						}}
						onSelectionDragStop={(e) => {
							// console.log("DRAG STOP");
						}}
						onPaneClick={() => {
							// console.log("PANE CLICK");
						}}
						onNodesChange={(changes) => {
							console.log(changes, 'NODES CHANGE');
							var newNodes = applyNodeChanges(changes, splitElements(this.props.elements).nodes);
							var elements = [...this.props.elements];
							newNodes.forEach((newNode) => {
								var index = this.props.elements.findIndex((element) => element.id == newNode.id);
								elements[index] = newNode;
								delete newNode.handleBounds;
							});

							this.props.setElements(elements);
						}}
						onEdgesChange={(changes) => { }}
						nodes={splitElements(this.props.elements).nodes}
						edges={splitElements(this.props.elements).edges}
						elements={this.props.elements}
					>
						<Background variant="dots" gap={15} size={0.5} />
						<Controls showZoom={false} showFitView={true} showInteractive={false}>
							<ControlButton
								onClick={() => {
									this.sortElements();
								}}
							>
								<FontAwesomeIcon icon={faRandom} />
							</ControlButton>
						</Controls>

						{this.state.selectedElements.nodes.length > 1 ? (
							<div className="multi-select-duplicate-container">
								<button
									className="btn btn-gray mr-3"
									onClick={(e) => {
										if (window.getSelection) {
											window.getSelection().removeAllRanges();
										} else if (document.selection) {
											document.selection.empty();
										}
										document.querySelector(".react-flow__pane").click();
									}}
								>
									Deselect
								</button>
								<button
									className="btn btn-red mr-3"
									onClick={(e) => {
										this.setState({ showSaveTemplateModal: true });
									}}
								>
									<FontAwesomeIcon icon={faSave} /> Save as Template
								</button>
								<button
									className="btn btn-gray"
									onClick={(e) => {
										var selection = { ...this.state.selectedElements };
										var newElements = [];
										var newEdges = [];
										var nodes = [...selection.nodes];
										var edges = [...selection.edges];
										for (var i = 0; i < nodes.length; i++) {
											var node = nodes[i];
											// nodes.forEach(node => {

											if (node.id == "root" || node.id == "error_handler") {
												continue;
											}

											if (node.type == "button" || node.type == "conditionChild" || node.type == "selectMenuOption" || node.type == "advMessageButton" || node.type == "advMessageSelectMenu") {
												// Get the node parent and add it to.
												var allEdges = splitElements(this.props.elements).edges;
												var parent = getIncomers(node, splitElements(this.props.elements).nodes, allEdges);
												if (parent) {
													var parentIndex = nodes.findIndex((n) => n.id == parent[0].id);
													if (parentIndex == -1) {
														nodes.push(parent[0]);
													}
												}
											} else if (node.type == "condition") {
												var allEdges = splitElements(this.props.elements).edges;
												var elementChildren = getOutgoers(node, splitElements(this.props.elements).nodes, allEdges);
												if (elementChildren) {
													var elseChild = elementChildren.find((c) => c.data.data.type == "else");
													if (elseChild) {
														var elseChildIndex = nodes.findIndex((n) => n.id == elseChild.id);
														if (elseChildIndex == -1) {
															var newEdge = {
																id: `edge_${s4()}_${s4()}_${s4()}`,
																source: node.id,
																target: elseChild.id,
																type: "smoothstep",
																animated: false,
																arrowHeadType: "arrowclosed",
																style: { stroke: "#fff" }
															};
															nodes.push(elseChild);
															edges.push(newEdge);
														}
													}
												}
											} else if (node.type == "loopChild") {
												var allEdges = splitElements(this.props.elements).edges;
												var parent = getIncomers(node, splitElements(this.props.elements).nodes, allEdges);
												if (parent) {
													var parentIndex = nodes.findIndex((n) => n.id == parent[0].id);
													if (parentIndex == -1) {
														nodes.push(parent[0]);
													}
												}
											} else if (node.type == "action" && node.data.data.type == "loop") {
												// Clone children
												var allEdges = splitElements(this.props.elements).edges;
												var elementChildren = getOutgoers(node, splitElements(this.props.elements).nodes, allEdges);
												if (elementChildren) {
													elementChildren.forEach((child) => {
														var childIndex = nodes.findIndex((n) => n.id == child.id);
														if (childIndex == -1) {
															nodes.push(child);
														}
													});
												}
											}
											var newNode = copy(node);
											var id_type = "action";
											if (node.type == "condition") {
												id_type = "condition";
											} else if (node.type == "button") {
												id_type = "button";
											} else if (node.type == "selectMenuOption") {
												id_type = "select_menu_option";
											} else if ("data" in node && "data" in node.data && "type" in node.data.data && node.data.data.type == "else") {
												id_type = "else";
											} else if (node.type == "conditionChild") {
												id_type = "conChild";
											}

											var id = `${id_type}_${new Date().getTime()}_${s4()}_${s4()}`;
											newNode.oldId = newNode.id;
											newNode.id = id;
											newNode.position.x += 500;

											newElements.push(newNode);
											if (newNode.type == "option") {
												var newEdge = {
													id: `edge_${s4()}_${s4()}_${s4()}`,
													source: newNode.id,
													target: "root",
													type: "smoothstep",
													animated: false,
													arrowHeadType: "arrowclosed",
													style: { stroke: "#fff" }
												};
												newElements.push(newEdge);
											}
											// });
										}
										var elements = [...this.props.elements, ...newElements];

										edges.forEach((edge, index) => {
											if (edge.source == "root") {
												// edges.splice(index, 1);
											} else {
												var source = newElements.find((element) => element.oldId == edge.source);
												var target = newElements.find((element) => element.oldId == edge.target);

												if (source && target) {
													var newEdge = copy(edge);
													// delete newEdge.id;
													newEdge.id = `edge_${new Date().getTime()}_${s4()}_${s4()}`;
													newEdge.source = source.id;
													newEdge.target = target.id;

													elements.push(newEdge);
												}
											}
										});

										// elements = [...elements, ...newElements, ...edges];

										this.props.setElements(elements);
										// this.props.setSelected(null);
										// Deselect the mutli select
										// this.setState({ selectedElements: { nodes: [], edges: [] } });
										// Click the react flow pane to deselect the nodes
										setTimeout(() => {

											if (window.getSelection) {
												window.getSelection().removeAllRanges();
											} else if (document.selection) {
												document.selection.empty();
											}
											document.querySelector(".react-flow__pane").click();

										}, 100);
									}}
									disabled={this.props.module_id != null && !this.props.premium}
								>
									<FontAwesomeIcon icon={faCopy} /> Duplicate Blocks
								</button>
								{/* <button className="btn btn-gray command-builder-save" onClick={(e) => {
                console.log("DELETE MUTLi");
                this.deleteMulti();
              }}><FontAwesomeIcon icon={faTrash} /> Delete Blocks</button> */}
							</div>
						) : null}
					</ReactFlow>

				</div>
			</KeyEventsHandler>
		);
	}
}

const mapStateToProps = (state) => ({
	elements: state.builder.elements,
	selected: state.builder.selected,
	index: state.builder.index,
	hidden: state.builder.hidden,
	highlightAction: state.builder.highlightAction,
	highlightComponent: state.builder.highlightComponent,
	mode: state.builder.mode,
	module_id: state.builder.module_id,
	premium: state.data.premium,
	builder_settings: state.data.user.builder_settings
});

const mapDispatchToProps = {
	setElements,
	setSelected,
	updateNode,
	updateElementData
};
let s4 = () => {
	return Math.floor((1 + Math.random()) * 0x10000)
		.toString(16)
		.substring(1);
};
function copy(obj) {
	// Get object type
	let type = Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();

	/**
	 * Create an immutable copy of an object
	 * @return {Object}
	 */
	function cloneObj() {
		let clone = {};
		for (let key in obj) {
			if (obj.hasOwnProperty(key)) {
				clone[key] = copy(obj[key]);
			}
		}
		return clone;
	}

	/**
	 * Create an immutable copy of an array
	 * @return {Array}
	 */
	function cloneArr() {
		return obj.map(function (item) {
			return copy(item);
		});
	}

	// Return a clone based on the object type
	if (type === "object") return cloneObj();
	if (type === "array") return cloneArr();
	return obj;
}

export default withMyHook(connect(mapStateToProps, mapDispatchToProps)(CommandBuilderCanvas));
