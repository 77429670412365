import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStickyNote, faExclamation, faLink } from "@fortawesome/free-solid-svg-icons";
import DefaultHandle from './DefaultHandle';
import { isMobile } from 'react-device-detect';

export class NoteNode extends Component {
    render() {
        const {
            id,
            data,
            selected,
            builder_settings,
            nodeHandleID,
            handleType,
            renderHandlePositions,
            renderHandleSizes,
            actionConnection,
            handleId,
            setSelected
        } = this.props;

        var useMobileBuilder = builder_settings?.mobileBuilder && isMobile;
        var nodePadding = useMobileBuilder ? "py-[50px]" : "py-[25px]";

        return (
            <>
                <DefaultHandle
                    nodeType="action"
                    selectedNodeHandleType={handleId}
                    nodeHandleID={nodeHandleID}
                    selectedNodeID={nodeHandleID}
                    selectedHandleType={handleType}
                    id={id}
                    position="top"
                    handleType="target"
                    isValidConnection={actionConnection}
                />

                {/* <DefaultHandle
						nodeType="action"
						selectedNodeHandleType={this.state.handleId}
						selectedNodeID={this.state.nodeHandleID}
						nodeHandleID={this.state.nodeHandleID}
						selectedHandleType={this.state.handleType}
						id={id}
						position="top"
						handleType="target"
					/> */}

                <div
                    id={id}
                    onTouchStart={(e) => {
                        setSelected(id);
                    }}
                    onClick={(e) => {
                        setSelected(id);
                    }}
                    style={{
                        borderColor: data.data?.color || '#9333ea',
                        maxWidth: '400px',
                        overflow: 'hidden',
                        border: selected == id
                            ? `2px solid ${builder_settings?.selectedColor || '#5865f2'}`
                            : '2px solid transparent'
                    }}
                    className={`action-node ${nodePadding} option-node bg-[#2f3136]`}
                >
                    <div className="inner" style={{ display: "flex" }}>
                        {data.data == undefined || data.data.validated == false ? (
                            <div className="node-invalid">
                                <FontAwesomeIcon icon={faExclamation} />
                            </div>
                        ) : null}

                        <div
                            className="object-icon object-icon-action"
                            style={{ backgroundColor: data.data?.color || '#9333ea' }}
                        >
                            <FontAwesomeIcon icon={faStickyNote} />
                        </div>

                        <div className="object-info object-info-action overflow-hidden">
                            <span
                                className="object-title break-words"
                                style={{ color: data.data?.color || '#9333ea' }}
                            >
                                {data.data?.title || "Note"}
                            </span>
                            <span className="object-description text-gray-300 whitespace-pre-line line-clamp-3 break-words">
                                {data.data?.description || "Add a description"}
                            </span>
                        </div>
                    </div>
                </div>

                <DefaultHandle
                    nodeType="action"
                    selectedNodeHandleType={handleId}
                    nodeHandleID={nodeHandleID}
                    selectedNodeID={nodeHandleID}
                    selectedHandleType={handleType}
                    id={id}
                    position="bottom"
                    handleType="source"
                    isValidConnection={actionConnection}
                />
            </>
        );
    }
} 