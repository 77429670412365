import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import DocsLink from '../../elements/DocsLink';
import Toggle from '../../elements/Toggle';
import { updateElementData } from '../../../../actions';

import TextArea from '../../elements/TextArea';
import RequestBuilder from '../../elements/RequestBuilder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import update from "immutability-helper";
import ModalBuilder from './ModalBuilder';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { copyToast } from '../toast';
export class ModalAction extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openBuilder: false,
            openTester: false
        };
    }

    componentDidMount() {
        // console.log("Updating data MOUNTED", this.props.id);
        if (this.props.data == undefined) {
            this.props.updateElementData(
                {
                    data: {
                        type: "modal",
                        modal_id: "",
                        modal_settings: {
                            title: "Form Title",
                            components: [{
                                label: "Input Label",
                                variable: "",
                                maxLength: "",
                                minLength: "",
                                placeholder: "",
                                required: false,
                                inputStyle: "SHORT",
                                defaultValue: ""
                            }]
                        },
                        ephemeral: false,
                        validated: false,
                    },
                    id: this.props.id
                }
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.data == undefined) {
            // console.log("Updating data", prevProps, this.props, this.props.id);

            this.props.updateElementData(
                {
                    data: {
                        type: "modal",
                        modal_id: "",
                        modal_settings: {
                            title: "Form Title",
                            components: [{
                                label: "Input Label",
                                variable: "",
                                maxLength: "",
                                minLength: "",
                                placeholder: "",
                                required: false,
                                inputStyle: "SHORT",
                                defaultValue: ""
                            }]
                        },
                        ephemeral: false,
                        validated: false,
                    },
                    id: this.props.id
                }
            );
        }
        else {
            // VALIDATE

            if (this.props.data.modal_id != "" && this.props.data.modal_settings.title != "" && this.validateExistingNames() && this.validateComponents() && this.validateVariableNames() == null) {
                if (this.props.data.validated == false) {
                    this.updateData("validated", true);
                }
            } else if (this.props.data.validated == true) {
                this.updateData("validated", false);
            }
        }
    }

    updateData = (key, value) => {

        // console.log("VALUE", value);
        var data = { ...this.props.data };
        data[key] = value;
        this.props.updateElementData(
            {
                data: data,
                id: this.props.id
            }
        );
    };

    validateComponents = () => {
        var passed = true;
        this.props.data.modal_settings.components.forEach(component => {
            if (component.label == "") {
                passed = false;
            } else if (component.variable == "") {
                passed = false;
            }
        });

        return passed;
    };

    nameChange = (value) => {



        value = value.toLowerCase();
        value = value.substring(0, 32);
        value = value.replace(" ", "-");
        // value = value.replace(/[^\w]+/gi,"")
        // value = value.replace(/[^[A-zÀ-Ÿ\d-]+/gi,"")
        const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/ug);
        value = value.replace(regexForNonAlphaNum, "");
        this.updateData("modal_id", value);
    };

    validateExistingNames = () => {
        var pass = true;
        var elements = this.props.elements;
        elements.forEach(element => {
            if (element.id != this.props.id && element.type == 'action' && element.data.node_options.title == 'Send a Form' && 'data' in element.data) {
                if (element.data.data.modal_id != "" && element.data.data.modal_id == this.props.data.modal_id) {
                    pass = false;
                }
            } else if (element.id != this.props.id && element.type == 'action' && element.data.node_options.title == 'Send an API Request' && 'data' in element.data) {
                if (element.data.data.name != "" && element.data.data.name == this.props.data.modal_id) {
                    pass = false;
                }
            }
        });


        return pass;
    };


    renderVariable = () => {
        var variables = [];

        this.props.data.modal_settings.components.forEach(component => {
            variables.push(
                <p style={{
                    color: "#fff",
                    opacity: "0.7",
                    fontWeight: 500
                }}><span style={{ color: "#f45142" }}>{`{${this.props.data.modal_id}.${component.variable}}`} <FontAwesomeIcon icon={faClipboard} fixedWidth className='text-sm hover:text-red hover:cursor-pointer' onClick={() => { navigator.clipboard.writeText(`{${this.props.data.modal_id}.${component.variable}}`); copyToast("Copied Variable"); }} /></span> - Returns the response of the {component.label} input.</p>

            );
        });
        return variables;
    };

    validateVariableNames = () => {
        var variables = [];
        var error = null;
        this.props.data.modal_settings.components.forEach(component => {
            if (variables.includes(component.variable)) {
                error = "Field variable names must be unique!";
            }
            variables.push(component.variable);
        });

        return error;
    };

    render() {
        if (this.props.data != undefined) {
            return (

                <>
                    <Transition.Root show={this.state.openBuilder} as={Fragment}>
                        <Dialog as="div" className="relative z-[10000]" onClose={() => {
                            this.setState({ openBuilder: false });
                        }}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-[#0000008a] transition-opacity" />
                            </Transition.Child>

                            <div className="fixed inset-0 z-10 overflow-y-auto">
                                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    >
                                        <Dialog.Panel className="relative bg-[#2f3136] text-white transform overflow-hidden rounded-lg bg-lightGray px-4 pt-4 pb-4 text-left shadow-xl transition-all sm:my-8  sm:p-6">
                                            {/* Close Button */}
                                            <div>
                                                <button type="button" className="absolute top-0 right-0 mt-2 mr-2 border-none" onClick={() => {
                                                    this.setState({ openBuilder: false });
                                                }}>
                                                    <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                                </button>
                                            </div>
                                            <ModalBuilder updateData={(modal_settings) => {
                                                this.updateData("modal_settings", modal_settings);
                                            }} modal_settings={this.props.data.modal_settings} modal_id={this.props.data.modal_id} />
                                            {/* <div className="sm:flex sm:items-start">
                                            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red sm:mx-0 sm:h-10 sm:w-10">
                                                <ExclamationTriangleIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                            </div>
                                            <div className="text-left ml-2">
                                                <Dialog.Title as="h3" className="text-xl text-white font-bold leading-6">
                                                    Import {this.props.mode == "event" ? "Event" : "Command"}
                                                </Dialog.Title>
                                                <div className="mt-2">
                                                    <p className="text-muted mb-3 font-semibold">
                                                        Are you sure you wish to import this {this.props.mode}? This will overwrite your current {this.props.mode}. Any custom variables associated with this {this.props.mode} will also be imported.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">

                                            <button
                                                type="button"
                                                className="inline-flex w-full justify-center rounded-md border border-transparent bg-red px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                                onClick={() => {
                                                    this.handleImport();
                                                    this.setState({ openModal: false });
                                                }}
                                            >
                                                Import
                                            </button>
                                            <button
                                                type="button"
                                                className=" inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                                                onClick={() => this.setState({ openModal: false })}
                                            >
                                                Cancel
                                            </button>

                                            <a
                                                type="button"
                                                target="_blank"
                                                href={`https://botghost.com/market/${this.props.mode}/${this.props.item.market_id}`}
                                                className="mr-auto inline-flex w-full justify-center rounded-md border border-transparent bg-red px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm hover:no-underline hover:opacity-80"

                                            >
                                                View {this.props.mode == "event" ? "Event" : "Command"} Page
                                            </a>
                                        </div> */}
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition.Root>


                    <div>


                        <div className="mb-15">
                            <h4>Send a Form<DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/send-a-form" /></h4>
                            <p>Send a form or modal and wait for the user to fill it out.</p>
                        </div>


                        <div className="mb-15">
                            <h4 style={{ marginBottom: "0px" }}>Form Name<DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/send-a-form#form-name" /></h4>

                            <div className="section-content-header mb-15">A name for this form. This will be used as a variable to access the responses entered by the user after they submit the form.</div>
                            <div className="long-input mb-15" style={{ width: "100%" }}>
                                <label>Form Name</label>
                                <input value={this.props.data.modal_id} className={`${this.props.data.modal_id == "" ? "command-required" : null}`} placeholder="my-form" required type="text" onChange={(e) => {
                                    this.nameChange(e.target.value);
                                    // this.updateData("name", e.target.value);
                                }}></input>
                            </div>
                            {!this.validateExistingNames() ? <p style={{ marginTop: "0px", color: "#f45142" }}>A Form action or API Request Action with this name already exists. Please rename this action.</p>
                                : null}
                        </div>

                        <h4 style={{ marginBottom: "0px" }}>Form Builder {!this.validateComponents() || this.props.data.modal_settings.title == "" ? <FontAwesomeIcon icon={faExclamationCircle} color="#f45142" /> : <FontAwesomeIcon icon={faCheckCircle} color="green" />}</h4>
                        <div className="section-content-header mb-15">Click the button below to open the Form builder.</div>
                        <div className='mb-15'>
                            <button disabled={this.props.data.modal_id == "" ? true : false} className="btn btn-primary" onClick={() => {
                                this.setState({ openBuilder: true });
                            }}>Form Builder</button>
                        </div>

                        <div>
                            <span className="text-red font-semibold">{this.validateVariableNames()}</span>
                        </div>


                        {this.props.data.modal_id != "" && this.validateComponents() ? <>
                            <hr className='slash-hr' style={{ borderTop: "1px solid #adb5bd" }}></hr>



                            <h4>Using Responses <DocsLink location="https://docs.botghost.com/custom-commands-and-events/actions/send-a-form#using-form-responses" /></h4>


                            <p className="mb-15" style={{
                                color: "#fff",
                                opacity: "0.7",
                                fontWeight: 500
                            }}>You can use the <span style={{ color: "#f45142", opacity: "1 !important" }}>{`{${this.props.data.modal_id}}`}</span> variable with dot notation to access responses for each input. </p>
                            {this.renderVariable()}

                        </> : null}


                    </div>
                </>

            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    selected: state.builder.selected,
    elements: state.builder.elements,
    saveFailed: state.builder.saveFailed
});
const mapDispatchToProps = {
    updateElementData
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAction);
