import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Dialog, Transition } from '@headlessui/react';
import { updateServerPopup } from "../../../actions";
import server from "../../../api/server";
import ReactGA from "react-ga";

export class ServerModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: true
		};
	}

	joinServer = async () => {
		ReactGA.initialize("UA-122665050-1");
		ReactGA.event({
			category: "Server Join",
			label: "Server Modal",
			action: "New Dashboard"
		});

		const response = await server.get("/dashboard/joinserver");
		if (response.data.failed && response.data.failed === true && response.data.reason === "auth") {
			window.open("https://discord.com/invite/9UPM6S4xyA", "_blank").focus();
		}
		this.setState({ open: false });
		this.props.closeModal();
	};

	render() {
		return (
			<Transition appear show={this.state.open} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-[9999]"
					onClose={() => {
						this.setState({ open: false });
						this.props.closeModal(true);
					}}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-[#1a1c1f]/90 z-[9999]" />
					</Transition.Child>

					<div className="fixed inset-0 overflow-y-auto z-[9999]">
						<div className="flex min-h-full items-center justify-center p-4 text-center">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 scale-95"
								enterTo="opacity-100 scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 scale-100"
								leaveTo="opacity-0 scale-95"
							>
								<Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-lg bg-[#2f3136] p-6 text-left align-middle shadow-xl transition-all border border-[#1a1c1f]">
									<button
										onClick={() => {
											this.setState({ open: false });
											this.props.closeModal(true);
										}}
										className="absolute right-4 top-4 text-[#b9bbbe] hover:text-white focus:outline-none"
									>
										<svg
											className="h-6 w-6"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth={2}
												d="M6 18L18 6M6 6l12 12"
											/>
										</svg>
									</button>

									<div className="flex flex-col items-center space-y-4">
										<img
											src={process.env.PUBLIC_URL + "/images/logo-red.png"}
											alt="BotGhost Logo"
											className="w-16 h-16"
										/>

										<h2 className="text-2xl font-bold text-center text-[#ffffff]">
											Join the BotGhost Official Discord Server
										</h2>

										<p className="text-center text-[#b9bbbe]">
											Join the discord server for help, giveaways and more.
										</p>

										<button
											onClick={this.joinServer}
											className="w-full sm:w-auto px-6 py-3 bg-[#ff4444] hover:bg-[#ff6666] text-white font-medium rounded-lg transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-[#ff4444] focus:ring-offset-2 focus:ring-offset-[#2f3136]"
										>
											Join the Server
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition>
		);
	}
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
	updateServerPopup
};

export default connect(mapStateToProps, mapDispatchToProps)(ServerModal);
