import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import history from '../../history';

export const ActiveSaleBanner = ({ active_sale, personal_sale }) => {
    const [timeLeft, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    });

    useEffect(() => {
        if (!active_sale && !personal_sale?.created_at) return;

        // Use active_sale expiry if available, otherwise fall back to personal sale
        const endDate = active_sale ?
            moment(active_sale.expires_at) :
            moment(personal_sale.created_at).add(3, 'days');

        const timer = setInterval(() => {
            const now = moment();
            const duration = moment.duration(endDate.diff(now));

            // If countdown is finished
            if (duration.asMilliseconds() <= 0) {
                setTimeLeft({
                    days: 0,
                    hours: 0,
                    minutes: 0,
                    seconds: 0
                });
                clearInterval(timer);
                return;
            }

            setTimeLeft({
                days: Math.floor(duration.asDays()),
                hours: duration.hours(),
                minutes: duration.minutes(),
                seconds: duration.seconds()
            });
        }, 1000);

        return () => clearInterval(timer);
    }, [active_sale, personal_sale]);

    const handleContinuePremium = () => {
        history.push('/dashboard/premium');
    };

    // If no active sale and no personal sale, don't render
    if (!active_sale && !personal_sale) return null;

    // Use active sale if available, otherwise fall back to personal sale
    const currentSale = active_sale || personal_sale;

    return (
        <div className="relative overflow-hidden bg-[#1a1433] p-6 sm:p-12 rounded-2xl my-4 text-white">
            {/* Dark gradient overlay */}
            <div className="absolute inset-0 bg-gradient-to-r from-[#1a1433] via-[#2d1b5a] to-[#1a1433]" />

            {/* Enhanced Purple/Violet Accent Glows */}
            <div className="absolute -bottom-32 right-0 w-2/3 h-[150%] bg-gradient-to-tl from-[#9d4edd]/30 via-[#7b2cbf]/20 to-transparent blur-3xl" />
            <div className="absolute top-0 right-0 w-1/2 h-full bg-gradient-to-bl from-[#c77dff]/25 via-[#9d4edd]/15 to-transparent blur-2xl" />

            {/* Additional subtle sale accent */}
            <div className="absolute inset-0 bg-gradient-to-r from-transparent via-[#9d4edd]/[0.05] to-[#7b2cbf]/[0.08]" />

            <div className="flex flex-col lg:flex-row items-center justify-between relative z-10 gap-8 lg:gap-0">
                {/* Content */}
                <div className="relative z-10 w-full lg:max-w-xl">
                    <h1 className="text-3xl sm:text-4xl font-bold mb-3">
                        {active_sale ? (
                            <>{active_sale.banner_title}</>
                        ) : (
                            <>
                                Exclusive Post-Trial Offer:{' '}
                                <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#c77dff] to-[#9d4edd]">
                                    40% OFF
                                </span>
                            </>
                        )}
                    </h1>

                    {/* Countdown Timer */}
                    <div className="flex gap-4 mb-5">
                        <div className="bg-[#2d1b5a] px-4 py-2 rounded-lg text-center min-w-[80px] border border-[#7b2cbf]/20">
                            <div className="text-2xl font-bold text-[#c77dff]">{timeLeft.days}</div>
                            <div className="text-xs text-[#9d4edd]">Days</div>
                        </div>
                        <div className="bg-[#2d1b5a] px-4 py-2 rounded-lg text-center min-w-[80px] border border-[#7b2cbf]/20">
                            <div className="text-2xl font-bold text-[#c77dff]">{timeLeft.hours}</div>
                            <div className="text-xs text-[#9d4edd]">Hours</div>
                        </div>
                        <div className="bg-[#2d1b5a] px-4 py-2 rounded-lg text-center min-w-[80px] border border-[#7b2cbf]/20">
                            <div className="text-2xl font-bold text-[#c77dff]">{timeLeft.minutes}</div>
                            <div className="text-xs text-[#9d4edd]">Minutes</div>
                        </div>
                        <div className="bg-[#2d1b5a] px-4 py-2 rounded-lg text-center min-w-[80px] border border-[#7b2cbf]/20">
                            <div className="text-2xl font-bold text-[#c77dff]">{timeLeft.seconds}</div>
                            <div className="text-xs text-[#9d4edd]">Seconds</div>
                        </div>
                    </div>

                    <p className="text-base sm:text-lg leading-relaxed text-[#e0b1ff] mb-5 lg:pr-12">
                        {active_sale ? active_sale.banner_description : (
                            <>
                                We hope you enjoyed your free trial! To help you continue enjoying premium features,
                                we're offering you an exclusive <span className="font-bold text-[#c77dff]">40% discount</span> on
                                any premium subscription. Don't let your bot lose its premium capabilities - upgrade now
                                at our best personal offer.
                            </>
                        )}
                    </p>

                    <button
                        onClick={handleContinuePremium}
                        className="relative w-full sm:w-auto px-8 py-3 rounded-lg font-semibold overflow-hidden
                            transition-all duration-200 hover:-translate-y-0.5 active:translate-y-0 
                            group cursor-pointer bg-[#9d4edd]"
                    >
                        {/* Gradient background */}
                        <div className="absolute inset-0 bg-gradient-to-r from-[#9d4edd] via-[#c77dff] to-[#7b2cbf] 
                            group-hover:opacity-90 transition-opacity" />

                        {/* Shine effect */}
                        <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/20 to-transparent 
                            translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-1000" />

                        {/* Button shadow */}
                        <div className="absolute inset-0 shadow-xl shadow-[#9d4edd]/20" />

                        {/* Button text */}
                        <span className="relative z-10 text-white">
                            {active_sale ? 'Upgrade Now!' : 'Get Your Discount Now!'}
                        </span>
                    </button>
                </div>

                {/* Promo Image - Hidden on mobile */}
                <div className="hidden lg:block relative z-10 mr-8">
                    <div>
                        <img
                            src={active_sale?.banner_image || process.env.PUBLIC_URL + "/images/logo-red.png"}
                            alt="Sale Offer"
                            className="transform hover:scale-105 transition-transform duration-500 drop-shadow-[0_0_15px_rgba(157,78,221,0.3)] max-w-[500px] max-h-[300px] object-contain"
                        />
                    </div>
                </div>
            </div>

            {/* Floating stars decoration */}
            <div className="absolute top-0 left-0 w-full h-full pointer-events-none z-20">
                <div className="absolute top-2 right-[5%] text-[#c77dff]/30 text-3xl animate-pulse delay-100">✦</div>
                <div className="absolute top-16 right-[15%] text-[#9d4edd]/40 text-2xl animate-pulse delay-300">✧</div>
                <div className="absolute top-4 right-[25%] text-[#c77dff]/30 text-3xl animate-pulse delay-500">✦</div>
                <div className="absolute top-20 right-[35%] text-[#9d4edd]/40 text-2xl animate-pulse delay-700">✧</div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    active_sale: state.data.active_sale,
    personal_sale: state.data.user?.personal_sale
});

export default connect(mapStateToProps)(ActiveSaleBanner);