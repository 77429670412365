import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation, faLink, faBars } from "@fortawesome/free-solid-svg-icons";
import DefaultHandle from './DefaultHandle';

export class AdvancedMessageSelectMenu extends Component {
    renderAdvancedMessageSelectMenuType = (data) => {
        if (data?.data?.menu_type == "basic") {
            return "Basic Select Menu";
        } else if (data?.data?.menu_type == "role") {
            return "Role Select Menu";
        } else if (data?.data?.menu_type == "channel") {
            return "Channel Select Menu";
        } else if (data?.data?.menu_type == "user") {
            return "User Select Menu";
        } else if (data?.data?.menu_type == "mentionable") {
            return "Mentionable Select Menu";
        }
    };

    render() {
        const {
            id,
            data,
            selected,
            builder_settings,
            nodeHandleID,
            handleType,
            handleId,
            actionConnection,
            setSelected
        } = this.props;

        return (
            <>
                <DefaultHandle
                    nodeType="selectmenu"
                    selectedNodeHandleType={handleId}
                    nodeHandleID={nodeHandleID}
                    selectedNodeID={nodeHandleID}
                    selectedHandleType={handleType}
                    id={id}
                    position="top"
                    handleType="target"
                    isValidConnection={actionConnection}
                    nonInteractive={true}
                />

                <div
                    id={id}
                    onTouchStart={(e) => {
                        setSelected(id);
                    }}
                    onClick={(e) => {
                        setSelected(id);
                    }}
                    style={{
                        border: selected == id
                            ? `2px solid ${builder_settings?.selectedColor || '#5865f2'}`
                            : '2px solid transparent'
                    }}
                    className="action-node !py-[20px] p-[10px] option-node"
                >
                    <div className="inner" style={{ display: "flex" }}>
                        {data.data == undefined || data.data.validated == false ? (
                            <div className="node-invalid">
                                <FontAwesomeIcon icon={faExclamation} />
                            </div>
                        ) : null}

                        {data.data == undefined || data.data.linked == false ? (
                            <div className="node-invalid" style={{ right: `${data.data != undefined && data.data.validated == false ? "10px" : ""}` }}>
                                <FontAwesomeIcon icon={faLink} />
                            </div>
                        ) : null}
                        <div
                            className="object-icon object-icon-action"
                            style={{ backgroundColor: builder_settings?.actionColor || '#358deb' }}
                        >
                            <FontAwesomeIcon icon={faBars} />
                        </div>

                        <div className="object-info object-info-action">
                            <span className="object-title text-sm">{this.renderAdvancedMessageSelectMenuType(data)}</span>
                            <span className="object-description text-xs">Select Menu</span>
                        </div>
                    </div>
                </div>

                <DefaultHandle
                    nodeType="action"
                    selectedNodeHandleType={handleId}
                    nodeHandleID={nodeHandleID}
                    selectedNodeID={nodeHandleID}
                    selectedHandleType={handleType}
                    id={id}
                    position="bottom"
                    handleType="source"
                    isValidConnection={actionConnection}
                />
            </>
        );
    }
} 