import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation, faLink, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import DefaultHandle from './DefaultHandle';
import { getHandlePosition, getHandleSize, getHandleStyles } from '../HandleSizes';
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import { getOutgoers, getIncomers, addEdge } from 'react-flow-renderer';
import splitElements from '../splitElements';
import { isMobile } from 'react-device-detect';

export class ActionNode extends Component {
    renderBlockLabels = (data) => {
        if (data && data.data && "command_label" in data.data && data.data.command_label) {
            return (
                <>
                    <span className="object-title">{data.data.command_label}</span>
                    <span className="object-description">{data.node_options.title}</span>
                </>
            );
        } else {
            return (
                <>
                    <span className="object-title">{data.node_options.title}</span>
                    <span className="object-description">{data.node_options.description}</span>
                </>
            );
        }
    };

    renderAdvMessageHandles = (id, data) => {
        const { nodeHandleID, handleType, actionConnection, elements } = this.props;

        var element = elements.find((element) => element.id == id);

        if (!element || element.data?.data?.messageType == "basic") {
            return (
                <DefaultHandle
                    nodeType="action"
                    selectedNodeHandleType={this.props.handleId}
                    nodeHandleID={nodeHandleID}
                    selectedNodeID={nodeHandleID}
                    selectedHandleType={handleType}
                    id={id}
                    position="bottom"
                    handleType="source"
                    isValidConnection={actionConnection}
                />
            );
        } else {
            return (
                <div className="relative w-full">
                    <DefaultHandle
                        nodeType="action"
                        selectedNodeHandleType={this.props.handleId}
                        nodeHandleID={nodeHandleID}
                        selectedNodeID={nodeHandleID}
                        selectedHandleType={handleType}
                        id={id}
                        position="bottom"
                        handleType="source"
                        isValidConnection={actionConnection}
                    // customClasses="left-[30%]"
                    />

                    <DefaultHandle
                        nodeType="components_source"
                        hidden={true}
                        selectedNodeHandleType={this.props.handleId}
                        nodeHandleID={nodeHandleID}
                        selectedNodeID={nodeHandleID}
                        selectedHandleType={handleType}
                        id={id}
                        position="bottom"
                        handleType="source"
                        isValidConnection={actionConnection}
                        nonInteractive={true}
                    // customClasses="left-[60%]"
                    />
                </div>
            );
        }
    };

    renderActionHandles = (id, data) => {
        const { nodeHandleID, handleType, actionConnection } = this.props;

        if (!data) return;

        if (data.data?.success_handles) {
            return (
                <div className="gap-x-2">
                    <DefaultHandle
                        nodeType="success"
                        selectedNodeHandleType={this.props.handleId}
                        nodeHandleID={nodeHandleID}
                        selectedNodeID={nodeHandleID}
                        selectedHandleType={handleType}
                        id={id}
                        position="bottom"
                        handleType="source"
                        isValidConnection={actionConnection}
                        customStyle={{ marginLeft: '-70px', border: '3px solid lime' }}
                    />
                    <DefaultHandle
                        nodeType="error"
                        selectedNodeHandleType={this.props.handleId}
                        nodeHandleID={nodeHandleID}
                        selectedNodeID={nodeHandleID}
                        selectedHandleType={handleType}
                        id={id}
                        position="bottom"
                        handleType="source"
                        isValidConnection={actionConnection}
                        customStyle={{ marginLeft: '70px', border: '3px solid red' }}
                    />
                </div>
            );
        }

        if (data.node_options.title == "Send a Message with attached Buttons" || data.node_options.title == "Send a Message with a Select Menu") {
            return (
                <DefaultHandle
                    nodeType={data.node_options.title == "Send a Message with attached Buttons" ? "button" : "selectmenu"}
                    selectedNodeHandleType={this.props.handleId}
                    nodeHandleID={nodeHandleID}
                    selectedNodeID={nodeHandleID}
                    selectedHandleType={handleType}
                    id={id}
                    position="bottom"
                    handleType="source"
                    isValidConnection={actionConnection}
                    nonInteractive={true}
                />
            );
        } else if (data.node_options.title == "Run a Loop") {
            return (
                <div className="gap-x-2">
                    <DefaultHandle
                        nodeType="loop_after"
                        selectedNodeHandleType={this.props.handleId}
                        nodeHandleID={nodeHandleID}
                        selectedNodeID={nodeHandleID}
                        selectedHandleType={handleType}
                        id={id}
                        position="bottom"
                        handleType="source"
                        isValidConnection={actionConnection}
                        nonInteractive={true}
                        customStyle={{ marginLeft: '-50px' }}
                    />
                    <DefaultHandle
                        nodeType="loop_actions"
                        selectedNodeHandleType={this.props.handleId}
                        nodeHandleID={nodeHandleID}
                        selectedNodeID={nodeHandleID}
                        selectedHandleType={handleType}
                        id={id}
                        position="bottom"
                        handleType="source"
                        isValidConnection={actionConnection}
                        nonInteractive={true}
                        customStyle={{ marginLeft: '50px' }}
                    />
                </div>
            );
        } else {
            return (
                <DefaultHandle
                    nodeType="action"
                    selectedNodeHandleType={this.props.handleId}
                    nodeHandleID={nodeHandleID}
                    selectedNodeID={nodeHandleID}
                    selectedHandleType={handleType}
                    id={id}
                    position="bottom"
                    handleType="source"
                    isValidConnection={actionConnection}
                />
            );
        }
    };



    render() {

        const {
            id,
            data,
            selected,
            builder_settings,
            nodeHandleID,
            handleType,
            handleId,
            actionConnection,
            setSelected,
            addToMessage
        } = this.props;
        var useMobileBuilder = builder_settings?.mobileBuilder && isMobile;
        var nodePadding = useMobileBuilder ? "py-[50px]" : "py-[25px]";
        if (data.node_options.title == "Send a Message" || data.node_options.title == "Send or Edit a Message") {
            return (
                <div className="min-w-[430px]">
                    <DefaultHandle
                        nodeType="action"
                        selectedNodeHandleType={handleId}
                        nodeHandleID={nodeHandleID}
                        selectedNodeID={nodeHandleID}
                        selectedHandleType={handleType}
                        id={id}
                        position="top"
                        handleType="target"
                        isValidConnection={actionConnection}
                    />

                    <div
                        id={id}
                        onTouchStart={(e) => {
                            setSelected(id);
                        }}
                        onClick={(e) => {
                            setSelected(id);
                        }}
                        style={{
                            position: "relative",
                            border: selected == id
                                ? `2px solid ${builder_settings?.selectedColor || '#5865f2'}`
                                : '2px solid transparent'
                        }}
                        className={`action-node ${nodePadding} option-node`}
                    >
                        <div className="inner" style={{ display: "flex" }}>
                            {data.data == undefined || data.data.validated == false ? (
                                <div className="node-invalid">
                                    <FontAwesomeIcon icon={faExclamation} />
                                </div>
                            ) : null}

                            {data.data == undefined || data.data.linked == false ? (
                                <div className="node-invalid" style={{ right: `${data.data != undefined && data.data.validated == false ? "10px" : ""}` }}>
                                    <FontAwesomeIcon icon={faLink} />
                                </div>
                            ) : null}
                            <div
                                className="object-icon object-icon-action"
                                style={{ backgroundColor: builder_settings?.actionColor || '#358deb' }}
                            >
                                <FontAwesomeIcon icon={data.node_options.icon} />
                            </div>

                            <div className="object-info object-info-action">{this.renderBlockLabels(data)}</div>
                        </div>
                    </div>

                    <div className="absolute -bottom-[14px] left-0 right-0 flex justify-between px-4">
                        <button
                            onTouchEnd={(e) => {
                                addToMessage("button", id);
                            }}
                            className="text-white border border-rounded-sm hover:!border-blue rounded-sm border-white border-1 !bg-[#2f3136] flex flex-row justify-center !p-[3px]"
                            onClick={() => {
                                addToMessage("button", id);
                            }}
                        >
                            <PlusCircleIcon className="h-4 w-4 mr-1" /> Button
                        </button>
                        <button
                            onTouchEnd={(e) => {
                                addToMessage("menu", id);
                            }}
                            onClick={() => {
                                addToMessage("menu", id);
                            }}
                            className="text-white border border-rounded-sm hover:!border-blue rounded-sm border-white border-1 focus:text-white focus:bg-[#2f3136] hover:!text-white hover:opacity-100 !bg-[#2f3136] flex flex-row justify-center !p-[3px] focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
                        >
                            <PlusCircleIcon className="h-4 w-4 mr-1" /> Menu
                        </button>
                    </div>
                    {this.renderAdvMessageHandles(id, data)}
                </div>
            );
        }

        return (

            <>
                <DefaultHandle
                    nodeType="action"
                    selectedNodeHandleType={handleId}
                    nodeHandleID={nodeHandleID}
                    selectedNodeID={nodeHandleID}
                    selectedHandleType={handleType}
                    id={id}
                    position="top"
                    handleType="target"
                    isValidConnection={actionConnection}
                />

                <div
                    id={id}
                    onTouchStart={(e) => {
                        setSelected(id);
                    }}
                    onClick={(e) => {
                        setSelected(id);
                    }}
                    style={{
                        position: "relative",
                        border: selected == id
                            ? `2px solid ${builder_settings?.selectedColor || '#5865f2'}`
                            : '2px solid transparent'
                    }}
                    className={`action-node ${nodePadding} option-node`}
                >
                    <div className="inner" style={{ display: "flex" }}>
                        {data.data == undefined || data.data.validated == false ? (
                            <div className="node-invalid">
                                <FontAwesomeIcon icon={faExclamation} />
                            </div>
                        ) : null}

                        {data.data == undefined || data.data.linked == false ? (
                            <div className="node-invalid" style={{ right: `${data.data != undefined && data.data.validated == false ? "10px" : ""}` }}>
                                <FontAwesomeIcon icon={faLink} />
                            </div>
                        ) : null}
                        <div
                            className="object-icon object-icon-action"
                            style={{ backgroundColor: builder_settings?.actionColor || '#358deb' }}
                        >
                            <FontAwesomeIcon icon={data.node_options.icon} />
                        </div>

                        <div className="object-info object-info-action">{this.renderBlockLabels(data)}</div>
                    </div>
                </div>

                {this.renderActionHandles(id, data)}
            </>
        );
    }
} 