import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import history from '../../../history';
import PremiumRequiredModal from './PremiumRequiredModal';

export class ModuleSideMenuItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            premiumModal: false,
            premiumMessage: ""
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location !== prevProps.location) {
            if (window.location.pathname == `/dashboard/module/${this.props.id}/${this.props.name.toLowerCase().split(" ").join("")}`) {
                this.setState({ active: true });
            } else {
                this.setState({ active: false });
            }
        }
    }

    checkCustomModuleEnabled = (id) => {
        var enabled = false;
        if (this.props.moduleSettings[id] != undefined && this.props.moduleSettings[id].enabled == true) {
            enabled = true;
        }
        return enabled;
    };

    renderMobileVersion() {
        const isPremiumLocked = this.props.premiumRequired && !this.props.premium;
        return (
            <div
                onClick={() => {
                    if (isPremiumLocked) {
                        this.setState({ premiumModal: true });
                    } else {
                        this.props.changePage(this.props.id, this.props.name.toLowerCase().split(" ").join(""));
                    }
                }}
                className={`flex items-center px-3 py-2 text-sm font-medium rounded-lg cursor-pointer
                    ${this.props.active ? "bg-red-600 text-white" : "text-white"}
                    ${isPremiumLocked ? "opacity-75" : ""}`}
            >
                <div className="flex items-center">
                    <img
                        src={this.props.img}
                        alt={this.props.name}
                        className="w-5 h-5 mr-3 rounded-full object-cover"
                    />
                </div>
                <span className="flex-1">{this.props.name}</span>
                {isPremiumLocked && (
                    <span className="ml-2 px-2 py-0.5 text-xs font-medium text-yellow-300 border border-yellow-300 rounded">
                        Premium
                    </span>
                )}
            </div>
        );
    }

    renderDesktopVersion() {
        return (
            <li className={`dash-sidebar-item ${this.props.premiumRequired == true && this.props.premium == false ? "side-menu-premium" : null}`} onClick={() => {
                if (this.props.premiumRequired == true && this.props.premium == false) {
                    this.setState({ premiumModal: true });
                } else {
                    this.props.changePage(this.props.id, this.props.name.toLowerCase().split(" ").join(""));
                }
            }}>
                <span className={`dash-sidebar-item ${this.props.active ? 'active' : ""}`}>
                    <div className={`${this.checkCustomModuleEnabled(this.props.id) == true ? "dash-sidebar-item-icon-enabled" : "dash-sidebar-item-icon-disabled"}`}>
                        <img src={this.props.img} className="w-[15px] h-[15px] mr-[15px] rounded-full"></img>
                    </div>
                    {this.props.name}
                </span>
            </li>
        );
    }

    render() {
        return (
            <>
                {this.state.premiumModal && (
                    <PremiumRequiredModal
                        open={this.state.premiumModal}
                        close={() => { this.setState({ premiumModal: false }); }}
                        premiumMessage={this.state.premiumMessage}
                    />
                )}
                {this.props.mobile ? this.renderMobileVersion() : this.renderDesktopVersion()}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    moduleSettings: state.data.bot.commands.moduleSettings,
    modules: state.data.modules,
    premium: state.data.premium,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ModuleSideMenuItem);