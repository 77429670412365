import React, { Component } from 'react';
import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faFileImport, faCode, faCodeBranch, faEnvelope, faEye, faHashtag, faMouse, faMousePointer, faPaperPlane, faReply, faReplyAll, faSortNumericDown, faTextHeight, faUser, faUserMinus, faUserPlus, faUsers, faQuestion, faHourglass, faComment, faComments, faCommentSlash, faUserSlash, faUserTimes, faUserFriends, faUsersSlash, faPercent, faGripVertical, faHammer, faTrash, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { setSelected, setBuilderSlotId, setBuilderModuleId, setBotModule, setElements, setBuilderIndex, setBuilderMode } from "../../../actions";


import { setBotSettings } from '../../../actions';
import Dashboard from "../Dashboard";
import CustomEventEditor from '../elements/CustomEventEditor';
import ModuleHeader from '../elements/ModuleHeader';
import SectionHeader from '../elements/SectionHeader';
import SlashCommand from '../elements/SlashCommand';
import LongText from '../inputs/LongText';
import ModuleSelect from '../inputs/ModuleSelect';
import ModuleToggle from '../inputs/ModuleToggle';
import ShortText from '../inputs/ShortText';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import ChannelSelect from '../inputs/ChannelSelect';
import ReactGA from 'react-ga';
import InputSwitcher from '../inputs/InputSwitcher';
import RoleSelect from '../inputs/RoleSelect';
import MultiRoleSelect from '../inputs/MultiRoleSelect';
import MultiChannelSelect from '../inputs/MultiChannelSelect';
import MultiWordAdd from '../inputs/MultiWordAdd';
import CustomColorPicker from '../inputs/CustomColorPicker';
import CustomModuleCommand from '../inputs/CustomModuleCommand';
import CustomSlots from '../inputs/CustomSlots';
import PremiumSection from '../elements/PremiumSection';
import server from '../../../api/server';
import RippleLoader from '../elements/RippleLoader';
import history from '../../../history';
import EmojiSelect from '../inputs/EmojiSelect';
import ModuleShop from '../inputs/ModuleShop';
import buildExistingCommand from '../commandbuilder/buildExistingCommand';


export class CustomModule extends Component {
    constructor(props) {
        super(props);

        this.state = {
            module: {},
            openModal: false,
            beta: false,
            loaded: false
        };
    }
    componentDidMount() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        this.setModule();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.modules.length != this.props.modules.length) {
            this.setModule();
        }

        if (this.state.module.id != this.props.match.params.id) {
            this.setModule();
            // Reload module page

        }
    }

    setModule = async () => {
        var module = this.props.modules.find(m => m.id === this.props.match.params.id);

        if (!module.sections) {
            // Get the module data from the API and set it
            var moduleData = await server.get(`/modules/data/${this.props.match.params.id}`);
            if (moduleData && moduleData.data) {

                this.setState({
                    module: moduleData.data,
                    loaded: true
                });
            } else {
                history.push('/dashboard/modules');
            }
        }

        // if module.beta == true and beta == false, redirect to dashboard home page;
        console.log(module, 'module here');
        if (this.state.module?.beta == true && this.props.beta == false) {
            history.push('/dashboard');
        }
    };

    checkRedundantModules = () => {
        // Status module
        if (this.state.module.id == "42056fd5-1c1d-4f89-8db7-794fe732424c") {
            if (this.props.bot?.status || this.props.bot.commands?.status?.enabled === true) {
                // Remove the status and disable the module
                console.log("DISABLE STATUS MODULE");
                this.props.setBotSettings({
                    value: "",
                    key: "status",
                });

                var status = { ...this.props.bot.commands.status };
                status.enabled = false;
                this.props.setBotModule({
                    module: "status",
                    module_data: status
                });


            }
        } else if (this.state.module.id == "b65529b9-b196-4088-8fef-3eaff0b0c047") {
            // Disable announcements
            // Disable Welcomer if it exists
            // Disable Autorole

            var autorole = { ...this.props.bot.commands.autorole };
            autorole.enabled = false;
            this.props.setBotModule({
                module: "autorole",
                module_data: autorole
            });

            var announcements = { ...this.props.bot.commands.announcements };
            announcements.enabled = false;

            this.props.setBotModule({
                module: "announcements",
                module_data: announcements
            });

            // Check for Welcome Cards in moduleSettings (8025ae63-e267-4314-84f7-86a54a4523ba)
            if (this.props.moduleSettings["8025ae63-e267-4314-84f7-86a54a4523ba"] !== undefined) {
                var settings = { ...this.props.moduleSettings };
                settings["8025ae63-e267-4314-84f7-86a54a4523ba"].enabled = false;
                this.props.setBotModule({
                    module: "moduleSettings",
                    module_data: settings,
                });
            }




        } else if (this.state.module.id == "8de92a0c-1e68-4577-900d-26e2bda7b396") {
            // Disable Autoresponder
            // bot.commands.responder.enabled

            var responder = { ...this.props.bot.commands.responder };
            responder.enabled = false;
            this.props.setBotModule({
                module: "responder",
                module_data: responder
            });

        } else if (this.state.module.id == "6fef2574-418b-47cd-adef-22c9c3e88051") {
            // Translate
            // bot.commands.utils.translate.enabled 

            var utils = { ...this.props.bot.commands.utils };
            utils.translate.enabled = false;
            this.props.setBotModule({
                module: "utils",
                module_data: utils
            });

        } else if (this.state.module.id == "5bda821f-56fe-4318-be20-1d2815a04dc3") {
            var economy = { ...this.props.bot.commands.economy };
            economy.enabled = false;
            this.props.setBotModule({
                module: "economy",
                module_data: economy
            });
        }

        // Weather https://dashboard.botghost.com/dashboard/module/e09631dc-4f8f-4bf7-a02c-27ea0187d78f/economy&minigames
        // bot.commands.utils.weather.enabled
        else if (this.state.module.id == "e09631dc-4f8f-4bf7-a02c-27ea0187d78f") {
            var utils = { ...this.props.bot.commands.utils };
            utils.weather.enabled = false;
            this.props.setBotModule({
                module: "utils",
                module_data: utils
            });
        }

        // Tickets 4393a6b8-0282-47fe-a7a9-a58584943e82
        // bot.comamnds
        // Tickets 4393a6b8-0282-47fe-a7a9-a58584943e82
        // bot.commands.tickets.enabled

        else if (this.state.module.id == "4393a6b8-0282-47fe-a7a9-a58584943e82") {
            var tickets = { ...this.props.bot.commands.tickets };
            tickets.enabled = false;
            this.props.setBotModule({
                module: "tickets",
                module_data: tickets
            });
        }


        // Moderation (ce84a76f-f0cc-42ce-8ebb-3790db06e943)

        // bot.commands.moderation.enabled
        else if (this.state.module.id == "ce84a76f-f0cc-42ce-8ebb-3790db06e943") {
            var moderation = { ...this.props.bot.commands.moderation };
            moderation.enabled = false;
            this.props.setBotModule({
                module: "moderation",
                module_data: moderation
            });
        }


        return;
    };

    renderSections = () => {
        var sections = [];
        var moduleSections = this.state.module.sections;
        if (this.props.moduleSettings[this.state.module.id] !== undefined) {
            moduleSections = this.props.moduleSettings[this.state.module.id].sections;
            // Check if the premium of this.props.moduleSettings[this.state.module.id] is different from this.state.module.premium
            if (this.props.moduleSettings[this.state.module.id].premium !== this.state.module.premium && this.state.module.premium == true) {
                console.log("DIFFERENT IN PREMIUM", this.props.moduleSettings[this.state.module.id].premium, this.state.module.premium);
                var module = { ...this.state.module };
                module.premium = this.props.moduleSettings[this.state.module.id].premium ? true : false;
                this.setState({ module: module });
            }
        }
        moduleSections.forEach(section => {
            var passed = true;
            if (section.module_section_id && this.props.moduleSettings[this.state.module.id]?.commands) {
                var commands = this.props.moduleSettings[this.state.module.id].commands;
                // console.log(commands, 'MODULE COMMANDS');
                commands.forEach(command => {
                    if (command.module_section_id === section.module_section_id && command.enabled == false) {
                        // console.log("MODULE COMMANDS 2");
                        passed = false;
                    }
                });

            }
            if (!passed) return;

            var sectionInputs = [];

            sectionInputs.push(
                section.inputs.map(input => {
                    var value = input.defaultValue;
                    if (this.props.moduleSettings[this.state.module.id] !== undefined && this.props.moduleSettings[this.state.module.id].settings[input.id] != undefined) {
                        // console.log("DONT SET VAUE SHJORT TEXT");
                        value = this.props.moduleSettings[this.state.module.id].settings[input.id].value;
                    }

                    // Check for SHOW object in input
                    if (input.show) {
                        var show = input.show;
                        var showConditions = [];

                        // If show is an array, add them all to showConditions, else just add the show object
                        if (Array.isArray(show)) {
                            showConditions = show;
                        } else {
                            showConditions.push(show);
                        }

                        var passed = true;
                        // Go through all the show conditions and check if they are met
                        for (var i = 0; i < showConditions.length; i++) {
                            var show = showConditions[i];
                            // Check if the show id is in the module settings
                            if (this.props.moduleSettings[this.state.module.id] !== undefined) {
                                if (this.props.moduleSettings[this.state.module.id].settings[show.id] !== undefined) {
                                    for (var key in this.props.moduleSettings[this.state.module.id].settings) {
                                        var showValue = show.value;

                                        if (typeof showValue == "object") {
                                            if (show.id == key && !showValue.includes(this.props.moduleSettings[this.state.module.id].settings[key].value)) {
                                                passed = false;
                                            }
                                        } else {
                                            if (show.id == key && this.props.moduleSettings[this.state.module.id].settings[key].value != show.value) {
                                                passed = false;
                                            }
                                        }

                                    }
                                    if (!passed) return;
                                }

                            } else {
                                // Go through all the inputs of each section. Find the show id and check if its default value is equal to the show value
                                var sectionInputs = this.state.module.sections.find(section => section.inputs.find(input => input.id == show.id));

                                if (sectionInputs) {
                                    var showInput = sectionInputs.inputs.find(input => input.id == show.id);
                                    if (showInput.defaultValue != show.value) {
                                        passed = false;
                                    } else {
                                        // passed = true;

                                    }
                                }
                            }

                        }

                        console.log(passed, 'SHOW CONDITIONS PASSED');


                        // if (this.props.moduleSettings[this.state.module.id]?.settings[show.id] !== undefined) {
                        //     for (var key in this.props.moduleSettings[this.state.module.id].settings) {
                        //         var showValue = show.value;

                        //         if (typeof showValue == "object") {
                        //             if (show.id == key && showValue.includes(this.props.moduleSettings[this.state.module.id].settings[key].value)) {
                        //                 passed = true;
                        //             }
                        //         } else {
                        //             if (show.id == key && this.props.moduleSettings[this.state.module.id].settings[key].value == show.value) {
                        //                 passed = true;
                        //             }
                        //         }

                        //     }
                        if (!passed) return;
                        // } else {
                        //     // Go through all the inputs of each section. Find the show id and check if its default value is equal to the show value
                        //     var sectionInputs = this.state.module.sections.find(section => section.inputs.find(input => input.id == show.id));

                        //     if (sectionInputs) {
                        //         var showInput = sectionInputs.inputs.find(input => input.id == show.id);
                        //         if (showInput.defaultValue != show.value) {
                        //             return;
                        //         } else {
                        //             passed = true;

                        //         }
                        //     }
                        //     if (!passed) return;
                        // }

                    }
                    switch (input.type) {
                        case "short":
                            return <ShortText value={value} settings={input} change={(value) => {

                                var settings = { ...this.props.moduleSettings };
                                settings[this.state.module.id].settings[input.id].value = value;
                                this.props.setBotModule({
                                    module: "moduleSettings",
                                    module_data: settings,
                                });
                            }} />;
                        case "long":
                            return <LongText value={value} settings={input} change={(value) => {
                                var settings = { ...this.props.moduleSettings };
                                settings[this.state.module.id].settings[input.id].value = value;
                                this.props.setBotModule({
                                    module: "moduleSettings",
                                    module_data: settings,
                                });
                            }} />;
                        case "select":
                            return <ModuleSelect value={value} settings={input} change={(value) => {
                                var settings = { ...this.props.moduleSettings };
                                settings[this.state.module.id].settings[input.id].value = value;
                                this.props.setBotModule({
                                    module: "moduleSettings",
                                    module_data: settings,
                                });
                            }} />;
                        case "toggle":
                            return <ModuleToggle change={(value) => {
                                var settings = { ...this.props.moduleSettings };
                                settings[this.state.module.id].settings[input.id].value = value;
                                this.props.setBotModule({
                                    module: "moduleSettings",
                                    module_data: settings,
                                });
                            }} value={value} settings={input} />;
                        case "channel_select":
                            return <ChannelSelect change={(value) => {
                                var settings = { ...this.props.moduleSettings };
                                settings[this.state.module.id].settings[input.id].value = value;
                                this.props.setBotModule({
                                    module: "moduleSettings",
                                    module_data: settings,
                                });
                            }} value={value} settings={input} />;
                        case "role_select":
                            return <RoleSelect change={(value) => {
                                var settings = { ...this.props.moduleSettings };
                                settings[this.state.module.id].settings[input.id].value = value;
                                this.props.setBotModule({
                                    module: "moduleSettings",
                                    module_data: settings,
                                });
                            }} value={value} settings={input} />;
                        case "emoji_input":
                            return <EmojiSelect value={value} settings={input}
                                change={(value) => {
                                    var settings = { ...this.props.moduleSettings };
                                    settings[this.state.module.id].settings[input.id].value = value;
                                    this.props.setBotModule({
                                        module: "moduleSettings",
                                        module_data: settings,
                                    });
                                }}

                            />;

                        case "input_switch":
                            return <InputSwitcher value={value || { text: "" }} settings={input}
                                change={(value) => {
                                    var settings = { ...this.props.moduleSettings };
                                    settings[this.state.module.id].settings[input.id].value = value;
                                    this.props.setBotModule({
                                        module: "moduleSettings",
                                        module_data: settings,
                                    });
                                }}

                            />;
                        case "multi_role_select":
                            return <MultiRoleSelect value={value} settings={input}
                                change={(value) => {
                                    var settings = { ...this.props.moduleSettings };
                                    settings[this.state.module.id].settings[input.id].value = value;
                                    this.props.setBotModule({
                                        module: "moduleSettings",
                                        module_data: settings,
                                    });
                                }}

                            />;

                        case "multi_channel_select":
                            return <MultiChannelSelect value={value} settings={input}
                                change={(value) => {
                                    var settings = { ...this.props.moduleSettings };
                                    settings[this.state.module.id].settings[input.id].value = value;
                                    this.props.setBotModule({
                                        module: "moduleSettings",
                                        module_data: settings,
                                    });
                                }}

                            />;

                        case "word_add_input":
                            return <MultiWordAdd value={value} settings={input}
                                change={(value) => {
                                    var settings = { ...this.props.moduleSettings };
                                    settings[this.state.module.id].settings[input.id].value = value;
                                    this.props.setBotModule({
                                        module: "moduleSettings",
                                        module_data: settings,
                                    });
                                }}

                            />;

                        case "color":
                            return <CustomColorPicker value={value} settings={input}
                                change={(value) => {
                                    var settings = { ...this.props.moduleSettings };
                                    settings[this.state.module.id].settings[input.id].value = value;
                                    this.props.setBotModule({
                                        module: "moduleSettings",
                                        module_data: settings,
                                    });
                                }}

                            />;

                        case "shop":
                            console.log("SHOP VALUE", value, input, 'SHOP VALUE');
                            return <ModuleShop onChange={(items) => {
                                var settings = { ...this.props.moduleSettings };
                                settings[this.state.module.id].settings[input.id].value = items;
                                this.props.setBotModule({
                                    module: "moduleSettings",
                                    module_data: settings,
                                });
                            }} value={value} settings={input} />;
                        case "slot":
                            return <CustomSlots value={value} settings={input}
                                addSlot={(slot, index) => {
                                    // console.log('value', slot, index);
                                    var settings = { ...this.props.moduleSettings };
                                    if (input.slot_type == "event") {
                                        var slot_event = { ...input.slot_event };
                                        slot_event.slot_id = slot.slot_id;
                                        slot_event.input_id = input.id;
                                        slot_event.name = `${input.individual_slot_name} #${settings[this.state.module.id].settings[input.id].value.length + 1}`;


                                        if (index == "new") {
                                            settings[this.state.module.id].events.push(slot_event);

                                            // console.log(slot_event, 'SLOT EVENT');
                                            if ('custom_variables' in slot_event) {
                                                var custom_variables = slot_event.custom_variables;
                                                var existing_variables = { ...this.props.bot.commands.variables };

                                                custom_variables.forEach((variable) => {
                                                    var variable_settings = existing_variables.variables.find((v) => v.reference == variable.reference);
                                                    if (!variable_settings && variable) {
                                                        existing_variables.variables.push(variable);
                                                    } else {
                                                        // Find index and update
                                                        var index = existing_variables.variables.findIndex((v) => v.reference == variable.reference);
                                                        existing_variables.variables[index] = variable;

                                                    }
                                                });
                                                this.props.setBotModule(
                                                    {
                                                        module: "variables",
                                                        module_data: existing_variables
                                                    }
                                                );
                                            }


                                            if (slot_event.type == "timedEvent") {
                                                console.log("TIMED EVENT INSIDE");
                                                var timed_events = { ...this.props.bot.commands.timed_events };
                                                var existing_timed_event = timed_events.events.find((e) => e.id == slot_event.timer_id);
                                                if (!existing_timed_event && this.state.module.timed_events) {
                                                    var timed_event = this.state.module.timed_events.find((e) => e.id == slot_event.timer_id);
                                                    if (timed_event) {
                                                        timed_events.events.push(timed_event);
                                                        this.props.setBotModule(
                                                            {
                                                                module: "timed_events",
                                                                module_data: timed_events
                                                            }

                                                        );
                                                    }

                                                }

                                            }
                                            settings[this.state.module.id].settings[input.id].value.push({
                                                settings: slot,
                                                // event: slot_event,
                                            });
                                        } else {
                                            settings[this.state.module.id].settings[input.id].value[index] = {
                                                settings: slot,
                                                // event: slot_event
                                            };
                                        }
                                    } else if (input.slot_type == "value") {
                                        if (index == "new") {
                                            settings[this.state.module.id].settings[input.id].value.push({
                                                settings: slot,
                                            });
                                        } else {
                                            settings[this.state.module.id].settings[input.id].value[index] = {
                                                settings: slot,
                                            };
                                        }
                                    }

                                    // Go through settings and remove .event if it exists

                                    settings[this.state.module.id].settings[input.id].value.forEach((slot, index) => {
                                        if (slot.event) {
                                            delete slot.event;
                                        }
                                    });

                                    this.props.setBotModule({
                                        module: "moduleSettings",
                                        module_data: settings,
                                    });



                                }}

                                deleteSlot={(index) => {
                                    // console.log('value', index);
                                    var settings = { ...this.props.moduleSettings };
                                    // Find corrosponding event
                                    if (input.slot_type == "event") {
                                        var events = settings[this.state.module.id].events;
                                        var event_index = events.findIndex((event) => {
                                            return event.slot_id == settings[this.state.module.id].settings[input.id].value[index].settings?.slot_id;
                                        });

                                        if (event_index !== -1) {
                                            settings[this.state.module.id].events.splice(event_index, 1);
                                        }

                                    }


                                    settings[this.state.module.id].settings[input.id].value.splice(index, 1);
                                    this.props.setBotModule({
                                        module: "moduleSettings",
                                        module_data: settings,
                                    });
                                }}

                            />;
                        default:
                            return <ShortText value={value} settings={input} />;
                    }
                })
            );

            var inputs = sectionInputs[0];

            inputs.forEach((input, index) => {
                if (!input) {
                    inputs.splice(index, 1);
                }
            });

            // console.log('Inputs', inputs);




            if (inputs.length > 0) {
                sections.push(
                    <PremiumSection premiumRequired={this.state.module.premium || section.premium == true ? true : false}>
                        <SectionHeader docs={section.help ? true : false} docs_link={section.help ? section.help : false} pretitle={section.description} title={section.title} premiumRequired={section.premium} module={true} />
                        {inputs}
                    </PremiumSection>
                );
            }

        });

        return sections;
    };

    commandBuilder = async (mode) => {

        var data = {
            title: '',
            description: '',
            data: {
                ephemeral: false,
                permissions: {
                    allowed_roles: [{ name: '@everyone', id: 'everyone' }],
                    banned_roles: [],
                    banned_channels: [],
                    banned_users: [],
                    required_permissions: []
                },
                cooldown: {
                    type: "disabled",
                    interval: 1,
                    interval_type: "minutes"
                },
                handleErrors: true,
                errors: [],
                addedModule: true
            }
        };

        var components = {
            Guild: true,
            Channel: true,
            User: true,
            Member: true,
            Interaction: true,
        };

        if (mode == 'event') {
            data = {
                event: true,
                enabled: true,
                data: {
                    name: "",
                    type: "",
                    addedModule: true
                }
            };
        }

        var elements = [
            {
                id: 'root',
                type: 'root',

                data: data,
                // target:"3",
                draggable: false,
                position: { x: 250, y: 250 },
            },
        ];

        if (mode != "event") {

            var errorElements = await buildExistingCommand({
                options: [],
                actions: [{

                    nodeID: "action_3_6d1a_9853",
                    embed: {
                        color: "#FF0000",
                        description: "{error_reason}",
                        title: ":x: {error_message}",
                        footer: "{error_block}"
                    },
                    emojis: [],
                    target: { reply: true },
                    type: "embed"
                }
                ]
            }, 'command', true);
            elements.push(...errorElements);
        } else {
            elements.push({
                id: "error_handler",
                type: "error",
                position: {
                    x: 850,
                    y: 0
                },
                draggable: false,
                data: {
                    node_options: {
                        title: "Error Handler",
                        description: "Handle errors that occur during the event execution",
                        icon: faExclamationTriangle
                    },
                    data: {}
                }
            });
        }
        // console.log(elements, 'ELEMENTS ', this.props.mode);
        this.props.setElements(elements);
        this.props.setBuilderMode({
            mode: mode == "event" ? "event" : "command",
            components: components
        });
        this.props.setSelected(null);
        var index = 0;
        if (module == 'event') {
            index = this.props.moduleSettings[this.state.module.id].events.length + 1;
        } else {
            index = this.props.moduleSettings[this.state.module.id].commands.length + 1;
        }
        this.props.setBuilderIndex(index);
        this.props.setBuilderModuleId(this.state.module.id);
        history.push("/dashboard/builder");
    };

    renderAddCommand = (mode) => {
        return (
            <div className='col-xl-6'>

                <div
                    className="command"
                    onClick={(e) => {
                        this.commandBuilder(mode);
                    }}
                >
                    <div
                        className="command-info"
                        onClick={(e) => {
                            this.commandBuilder(mode);
                        }}
                    >
                        <h3
                            onClick={(e) => {
                                this.commandBuilder(mode);
                            }}
                        >
                            Add {mode == "event" ? "Event" : "Command"}
                        </h3>
                        <span
                            onClick={(e) => {
                                this.commandBuilder(mode);
                            }}
                        >
                            This {mode == "event" ? "event" : "command"} will have access to all the variables and settings of this module.
                        </span>
                    </div>

                    <div className="command-options justify-center" onClick={(e) => {
                        e.stopPropagation();
                    }}>




                        <div className="command-option">
                            <button className="btn btn-red" onClick={(e) => { this.commandBuilder(mode); }}>Add</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderCommands = () => {
        var commands = [];
        if (this.props.moduleSettings[this.state.module.id] !== undefined) {
            // console.log(this.props.moduleSettings[this.state.module.id].commands, 'HERE');
            this.props.moduleSettings[this.state.module.id].commands.forEach((command, index) => {
                if (command.event || !command.name || !command.description) {
                    return;
                }

                //   filter = filter.split(" ").join("-");
                //   if (this.state.filter == "" || (name.includes(filter) || description.includes(filter))) {
                // commands.push(
                //     <SlashCommand module={true} command={command} index={index}></SlashCommand>
                // );


                commands.push(
                    <CustomModuleCommand
                        deleteCommand={() => {
                            var settings = { ...this.props.moduleSettings };
                            settings[this.state.module.id].commands.splice(index, 1);
                            this.props.setBotModule({
                                module: "moduleSettings",
                                module_data: settings,
                            });

                        }}
                        custom_module={true} toggleCommand={(value) => {
                            var settings = { ...this.props.moduleSettings };
                            settings[this.state.module.id].commands[index].enabled = value;
                            this.props.setBotModule({
                                module: "moduleSettings",
                                module_data: settings,
                            });
                        }} edit={true} command={command} mode={"command"} index={index} />
                );
                //   }
                // commands.push(
                //     <SlashCommand command={command} index={index}></SlashCommand>
                // );
            });
        }
        else if (this.state.module != undefined) {
            this.state.module.commands.forEach((command, index) => {
                if (command.event || !command.name || !command.description) {
                    return;
                }

                //   filter = filter.split(" ").join("-");
                //   if (this.state.filter == "" || (name.includes(filter) || description.includes(filter))) {
                commands.push(
                    <CustomModuleCommand deleteCommand={() => {
                        var settings = { ...this.props.moduleSettings };
                        settings[this.state.module.id].commands.splice(index, 1);
                        this.props.setBotModule({
                            module: "moduleSettings",
                            module_data: settings,
                        });
                    }} custom_module={true} toggleCommand={(value) => {
                        var settings = { ...this.props.moduleSettings };
                        settings[this.state.module.id].commands[index].enabled = value;
                        this.props.setBotModule({
                            module: "moduleSettings",
                            module_data: settings,
                        });
                    }} edit={true} command={command} mode={"command"} index={index} />
                );
                //   }
                // commands.push(
                //   <SlashCommand command={command} index={index}></SlashCommand>
                // );
            });
        }
        if (commands.length > 0 || (this.state.module?.allowAddCommands && this.props.premium)) {
            return (<PremiumSection premiumRequired={this.state.module.premium ? true : false}>
                {/* <SectionHeader pretitle={`${this.state.module.name}`} title="Commands" /> */}
                <div>
                    <p class="section-pretitle">{this.state.module.name} </p>
                    <div className='flex items-center'>
                        <h2 class="section-title">Commands</h2>

                    </div>
                </div>

                <div className="row">
                    {commands}

                    {this.state.module?.allowAddCommands && this.props.premium ? this.renderAddCommand() : null}

                </div>
            </PremiumSection>);
        } else {
            return null;
        }

    };

    renderEvents = () => {
        var events = [];
        if (this.props.moduleSettings[this.state.module.id] !== undefined) {
            // console.log(this.props.moduleSettings[this.state.module.id].events, 'HERE');
            this.props.moduleSettings[this.state.module.id].events.forEach((command, index) => {


                //   filter = filter.split(" ").join("-");
                //   if (this.state.filter == "" || (name.includes(filter) || description.includes(filter))) {
                events.push(
                    <CustomModuleCommand deleteCommand={() => {
                        var settings = { ...this.props.moduleSettings };
                        settings[this.state.module.id].events.splice(index, 1);
                        this.props.setBotModule({
                            module: "moduleSettings",
                            module_data: settings,
                        });
                    }} custom_module={true} toggleCommand={(value) => {
                        var settings = { ...this.props.moduleSettings };
                        settings[this.state.module.id].events[index].enabled = value;
                        this.props.setBotModule({
                            module: "moduleSettings",
                            module_data: settings,
                        });
                    }} edit={true} command={command} mode={"event"} index={index} />
                );
                //   }
                // commands.push(
                //   <SlashCommand command={command} index={index}></SlashCommand>
                // );
            });
        }
        else if (this.state.module != undefined) {
            this.state.module.events.forEach((command, index) => {

                //   filter = filter.split(" ").join("-");
                //   if (this.state.filter == "" || (name.includes(filter) || description.includes(filter))) {
                events.push(
                    <CustomModuleCommand deleteCommand={() => {
                        var settings = { ...this.props.moduleSettings };
                        settings[this.state.module.id].events.splice(index, 1);
                        this.props.setBotModule({
                            module: "moduleSettings",
                            module_data: settings,
                        });
                    }} custom_module={true} toggleCommand={(value) => {
                        var settings = { ...this.props.moduleSettings };
                        settings[this.state.module.id].events[index].enabled = value;
                        this.props.setBotModule({
                            module: "moduleSettings",
                            module_data: settings,
                        });
                    }} edit={true} command={command} mode={"event"} index={index} />
                );
                //   }
                // commands.push(
                //   <SlashCommand command={command} index={index}></SlashCommand>
                // );
            });
        }
        if (events.length > 0 || (this.state.module?.allowAddEvents && this.props.premium)) {
            return (<PremiumSection premiumRequired={this.state.module.premium ? true : false}>
                <SectionHeader pretitle={`${this.state.module.name}`} title="Events" />

                <div className="row">
                    {events}
                    {this.state.module?.allowAddEvents && this.props.premium ? this.renderAddCommand('event') : null}
                </div>
            </PremiumSection>
            );
        } else {
            return null;
        }

    };

    hasPremiumContent = () => {
        const moduleId = this.state.module.id;
        const settings = this.props.moduleSettings[moduleId];

        // Return false if module isn't enabled or settings don't exist
        if (!settings?.enabled) {
            return false;
        }

        // Check premium sections and their inputs
        const hasActivePremiumInputs = this.state.module.sections.some(section => {
            if (section.premium) {
                // Check if any inputs in premium section are different from defaults
                return section.inputs.some(input => {
                    const currentValue = settings.settings[input.id]?.value;
                    const defaultValue = input.defaultValue !== undefined ?
                        input.defaultValue :
                        (input.type.includes("multi") || input.type === "slot" ? [] : "");

                    return JSON.stringify(currentValue) !== JSON.stringify(defaultValue);
                });
            } else {
                // Check individual premium inputs
                return section.inputs.some(input => {
                    if (!input.premium) return false;

                    const currentValue = settings.settings[input.id]?.value;
                    const defaultValue = input.defaultValue !== undefined ?
                        input.defaultValue :
                        (input.type.includes("multi") || input.type === "slot" ? [] : "");

                    return JSON.stringify(currentValue) !== JSON.stringify(defaultValue);
                });
            }
        });

        // Check premium commands
        const hasActivePremiumCommands = settings.commands?.some(command =>
            command.premium && command.enabled
        );

        // Check premium events
        const hasActivePremiumEvents = settings.events?.some(event =>
            event.premium && event.enabled
        );

        return hasActivePremiumInputs || hasActivePremiumCommands || hasActivePremiumEvents;
    };

    render() {
        if (this.state.loaded === false) return (<Dashboard>
            <div className='h-full flex items-center justify-center w-full'>
                <RippleLoader></RippleLoader>
            </div>
        </Dashboard>);
        return (
            <Dashboard>
                <Transition.Root show={this.state.openModal} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={() => {
                        this.setState({ openModal: false });
                    }}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-[#0000008a] transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-lightGray px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                        <div className="sm:flex sm:items-start">
                                            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red sm:mx-0 sm:h-10 sm:w-10">
                                                <ExclamationTriangleIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                            </div>
                                            <div className="text-left ml-2">
                                                <Dialog.Title as="h3" className="text-xl text-white font-bold leading-6">
                                                    Update Module
                                                </Dialog.Title>
                                                <div className="mt-2">
                                                    <p className="text-muted mb-3 font-semibold">
                                                        Are you sure you wish to update this module? Doing so will overwrite any changes you have made to associated commands and events.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">

                                            <button
                                                type="button"
                                                className="inline-flex w-full justify-center rounded-md border border-transparent bg-red px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                                onClick={() => {
                                                    // Replace sections
                                                    // Go through inputs of sections and add them to moduleSettings if they don't exist

                                                    // Replace commands/events
                                                    var custom_variables = [];

                                                    var moduleSettings = this.props.moduleSettings[this.state.module.id].settings;
                                                    this.state.module.sections.forEach(section => {
                                                        section.inputs.forEach(input => {
                                                            if (moduleSettings[input.id] === undefined) {
                                                                moduleSettings[input.id] = {
                                                                    value: input.defaultValue != undefined ? input.defaultValue : "",
                                                                    id: input.id,
                                                                    name: input.name,
                                                                    type: input.type,
                                                                };
                                                            }
                                                        });
                                                    });

                                                    var settings = { ...this.props.moduleSettings };

                                                    var events = [...this.state.module.events];

                                                    var existing_commands = [];
                                                    var existing_events = [];

                                                    // Go through and if command/event module_edited == true add it to the existing_commands/events
                                                    this.props.moduleSettings[this.state.module.id].commands.forEach(command => {
                                                        if (command.module_edited) {
                                                            command.enabled = false;
                                                            existing_commands.push(command);
                                                        }
                                                    });

                                                    this.props.moduleSettings[this.state.module.id].events.forEach(event => {
                                                        if (event.module_edited && !event.slot_id) {
                                                            event.enabled = false;
                                                            existing_events.push(event);
                                                        }
                                                    });

                                                    console.log(existing_commands, existing_events, 'EXISTING COMMANDS AND EVENTS');


                                                    if (events.length > 0) {
                                                        events.forEach(event => {
                                                            if ('custom_variables' in event) {
                                                                custom_variables = [...custom_variables, ...event.custom_variables];
                                                                // var custom_variables = event.custom_variables;

                                                                // var existing_variables = { ...this.props.bot.commands.variables };

                                                                // custom_variables.forEach((variable) => {
                                                                //     var variable_settings = existing_variables.variables.find((v) => v.reference == variable.reference);
                                                                //     if (!variable_settings && variable) {
                                                                //         existing_variables.variables.push(variable);
                                                                //     }
                                                                // });
                                                                // this.props.setBotModule(
                                                                //     {
                                                                //         module: "variables",
                                                                //         module_data: existing_variables
                                                                //     }
                                                                // );
                                                            }
                                                        });
                                                    }

                                                    if (this.state.module.commands.length > 0) {
                                                        this.state.module.commands.forEach(command => {
                                                            if ('custom_variables' in command) {
                                                                custom_variables = [...custom_variables, ...command.custom_variables];
                                                                // var custom_variables = command.custom_variables;
                                                                // var existing_variables = { ...this.props.bot.commands.variables };

                                                                // custom_variables.forEach((variable) => {
                                                                //     var variable_settings = existing_variables.variables.find((v) => v.reference == variable.reference);
                                                                //     if (!variable_settings && variable) {
                                                                //         existing_variables.variables.push(variable);
                                                                //     }
                                                                // });
                                                                // this.props.setBotModule(
                                                                //     {
                                                                //         module: "variables",
                                                                //         module_data: existing_variables
                                                                //     }
                                                                // );
                                                            }
                                                        });
                                                    }

                                                    var moduleEvents = this.props.moduleSettings[this.state.module.id].events;

                                                    moduleEvents.forEach(event => {
                                                        if (event.slot_id) {
                                                            // Find the slot input;
                                                            var slotSection = this.state.module.sections.find(section => section.inputs.find(input => input.id == event.input_id));
                                                            if (!slotSection) return;
                                                            var slotInput = slotSection.inputs.find(input => input.id == event.input_id);
                                                            if (slotInput) {
                                                                var slot_event = slotInput.slot_event;
                                                                events.push({
                                                                    ...slotInput.slot_event,
                                                                    slot_id: event.slot_id,
                                                                    input_id: event.input_id,
                                                                    name: event.name,

                                                                });
                                                            }
                                                        }
                                                    });

                                                    var commands = [...existing_commands, ...this.state.module.commands];
                                                    events = [...existing_events, ...events];

                                                    settings[this.state.module.id] = {
                                                        enabled: true,
                                                        settings: moduleSettings,
                                                        sections: this.state.module.sections,
                                                        version: this.state.module.version,
                                                        commands: commands,
                                                        events: events,
                                                        beta_version: false,
                                                    };
                                                    this.props.setBotModule({
                                                        module: "moduleSettings",
                                                        module_data: settings,
                                                    });

                                                    // Go through custom_variables
                                                    var existing_variables = { ...this.props.bot.commands.variables };
                                                    var vars_modified = false;
                                                    custom_variables.forEach(custom_variable => {
                                                        var variable_settings = existing_variables.variables.find((v) => v.reference == custom_variable.reference);
                                                        if (!variable_settings && custom_variable) {
                                                            existing_variables.variables.push(custom_variable);
                                                            vars_modified = true;
                                                        } else {
                                                            // update defaultValue
                                                            // Find index
                                                            var index = existing_variables.variables.findIndex((v) => v.reference == custom_variable.reference);
                                                            existing_variables.variables[index] = custom_variable;
                                                            vars_modified = true;
                                                        }

                                                    });

                                                    // Go through this.state.variables
                                                    this.state.module.variables.forEach(variable => {
                                                        var variable_settings = existing_variables.variables.find((v) => v.reference == variable.reference);
                                                        if (!variable_settings && variable) {
                                                            existing_variables.variables.push(variable);
                                                            vars_modified = true;
                                                        } else {
                                                            // update defaultValue
                                                            // Find index
                                                            var index = existing_variables.variables.findIndex((v) => v.reference == variable.reference);
                                                            existing_variables.variables[index] = variable;
                                                            vars_modified = true;
                                                        }

                                                    });

                                                    if (vars_modified) {
                                                        this.props.setBotModule(
                                                            {
                                                                module: "variables",
                                                                module_data: existing_variables
                                                            }
                                                        );
                                                    }

                                                    this.setState({ openModal: false });
                                                }}
                                            >
                                                Update Module
                                            </button>
                                            <button
                                                type="button"
                                                className=" inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                                                onClick={() => this.setState({ openModal: false })}
                                            >
                                                Cancel
                                            </button>

                                            {/* <a
                                                type="button"
                                                target="_blank"
                                                href={`https://botghost.com/market/${this.props.mode}/${this.props.item.market_id}`}
                                                className="mr-auto inline-flex w-full justify-center rounded-md border border-transparent bg-red px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm hover:no-underline hover:opacity-80"

                                            >
                                                View {this.props.mode == "event" ? "Event" : "Command"} Page
                                            </a> */}
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>


                <ModuleHeader
                    hasPremiumInputs={this.hasPremiumContent()}
                    premiumReset={() => {
                        // Only proceed if this is a premium module and user is not premium
                        if (this.props.premium) {
                            return;
                        }

                        const settings = { ...this.props.moduleSettings };
                        const moduleId = this.state.module.id;

                        // Skip if module settings don't exist
                        if (!settings[moduleId]) {
                            return;
                        }

                        // Go through each section and reset premium inputs/sections
                        this.state.module.sections.forEach(section => {
                            // If entire section is premium, reset all its inputs
                            if (section.premium) {
                                section.inputs.forEach(input => {
                                    settings[moduleId].settings[input.id] = {
                                        ...settings[moduleId].settings[input.id],
                                        value: input.defaultValue !== undefined ? input.defaultValue :
                                            (input.type.includes("multi") || input.type === "slot" ? [] : "")
                                    };
                                });
                            } else {
                                // Otherwise check individual premium inputs
                                section.inputs.forEach(input => {
                                    if (input.premium) {
                                        settings[moduleId].settings[input.id] = {
                                            ...settings[moduleId].settings[input.id],
                                            value: input.defaultValue !== undefined ? input.defaultValue :
                                                (input.type.includes("multi") || input.type === "slot" ? [] : "")
                                        };
                                    }
                                });
                            }
                        });

                        // Reset premium commands
                        if (settings[moduleId].commands) {
                            settings[moduleId].commands = settings[moduleId].commands.map(command => {
                                if (command.premium) {
                                    return {
                                        ...command,
                                        enabled: false
                                    };
                                }
                                return command;
                            });
                        }

                        // Reset premium events
                        if (settings[moduleId].events) {
                            settings[moduleId].events = settings[moduleId].events.map(event => {
                                if (event.premium) {
                                    return {
                                        ...event,
                                        enabled: false
                                    };
                                }
                                return event;
                            });
                        }

                        // Update the module settings
                        this.props.setBotModule({
                            module: "moduleSettings",
                            module_data: settings
                        });
                    }}
                    premiumRequired={this.state.module.premium}
                    description={this.state.module.description}
                    title={this.state.module.name}
                    img={this.state.module.img}
                    showDocs={this.state.module.docs ? true : false}
                    tutorial={this.state.module.docs}
                    // updateAvailable={true}
                    updateAvailable={this.props.moduleSettings[this.state.module.id] && this.props.moduleSettings[this.state.module.id].version < this.state.module.version}
                    updateModule={() => {
                        this.setState({ openModal: true });
                    }}
                    customModule={true}
                    beta={this.state.module.beta}
                    resetModule={() => {
                        var settings = { ...this.props.moduleSettings };


                        delete settings[this.state.module.id];

                        this.props.setBotModule({
                            module: "moduleSettings",
                            module_data: settings,
                        });
                    }}
                    custom={true}
                    showEnabled={true}
                    enabledValue={this.props.moduleSettings[this.state.module.id] !== undefined ? this.props.moduleSettings[this.state.module.id].enabled : false}
                    change={(value) => {
                        // console.log("CHANGED", this.props.moduleSettings);
                        this.checkRedundantModules();
                        if (this.props.moduleSettings[this.state.module.id] === undefined) {
                            var moduleSettings = {};
                            this.state.module.sections.forEach(section => {

                                section.inputs.forEach(input => {
                                    if (input.type.includes("multi")) {

                                        moduleSettings[input.id] = {
                                            value: input.defaultValue != undefined ? input.defaultValue : [],
                                            id: input.id,
                                            name: input.name,
                                            type: input.type,
                                        };
                                    } else if (input.type == "slot") {
                                        moduleSettings[input.id] = {
                                            value: input.defaultValue != undefined ? input.defaultValue : [],
                                            id: input.id,
                                            name: input.name,
                                            type: input.type,
                                            inputs: input.inputs
                                        };
                                    } else {
                                        moduleSettings[input.id] = {
                                            value: input.defaultValue != undefined ? input.defaultValue : "",
                                            id: input.id,
                                            name: input.name,
                                            type: input.type,
                                        };
                                    }

                                });


                            });

                            var settings = { ...this.props.moduleSettings };

                            var custom_variables = [];


                            if (this.state.module.commands.length > 0) {
                                this.state.module.commands.forEach(command => {
                                    if ('custom_variables' in command) {
                                        custom_variables = [...custom_variables, ...command.custom_variables];
                                        // var custom_variables = command.custom_variables;
                                        // var existing_variables = { ...this.props.bot.commands.variables };

                                        // custom_variables.forEach((variable) => {
                                        //     var variable_settings = existing_variables.variables.find((v) => v.reference == variable.reference);
                                        //     if (!variable_settings && variable) {
                                        //         existing_variables.variables.push(variable);
                                        //     }
                                        // });
                                        // this.props.setBotModule(
                                        //     {
                                        //         module: "variables",
                                        //         module_data: existing_variables
                                        //     }
                                        // );
                                    }
                                });
                            }

                            if (this.state.module.events.length > 0) {
                                this.state.module.events.forEach(event => {
                                    if ('custom_variables' in event) {
                                        custom_variables = [...custom_variables, ...event.custom_variables];
                                        // var custom_variables = event.custom_variables;
                                        // var existing_variables = { ...this.props.bot.commands.variables };

                                        // custom_variables.forEach((variable) => {
                                        //     var variable_settings = existing_variables.variables.find((v) => v.reference == variable.reference);
                                        //     if (!variable_settings && variable) {
                                        //         existing_variables.variables.push(variable);
                                        //     }
                                        // });
                                        // this.props.setBotModule(
                                        //     {
                                        //         module: "variables",
                                        //         module_data: existing_variables
                                        //     }
                                        // );
                                    }

                                    if (event.type == "timedEvent") {

                                        var timed_events = { ...this.props.bot.commands.timed_events };
                                        var existing_timed_event = timed_events.events.find((e) => e.id == event.timer_id);
                                        if (!existing_timed_event && this.state.module.timed_events) {
                                            console.log(this.state.module.timed_events);
                                            var timed_event = this.state.module.timed_events.find((e) => e.id == event.timer_id);
                                            console.log(timed_event, 'TIMED EVENT');
                                            if (timed_event) {
                                                timed_events.events.push(timed_event);
                                                this.props.setBotModule(
                                                    {
                                                        module: "timed_events",
                                                        module_data: timed_events
                                                    }

                                                );
                                            }

                                        }

                                    }
                                });
                            }



                            settings[this.state.module.id] = {
                                enabled: true,
                                settings: moduleSettings,
                                version: this.state.module.version,
                                commands: [...this.state.module.commands],
                                sections: [...this.state.module.sections],
                                beta_version: false,
                                events: [...this.state.module.events],
                                premium: this.state.module.premium ? true : false
                            };
                            this.props.setBotModule({
                                module: "moduleSettings",
                                module_data: settings,
                            });

                            var existing_variables = { ...this.props.bot.commands.variables };
                            var vars_modified = false;
                            custom_variables.forEach(custom_variable => {
                                var variable_settings = existing_variables.variables.find((v) => v.reference == custom_variable.reference);
                                if (!variable_settings && custom_variable) {
                                    existing_variables.variables.push(custom_variable);
                                    vars_modified = true;
                                } else {
                                    // update defaultValue
                                    // Find index
                                    var index = existing_variables.variables.findIndex((v) => v.reference == custom_variable.reference);
                                    existing_variables.variables[index] = custom_variable;
                                    vars_modified = true;
                                }

                            });

                            // Go through this.state.variables
                            this.state.module.variables.forEach(variable => {
                                var variable_settings = existing_variables.variables.find((v) => v.reference == variable.reference);
                                if (!variable_settings && variable) {
                                    existing_variables.variables.push(variable);
                                    vars_modified = true;
                                } else {
                                    // update defaultValue
                                    // Find index
                                    var index = existing_variables.variables.findIndex((v) => v.reference == variable.reference);
                                    existing_variables.variables[index] = variable;
                                    vars_modified = true;
                                }

                            });

                            if (vars_modified) {
                                this.props.setBotModule(
                                    {
                                        module: "variables",
                                        module_data: existing_variables
                                    }
                                );
                            }


                        } else {
                            var settings = { ...this.props.moduleSettings };
                            settings[this.state.module.id].enabled = value;

                            this.props.setBotModule({
                                module: "moduleSettings",
                                module_data: settings,
                            });
                        }
                    }}

                ></ModuleHeader>

                <div className={`${(this.props.moduleSettings[this.state.module.id] == undefined || !this.props.moduleSettings[this.state.module.id].enabled) && !(this.props.premium == false && this.state.module.premium == true) ? "pointer-events-none opacity-50" : ""}`}>
                    {this.renderSections()}


                    {this.renderCommands()}


                    {this.renderEvents()}
                </div>

            </Dashboard >
        );
    }
}

const mapStateToProps = (state) => ({
    modules: state.data.modules,
    moduleSettings: state.data.bot.commands.moduleSettings,
    premium: state.data.bot.premium,
    bot: state.data.bot,
    beta: state.data.beta ? true : false
});

const mapDispatchToProps = {
    setBotModule,
    setBotSettings,
    setBuilderIndex,
    setBuilderModuleId,
    setElements,
    setSelected,
    setBuilderSlotId,
    setBuilderMode
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomModule);