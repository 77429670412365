import React, { Component } from "react";
import { connect } from 'react-redux';
import PremiumModal from "./PremiumModal";

import { setQuickStart } from "../../../actions";
import { DocsLink } from "./DocsLink";
import ReactGA from 'react-ga';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport, faExclamation, faQuestionCircle, faVideo } from '@fortawesome/free-solid-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';


class ModuleHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  modalCheck = () => {
    if (this.props.premiumRequired == true && this.props.premium == false && this.props.enabledValue !== true) {
      this.setState({ showModal: true });
    }
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  change = () => {
    this.props.change(!this.props.enabledValue);
    var quick_start = { ...this.props.quick_start };
    quick_start["enable_module"] = true;
    this.props.setQuickStart(quick_start);
  };

  tutorialClick = () => {
    // console.log(this.props);
    ReactGA.initialize('UA-122665050-1');
    ReactGA.event({
      category: "Documentation Click",
      label: this.props.tutorial,
      action: "New Dashboard"
    });
  };

  renderButtons = () => {
    var buttons = [];

    if (this.props.premium == false && this.props.hasPremiumInputs == true && this.props.enabledValue == true) {
      buttons.push(
        <button
          className="px-4 py-2 mr-2 text-sm font-medium text-white bg-[#F87171] hover:bg-[#EF4444] rounded-md transition-colors duration-200"
          onClick={() => { this.props.premiumReset(); }}
        >
          Reset Premium Inputs
        </button>
      );
    }

    if (this.props.customModule) {
      buttons.push(
        <button
          className="px-4 py-2 mr-2 text-sm font-medium text-white bg-[#F87171] hover:bg-[#EF4444] rounded-md transition-colors duration-200"
          onClick={() => { this.props.resetModule(); }}
        >
          Reset to Default
        </button>
      );
    }

    if (this.props.updateAvailable) {
      buttons.push(
        <button
          className="px-4 py-2 mr-2 text-sm font-medium text-white bg-[#F87171] hover:bg-[#EF4444] rounded-md transition-colors duration-200"
          onClick={() => { this.props.updateModule(); }}
        >
          Update Module
        </button>
      );
    }

    if (this.props.tutorial != undefined) {
      buttons.push(
        <a
          onClick={() => { this.tutorialClick(); }}
          className="px-4 py-2 mr-2 text-sm font-medium text-white bg-[#F87171] hover:bg-[#EF4444] rounded-md transition-colors duration-200 inline-flex items-center"
          href={this.props.tutorial}
          target="_blank"
        >
          <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" /> Docs
        </a>
      );
    }

    if (this.props.youtube != undefined) {
      buttons.push(
        <a
          className="px-4 py-2 mr-2 text-sm font-medium text-white bg-[#F87171] hover:bg-[#EF4444] rounded-md transition-colors duration-200 inline-flex items-center"
          href={this.props.youtube}
          target="_blank"
        >
          <FontAwesomeIcon icon={faYoutube} className="mr-2" /> Tutorial
        </a>
      );
    }

    if (this.props.showEnabled != false) {
      buttons.push(
        <button
          disabled={this.props.premiumRequired == true && this.props.premium == false && this.props.enabledValue !== true}
          className={`px-4 py-2 text-sm font-medium rounded-md transition-all duration-200 border-2 ${this.props.enabledValue
            ? 'bg-transparent border-[#EF4444] text-[#EF4444] hover:bg-[#FEE2E2]'
            : 'bg-[#10B981] border-[#10B981] text-white hover:bg-[#059669] hover:border-[#059669] hithere'
            } ${this.props.premiumRequired == true && this.props.premium == false && this.props.enabledValue == false
              ? 'opacity-50 cursor-not-allowed'
              : ''
            }`}
          onClick={(e) => { this.change(); }}
        >
          {this.props.enabledValue ? "Disable" : "Enable"}
        </button>
      );
    }

    return buttons;
  };



  render() {
    return (
      <div>
        <div onClick={() => { this.modalCheck(); }} className={`module-header ${this.props.premium == false && this.props.premiumRequired == true && this.props.enabledValue !== true ? "premium-section" : null}`}>
          <div className="module-header-main">
            <img
              className={`${this.props.custom ? "rounded-full" : ""}`}
              src={this.props.custom ? this.props.img : process.env.PUBLIC_URL + `/images/icons/${this.props.icon}`}
            ></img>
            <div>
              <div className="module-header-text"><h1>{this.props.title}</h1>{this.props.updateAvailable == true ? <span className="bg-[#f45142] text-white text-xs font-bold mr-2 px-2.5 py-1 rounded !opacity-100">Update Available</span> : null} {this.props.beta == true ? <span className="bg-[blue] text-white text-xs font-bold mr-2 px-2.5 py-1 rounded !opacity-100">Beta</span> : null}</div>
              <div className="module-header-description">{this.props.description}</div>
            </div>
          </div>
          <div className="module-header-buttons m-w-100">
            <div className="module-header-action m-w-100 button-group-header">
              {this.renderButtons()}
            </div>
          </div>
        </div>
        <div>
          {this.state.showModal ? <PremiumModal closeModal={this.closeModal}></PremiumModal> : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  premium: state.data.premium
});

const mapDispatchToProps = {
  setQuickStart
};

export default connect(mapStateToProps, mapDispatchToProps)(ModuleHeader);
